
import { useState } from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  .cls-1 {
    fill: #000;
    stroke-width: 0;
  }
  .cls-1-dark {
    fill: #141414;
    stroke-width: 0;
  }
`;
function BGComponent(){

    const [isDark, setIsDark]= useState(false)
    return (
        <StyledSVG id="PATTERNS" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 3283.5 2298.4" style={isDark &&{ backgroundColor:'#000000'}}>
  <defs>

  </defs>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2s.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,349.3c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2,.3.7.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,334.2l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3283.5,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3300.9,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3286.6,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM3284.5,130.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.5,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3270.9,83.7c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM3252.8,68l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3281,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3249.2,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3233.5,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3214,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3280.6,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3295.8,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3285.5,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3275.2,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3254.6,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3244.4,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3234.1,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3223.8,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3213.5,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3306.5,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3273.9,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3307.9,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM3274.2,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3294.4,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3048.4,68.1c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3052.6,85.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3056.9,102.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3061.2,119.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2988.7,141.7c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3039.8,33.9c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3044.1,51c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2970.7,42.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2973.5,53.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2976.3,65.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2979.1,76.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2981.9,87.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2984.7,98.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2987.5,109.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2990.3,121.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2993.1,132.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3186.1,154.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,171h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,187.4h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3167.1,203.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165.6,207.8c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3198.8,121.6h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3192.5,138.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3185.8,108c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3181.6,118.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3177.5,129.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.3,140.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3169.2,151c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165,161.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3160.9,172.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3156.7,183.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3152.6,194.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3087.7,318.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3071.4,311.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3055.1,304.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.8,298.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3104,325.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3123.8,314.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3113.1,310.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3102.5,306c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3091.8,301.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3081.2,297.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3070.5,292.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3059.8,288.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3049.2,283.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.5,279.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,20.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3080.5,54.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,51.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3097.2,10.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3084.5,61.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,76.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0s-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM3063.3,59.6c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3295.7,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3304.6,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3252.4,25.8c-.6,0-1-.4-1-1V3.6c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3244.9,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM3244.3,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3077.3,161.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.7,157.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.3,148c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3085.9,156.3c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3034,163.3c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3025.9,163.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM3025.6,142.2c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.4,107.3c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3250.9,128.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3243.8,133.7c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3213.6,97.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3242.2,141.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3255.1,152.3c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM3246.5,144.6c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3111.6,83.4c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3138.1,70.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.6,79c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3116.2,59.8c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3s1.2-2,2.2-2.5c2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.5-1.9.5ZM3116.2,53c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3117.2,72.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3207.1,72.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3235.8,80c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.8,75.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3228.1,58.6c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM3228.1,51.8c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM3231.4,55h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3231,71c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,123.4c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM2958.3,127c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3081.1,131.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM3091.3,80.3c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3075,104.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3188.3,53c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM3193,8.9l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3201.5,38.6c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2924.5,68l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,21c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2,.3.7.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,5.9l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,14.8c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2968,291.9c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3221.7,224.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187.4,242.5c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181.9,235.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3226,215.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,250.3c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.1,257.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM3170,236.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3143.1,48.7c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,64.9c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.7,71.4c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3140.1,39.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174,79.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,88.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM3178.9,81.9c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3001.5,225.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2983.8,205.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2989.4,223.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6s1,2.1.9,3.2c-.3,2.2-2.1,3.9-4.3,3.9ZM3159.6,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,318.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2977.6,325.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2996.2,314.9c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM2996.2,308.2c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6s1.1.6,1.7.5c.6,0,1.2-.4,1.6-.9.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.3,327.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.6c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3041.4,206.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030,222.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3022.6,216.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030.8,201.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM3029.1,195.3c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3013.1,207.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3146.8,253.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125,236c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3153.1,246.1c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3139.8,230.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.9,1-2.1,1.6-3.4,1.6ZM3139.8,224c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3147.3,241.3c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3190.7,165.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.5,180c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4s-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3199.5,170.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.7,157.4c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.8,1.4-2.3,2.2-3.8,2.2ZM3215.7,150.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9h0c.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3214.3,170.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3109.6,220.8c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM3071.7,187.6c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8s1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3082.9,215.3c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3245.7,217.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM3247.1,165c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3234.8,192.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3002,279.2c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM3010.8,235.8l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3016.2,266.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3003.4,194.5c-1,0-2.1-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.7,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2972.8,174.8l28.7,17.2c.9.5,1.9.7,2.9.4s1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2988,186c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3075,184.3c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2983.4,252.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3272.7,105.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3174.5,303.8c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3107.7,29.1c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3192.2,210.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2916.5,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,3.4c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3214,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,5.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5s.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,3.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,15.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,12.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,5.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM3159.6-6.4c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,4.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,677.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,662.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3283.5,481.9c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3300.9,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3286.6,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM3286.6,455.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.5,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3270.9,412c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM3252.8,396.3l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3281,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3249.2,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1s-1.7,2-1.9,3.3c-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3233.5,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3214,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3280.6,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3295.8,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3285.5,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3275.2,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3254.6,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3244.4,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3234.1,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3223.8,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3213.5,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3306.5,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3273.9,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3307.9,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM3274.2,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3294.4,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3048.4,396.5c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3052.6,413.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3056.9,430.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3061.2,447.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2988.7,470.1c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3039.8,362.3c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3s2.5-2.7,4.3-3.2c.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1s-.5,1-1.1.9c-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3044.1,379.4c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2970.7,371.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2973.5,382.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2976.3,393.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2979.1,404.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2981.9,415.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2984.7,427.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2987.5,438.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2990.3,449.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2993.1,460.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3186.1,482.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,499.3h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,515.7h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3167.1,532.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165.6,536.1c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3198.8,450h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3192.5,466.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3185.8,436.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3181.6,447.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3177.5,457.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.3,468.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3169.2,479.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165,490.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3160.9,500.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3156.7,511.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3152.6,522.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3087.7,646.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3071.4,640c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3055.1,633.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.8,626.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3104,653.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3123.8,643.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3113.1,638.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3102.5,634.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3091.8,629.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3081.2,625.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3070.5,621.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3059.8,616.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3049.2,612.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.5,607.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2s.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3080.5,383.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,380.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3097.2,339.1c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3084.5,390c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,404.8c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM3063.3,388c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3295.7,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3304.6,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3252.4,354.1c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3244.9,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM3244.3,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3077.3,489.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.7,485.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.3,476.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3085.9,484.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3034,491.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3025.9,492.3c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM3025.6,470.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.4,435.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3250.9,456.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3243.8,462.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3213.6,425.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3242.2,469.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3255.1,480.6c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM3246.5,473c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3111.6,411.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3138.1,398.7c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.6,407.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3116.2,388.1c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3s1.2-2,2.2-2.5c2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM3116.2,381.4c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1,1.2-.6,1.6-2,1.1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3117.2,400.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3207.1,401.2c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3235.8,408.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.8,403.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3228.1,386.9c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.5,2-2.3,3.3-4.2,3.3ZM3228.1,380.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0ZM3231.4,383.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3231,399.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,451.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,481.9c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM2958.3,455.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3081.1,459.7c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM3091.3,408.7c-.3,0-.6,0-.9,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3075,433.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3188.3,381.3c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM3193,337.2l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3201.5,367c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2924.5,396.3l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,349.3c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2,.3.7.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,334.2l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1s-1.7,2-1.9,3.3c-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2968,620.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3221.7,552.7c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187.4,570.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181.9,563.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3226,543.6c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,578.7c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.1,585.8c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM3170,565c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3143.1,377.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,393.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.7,399.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3140.1,367.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174,407.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,416.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM3178.9,410.2c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3001.5,553.9c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2983.8,534c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2989.4,551.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,1.2.1,2.2.7,2.9,1.6s1,2.1.9,3.2c-.3,2.2-2.1,3.9-4.3,3.9ZM3159.6,650.3c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,647.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2977.6,653.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2996.2,643.3c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2s1.8-1.5,2.9-1.6c1.2-.1,2.3.2,3.2.9.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2h0c-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM2996.2,636.6c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.3,655.5c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3041.4,534.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030,551.2c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3022.6,544.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030.9,529.6c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM3029.1,523.7c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3013.1,536c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3146.8,582.2c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4s1-.5,1.4,0c2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125,564.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3153.1,574.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3139.8,559c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1h0c.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM3139.8,552.3c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.8,1-.7,2.5.3,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6,0,0,0,0,0,0-.4-.4-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3147.3,569.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3190.7,493.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.5,508.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4s-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3199.5,498.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.7,485.8c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3h0c-.8,1.4-2.3,2.2-3.8,2.2ZM3215.7,479c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.3-.6.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3ZM3218.6,483.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3214.3,498.4c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3109.6,549.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9h0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM3071.7,515.9c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8s1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3082.9,543.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3245.7,545.4c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM3247.1,493.3c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3234.8,520.5c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3002,607.5c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4s.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM3010.8,564.1l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3016.2,594.6c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3003.4,522.8c-1,0-2-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.7,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM2972.8,503.1l28.7,17.2c1.8,1.1,4.1.5,5.2-1.3l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2988,514.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3075,512.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2983.4,580.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3272.7,434.2h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3174.5,632.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3107.7,357.4c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3192.2,538.9c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2916.6,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3214,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6s1,2.1.9,3.2c-.3,2.2-2.1,3.9-4.3,3.9ZM3159.6,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,1006c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2,.3.7.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,990.9l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3283.5,810.2c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3300.9,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3286.6,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM3286.6,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.5,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3270.9,740.4c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM3252.8,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3281,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3249.2,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3233.5,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3214,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3280.6,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3295.8,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3285.5,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3275.2,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3254.6,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3244.4,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3234.1,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3223.8,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3213.5,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3306.5,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3273.9,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3307.9,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM3274.2,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3294.4,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3048.4,724.8c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3052.6,741.9c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3056.9,759c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3061.2,776.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2988.7,798.4c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3039.8,690.6c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3s-.2-3.7.8-5.3c1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.3,1.3,1.1,2.4,2.3,3.1,1.1.7,2.5.9,3.8.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3044.1,707.7c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2970.7,699.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2973.5,710.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2976.3,721.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2979.1,733c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2981.9,744.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2984.7,755.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2987.5,766.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2990.3,777.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2993.1,789c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3186.1,811.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,827.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,844.1h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3167.1,860.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165.6,864.5c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3198.8,778.3h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3192.5,794.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3185.8,764.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3181.6,775.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3177.5,786.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.3,797c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3169.2,807.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165,818.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3160.9,829.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3156.7,840c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3152.6,850.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3087.7,975c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3071.4,968.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3055.1,961.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.8,954.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3104,981.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3123.8,971.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3113.1,967.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3102.5,962.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3091.8,958.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3081.2,953.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3070.5,949.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3059.8,945c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3049.2,940.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.5,936.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3080.5,711.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,708.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3097.2,667.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3084.5,718.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,733.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0s-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM3063.3,716.3c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3295.7,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3304.6,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3252.4,682.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3244.9,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM3244.3,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3077.3,818.1c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.7,813.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.3,804.7c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3085.9,813c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3034,820c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3025.9,820.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM3025.6,798.9c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.4,764c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3250.9,784.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3243.8,790.4c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3213.6,754.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3242.2,798.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3255.1,809c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM3246.5,801.3c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3111.6,740.1c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3138.1,727.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.6,735.7c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3116.2,716.5c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3s1.2-2,2.2-2.5c2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.5-1.9.5ZM3116.2,709.7c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3117.2,728.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3207.1,729.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3235.8,736.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.8,732c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3228.1,715.3c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM3228.1,708.5c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM3231.4,711.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3231,727.7c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,780.1c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,810.2c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2958.3,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3081.1,788.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM3091.3,737c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3075,761.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3188.3,709.7c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM3193,665.6l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3201.5,695.3c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2924.5,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,677.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,662.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2968,948.6c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3221.7,881.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187.4,899.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181.9,891.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3226,872c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,907c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.1,914.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM3170,893.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3143.1,705.4c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,721.6c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.7,728.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3140.1,695.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174,735.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,745.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM3178.9,738.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3001.5,882.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2983.8,862.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2989.4,880.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM3159.6,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,975.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2977.6,981.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2996.2,971.6c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM2996.2,964.9c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM2998.9,969.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.3,983.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3041.4,863c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030,879.5c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3022.6,872.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030.9,858c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.9,1-2,1.4-3.2,1.4ZM3029.1,852h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3013.1,864.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3146.8,910.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125,892.7c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3153.1,902.8c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3139.8,887.4c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1,.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM3139.8,880.7c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6h0c-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3147.3,898c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3190.7,822.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.5,836.7c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3199.5,827.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.7,814.1c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.8,1.4-2.3,2.2-3.8,2.2ZM3215.7,807.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3214.3,826.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3109.6,877.5c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5s-1.1-2.8-.8-4.3l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7s2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM3071.7,844.3c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.2,1,0,2,.5,2.8s1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3082.9,872c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3245.7,873.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM3247.1,821.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3234.8,848.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3002,935.9c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1h0s3.2-3.4,3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM3010.8,892.5l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3016.2,923c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3003.4,851.2c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.7,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2972.8,831.5l28.7,17.2c.9.5,1.9.7,2.9.4s1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2988,842.7c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3075,841c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2983.4,908.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3272.7,762.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3174.5,960.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3107.7,685.8c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3192.2,867.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2916.5,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3214,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,1.2.1,2.2.7,2.9,1.6s1,2.1.9,3.2c-.3,2.2-2.1,3.9-4.3,3.9ZM3159.6,650.3c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3283.5,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3300.9,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3286.6,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM3284.5,1115.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.5,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3270.9,1068.7c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM3252.8,1053l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3281,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3249.2,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3233.5,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3214,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3280.6,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3295.8,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3285.5,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3275.2,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3254.6,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3244.4,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3234.1,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3223.8,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3213.5,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3306.5,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3273.9,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3307.9,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM3274.2,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3294.4,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3048.4,1053.2c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3052.6,1070.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3056.9,1087.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3061.2,1104.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2988.7,1126.8c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3039.8,1019c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3s2.5-2.7,4.3-3.2c.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3044.1,1036.1c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2970.7,1027.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2973.5,1039c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2976.3,1050.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2979.1,1061.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2981.9,1072.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2984.7,1083.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2987.5,1095c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2990.3,1106.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2993.1,1117.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3186.1,1139.6h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,1156h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,1172.4h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3167.1,1188.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165.6,1192.8c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3198.8,1106.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3192.5,1123.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3185.8,1093c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3181.6,1103.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3177.5,1114.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.3,1125.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3169.2,1136.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165,1146.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3160.9,1157.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3156.7,1168.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3152.6,1179.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3087.7,1303.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3071.4,1296.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3055.1,1289.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.8,1283.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3104,1310.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3123.8,1299.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3113.1,1295.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3102.5,1291c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3091.8,1286.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3081.2,1282.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3070.5,1277.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3059.8,1273.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3049.2,1269c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.5,1264.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3080.5,1040c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,1037c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3097.2,995.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3084.5,1046.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,1061.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2s-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM3063.3,1044.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3295.7,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3304.6,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3252.4,1010.8c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3244.9,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM3244.3,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3077.3,1146.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.7,1142.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.3,1133.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3085.9,1141.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3034,1148.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3025.9,1148.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM3025.6,1127.3c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.4,1092.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3250.9,1113.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3243.8,1118.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3213.6,1082.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3242.2,1126.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3255.1,1137.3c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM3246.5,1129.7c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3111.6,1068.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3138.1,1055.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.6,1064.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3116.2,1044.8c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3s1.2-2,2.2-2.5c2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.5-1.9.5ZM3116.2,1038.1c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3117.2,1057.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3207.1,1057.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3235.8,1065c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.8,1060.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3228.1,1043.6c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM3228.1,1036.9c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM3231.4,1040.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3231,1056.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,1108.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM2958.3,1112.1c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3081.1,1116.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM3091.3,1065.4c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3075,1089.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3188.3,1038c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM3193,993.9l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3201.5,1023.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2924.5,1053l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,1006c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2,.3.7.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,990.9l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1s-1-.3-1.1-.9c-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2968,1277c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3221.7,1209.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187.4,1227.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181.9,1220.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3226,1200.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,1235.4c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.1,1242.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM3170,1221.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3143.1,1033.8c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,1050c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.7,1056.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3140.1,1024.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174,1064.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,1073.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM3178.9,1066.9c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3001.5,1210.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2983.8,1190.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2989.4,1208.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,1325.7s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM3159.6,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6s-.1-1.2-.5-1.7c-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,1303.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2977.6,1310.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2996.2,1300c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM2996.2,1293.3c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM2998.9,1297.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.3,1312.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3041.4,1191.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030,1207.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3022.6,1201.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030.8,1186.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM3029.1,1180.4c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3013.1,1192.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3146.8,1238.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125,1221.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3153.1,1231.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3139.8,1215.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.9,1-2.1,1.6-3.4,1.6ZM3139.8,1209c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3147.3,1226.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3190.7,1150.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.5,1165.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3199.5,1155.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.7,1142.5c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.8,1.4-2.3,2.2-3.8,2.2ZM3215.7,1135.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9h0c.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3214.3,1155.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3109.6,1205.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM3071.7,1172.6c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8s1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3082.9,1200.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3245.7,1202.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM3247.1,1150c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3234.8,1177.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3002,1264.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM3010.8,1220.8l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3016.2,1251.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3003.4,1179.5c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.7,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2972.8,1159.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2988,1171.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3075,1169.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2983.4,1237.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3272.7,1090.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3174.5,1288.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3107.7,1014.1c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3192.2,1195.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2916.5,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3214,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM3159.6,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3283.5,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3300.9,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3286.6,1447.2c-1.6,0-3.1-.9-3.9-2.3-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM3286.6,1440.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.5,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3270.9,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-9.4,33.1h0s-1.3,4.5-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM3252.8,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3281,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3249.2,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3233.5,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3214,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3280.6,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3295.8,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3285.5,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3275.2,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3254.6,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3244.4,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3234.1,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3223.8,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3213.5,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3306.5,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3273.9,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3307.9,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM3274.2,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3294.4,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3048.4,1381.5c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3052.6,1398.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3056.9,1415.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3061.2,1432.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2988.7,1455.1c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3039.8,1347.3c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1s2.5.9,3.8.6c.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3s.2,1.1-.3,1.4c-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3044.1,1364.4c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2970.7,1356.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2973.5,1367.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2976.3,1378.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2979.1,1389.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2981.9,1400.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2984.7,1412.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2987.5,1423.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2990.3,1434.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2993.1,1445.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3186.1,1467.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,1484.4h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,1500.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3167.1,1517.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165.6,1521.2c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3198.8,1435h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3192.5,1451.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3185.8,1421.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3181.6,1432.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3177.5,1442.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.3,1453.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3169.2,1464.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165,1475.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3160.9,1486c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3156.7,1496.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3152.6,1507.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3087.7,1631.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3071.4,1625c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3055.1,1618.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.8,1611.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3104,1638.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3123.8,1628.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3113.1,1623.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3102.5,1619.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3091.8,1615c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3081.2,1610.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3070.5,1606.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3059.8,1601.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3049.2,1597.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.5,1592.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3080.5,1368.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,1365.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3097.2,1324.2c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3084.5,1375c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,1389.9c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM3063.3,1373c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3295.7,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3304.6,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3252.4,1339.2c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3244.9,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM3244.3,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3077.3,1474.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.7,1470.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.3,1461.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3085.9,1469.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3034,1476.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3025.9,1477.3c-2.5,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM3025.6,1455.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.4,1420.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3250.9,1441.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3243.8,1447.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3213.6,1410.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3242.2,1454.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3255.1,1465.7c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM3246.5,1458c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3111.6,1396.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3138.1,1383.8c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.6,1392.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3116.2,1373.2c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.5-1.9.5ZM3116.2,1366.4c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1,1.2-.6,1.6-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3117.2,1385.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3207.1,1386.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3235.8,1393.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.8,1388.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3228.1,1372c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM3228.1,1365.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5s0,1.2.3,1.8c.3.5.8.9,1.5,1.1,1.3.3,2.5-.5,2.9-1.7.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0ZM3231.4,1368.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3231,1384.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,1436.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,1466.9c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,1447.2c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM2958.3,1440.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4s-.1,1.2.2,1.8h0c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3081.1,1444.8c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM3091.3,1393.7c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7s-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3075,1418.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3188.3,1366.4c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM3193,1322.3l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3201.5,1352c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1h0s-1.3,4.5-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2924.5,1381.4l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2968,1605.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3221.7,1537.8c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187.4,1555.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181.9,1548.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3226,1528.7c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,1563.7c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.1,1570.9c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5.9-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM3170,1550.1c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3143.1,1362.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,1378.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.7,1384.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3140.1,1352.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174,1392.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,1401.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.4,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM3178.9,1395.3c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3001.5,1539c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2983.8,1519.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2989.4,1536.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM3159.6,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.2,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,1632.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1s.5,1,.1,1.4c-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2977.6,1638.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2996.2,1628.3c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM2996.2,1621.6c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM2998.9,1626.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.3,1640.6c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3041.4,1519.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030,1536.2c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3022.6,1529.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030.9,1514.7c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.9,1-2,1.4-3.2,1.4ZM3029.1,1508.7h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8s.6-1.1.6-1.7c0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3013.1,1521c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3146.8,1567.3c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125,1549.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3153.1,1559.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3139.8,1544.1c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.9,1-2.1,1.6-3.4,1.6ZM3139.8,1537.4c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3147.3,1554.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3190.7,1478.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.5,1493.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3199.5,1483.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.7,1470.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4s1.7,1.5,2.1,2.6c.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM3215.7,1464.1c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3214.3,1483.5c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3109.6,1534.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM3071.7,1501c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3082.9,1528.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3245.7,1530.5c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4s1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM3247.1,1478.4c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3234.8,1505.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3002,1592.5c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM3010.8,1549.2l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3016.2,1579.7c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3003.4,1507.9c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2972.8,1488.2l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2988,1499.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3075,1497.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2983.4,1565.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3272.7,1419.3h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3174.5,1617.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3107.7,1342.5c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3192.2,1524c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2916.5,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3214,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,1325.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM3159.6,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6s-.1-1.2-.5-1.7c-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3283.5,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3300.9,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3286.6,1775.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM3286.6,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.5,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3270.9,1725.4c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM3252.8,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3281,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3249.2,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3233.5,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3214,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3280.6,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3295.8,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3285.5,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3275.2,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3254.6,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3244.4,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3234.1,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3223.8,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3213.5,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3306.5,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3273.9,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3307.9,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM3274.2,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3294.4,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3048.4,1709.9c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3052.6,1727c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3056.9,1744.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3061.2,1761.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2988.7,1783.5c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3039.8,1675.7c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.5-1.8-.2-3.7.8-5.3s2.5-2.7,4.3-3.2c.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.3,1.3,1.1,2.4,2.3,3.1s2.5.9,3.8.6c.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3044.1,1692.8c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2970.7,1684.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2973.5,1695.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2976.3,1706.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2979.1,1718.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2981.9,1729.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2984.7,1740.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2987.5,1751.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2990.3,1762.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2993.1,1774c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3186.1,1796.3h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,1812.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,1829.1h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3167.1,1845.6h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165.6,1849.5c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3198.8,1763.4h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3192.5,1779.8h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3185.8,1749.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3181.6,1760.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3177.5,1771.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.3,1782c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3169.2,1792.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165,1803.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3160.9,1814.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3156.7,1825.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3152.6,1835.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3087.7,1960.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3071.4,1953.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3055.1,1946.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.8,1939.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3104,1966.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3123.8,1956.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3113.1,1952.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3102.5,1947.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3091.8,1943.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3081.2,1938.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3070.5,1934.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3059.8,1930.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3049.2,1925.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.5,1921.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3080.5,1696.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,1693.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3097.2,1652.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3084.5,1703.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,1718.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM3063.3,1701.4c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3295.7,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3304.6,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3252.4,1667.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3244.9,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM3244.3,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3077.3,1803.2c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.7,1798.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.3,1789.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3085.9,1798.1c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3034,1805.1c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3025.9,1805.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM3025.6,1784c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.4,1749.1c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3250.9,1769.9c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3243.8,1775.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3213.6,1739.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3242.2,1783.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3255.1,1794c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM3246.5,1786.4c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3111.6,1725.2c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3138.1,1712.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.6,1720.8c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3116.2,1701.5c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2s-.6-2.2-.2-3.3,1.2-2,2.2-2.5c1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.5-1.9.5ZM3116.2,1694.8c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1,1.2-.6,1.6-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3117.2,1714c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3207.1,1714.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3235.8,1721.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.8,1717c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3228.1,1700.3c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.5,2-2.3,3.3-4.2,3.3ZM3228.1,1693.6c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0ZM3231.4,1696.8h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3231,1712.8c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,1765.2c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,1775.5c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM2958.3,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4s-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3081.1,1773.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM3091.3,1722.1c-.3,0-.6,0-.9,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7s-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3075,1746.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3188.3,1694.7c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5h0s2.8-3.7,2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM3193,1650.6l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3201.5,1680.4c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2924.5,1709.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2968,1933.7c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3s-2.1,1.6-2.5,2.9c-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3221.7,1866.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187.4,1884.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181.9,1877c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3226,1857c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,1892.1c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.1,1899.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM3170,1878.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3143.1,1690.5c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,1706.7c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.7,1713.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3140.1,1680.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174,1721c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,1730.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM3178.9,1723.6c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3001.5,1867.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2983.8,1847.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2989.4,1865.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM3159.6,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,1960.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2977.6,1966.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2996.2,1956.7c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM2996.2,1950c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.3,1968.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3041.4,1848c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030,1864.6c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3022.6,1857.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030.8,1843c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM3029.1,1837.1h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3013.1,1849.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3146.8,1895.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125,1877.8c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3153.1,1887.9c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3139.8,1872.4c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.9,1-2.1,1.6-3.4,1.6ZM3139.8,1865.7c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3147.3,1883.1c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3190.7,1807.3c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.5,1821.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3199.5,1812.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.7,1799.2c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.8,1.4-2.3,2.2-3.8,2.2ZM3215.7,1792.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9s.2-2.6-.9-3.2c-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3214.3,1811.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3109.6,1862.6c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM3071.7,1829.3c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3082.9,1857.1c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3245.7,1858.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0s2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM3247.1,1806.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3234.8,1833.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3002,1920.9c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4s.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM3010.8,1877.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3016.2,1908c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3003.4,1836.2c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.7,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2972.8,1816.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9s-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2988,1827.8c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3075,1826.1c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2983.4,1893.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3272.7,1747.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3174.5,1945.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3107.7,1670.8c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3192.2,1852.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2916.5,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3214,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM3159.6,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.2,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,2319.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,2319.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM3032.5,2304.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,2313.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3283.5,2123.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3300.9,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3286.6,2103.9c-1.6,0-3.1-.9-3.9-2.3-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM3286.6,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.5,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3270.9,2053.8c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM3252.8,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3281,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3249.2,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3233.5,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3214,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3280.6,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3295.8,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3285.5,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3275.2,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3264.9,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3254.6,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3244.4,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3234.1,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3223.8,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3213.5,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3306.5,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3273.9,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3307.9,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM3274.2,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3294.4,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3048.4,2038.2c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3052.6,2055.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3056.9,2072.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3061.2,2089.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2988.7,2111.8c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3039.8,2004c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1s2.5.9,3.8.6c.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3044.1,2021.1c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2970.7,2012.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2973.5,2024c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2976.3,2035.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2979.1,2046.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2981.9,2057.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2984.7,2068.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2987.5,2080c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2990.3,2091.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2993.1,2102.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3186.1,2124.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,2141.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,2157.5h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3167.1,2173.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165.6,2177.9c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3198.8,2091.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3192.5,2108.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3185.8,2078.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3181.6,2088.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3177.5,2099.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3173.3,2110.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3169.2,2121.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3165,2131.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3160.9,2142.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3156.7,2153.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3152.6,2164.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3087.7,2288.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3071.4,2281.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3055.1,2275c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.8,2268.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,2304.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,2301.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3104,2295.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3123.8,2284.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3113.1,2280.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3102.5,2276.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3091.8,2271.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3081.2,2267.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3070.5,2262.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3059.8,2258.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3049.2,2254c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M3038.5,2249.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3098.8,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3080.5,2025c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,2022c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3097.2,1980.9c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3084.5,2031.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3071.9,2046.6c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM3063.3,2029.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3295.7,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3289.2,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3304.6,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3252.4,1995.9c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3244.9,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM3244.3,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3077.3,2131.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.7,2127.2c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3038.3,2118.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3085.9,2126.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3034,2133.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3025.9,2134c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.4-4.6,11.1-3.3,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM3025.6,2112.3c-2,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.4,2077.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3250.9,2098.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3243.8,2103.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3213.6,2067.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3242.2,2111.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3255.1,2122.4c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM3246.5,2114.7c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3111.6,2053.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3138.1,2040.5c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.6,2049.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3116.2,2029.9c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2s-.6-2.2-.2-3.3c.4-1.1,1.2-2,2.2-2.5s2.2-.6,3.3-.2c1.1.4,2,1.2,2.5,2.2.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.5-1.9.5ZM3116.2,2023.1c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1,1.2-.6,1.6-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3117.2,2042.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3207.1,2042.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3235.8,2050.1c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c.1-.5.7-.9,1.2-.7l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.8,2045.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3228.1,2028.7c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM3228.1,2021.9c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM3231.4,2025.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3231,2041.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,2093.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,2123.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,2103.9c-1.6,0-3.1-.9-3.9-2.3-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2958.3,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8,0,0,0,0,0,0,.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3081.1,2101.5c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM3091.3,2050.4c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3075,2074.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3188.3,2023.1c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5h0s2.8-3.7,2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM3193,1979l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3201.5,2008.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2924.5,2038.1l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3064.9,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM3032.5,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3049.1,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2968,2262c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3s-2.1,1.6-2.5,2.9c-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3221.7,2194.5c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187.4,2212.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181.9,2205.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3226,2185.4c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,2220.4c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.1,2227.6c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM3170,2206.8c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3143.1,2018.8c-.4,0-.8-.3-1-.7l-6.6-21.3c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,2035c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174.7,2041.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3140.1,2009.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3174,2049.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3187,2058.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM3178.9,2052c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3001.5,2195.7c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2983.8,2175.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2989.4,2193.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,2313.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,2310.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-1,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,2304s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,2298.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM3159.6,2292c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,2303.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2969.8,2289c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,2307.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2977.6,2295.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2996.2,2285c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2-.9,1.1-2.1,1.6-3.4,1.6ZM2996.2,2278.3c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2992.3,2297.3c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3041.4,2176.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030,2192.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0s.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3022.6,2186.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3030.8,2171.4c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.9,1-2,1.4-3.2,1.4ZM3029.1,2165.4h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3013.1,2177.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3146.8,2224c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125,2206.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3153.1,2216.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3139.8,2200.8c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.9,1-2.1,1.6-3.4,1.6ZM3139.8,2194.1c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3147.3,2211.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3190.7,2135.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3216.5,2150.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3199.5,2140.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3215.7,2127.5c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.8,1.4-2.3,2.2-3.8,2.2ZM3215.7,2120.8c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3214.3,2140.2c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3109.6,2190.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM3071.7,2157.7c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3082.9,2185.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3245.7,2187.2c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM3247.1,2135.1c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3234.8,2162.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3002,2249.2c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4s.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM3010.8,2205.9l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3016.2,2236.4c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3003.4,2164.6c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.7,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2972.8,2144.9l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2988,2156.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3075,2154.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2983.4,2222.3c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3272.7,2075.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3174.5,2273.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3107.7,1999.2c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3192.2,2180.7c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2916.5,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3217.8,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3214,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3125.8,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3120.3,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3179.8,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3151.9,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3181,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3159.6,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM3159.6,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3173.5,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2992.9,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,349.3c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,334.2l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM2958.3,127c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2924.5,68l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720,68.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2724.3,85.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2728.6,102.3c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2732.8,119.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2660.3,141.7c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2711.5,33.9c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2715.7,51c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2642.3,42.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2645.2,53.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2648,65.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2650.8,76.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2653.6,87.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2656.4,98.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2659.2,109.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2662,121.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2664.8,132.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.8,154.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,171h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,187.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2838.8,203.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2837.3,207.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2870.5,121.6h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2864.1,138.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.4,108c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2853.3,118.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2849.1,129.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845,140.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2840.8,151c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2836.7,161.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2832.5,172.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2828.4,183.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2824.2,194.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2759.3,318.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2743.1,311.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2726.8,304.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.5,298.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2775.6,325.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2795.5,314.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2784.8,310.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2774.1,306c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2763.5,301.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2752.8,297.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2742.1,292.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2731.5,288.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720.8,283.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.2,279.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,20.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.1,54.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.5,51.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2768.9,10.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2756.1,61.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.6,76.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2735,59.6c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2924,25.8c-.6,0-1-.4-1-1V3.6c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2916.5,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2749,161.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710.4,157.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710,148c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2757.6,156.3c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2705.6,163.3c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2697.5,163.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM2697.3,142.2c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887,107.3c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2922.6,128.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2915.5,133.7c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.3,97.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2913.8,141.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2926.8,152.3c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2918.2,144.6c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2783.3,83.4c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2809.8,70.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2792.2,79c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2787.9,59.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM2787.9,53c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2s2,1.6,3.2,1.1c1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2788.9,72.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2878.8,72.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2907.5,80c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.5,75.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2899.8,58.6c-.4,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2899.8,51.8c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8s-.8-.9-1.5-1.1c-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2902.7,71c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,123.4c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,133.8c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2627.8,130.5c.6,1.2,2,1.6,3.2,1,1.2-.6,1.6-2,1-3.2-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.7,131.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2762.9,80.3c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,104.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859.9,53c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2864.7,8.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8s.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2873.1,38.6c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM2596.1,68l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,21c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,5.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,14.8c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.6,291.9c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2893.4,224.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859,242.5c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2853.6,235.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2897.6,215.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,250.3c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.8,257.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM2841.6,236.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2814.8,48.7c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.7,64.9c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2846.3,71.4c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.8,39.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.6,79.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,88.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.6,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2850.5,81.9c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.2,225.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2655.4,205.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2661,223.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2831.3,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,318.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.2,325.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2667.9,314.9c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.9,1.1-2.1,1.6-3.4,1.6ZM2667.9,308.2c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2663.9,327.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2713,206.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2701.7,222.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2694.3,216.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2702.5,201.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2700.7,195.3c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2684.8,207.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.4,253.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2796.7,236c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2824.8,246.1c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.5,230.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM2811.5,224c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.9,241.3c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2862.4,165.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.2,180c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2871.1,170.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887.3,157.4c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.8,1.4-2.3,2.2-3.8,2.2ZM2887.3,150.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9h0c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.9,170.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2781.2,220.8c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c0,.3-.2.5-.4.6-.2.1-.5.2-.8.1l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2743.4,187.6c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2754.6,215.3c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2917.4,217.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM2918.7,165c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2906.4,192.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5s0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.7,279.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.5-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2682.5,235.8l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2687.8,266.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2675,194.5c-1,0-2.1-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2644.4,174.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2659.6,186c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,184.3c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2655.1,252.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2944.3,105.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2846.2,303.8c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2779.3,29.1c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2863.8,210.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,3.4c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,5.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5s.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,3.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,15.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,12.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,5.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM2831.3-6.4c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,4.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,481.9c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM2958.3,455.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2924.5,396.3l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720,396.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2724.3,413.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2728.6,430.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2732.8,447.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2660.3,470.1c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2711.5,362.3c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3s2.5-2.7,4.3-3.2c.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1s-.5,1-1.1.9c-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2715.7,379.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2642.3,371.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2645.2,382.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2648,393.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2650.8,404.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2653.6,415.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2656.4,427.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2659.2,438.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2662,449.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2664.8,460.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.8,482.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,499.3h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,515.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2838.8,532.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2837.3,536.1c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2870.5,450h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2864.1,466.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.4,436.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2853.3,447.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2849.1,457.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845,468.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2840.8,479.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2836.7,490.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2832.5,500.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2828.4,511.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2824.2,522.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2759.3,646.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2743.1,640c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2726.8,633.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.5,626.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2775.6,653.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2795.5,643.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2784.8,638.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2774.1,634.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2763.5,629.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2752.8,625.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2742.1,621.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2731.5,616.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720.8,612.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.2,607.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.1,383.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.5,380.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2768.9,339.1c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2756.1,390c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.6,404.8c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2735,388c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2924,354.1c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2916.6,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2749,489.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710.4,485.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710,476.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2757.6,484.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2705.6,491.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2697.5,492.3c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM2697.3,470.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887,435.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2922.6,456.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2915.5,462.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.3,425.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2913.8,469.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2926.8,480.6c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2918.2,473c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2783.3,411.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2809.8,398.7c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2792.2,407.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2787.9,388.1c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM2787.9,381.4c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2788.9,400.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2878.8,401.2c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2907.5,408.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.5,403.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2899.8,386.9c-.4,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2s.8,2.2.5,3.3c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2899.8,380.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2902.7,399.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,451.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,462.1c-1.6,0-3.1-.9-3.9-2.3,0,0,0,0,0,0-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2627.8,458.8c.6,1.2,2,1.6,3.2,1,1.2-.6,1.6-2,1-3.2-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.7,459.7c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2762.9,408.7c-.3,0-.6,0-.9,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,433.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859.9,381.3c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2864.7,337.2l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2873.1,367c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2596.1,396.3l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,349.3c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,334.2l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.6,620.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2893.4,552.7c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859,570.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2853.6,563.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2897.6,543.6c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,578.7c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.8,585.8c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM2841.6,565c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2814.8,377.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.7,393.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2846.3,399.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.8,367.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.6,407.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,416.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.6,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2850.5,410.2c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.2,553.9c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2655.4,534c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2661,551.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2831.3,650.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,647.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.2,653.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2667.9,643.3c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9s.2-2.3.9-3.2c1.5-1.9,4.3-2.2,6.1-.7.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2-.9,1.1-2.1,1.6-3.4,1.6ZM2667.9,636.6c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2663.9,655.5c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2713,534.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2701.7,551.2c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2694.3,544.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2702.5,529.6c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM2700.7,523.7c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2684.8,536c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.4,582.2c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2796.7,564.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2824.8,574.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.5,559c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1h0c1.9,1.5,2.1,4.3.6,6.1-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM2811.5,552.3c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.8,1-.7,2.5.3,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.7-2.5-.3-3.3,0,0,0,0,0,0-.4-.4-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.9,569.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2862.4,493.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.2,508.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2871.1,498.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887.3,485.8c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9h0c-.8,1.4-2.3,2.2-3.8,2.2ZM2887.3,479c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3ZM2890.2,483.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.9,498.4c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2781.2,549.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c0,.3-.2.5-.4.6-.2.1-.5.2-.8.1l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9h0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM2743.4,515.9c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.2,1,0,2,.5,2.8s1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2754.6,543.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2917.4,545.4c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM2918.7,493.3c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2906.4,520.5c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5s0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.7,607.5c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2682.5,564.1l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2687.8,594.6c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2675,522.8c-1,0-2.1-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2644.4,503.1l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2659.6,514.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,512.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2655.1,580.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2944.3,434.2h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2846.2,632.2c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2779.3,357.4c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2863.8,538.9c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2831.3,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,1006c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,810.2c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2958.3,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2924.5,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720,724.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2724.3,741.9c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2728.6,759c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2732.8,776.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2660.3,798.4c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2711.5,690.6c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2715.7,707.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2642.3,699.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2645.2,710.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2648,721.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2650.8,733c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2653.6,744.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2656.4,755.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2659.2,766.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2662,777.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2664.8,789c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.8,811.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,827.7h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,844.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2838.8,860.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2837.3,864.5c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6s.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2870.5,778.3h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2864.1,794.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.4,764.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2853.3,775.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2849.1,786.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845,797c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2840.8,807.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2836.7,818.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2832.5,829.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2828.4,840c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2824.2,850.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2759.3,975c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2743.1,968.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2726.8,961.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.5,954.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2775.6,981.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2795.5,971.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2784.8,967.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2774.1,962.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2763.5,958.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2752.8,953.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2742.1,949.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2731.5,945c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720.8,940.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.2,936.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.1,711.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.5,708.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2768.9,667.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2756.1,718.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.6,733.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2735,716.3c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2924,682.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2916.5,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2749,818.1c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710.4,813.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710,804.7c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2757.6,813c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2705.6,820c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2697.5,820.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM2697.3,798.9c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887,764c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2922.6,784.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2915.5,790.4c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.3,754.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2913.8,798.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2926.8,809c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2918.2,801.3c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2783.3,740.1c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2809.8,727.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2792.2,735.7c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2787.9,716.5c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM2787.9,709.7c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2788.9,728.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2878.8,729.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2907.5,736.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.5,732c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2899.8,715.3c-.4,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2899.8,708.5c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8s-.8-.9-1.5-1.1c-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2902.7,727.7c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,780.1c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,790.5c-1.6,0-3.1-.9-3.9-2.3t0,0c-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2627.8,787.2c.6,1.2,2,1.6,3.2,1,1.2-.6,1.6-2,1-3.2-.6-1.2-2-1.6-3.2-1-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.7,788.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM2762.9,737c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,761.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859.9,709.7c-1.2,0-2.4-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-1.1,1.5-2.9,2.3-4.6,2.3ZM2864.7,665.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8s.7,1.9,1.5,2.5l12.4,9.3c1.7,1.3,4,.9,5.3-.7l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2873.1,695.3c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2596.1,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.6,948.6c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2893.4,881.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859,899.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2s.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2853.6,891.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2897.6,872c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,907c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4s1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.8,914.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2841.6,893.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2814.8,705.4c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.7,721.6c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2846.3,728.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.8,695.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.6,735.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,745.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.6,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2850.5,738.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.2,882.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2655.4,862.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2661,880.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9s2.1-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM2831.3,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,975.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.2,981.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2667.9,971.6c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM2667.9,964.9c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5ZM2670.5,969.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2663.9,983.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2713,863c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2701.7,879.5c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2694.3,872.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2702.5,858c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM2700.7,852h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2684.8,864.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.4,910.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2796.7,892.7c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2824.8,902.8c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.5,887.4c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1,1.9,1.5,2.1,4.3.6,6.1-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM2811.5,880.7c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.7-2.5-.3-3.3h0c-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.9,898c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2862.4,822.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.2,836.7c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2871.1,827.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887.3,814.1c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.8,1.4-2.3,2.2-3.8,2.2ZM2887.3,807.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.9,826.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2781.2,877.5c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c0,.3-.2.5-.4.6-.2.1-.5.2-.8.1l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2743.4,844.3c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.2,1,0,2,.5,2.8s1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2754.6,872c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2917.4,873.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5h0s1.9,4.3,1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM2918.7,821.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2906.4,848.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.7,935.9c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2682.5,892.5l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2687.8,923c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2675,851.2c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2644.4,831.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2659.6,842.7c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,841c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2655.1,908.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2944.3,762.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2846.2,960.5c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2779.3,685.8c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2863.8,867.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2831.3,650.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,1334.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5s-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,1319.3l30.9,12.8c.9.4,2,.4,2.9,0s1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM2958.3,1112.1c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2924.5,1053l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1s-1-.3-1.1-.9c-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720,1053.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2724.3,1070.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2728.6,1087.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2732.8,1104.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2660.3,1126.8c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2711.5,1019c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3s2.5-2.7,4.3-3.2c.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2715.7,1036.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2642.3,1027.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2645.2,1039c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2648,1050.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2650.8,1061.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2653.6,1072.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2656.4,1083.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2659.2,1095c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2662,1106.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2664.8,1117.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.8,1139.6h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,1156h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,1172.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2838.8,1188.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2837.3,1192.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2870.5,1106.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2864.1,1123.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.4,1093c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2853.3,1103.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2849.1,1114.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845,1125.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2840.8,1136.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2836.7,1146.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2832.5,1157.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2828.4,1168.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2824.2,1179.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2759.3,1303.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2743.1,1296.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2726.8,1289.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.5,1283.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2775.6,1310.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2795.5,1299.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2784.8,1295.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2774.1,1291c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2763.5,1286.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2752.8,1282.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2742.1,1277.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2731.5,1273.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720.8,1269c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.2,1264.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.1,1040c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.5,1037c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2768.9,995.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2756.1,1046.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.6,1061.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2735,1044.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2924,1010.8c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2916.5,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2749,1146.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710.4,1142.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710,1133.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2757.6,1141.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2705.6,1148.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2697.5,1148.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM2697.3,1127.3c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887,1092.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2922.6,1113.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2915.5,1118.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.3,1082.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2913.8,1126.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2926.8,1137.3c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2918.2,1129.7c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2783.3,1068.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2809.8,1055.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2792.2,1064.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2787.9,1044.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM2787.9,1038.1c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2s2,1.6,3.2,1.1c1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2788.9,1057.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2878.8,1057.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2907.5,1065c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.5,1060.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2899.8,1043.6c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2899.8,1036.9c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8s-.8-.9-1.5-1.1c-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2902.7,1056.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,1108.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,1118.8c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2627.8,1115.5c.6,1.2,2,1.6,3.2,1,1.2-.6,1.6-2,1-3.2-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.7,1116.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2762.9,1065.4c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,1089.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859.9,1038c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2864.7,993.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8s.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2873.1,1023.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM2596.1,1053l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,1006c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.6,1277c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2893.4,1209.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859,1227.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2853.6,1220.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2897.6,1200.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,1235.4c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.8,1242.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2841.6,1221.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2814.8,1033.8c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.7,1050c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2846.3,1056.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.8,1024.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.6,1064.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,1073.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.6,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2850.5,1066.9c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.2,1210.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2655.4,1190.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2661,1208.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,1325.7s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2831.3,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,1303.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.2,1310.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2667.9,1300c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM2667.9,1293.3c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5ZM2670.5,1297.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2663.9,1312.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2713,1191.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2701.7,1207.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2694.3,1201.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2702.5,1186.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2700.7,1180.4c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2684.8,1192.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.4,1238.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2796.7,1221.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2824.8,1231.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.5,1215.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM2811.5,1209c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.9,1226.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2862.4,1150.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.2,1165.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2871.1,1155.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887.3,1142.5c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.8,1.4-2.3,2.2-3.8,2.2ZM2887.3,1135.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9h0c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.9,1155.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2781.2,1205.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c0,.3-.2.5-.4.6-.2.1-.5.2-.8.1l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2743.4,1172.6c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2754.6,1200.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2917.4,1202.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM2918.7,1150c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2906.4,1177.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.7,1264.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2682.5,1220.8l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2687.8,1251.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2675,1179.5c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2644.4,1159.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2659.6,1171.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,1169.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2655.1,1237.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2944.3,1090.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2846.2,1288.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2779.3,1014.1c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2863.8,1195.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9s2.1-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM2831.3,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,1662.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,1647.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,1466.9c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,1447.2c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM2958.3,1440.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4s-.1,1.2.2,1.8h0c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1h0s-1.3,4.5-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2924.5,1381.4l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720,1381.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2724.3,1398.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2728.6,1415.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2732.8,1432.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2660.3,1455.1c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2711.5,1347.3c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2715.7,1364.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2642.3,1356.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2645.2,1367.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2648,1378.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2650.8,1389.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2653.6,1400.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2656.4,1412.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2659.2,1423.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2662,1434.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2664.8,1445.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.8,1467.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,1484.4h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,1500.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2838.8,1517.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2837.3,1521.2c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2870.5,1435h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2864.1,1451.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.4,1421.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2853.3,1432.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2849.1,1442.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845,1453.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2840.8,1464.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2836.7,1475.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2832.5,1486c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2828.4,1496.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2824.2,1507.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2759.3,1631.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2743.1,1625c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2726.8,1618.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.5,1611.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8.7,1.7.7,3.6,0,5.4-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2775.6,1638.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2795.5,1628.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2784.8,1623.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2774.1,1619.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2763.5,1615c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2752.8,1610.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2742.1,1606.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2731.5,1601.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720.8,1597.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.2,1592.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.1,1368.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.5,1365.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2768.9,1324.2c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2756.1,1375c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.6,1389.9c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2735,1373c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2924,1339.2c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2916.5,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2749,1474.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710.4,1470.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710,1461.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2757.6,1469.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2705.6,1476.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2697.5,1477.3c-2.5,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM2697.3,1455.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887,1420.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2922.6,1441.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2915.5,1447.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.3,1410.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2913.8,1454.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2926.8,1465.7c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2918.2,1458c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2783.3,1396.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2809.8,1383.8c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2792.2,1392.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2787.9,1373.2c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM2787.9,1366.4c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2788.9,1385.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2878.8,1386.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2907.5,1393.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.5,1388.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2899.8,1372c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2899.8,1365.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5s0,1.2.3,1.8c.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2902.7,1384.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,1436.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,1447.2c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2627.8,1443.9c.6,1.2,2,1.6,3.2,1,1.2-.6,1.6-2,1-3.2-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.7,1444.8c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM2762.9,1393.7c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,1418.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859.9,1366.4c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5h0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2864.7,1322.3l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2873.1,1352c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2596.1,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,1334.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,1319.3l30.9,12.8c.9.4,2,.4,2.9,0s1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.6,1605.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2893.4,1537.8c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859,1555.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2s.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2853.6,1548.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2897.6,1528.7c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,1563.7c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.8,1570.9c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5.9-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2841.6,1550.1c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2814.8,1362.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.7,1378.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2846.3,1384.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.8,1352.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.6,1392.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,1401.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.6,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.4,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2850.5,1395.3c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.2,1539c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2655.4,1519.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2661,1536.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2831.3,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,1632.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.2,1638.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2667.9,1628.3c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM2667.9,1621.6c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5ZM2670.5,1626.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2663.9,1640.6c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2713,1519.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2701.7,1536.2c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2694.3,1529.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2702.5,1514.7c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2700.7,1508.7h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2684.8,1521c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.4,1567.3c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2796.7,1549.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2824.8,1559.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.5,1544.1c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM2811.5,1537.4c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.9,1554.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2862.4,1478.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.2,1493.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2871.1,1483.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887.3,1470.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM2887.3,1464.1c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.9,1483.5c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2781.2,1534.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c0,.3-.2.5-.4.6-.2.1-.5.2-.8.1l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2743.4,1501c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2754.6,1528.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2917.4,1530.5c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM2918.7,1478.4c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2906.4,1505.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.7,1592.6c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2682.5,1549.2l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2687.8,1579.7c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2675,1507.9c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2644.4,1488.2l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2659.6,1499.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,1497.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2655.1,1565.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2944.3,1419.3h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2846.2,1617.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2779.3,1342.5c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2863.8,1524c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,1325.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2831.3,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,1991.1c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,1976l30.9,12.8c.9.4,2,.4,2.9,0s1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,1775.5c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM2958.3,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4s-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2924.5,1709.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720,1709.9c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2724.3,1727c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2728.6,1744.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2732.8,1761.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2660.3,1783.5c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2711.5,1675.7c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3s2.5-2.7,4.3-3.2c.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2715.7,1692.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2642.3,1684.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2645.2,1695.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2648,1706.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2650.8,1718.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2653.6,1729.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2656.4,1740.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2659.2,1751.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2662,1762.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2664.8,1774c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.8,1796.3h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,1812.7h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,1829.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2838.8,1845.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2837.3,1849.5c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2870.5,1763.4h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2864.1,1779.8h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.4,1749.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2853.3,1760.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2849.1,1771.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845,1782c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2840.8,1792.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2836.7,1803.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2832.5,1814.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2828.4,1825.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2824.2,1835.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2759.3,1960.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2743.1,1953.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2726.8,1946.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.5,1939.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2775.6,1966.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2795.5,1956.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2784.8,1952.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2774.1,1947.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2763.5,1943.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2752.8,1938.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2742.1,1934.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2731.5,1930.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720.8,1925.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.2,1921.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.1,1696.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.5,1693.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2768.9,1652.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2756.1,1703.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.6,1718.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2735,1701.4c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2924,1667.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2916.5,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2749,1803.2c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710.4,1798.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710,1789.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2757.6,1798.1c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2705.6,1805.1c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2697.5,1805.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM2697.3,1784c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887,1749.1c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2922.6,1769.9c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2915.5,1775.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.3,1739.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2913.8,1783.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2926.8,1794c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2918.2,1786.4c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2783.3,1725.2c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2809.8,1712.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2792.2,1720.8c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2787.9,1701.5c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM2787.9,1694.8c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2788.9,1714c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2878.8,1714.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2907.5,1721.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.5,1717c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2899.8,1700.3c-.4,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2899.8,1693.6c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2902.7,1712.8c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,1765.2c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,1775.5c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2627.8,1772.2c.6,1.2,2,1.6,3.2,1,1.2-.6,1.6-2,1-3.2-.6-1.2-2-1.6-3.2-1-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.7,1773.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM2762.9,1722.1c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,1746.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859.9,1694.7c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2864.7,1650.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2873.1,1680.4c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2596.1,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,1662.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,1647.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.6,1933.7c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2893.4,1866.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859,1884.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2s.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2853.6,1877c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2897.6,1857c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,1892.1c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.8,1899.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2841.6,1878.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.2.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2814.8,1690.5c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.7,1706.7c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2846.3,1713.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.8,1680.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.6,1721c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,1730.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.6,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2850.5,1723.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.2,1867.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2655.4,1847.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2661,1865.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2831.3,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,1960.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.2,1966.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2667.9,1956.7c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.9,1.1-2.1,1.6-3.4,1.6ZM2667.9,1950c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2663.9,1968.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2713,1848c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2701.7,1864.6c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2694.3,1857.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2702.5,1843c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2700.7,1837.1c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2684.8,1849.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.4,1895.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2796.7,1877.8c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2824.8,1887.9c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.5,1872.4c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM2811.5,1865.7c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.9,1883.1c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2862.4,1807.3c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.2,1821.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2871.1,1812.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887.3,1799.2c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.8,1.4-2.3,2.2-3.8,2.2ZM2887.3,1792.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.9,1811.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2781.2,1862.6c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c0,.3-.2.5-.4.6-.2.1-.5.2-.8.1l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2743.4,1829.3c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2754.6,1857.1c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2917.4,1858.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0s2.6,1.6,3.2,3.1l13.9,31.5h0s1.9,4.3,1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM2918.7,1806.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2906.4,1833.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.7,1920.9c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2682.5,1877.5l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2687.8,1908c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2675,1836.2c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2644.4,1816.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2659.6,1827.8c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,1826.1c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6s-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2655.1,1893.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2944.3,1747.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2846.2,1945.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2779.3,1670.8c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2863.8,1852.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8.7,1.7.7,3.6,0,5.4-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2831.3,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,2319.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,2319.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,2304.3l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,2313.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2955.2,2123.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2972.5,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2958.3,2103.9c-1.6,0-3.1-.9-3.9-2.3-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2958.3,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8,0,0,0,0,0,0,.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2961.2,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2942.5,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2924.5,2038.1l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2952.6,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2920.8,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.1,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2952.3,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2967.5,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2957.2,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2946.9,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2936.6,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2926.3,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2916,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2905.7,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2895.4,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2885.1,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2978.1,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2945.6,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2979.5,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2945.8,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2966,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720,2038.2c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2724.3,2055.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2728.6,2072.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2732.8,2089.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2660.3,2111.8c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2711.5,2004c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1s2.5.9,3.8.6c.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2715.7,2021.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2642.3,2012.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2645.2,2024c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2648,2035.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2650.8,2046.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2653.6,2057.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2656.4,2068.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2659.2,2080c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2662,2091.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2664.8,2102.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.8,2124.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,2141.1h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,2157.5h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2838.8,2173.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2837.3,2177.9c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2870.5,2091.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2864.1,2108.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2857.4,2078.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2853.3,2088.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2849.1,2099.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2845,2110.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2840.8,2121.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2836.7,2131.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2832.5,2142.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2828.4,2153.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2824.2,2164.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2759.3,2288.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2743.1,2281.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2726.8,2275c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.5,2268.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,2304.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,2301.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2775.6,2295.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2795.5,2284.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2784.8,2280.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2774.1,2276.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2763.5,2271.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2752.8,2267.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2742.1,2262.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2731.5,2258.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2720.8,2254c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2710.2,2249.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2770.5,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.1,2025c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.5,2022c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2768.9,1980.9c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2756.1,2031.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2743.6,2046.6c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2735,2029.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2967.4,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2960.8,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2976.2,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2924,1995.9c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2916.5,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2916,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2749,2131.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710.4,2127.2c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2710,2118.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2757.6,2126.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2705.6,2133.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2697.5,2134c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.4-4.6,11.1-3.3,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM2697.3,2112.3c-2,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887,2077.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2922.6,2098.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2915.5,2103.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.3,2067.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2913.8,2111.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2926.8,2122.4c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2918.2,2114.7c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2783.3,2053.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2809.8,2040.5c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2792.2,2049.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2787.9,2029.9c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM2787.9,2023.1c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2788.9,2042.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2878.8,2042.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2907.5,2050.1c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c.1-.5.7-.9,1.2-.7l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.5,2045.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2899.8,2028.7c-.4,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2899.8,2021.9c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2902.7,2041.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,2093.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,2103.9c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8s.3,4.8-1.8,5.9c-.6.3-1.3.5-2,.5ZM2627.8,2100.6c.6,1.2,2,1.6,3.2,1,1.2-.6,1.6-2,1-3.2-.6-1.2-2-1.6-3.2-1-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2752.7,2101.5c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM2762.9,2050.4c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,2074.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859.9,2023.1c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5h0s2.8-3.7,2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2864.7,1979l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2873.1,2008.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2596.1,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2736.5,1991.1c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2704.2,1976l30.9,12.8c.9.4,2,.4,2.9,0s1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2720.7,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.6,2262c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2893.4,2194.5c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2859,2212.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2s.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2853.6,2205.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2897.6,2185.4c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,2220.4c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.8,2227.6c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2841.6,2206.8c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.2.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2814.8,2018.8c-.4,0-.8-.3-1-.7l-6.6-21.3c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.7,2035c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2846.3,2041.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.8,2009.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.6,2049.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2858.6,2058.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2850.5,2052c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.2,2195.7c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2655.4,2175.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2661,2193.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,2313.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,2310.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-1,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,2304s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,2298.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2831.3,2292c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,2303.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2641.4,2289c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,2307.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.2,2295.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2667.9,2285c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2-.9,1.1-2.1,1.6-3.4,1.6ZM2667.9,2278.3c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2663.9,2297.3c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2713,2176.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2701.7,2192.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2694.3,2186.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2702.5,2171.4c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM2700.7,2165.4c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2684.8,2177.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.4,2224c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2796.7,2206.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5s3.5,1,4.7.9c.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2824.8,2216.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2811.5,2200.8c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM2811.5,2194.1c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2818.9,2211.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2862.4,2135.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2888.2,2150.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2871.1,2140.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2887.3,2127.5c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.8,1.4-2.3,2.2-3.8,2.2ZM2887.3,2120.8c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2885.9,2140.2c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2781.2,2190.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c0,.3-.2.5-.4.6-.2.1-.5.2-.8.1l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM2743.4,2157.7c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2754.6,2185.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2917.4,2187.2c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5h0s1.9,4.3,1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM2918.7,2135.1c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2906.4,2162.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2673.7,2249.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2682.5,2205.9l-22.9,24.4c-.7.7-1.1,1.7-1,2.7s.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2687.8,2236.4c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2675,2164.6c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.3c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2644.4,2144.9l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2659.6,2156.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2746.6,2154.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2655.1,2222.3c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2944.3,2075.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2846.2,2273.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2779.3,1999.2c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2863.8,2180.7c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2889.4,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2885.6,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2797.4,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2791.9,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2851.5,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2823.6,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2852.6,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2831.3,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2831.3,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2845.1,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2664.5,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2s.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,349.3c-.8,0-1.5-.1-2.2-.4l-36.1-15c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2375.9,334.2l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,133.8c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM2627.8,130.5c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM2596.1,68l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2391.7,68.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2395.9,85.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2400.2,102.3c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2404.5,119.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2332,141.7c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2383.1,33.9c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2387.4,51c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2314,42.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2316.8,53.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2319.6,65.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2322.4,76.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2325.2,87.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2328,98.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2330.8,109.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2333.6,121.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2336.4,132.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.4,154.5h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2523.1,171h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,187.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2510.4,203.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.9,207.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2542.1,121.6h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2535.8,138.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.1,108c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2524.9,118.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2520.8,129.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.6,140.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2512.5,151c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.3,161.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2504.2,172.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2500,183.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2495.9,194.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2431,318.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2414.7,311.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2398.4,304.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2382.1,298.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2447.3,325.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2467.1,314.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2456.5,310.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2445.8,306c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2435.1,301.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2424.5,297.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2413.8,292.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2403.1,288.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2392.5,283.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2381.8,279.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,20.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2423.8,54.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,51.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2440.5,10.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2427.8,61.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,76.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2406.6,59.6c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2595.7,25.8c-.6,0-1-.4-1-1V3.6c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2420.6,161.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2382,157.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2381.6,148c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2429.2,156.3c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2377.3,163.3c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2369.2,163.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM2368.9,142.2c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2558.7,107.3c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2594.2,128.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2587.1,133.7c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2556.9,97.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2585.5,141.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2598.4,152.3c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2589.8,144.6c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2454.9,83.4c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2481.4,70.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.9,79c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2459.6,59.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM2459.5,53c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1,1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.3-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2460.5,72.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2550.4,72.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2579.1,80c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2560.1,75.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2571.4,58.6c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2571.4,51.8c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8s-.8-.9-1.5-1.1c-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2574.3,71c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,123.4c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9s4.8-.3,5.9,1.8c1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2299.5,130.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2424.4,131.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2434.6,80.3c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,104.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2531.6,53c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2536.3,8.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8s.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2544.8,38.6c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.8,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM2267.8,68l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,21c-.8,0-1.5-.1-2.2-.4l-36.1-15c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2375.9,5.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,14.8c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2311.3,291.9c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2565,224.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.7,242.5c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2525.2,235.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2569.3,215.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,250.3c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.4,257.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM2513.3,236.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2486.4,48.7c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,64.9c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2518,71.4c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.4,39.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.3,79.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,88.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2522.2,81.9c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2344.8,225.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2327.1,205.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2332.7,223.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,318.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2320.9,325.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2339.5,314.9c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM2339.5,308.2c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM2342.2,312.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2335.6,327.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.6c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2384.7,206.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2373.4,222.9c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2365.9,216.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2374.1,201.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2374.2,194.5s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM2371.7,194.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2356.4,207.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.1,253.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2468.3,236c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2496.4,246.1c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.1,230.7c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2,1.5-1.9,4.3-2.1,6.1-.6h0c.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2s-1.8,1.5-2.9,1.6c-.1,0-.3,0-.4,0ZM2483.1,224c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7s-.4-1.2-.9-1.6h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.6,241.3c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2534,165.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559.8,180c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2542.8,170.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559,157.4c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM2559,150.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.6-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.6.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2557.6,170.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2452.9,220.8c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2415,187.6c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2426.2,215.3c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2589.1,217.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM2590.4,165c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2578.1,192.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2345.3,279.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2354.1,235.8l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2359.5,266.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2346.7,194.5c-1,0-2.1-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2316.1,174.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2331.3,186c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,184.3c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2326.7,252.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2616,105.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2517.8,303.8c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2451,29.1c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2535.5,210.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,3.4c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,5.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5s.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,3.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,15.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,12.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,5.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9-6.4c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,4.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2375.9,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,462.1c-1.6,0-3.1-.9-3.9-2.3,0,0,0,0,0,0-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM2627.8,458.8c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2596.1,396.3l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2391.7,396.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2395.9,413.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2400.2,430.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2404.5,447.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2332,470.1c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2383.1,362.3c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1s-.5,1-1.1.9c-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2387.4,379.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2314,371.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2316.8,382.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2319.6,393.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2322.4,404.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2325.2,415.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2328,427.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2330.8,438.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2333.6,449.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2336.4,460.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.4,482.9h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2523.1,499.3h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,515.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2510.4,532.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.9,536.1c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2542.1,450h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2535.8,466.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.1,436.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2524.9,447.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2520.8,457.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.6,468.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2512.5,479.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.3,490.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2504.2,500.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2500,511.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2495.9,522.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2431,646.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2414.7,640c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2398.4,633.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2382.1,626.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2447.3,653.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2467.1,643.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2456.5,638.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2445.8,634.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2435.1,629.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2424.5,625.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2413.8,621.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2403.1,616.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2392.5,612.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2381.8,607.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2s.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2423.8,383.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,380.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2440.5,339.1c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2427.8,390c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,404.8c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2406.6,388c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2595.7,354.1c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2420.6,489.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2382,485.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2381.6,476.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2429.2,484.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2377.3,491.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2369.2,492.3c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM2368.9,470.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2558.7,435.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1s.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2594.2,456.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2587.1,462.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2556.9,425.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2585.5,469.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2598.4,480.6c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2589.8,473c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2454.9,411.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2481.4,398.7c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.9,407.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2459.6,388.1c-1.6,0-3.2-.9-3.9-2.4-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM2459.5,381.4c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4s-.2,1.2.1,1.8c.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2460.5,400.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2550.4,401.2c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2579.1,408.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2560.1,403.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2571.4,386.9c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2s.8,2.2.5,3.3c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2571.4,380.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2574.3,399.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,451.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2301.6,455.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2424.4,459.7c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5-.7,1.1-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2434.6,408.7c-.3,0-.6,0-.9,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,433.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2531.6,381.3c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2536.3,337.2l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2544.8,367c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.9,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2267.8,396.3l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,349.3c-.8,0-1.5-.1-2.2-.4l-36.1-15c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2375.9,334.2l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2311.3,620.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2565,552.7c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.7,570.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2525.2,563.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2569.3,543.6c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,578.7c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.4,585.8c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM2513.3,565c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2486.4,377.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,393.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2518,399.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.4,367.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.3,407.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,416.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2522.2,410.2c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2344.8,553.9c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2327.1,534c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2332.7,551.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3.1,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,650.3c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,647.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2320.9,653.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2339.5,643.3c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM2339.5,636.6c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM2342.2,641h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2335.6,655.5c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2384.7,534.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2373.4,551.2c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2365.9,544.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2374.1,529.6c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2374.2,522.9s0,0-.1,0c-.6,0-1.2.3-1.6.8-.9,1-.8,2.5.2,3.3.5.4,1,.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2356.4,536c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.1,582.2c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2468.3,564.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2496.4,574.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.1,559c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1h0c.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2s-1.8,1.5-2.9,1.6c-.1,0-.3,0-.4,0ZM2483.1,552.3c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7s-.4-1.2-.9-1.6c0,0,0,0,0,0-.4-.4-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.6,569.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2534,493.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559.8,508.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2542.8,498.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559,485.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9h0c-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM2559,479c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.6-.4,1.2-.2,1.8s.6,1.1,1.1,1.4c.6.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3ZM2561.9,483.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2557.6,498.4c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2452.9,549.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9h0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM2415,515.9c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4.1,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2426.2,543.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2589.1,545.4c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM2590.4,493.3c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2578.1,520.5c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2345.3,607.5c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2354.1,564.1l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2359.5,594.6c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2346.7,522.8c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2316.1,503.1l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2331.3,514.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,512.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2326.7,580.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2616,434.2h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2517.8,632.2c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2451,357.4c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2535.5,538.9c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,1006c-.8,0-1.5-.1-2.2-.4l-36.1-15c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2375.9,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,790.5c-1.6,0-3.1-.9-3.9-2.3t0,0c-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2627.8,787.2c.6,1.2,2,1.6,3.2,1,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2596.1,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2391.7,724.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2395.9,741.9c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2400.2,759c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2404.5,776.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2332,798.4c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2383.1,690.6c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3s-.9,2.5-.6,3.8c.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2387.4,707.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2314,699.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2316.8,710.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2319.6,721.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2322.4,733c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2325.2,744.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2328,755.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2330.8,766.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2333.6,777.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2336.4,789c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.4,811.2h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2523.1,827.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,844.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2510.4,860.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.9,864.5c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2542.1,778.3h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2535.8,794.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.1,764.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2524.9,775.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2520.8,786.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.6,797c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2512.5,807.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.3,818.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2504.2,829.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2500,840c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2495.9,850.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2431,975c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2414.7,968.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2398.4,961.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2382.1,954.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2447.3,981.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2467.1,971.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2456.5,967.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2445.8,962.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2435.1,958.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2424.5,953.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2413.8,949.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2403.1,945c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2392.5,940.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2381.8,936.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2423.8,711.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,708.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2440.5,667.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2427.8,718.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,733.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2406.6,716.3c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2595.7,682.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2420.6,818.1c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2382,813.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2381.6,804.7c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2429.2,813c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2377.3,820c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2369.2,820.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8,0,.3-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM2368.9,798.9c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2558.7,764c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1s.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2594.2,784.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2587.1,790.4c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2556.9,754.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2585.5,798.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2598.4,809c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2589.8,801.3c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2454.9,740.1c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2481.4,727.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.9,735.7c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2459.6,716.5c-1.6,0-3.2-.9-3.9-2.4-.5-1-.6-2.2-.2-3.3s1.2-2,2.2-2.5c2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM2459.5,709.7c-.4,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2460.5,728.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2550.4,729.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2579.1,736.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2560.1,732c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2571.4,715.3c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2571.4,708.5c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8s-.8-.9-1.5-1.1c-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2574.3,727.7c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,780.1c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2301.6,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2424.4,788.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1s-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5-.7,1.1-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2434.6,737c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,761.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2531.6,709.7c-1.2,0-2.4-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5h0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-1.1,1.5-2.9,2.3-4.6,2.3ZM2536.3,665.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8s.7,1.9,1.5,2.5l12.4,9.3c1.7,1.3,4,.9,5.3-.7l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2544.8,695.3c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.9,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2267.8,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2375.9,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2311.3,948.6c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2565,881.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.7,899.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2525.2,891.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2569.3,872c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,907c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4s1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.4,914.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2513.3,893.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2486.4,705.4c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,721.6c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2518,728.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.4,695.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.3,735.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,745.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3.2,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2522.2,738.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2344.8,882.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2327.1,862.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2332.7,880.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.4.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,975.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2320.9,981.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2339.5,971.6c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM2339.5,964.9c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM2342.2,969.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2335.6,983.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2384.7,863c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2373.4,879.5c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2365.9,872.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2374.1,858c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2372.4,852h0c-.9,1-.8,2.5.2,3.3.5.4,1,.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.5-.4-1-.6-1.7-.6-.6,0-1.2.3-1.6.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2356.4,864.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.1,910.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2468.3,892.7c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2496.4,902.8c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.1,887.4c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1,.9.7,1.5,1.8,1.6,2.9s-.2,2.3-1,3.2-1.8,1.5-2.9,1.6c-.1,0-.3,0-.4,0ZM2483.1,880.7c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7s-.4-1.2-.9-1.6h0c-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.6,898c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2534,822.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559.8,836.7c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2542.8,827.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559,814.1c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM2559,807.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.6-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.6.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2557.6,826.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2452.9,877.5c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2415,844.3c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2426.2,872c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2589.1,873.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5h0s1.9,4.3,1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM2590.4,821.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2578.1,848.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2345.3,935.9c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2354.1,892.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2359.5,923c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2346.7,851.2c-1,0-2.1-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2316.1,831.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2331.3,842.7c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,841c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2326.7,908.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2616,762.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2517.8,960.5c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2451,685.8c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2535.5,867.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3.1,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,650.3c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5s-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM2375.9,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,1118.8c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM2627.8,1115.5c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM2596.1,1053l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2391.7,1053.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2395.9,1070.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2400.2,1087.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2404.5,1104.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2332,1126.8c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2383.1,1019c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2387.4,1036.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2314,1027.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2316.8,1039c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2319.6,1050.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2322.4,1061.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2325.2,1072.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2328,1083.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2330.8,1095c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2333.6,1106.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2336.4,1117.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.4,1139.6h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2523.1,1156h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,1172.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2510.4,1188.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.9,1192.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2542.1,1106.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2535.8,1123.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.1,1093c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2524.9,1103.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2520.8,1114.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.6,1125.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2512.5,1136.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.3,1146.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2504.2,1157.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2500,1168.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2495.9,1179.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2431,1303.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2414.7,1296.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2398.4,1289.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2382.1,1283.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2447.3,1310.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2467.1,1299.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2456.5,1295.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2445.8,1291c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2435.1,1286.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2424.5,1282.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2413.8,1277.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2403.1,1273.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2392.5,1269c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2381.8,1264.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2423.8,1040c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,1037c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2440.5,995.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2427.8,1046.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,1061.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2406.6,1044.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2595.7,1010.8c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2420.6,1146.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2382,1142.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2381.6,1133.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2429.2,1141.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2377.3,1148.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2369.2,1148.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM2368.9,1127.3c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2558.7,1092.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2594.2,1113.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2587.1,1118.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2556.9,1082.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2585.5,1126.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2598.4,1137.3c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2589.8,1129.7c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2454.9,1068.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2481.4,1055.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.9,1064.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2459.6,1044.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM2459.5,1038.1c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1,1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.3-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2460.5,1057.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2550.4,1057.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2579.1,1065c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2560.1,1060.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2571.4,1043.6c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2571.4,1036.9c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8s-.8-.9-1.5-1.1c-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2574.3,1056.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,1108.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2299.5,1115.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2424.4,1116.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2434.6,1065.4c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,1089.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2531.6,1038c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2536.3,993.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8s.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2544.8,1023.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.8,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM2267.8,1053l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,1006c-.8,0-1.5-.1-2.2-.4l-36.1-15c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2375.9,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2311.3,1277c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2565,1209.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.7,1227.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2525.2,1220.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2569.3,1200.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,1235.4c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.4,1242.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2513.3,1221.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2486.4,1033.8c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,1050c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2518,1056.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.4,1024.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.3,1064.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,1073.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2522.2,1066.9c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2344.8,1210.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2327.1,1190.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2332.7,1208.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,1325.7s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,1303.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2320.9,1310.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2339.5,1300c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM2339.5,1293.3c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM2342.2,1297.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2335.6,1312.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2384.7,1191.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2373.4,1207.9c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2365.9,1201.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2374.1,1186.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2374.2,1179.6s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM2371.7,1179.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2356.4,1192.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.1,1238.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2468.3,1221.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2496.4,1231.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.1,1215.7c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2,1.5-1.9,4.3-2.1,6.1-.6h0c.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2s-1.8,1.5-2.9,1.6c-.1,0-.3,0-.4,0ZM2483.1,1209c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7s-.4-1.2-.9-1.6h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.6,1226.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2534,1150.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559.8,1165.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2542.8,1155.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559,1142.5c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM2559,1135.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.6-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.6.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2557.6,1155.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2452.9,1205.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2415,1172.6c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2426.2,1200.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2589.1,1202.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM2590.4,1150c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2578.1,1177.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2345.3,1264.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2354.1,1220.8l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2359.5,1251.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2346.7,1179.5c-1,0-2.1-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2316.1,1159.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2331.3,1171.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,1169.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2326.7,1237.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2616,1090.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2517.8,1288.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2451,1014.1c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2535.5,1195.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM2375.9,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,1447.2c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM2627.8,1443.9c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2596.1,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2391.7,1381.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2395.9,1398.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3.1,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2400.2,1415.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2404.5,1432.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2332,1455.1c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2383.1,1347.3c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2387.4,1364.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2314,1356.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2316.8,1367.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2319.6,1378.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2322.4,1389.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2325.2,1400.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2328,1412.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2330.8,1423.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2333.6,1434.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2336.4,1445.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.4,1467.9h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2523.1,1484.4h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,1500.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2510.4,1517.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.9,1521.2c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2542.1,1435h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2535.8,1451.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.1,1421.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2524.9,1432.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2520.8,1442.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.6,1453.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2512.5,1464.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.3,1475.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2504.2,1486c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2500,1496.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2495.9,1507.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2431,1631.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2414.7,1625c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2398.4,1618.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2382.1,1611.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2447.3,1638.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,.1-2.2,1.2-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2467.1,1628.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2456.5,1623.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2445.8,1619.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2435.1,1615c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2424.5,1610.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2413.8,1606.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2403.1,1601.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2392.5,1597.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2381.8,1592.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2423.8,1368.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,1365.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2440.5,1324.2c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2427.8,1375c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,1389.9c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2406.6,1373c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2595.7,1339.2c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2420.6,1474.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2382,1470.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2381.6,1461.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2429.2,1469.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2377.3,1476.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2369.2,1477.3c-2.5,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM2368.9,1455.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2558.7,1420.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1s.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2594.2,1441.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2587.1,1447.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2556.9,1410.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2585.5,1454.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2598.4,1465.7c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8s.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2589.8,1458c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2454.9,1396.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2481.4,1383.8c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.9,1392.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2459.5,1373.2c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM2459.5,1366.4c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.3-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2460.5,1385.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2550.4,1386.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2579.1,1393.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2560.1,1388.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2571.4,1372c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2571.4,1365.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2574.3,1384.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,1436.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,1447.2c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2301.6,1440.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2424.4,1444.8c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2434.6,1393.7c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,1418.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2531.6,1366.4c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5h0s2.8-3.7,2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2536.3,1322.3l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2544.8,1352c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.9,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2267.8,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM2375.9,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2311.3,1605.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2565,1537.8c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.7,1555.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2525.2,1548.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2569.3,1528.7c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,1563.7c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.4,1570.9c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5.9-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2513.3,1550.1c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2486.4,1362.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,1378.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2518,1384.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.4,1352.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.3,1392.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,1401.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2522.2,1395.3c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2344.8,1539c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2327.1,1519.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2332.7,1536.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,1632.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2320.9,1638.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2339.5,1628.3c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM2339.5,1621.6c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM2342.2,1626.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2335.6,1640.6c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2384.7,1519.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2373.4,1536.2c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2365.9,1529.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2374.1,1514.7c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2372.4,1508.7h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2356.4,1521c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.1,1567.3c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2468.3,1549.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.3.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2496.4,1559.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.1,1544.1c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2,1.5-1.9,4.3-2.1,6.1-.6,1.9,1.5,2.1,4.3.6,6.1-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM2483.1,1537.4c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.6,1554.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2534,1478.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559.8,1493.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2542.8,1483.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559,1470.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM2559,1464.1c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2557.6,1483.5c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2452.9,1534.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2415,1501c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2426.2,1528.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2589.1,1530.5c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM2590.4,1478.4c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2578.1,1505.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2345.3,1592.6c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l26.8-28.5c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2354.1,1549.2l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2359.5,1579.7c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2346.7,1507.9c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2316.1,1488.2l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2331.3,1499.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,1497.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2326.7,1565.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2616,1419.3h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2517.8,1617.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2451,1342.5c-.5,0-.9-.3-1-.8l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2535.5,1524c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,1325.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM2375.9,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,1775.5c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM2627.8,1772.2c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.6-1.2-2-1.6-3.2-1-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2596.1,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,4-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2391.7,1709.9c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3.1,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2395.9,1727c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2400.2,1744.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2404.5,1761.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2332,1783.5c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2383.1,1675.7c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2387.4,1692.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2314,1684.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2316.8,1695.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2319.6,1706.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2322.4,1718.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2325.2,1729.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2328,1740.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2330.8,1751.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2333.6,1762.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2336.4,1774c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.4,1796.3h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2523.1,1812.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,1829.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2510.4,1845.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.9,1849.5c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2542.1,1763.4h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2535.8,1779.8h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.1,1749.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2524.9,1760.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2520.8,1771.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.6,1782c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2512.5,1792.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.3,1803.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2504.2,1814.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2500,1825.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2495.9,1835.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2431,1960.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2414.7,1953.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2398.4,1946.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2382.1,1939.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5s-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2447.3,1966.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2467.1,1956.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2456.5,1952.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2445.8,1947.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2435.1,1943.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2424.5,1938.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2413.8,1934.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2403.1,1930.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2392.5,1925.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2381.8,1921.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2423.8,1696.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,1693.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2440.5,1652.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2427.8,1703.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,1718.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2406.6,1701.4c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2595.7,1667.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2420.6,1803.2c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2382,1798.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2381.6,1789.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2429.2,1798.1c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2377.3,1805.1c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2369.2,1805.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM2368.9,1784c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2558.7,1749.1c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2594.2,1769.9c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2587.1,1775.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2556.9,1739.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2585.5,1783.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2598.4,1794c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8s.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2589.8,1786.4c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2454.9,1725.2c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2481.4,1712.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.9,1720.8c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2459.5,1701.5c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3s1.2-2,2.2-2.5c2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM2459.5,1694.8c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2460.5,1714c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2550.4,1714.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2579.1,1721.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2560.1,1717c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2571.4,1700.3c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2s-.8-2.2-.5-3.3c.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2571.4,1693.6c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2574.3,1712.8c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,1765.2c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,1775.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2301.6,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2424.4,1773.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0l1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2434.6,1722.1c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,1746.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2531.6,1694.7c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2536.3,1650.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2544.8,1680.4c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.8,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2267.8,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM2375.9,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2311.3,1933.7c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3s-2.1,1.6-2.5,2.9c-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2565,1866.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.7,1884.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2525.2,1877c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2569.3,1857c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,1892.1c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.4,1899.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2513.3,1878.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2486.4,1690.5c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,1706.7c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2518,1713.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.4,1680.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.3,1721c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,1730.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2522.2,1723.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2344.8,1867.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2327.1,1847.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2332.7,1865.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,1960.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2320.9,1966.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2339.5,1956.7c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM2339.5,1950c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM2342.2,1954.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2335.6,1968.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2384.7,1848c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2373.4,1864.6c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2365.9,1857.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2374.1,1843c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2374.2,1836.3s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM2371.7,1836.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2356.4,1849.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.1,1895.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2468.3,1877.8c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2496.4,1887.9c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.1,1872.4c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2,1.5-1.9,4.3-2.1,6.1-.6h0c.9.7,1.5,1.8,1.6,2.9s-.2,2.3-1,3.2c-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM2483.1,1865.7c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.6,1883.1c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2534,1807.3c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559.8,1821.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2542.8,1812.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559,1799.2c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM2559,1792.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2557.6,1811.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2452.9,1862.6c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2415,1829.3c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2426.2,1857.1c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2589.1,1858.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5h0s1.9,4.3,1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM2590.4,1806.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2578.1,1833.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2345.3,1920.9c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l26.8-28.5c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM2354.1,1877.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2359.5,1908c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2346.7,1836.2c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2316.1,1816.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2331.3,1827.8c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,1826.1c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6s-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2326.7,1893.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2616,1747.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2517.8,1945.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2451,1670.8c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2535.5,1852.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,2319.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,2319.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2375.9,2304.3l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,2313.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2626.8,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2644.2,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2629.9,2103.9c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM2627.8,2100.6c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4s.1-1.2-.2-1.8c-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.8,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2614.2,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2596.1,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2624.3,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2592.5,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2576.8,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2623.9,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2628.8,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2618.5,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2608.2,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2597.9,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2587.7,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2577.4,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2567.1,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2556.8,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2649.8,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2617.2,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2651.2,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2617.5,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2637.7,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2391.7,2038.2c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2395.9,2055.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2400.2,2072.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2404.5,2089.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2332,2111.8c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2383.1,2004c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1s2.5.9,3.8.6c.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2387.4,2021.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2314,2012.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2316.8,2024c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2319.6,2035.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2322.4,2046.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2325.2,2057.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2328,2068.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2330.8,2080c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2333.6,2091.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2336.4,2102.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.4,2124.6h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2523.1,2141.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,2157.5h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2510.4,2173.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.9,2177.9c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2542.1,2091.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2535.8,2108.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2529.1,2078.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2524.9,2088.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2520.8,2099.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2516.6,2110.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2512.5,2121.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2508.3,2131.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2504.2,2142.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2500,2153.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2495.9,2164.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2431,2288.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2414.7,2281.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2398.4,2275c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2382.1,2268.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,2304.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,2301.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7s-2.6-.5-3.8,0c-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2447.3,2295.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2467.1,2284.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2456.5,2280.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2445.8,2276.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2435.1,2271.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2424.5,2267.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2413.8,2262.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2403.1,2258.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2392.5,2254c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2381.8,2249.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2442.1,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2423.8,2025c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,2022c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2440.5,1980.9c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2427.8,2031.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2415.2,2046.6c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2406.6,2029.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2639.1,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2632.5,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2647.9,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2595.7,1995.9c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2588.2,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2587.6,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2420.6,2131.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2382,2127.2c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2381.6,2118.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2429.2,2126.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2377.3,2133.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2369.2,2134c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.4-4.6,11.1-3.3,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM2368.9,2112.3c-2,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2558.7,2077.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2594.2,2098.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2587.1,2103.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2556.9,2067.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2585.5,2111.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2598.4,2122.4c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8s.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2589.8,2114.7c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2454.9,2053.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2481.4,2040.5c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.9,2049.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2459.5,2029.9c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM2459.5,2023.1c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4s-.2,1.2.1,1.8c.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2460.5,2042.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2550.4,2042.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2579.1,2050.1c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c.1-.5.7-.9,1.2-.7l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2560.1,2045.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2571.4,2028.7c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2s-.8-2.2-.5-3.3c.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2571.4,2021.9c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2574.3,2041.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,2093.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,2103.9c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8s.3,4.8-1.8,5.9c-.6.3-1.3.5-2,.5ZM2301.6,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2424.4,2101.5c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM2434.6,2050.4c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,2074.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2531.6,2023.1c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5h0s2.8-3.7,2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2536.3,1979l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2544.8,2008.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.8,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2267.8,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2408.2,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM2375.9,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2392.4,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2311.3,2262c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1s-1.7,2-1.9,3.3c-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2565,2194.5c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.7,2212.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2525.2,2205.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2569.3,2185.4c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,2220.4c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.4,2227.6c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2513.3,2206.8c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2486.4,2018.8c-.4,0-.8-.3-1-.7l-6.6-21.3c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,2035c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2518,2041.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.4,2009.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2517.3,2049.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2530.3,2058.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2522.2,2052c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2344.8,2195.7c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2327.1,2175.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2332.7,2193.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,2313.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,2310.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-1,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,2304s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,2298.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,2292c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,2303.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2313.1,2289c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,2307.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2320.9,2295.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2339.5,2285c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2h0c-.9,1.1-2.1,1.6-3.4,1.6ZM2339.5,2278.3c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.4-.5.6-1.1.5-1.7s-.4-1.2-.9-1.6c-.4-.3-.9-.5-1.5-.5ZM2342.2,2282.8h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2335.6,2297.3c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2384.7,2176.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2373.4,2192.9c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2365.9,2186.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2374.2,2171.4c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM2374.2,2164.6s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM2371.7,2164.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2356.4,2177.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.1,2224c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2468.3,2206.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.5.1-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2496.4,2216.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2483.1,2200.8c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2,1.5-1.9,4.3-2.1,6.1-.6.9.7,1.5,1.8,1.6,2.9s-.2,2.3-1,3.2c-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM2483.1,2194.1c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2490.6,2211.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2534,2135.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559.8,2150.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2542.8,2140.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2559,2127.5c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM2559,2120.8c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2557.6,2140.2c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2452.9,2190.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM2415,2157.7c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2426.2,2185.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2589.1,2187.2c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5h0s1.9,4.3,1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM2590.4,2135.1c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2578.1,2162.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2345.3,2249.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l26.8-28.5c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2354.1,2205.9l-22.9,24.4c-.7.7-1.1,1.7-1,2.7s.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2359.5,2236.4c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2346.7,2164.6c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM2316.1,2144.9l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2331.3,2156.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2418.3,2154.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2326.7,2222.3c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2616,2075.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2517.8,2273.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2451,1999.2c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2535.5,2180.7c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2561.1,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2557.3,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2469.1,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2463.6,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2523.2,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2495.2,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2524.3,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2502.9,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2502.9,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2516.8,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2336.2,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,349.3c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0s-4.3-1.8-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1s.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2047.5,334.2l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9s4.8-.3,5.9,1.8c.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM2299.5,130.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.8,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM2267.8,68l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2063.3,68.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2067.6,85.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2071.9,102.3c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2076.1,119.4c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2003.6,141.7c-.4,0-.9-.3-1-.8l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2054.8,33.9c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2059,51c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1985.7,42.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1988.5,53.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1991.3,65.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1994.1,76.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1996.9,87.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1999.7,98.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2002.5,109.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2005.3,121.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2008.1,132.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2201.1,154.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,171h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,187.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2182.1,203.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180.6,207.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2213.8,121.6h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2207.4,138.1h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2200.7,108c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2196.6,118.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2192.4,129.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.3,140.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2184.1,151c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180,161.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2175.8,172.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2171.7,183.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2167.5,194.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2102.6,318.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2086.4,311.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2070.1,304.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.8,298.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2118.9,325.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2138.8,314.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2128.1,310.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2117.4,306c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2106.8,301.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2096.1,297.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2085.4,292.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2074.8,288.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2064.1,283.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.5,279.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,20.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2095.4,54.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.8,51.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2112.2,10.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2099.4,61.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.9,76.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0s-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2078.3,59.6c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2267.3,25.8c-.6,0-1-.4-1-1V3.6c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2092.3,161.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.7,157.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.3,148c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2100.9,156.3c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2048.9,163.3c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2040.8,163.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM2040.6,142.2c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.3,107.3c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2265.9,128.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2258.8,133.7c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2228.6,97.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2257.1,141.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2270.1,152.3c-.5,0-1,0-1.5-.1-6.3-.9-9.2-7.1-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2261.5,144.6c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2126.6,83.4c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2153.1,70.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.5,79c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2131.2,59.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2s.6,2.2.2,3.3-1.2,2-2.2,2.5c-.6.3-1.3.4-1.9.4ZM2131.2,53c-.4,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2132.2,72.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2222.1,72.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2250.8,80c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.8,75.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2243.1,58.6c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2243.1,51.8c-1.1,0-2,.7-2.3,1.8-.2.6,0,1.2.3,1.8s.8.9,1.5,1.1c.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2246,71c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,123.4c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1971.1,130.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2096,131.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2106.2,80.3c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,104.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2203.2,53c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l23.6-31.2c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2208,8.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2216.4,38.6c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM1939.4,68l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,21c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0s-4.3-1.8-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2047.5,5.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,14.8c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1983,291.9c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2236.7,224.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2202.3,242.5c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196.9,235.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2240.9,215.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,250.3c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.1,257.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM2184.9,236.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2158.1,48.7c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196,64.9c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.6,71.4c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2155.1,39.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.9,79.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,88.5c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.2,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2193.8,81.9c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2016.5,225.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1998.7,205.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2004.4,223.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,328.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2.7-2.2,1.6-2.9c.9-.7,2-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2174.6,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,318.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1992.5,325.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2011.2,314.9c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM2011.2,308.2c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.2,327.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.6c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2056.3,206.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045,222.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2037.6,216.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045.8,201.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2,1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2045.8,194.5c-.6,0-1.3.3-1.8.8h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.5-.4-1-.6-1.6-.6ZM2043.3,194.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2028.1,207.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2161.7,253.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140,236c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2168.1,246.1c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2154.8,230.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,0,0,0,0,0,0,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM2154.8,224c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2162.2,241.3c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2205.7,165.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.5,180c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4s-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2214.4,170.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.6,157.4c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9h0c-.8,1.4-2.3,2.2-3.8,2.2ZM2230.6,150.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3ZM2233.5,154.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2229.2,170.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2124.5,220.8c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2086.7,187.6c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2097.9,215.3c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2260.7,217.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM2262,165c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2249.7,192.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2017,279.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2025.8,235.8l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2031.1,266.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2018.3,194.5c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1987.7,174.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2002.9,186c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,184.3c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1998.4,252.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2287.6,105.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2189.5,303.8c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2122.6,29.1c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2207.1,210.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,3.4c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,5.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,3.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,15.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,12.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,5.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6.3c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2.7-2.2,1.6-2.9c.9-.7,2-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM2174.6-6.4c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,4.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1s.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2047.5,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM2301.6,455.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2s1-.8,1.2-1.4c.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.9,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2267.8,396.3l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2063.3,396.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2067.6,413.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2071.9,430.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3.1,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2076.1,447.8c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2003.6,470.1c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2054.8,362.3c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1s-.5,1-1.1.9c-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2059,379.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1985.7,371.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1988.5,382.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1991.3,393.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1994.1,404.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1996.9,415.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1999.7,427.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2002.5,438.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2005.3,449.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2008.1,460.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2201.1,482.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,499.3h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,515.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2182.1,532.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180.6,536.1c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2213.8,450h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2207.4,466.4h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2200.7,436.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2196.6,447.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2192.4,457.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.3,468.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2184.1,479.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180,490.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2175.8,500.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2171.7,511.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2167.5,522.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2102.6,646.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2086.4,640c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2070.1,633.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.8,626.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2118.9,653.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2138.8,643.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2128.1,638.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2117.4,634.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2106.8,629.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2096.1,625.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2085.4,621.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2074.8,616.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2064.1,612.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.5,607.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2095.4,383.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.8,380.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2112.2,339.1c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2099.4,390c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.9,404.8c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2078.3,388c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2267.3,354.1c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2092.3,489.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.7,485.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.3,476.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2100.9,484.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2048.9,491.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2040.8,492.3c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM2040.6,470.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.3,435.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2265.9,456.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2258.8,462.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2228.6,425.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2257.1,469.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2270.1,480.6c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2261.5,473c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2126.6,411.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2153.1,398.7c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.5,407.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2131.2,388.1c-1.6,0-3.2-.9-3.9-2.4-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2,1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM2131.2,381.4c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4s-.2,1.2.1,1.8c.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.6-2,1.1-3.2h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2132.2,400.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2222.1,401.2c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2250.8,408.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.8,403.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2243.1,386.9c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7s2.2-.8,3.3-.5c2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2243.1,380.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8s.8.9,1.5,1.1c.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8s-.8-.9-1.5-1.1c-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2246,399.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,451.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1973.2,455.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2096,459.7c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5-.7,1.1-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2106.2,408.7c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,433.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2203.2,381.3c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2208,337.2l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2216.4,367c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM1939.4,396.3l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,349.3c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0s-4.3-1.8-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1s.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2047.5,334.2l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1s-1.7,2-1.9,3.3c-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1983,620.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2236.7,552.7c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2202.3,570.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196.9,563.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2240.9,543.6c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,578.7c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.1,585.8c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM2184.9,565c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2158.1,377.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196,393.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.6,399.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2155.1,367.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.9,407.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,416.8c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.2,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2193.8,410.2c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2016.5,553.9c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1998.7,534c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2004.4,551.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,657c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.3-2.4,2.4-4.1,4.8-3.8,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2174.6,650.3c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,647.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1992.5,653.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2011.2,643.3c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2h0c-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM2011.2,636.6c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.2,655.5c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2056.3,534.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045,551.2c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2037.6,544.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045.8,529.6c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2,1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2045.8,522.9c-.6,0-1.3.3-1.8.8-.9,1-.8,2.5.2,3.3.5.4,1,.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.5-.4-1-.6-1.6-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2028.1,536c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2161.7,582.2c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4s1-.5,1.4,0c2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140,564.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2168.1,574.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2154.8,559c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2,1.5-1.9,4.3-2.1,6.1-.6h0c.9.7,1.5,1.8,1.6,2.9s-.2,2.3-1,3.2c-.9,1-2.1,1.6-3.4,1.6ZM2154.8,552.3c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2162.2,569.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2205.7,493.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.5,508.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4s-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2214.4,498.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.6,485.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9h0c-.8,1.4-2.3,2.2-3.8,2.2ZM2230.6,479c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3ZM2233.5,483.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2229.2,498.4c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2124.5,549.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9h0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM2086.7,515.9c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4.1,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2097.9,543.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2260.7,545.4c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0s-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM2262,493.3c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2249.7,520.5c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2017,607.5c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2025.8,564.1l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2031.1,594.6c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2018.3,522.8c-1,0-2-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1987.7,503.1l28.7,17.2c1.8,1.1,4.1.5,5.2-1.3l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2002.9,514.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,512.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1998.4,580.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2287.6,434.2h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2189.5,632.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2122.6,357.4c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2207.1,538.9c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,328.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2.7-2.2,1.6-2.9c.9-.7,2-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2174.6,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,1006c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0s-4.3-1.8-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2047.5,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM2301.6,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.9,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2267.8,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.4.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2063.3,724.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2067.6,741.9c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3.1,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2071.9,759c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2076.1,776.1c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2003.6,798.4c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2054.8,690.6c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3s-.9,2.5-.6,3.8c.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2059,707.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1985.7,699.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1988.5,710.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1991.3,721.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1994.1,733c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1996.9,744.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1999.7,755.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2002.5,766.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2005.3,777.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2008.1,789c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2201.1,811.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,827.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,844.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2182.1,860.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180.6,864.5c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2213.8,778.3h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2207.4,794.8h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2200.7,764.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2196.6,775.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2192.4,786.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.3,797c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2184.1,807.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180,818.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2175.8,829.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2171.7,840c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2167.5,850.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2102.6,975c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2086.4,968.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2070.1,961.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.8,954.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2118.9,981.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2138.8,971.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2128.1,967.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2117.4,962.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2106.8,958.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2096.1,953.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2085.4,949.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2074.8,945c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2064.1,940.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.5,936.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2095.4,711.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.8,708.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2112.2,667.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2099.4,718.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.9,733.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0s-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2078.3,716.3c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2267.3,682.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2092.3,818.1c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.7,813.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.3,804.7c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2100.9,813c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2048.9,820c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2040.8,820.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8,0,.3-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM2040.6,798.9c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.3,764c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2265.9,784.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2258.8,790.4c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2228.6,754.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2257.1,798.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2270.1,809c-.5,0-1,0-1.5-.1-6.3-.9-9.2-7.1-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2261.5,801.3c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2126.6,740.1c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2153.1,727.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.5,735.7c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2131.2,716.5c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.4-1.9.4ZM2131.2,709.7c-.4,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2132.2,728.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2222.1,729.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2250.8,736.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.8,732c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2243.1,715.3c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7s2.2-.8,3.3-.5c2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2243.1,708.5c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8s.8.9,1.5,1.1c.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2246,727.7c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,780.1c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM1973.2,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2096,788.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1s-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5-.7,1.1-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2106.2,737c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,761.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2203.2,709.7c-1.2,0-2.4-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-1.1,1.5-2.9,2.3-4.6,2.3ZM2208,665.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c1.7,1.3,4,.9,5.3-.7l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2216.4,695.3c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM1939.4,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2047.5,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1983,948.6c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2236.7,881.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2202.3,899.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196.9,891.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2240.9,872c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,907c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.1,914.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2184.9,893.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2158.1,705.4c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196,721.6c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.6,728.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2155.1,695.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.9,735.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,745.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2193.8,738.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2016.5,882.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1998.7,862.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2004.4,880.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,985.3c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2.7-2.2,1.6-2.9c.9-.7,2-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM2174.6,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,975.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1992.5,981.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2011.2,971.6c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM2011.2,964.9c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.2,983.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2056.3,863c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045,879.5c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2037.6,872.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045.8,858c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2044.1,852h0c-.9,1-.8,2.5.2,3.3.5.4,1,.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2028.1,864.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2161.7,910.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140,892.7c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2168.1,902.8c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2154.8,887.4c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2,1.5-1.9,4.3-2.1,6.1-.6h0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM2154.8,880.7c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2162.2,898c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2205.7,822.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.5,836.7c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2214.4,827.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.6,814.1c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3h0c-.8,1.4-2.3,2.2-3.8,2.2ZM2230.6,807.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3ZM2233.5,811.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2229.2,826.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2124.5,877.5c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2086.7,844.3c-.7,0-1.4.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2097.9,872c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2260.7,873.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0s-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5h0s1.9,4.3,1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM2262,821.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2249.7,848.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2017,935.9c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2025.8,892.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2031.1,923c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2018.3,851.2c-1,0-2-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1987.7,831.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2002.9,842.7c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,841c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1998.4,908.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2287.6,762.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2189.5,960.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2122.6,685.8c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2207.1,867.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,657c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.3-2.4,2.4-4.1,4.8-3.8,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2174.6,650.3c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM2047.5,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM2299.5,1115.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.8,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM2267.8,1053l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2063.3,1053.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2067.6,1070.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2071.9,1087.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2076.1,1104.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2003.6,1126.8c-.4,0-.9-.3-1-.8l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2054.8,1019c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2059,1036.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1985.7,1027.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1988.5,1039c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1991.3,1050.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1994.1,1061.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1996.9,1072.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1999.7,1083.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2002.5,1095c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2005.3,1106.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2008.1,1117.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2201.1,1139.6h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,1156h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,1172.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2182.1,1188.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180.6,1192.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2213.8,1106.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2207.4,1123.1h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2200.7,1093c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2196.6,1103.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2192.4,1114.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.3,1125.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2184.1,1136.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180,1146.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2175.8,1157.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2171.7,1168.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2167.5,1179.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2102.6,1303.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2086.4,1296.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2070.1,1289.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.8,1283.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2118.9,1310.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2138.8,1299.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2128.1,1295.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2117.4,1291c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2106.8,1286.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2096.1,1282.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2085.4,1277.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2074.8,1273.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2064.1,1269c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.5,1264.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2095.4,1040c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.8,1037c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2112.2,995.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2099.4,1046.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.9,1061.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2s-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2078.3,1044.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2267.3,1010.8c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2092.3,1146.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.7,1142.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.3,1133.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2100.9,1141.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2048.9,1148.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2040.8,1148.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM2040.6,1127.3c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.3,1092.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2265.9,1113.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2258.8,1118.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2228.6,1082.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2257.1,1126.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2270.1,1137.3c-.5,0-1,0-1.5-.1-6.3-.9-9.2-7.1-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2261.5,1129.7c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2126.6,1068.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2153.1,1055.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.5,1064.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2131.2,1044.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2s.6,2.2.2,3.3-1.2,2-2.2,2.5c-.6.3-1.3.4-1.9.4ZM2131.2,1038.1c-.4,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2132.2,1057.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2222.1,1057.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2250.8,1065c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.8,1060.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2243.1,1043.6c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2243.1,1036.9c-1.1,0-2,.7-2.3,1.8-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2246,1056.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,1108.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1971.1,1115.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2096,1116.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2106.2,1065.4c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,1089.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2203.2,1038c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l23.6-31.2c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2208,993.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2216.4,1023.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM1939.4,1053l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,1006c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0s-4.3-1.8-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2047.5,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1983,1277c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2236.7,1209.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2202.3,1227.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196.9,1220.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2240.9,1200.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,1235.4c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.1,1242.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2184.9,1221.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2158.1,1033.8c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196,1050c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.6,1056.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2155.1,1024.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.9,1064.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,1073.5c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.2,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2193.8,1066.9c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2016.5,1210.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1998.7,1190.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2004.4,1208.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,1325.7s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,1313.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2174.6,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,1303.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1992.5,1310.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2011.2,1300c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM2011.2,1293.3c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.2,1312.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2056.3,1191.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045,1207.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2037.6,1201.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045.8,1186.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2,1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2045.8,1179.6c-.6,0-1.3.3-1.8.8h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.5-.4-1-.6-1.6-.6ZM2043.3,1179.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2028.1,1192.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2161.7,1238.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140,1221.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2168.1,1231.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2154.8,1215.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,0,0,0,0,0,0,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM2154.8,1209c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2162.2,1226.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2205.7,1150.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.5,1165.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2214.4,1155.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.6,1142.5c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9h0c-.8,1.4-2.3,2.2-3.8,2.2ZM2230.6,1135.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3ZM2233.5,1139.8h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2229.2,1155.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2124.5,1205.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2086.7,1172.6c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2097.9,1200.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2260.7,1202.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM2262,1150c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2249.7,1177.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2017,1264.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2025.8,1220.8l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2031.1,1251.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2018.3,1179.5c-1,0-2-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1987.7,1159.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2002.9,1171.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,1169.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1998.4,1237.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2287.6,1090.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2189.5,1288.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2122.6,1014.1c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2207.1,1195.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,985.3c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2.7-2.2,1.6-2.9c.9-.7,2-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM2174.6,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.2-.5.8-.7,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM2047.5,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,1447.2c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM2301.6,1440.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.9,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM2267.8,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2063.3,1381.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2067.6,1398.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3.1,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2071.9,1415.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2076.1,1432.8c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2003.6,1455.1c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2054.8,1347.3c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2059,1364.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1985.7,1356.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1988.5,1367.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1991.3,1378.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1994.1,1389.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1996.9,1400.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1999.7,1412.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2002.5,1423.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2005.3,1434.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2008.1,1445.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2201.1,1467.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,1484.4h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,1500.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2182.1,1517.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180.6,1521.2c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2213.8,1435h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2207.4,1451.5h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2200.7,1421.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2196.6,1432.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2192.4,1442.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.3,1453.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2184.1,1464.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180,1475.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2175.8,1486c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2171.7,1496.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2167.5,1507.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2102.6,1631.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2086.4,1625c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2070.1,1618.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.8,1611.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2118.9,1638.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2138.8,1628.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2128.1,1623.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2117.4,1619.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2106.8,1615c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2096.1,1610.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2085.4,1606.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2074.8,1601.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2064.1,1597.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.5,1592.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2095.4,1368.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.8,1365.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2112.2,1324.2c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2099.4,1375c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.9,1389.9c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2078.3,1373c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2267.3,1339.2c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2092.3,1474.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.7,1470.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.3,1461.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2100.9,1469.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2048.9,1476.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2040.8,1477.3c-2.5,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM2040.6,1455.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.3,1420.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2265.9,1441.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2258.8,1447.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2228.6,1410.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2257.1,1454.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2270.1,1465.7c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8s.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2261.5,1458c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2126.6,1396.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2153.1,1383.8c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.5,1392.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2131.2,1373.2c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.5-1.9.5ZM2131.2,1366.4c-.4,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2132.2,1385.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2222.1,1386.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2250.8,1393.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.8,1388.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2243.1,1372c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2243.1,1365.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2246,1384.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,1436.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,1447.2c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM1973.2,1440.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2096,1444.8c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2106.2,1393.7c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,1418.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2203.2,1366.4c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l20.7-27.5h0s2.8-3.7,2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2208,1322.3l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2216.4,1352c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM1939.4,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM2047.5,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1983,1605.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2236.7,1537.8c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2202.3,1555.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196.9,1548.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2240.9,1528.7c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,1563.7c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.1,1570.9c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5.9-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2184.9,1550.1c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2158.1,1362.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196,1378.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.6,1384.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2155.1,1352.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.9,1392.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,1401.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.2,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2193.8,1395.3c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2016.5,1539c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1998.7,1519.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2004.4,1536.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,1642c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2174.6,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,1632.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1s.5,1,.1,1.4c-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1992.5,1638.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2011.2,1628.3c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.2-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM2011.2,1621.6c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.2,1640.6c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2056.3,1519.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045,1536.2c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2037.6,1529.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045.8,1514.7c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2044.1,1508.7h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2028.1,1521c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2161.7,1567.3c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140,1549.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.3.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2168.1,1559.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2154.8,1544.1c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6t0,0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM2154.8,1537.4c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2162.2,1554.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2205.7,1478.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.5,1493.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2214.4,1483.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.6,1470.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3h0c-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM2230.6,1464.1c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3ZM2233.5,1468.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2229.2,1483.5c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2124.5,1534.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2086.7,1501c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2097.9,1528.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2260.7,1530.5c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM2262,1478.4c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2249.7,1505.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2017,1592.5c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l26.8-28.5c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM2025.8,1549.2l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2031.1,1579.7c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2018.3,1507.9c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1987.7,1488.2l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2002.9,1499.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,1497.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3s.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1998.4,1565.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2287.6,1419.3h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2189.5,1617.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2122.6,1342.5c-.5,0-.9-.3-1-.8l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2207.1,1524c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,1325.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,1313.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM2174.6,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM2047.5,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,1775.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM2301.6,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.8,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2267.8,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2063.3,1709.9c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3.1,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2067.6,1727c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2071.9,1744.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2076.1,1761.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8s1.6,2.1,2.8,2.6c1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2003.6,1783.5c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2054.8,1675.7c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2059,1692.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1985.7,1684.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1988.5,1695.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1991.3,1706.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1994.1,1718.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1996.9,1729.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1999.7,1740.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2002.5,1751.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2005.3,1762.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2008.1,1774c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2201.1,1796.3h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,1812.7h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.7-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,1829.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2182.1,1845.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180.6,1849.5c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2213.8,1763.4h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2207.4,1779.8h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2200.7,1749.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2196.6,1760.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2192.4,1771.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.3,1782c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2184.1,1792.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180,1803.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2175.8,1814.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2171.7,1825.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2167.5,1835.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2102.6,1960.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2086.4,1953.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2070.1,1946.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.8,1939.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2118.9,1966.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2138.8,1956.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2128.1,1952.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2117.4,1947.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2106.8,1943.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2096.1,1938.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2085.4,1934.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2074.8,1930.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2064.1,1925.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.5,1921.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2095.4,1696.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.8,1693.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2112.2,1652.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2099.4,1703.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.9,1718.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2078.3,1701.4c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2267.3,1667.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2092.3,1803.2c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.7,1798.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.3,1789.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2100.9,1798.1c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2048.9,1805.1c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2040.8,1805.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM2040.6,1784c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.3,1749.1c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2265.9,1769.9c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2258.8,1775.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2228.6,1739.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2257.1,1783.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2270.1,1794c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8s.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2261.5,1786.4c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2126.6,1725.2c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2153.1,1712.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.5,1720.8c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2131.2,1701.5c-1.6,0-3.2-.9-3.9-2.4-.5-1-.6-2.2-.2-3.3s1.2-2,2.2-2.5c1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2s.6,2.2.2,3.3-1.2,2-2.2,2.5c-.6.3-1.3.4-1.9.4ZM2131.2,1694.8c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2132.2,1714c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2222.1,1714.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2250.8,1721.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.8,1717c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2243.1,1700.3c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2s-.8-2.2-.5-3.3c.6-2.3,3-3.8,5.3-3.2,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2243.1,1693.6c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2246,1712.8c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,1765.2c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,1775.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM1973.2,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2096,1773.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM2106.2,1722.1c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,1746.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2203.2,1694.7c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2208,1650.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2216.4,1680.4c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1939.4,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.2-.5.8-.7,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM2047.5,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1983,1933.7c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3s-2.1,1.6-2.5,2.9c-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2236.7,1866.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2202.3,1884.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196.9,1877c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2240.9,1857c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,1892.1c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.1,1899.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2184.9,1878.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2158.1,1690.5c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196,1706.7c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.6,1713.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2155.1,1680.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.9,1721c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,1730.2c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2193.8,1723.6c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2016.5,1867.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1998.7,1847.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2004.4,1865.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.6,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2174.6,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,1960.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1992.5,1966.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2011.2,1956.7c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.2-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM2011.2,1950c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7s.4,1.2.9,1.6,1.1.6,1.7.5c.6,0,1.2-.4,1.6-.9h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.2,1968.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2056.3,1848c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045,1864.6c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2037.6,1857.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045.8,1843c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2,1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM2045.8,1836.3s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM2043.3,1836.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2028.1,1849.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2161.7,1895.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140,1877.8c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2168.1,1887.9c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2154.8,1872.4c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6t0,0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM2154.8,1865.7c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2162.2,1883.1c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2205.7,1807.3c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.5,1821.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2214.4,1812.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.6,1799.2c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9h0c-.8,1.4-2.3,2.2-3.8,2.2ZM2230.6,1792.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3ZM2233.5,1796.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2229.2,1811.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2124.5,1862.6c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM2086.7,1829.3c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2097.9,1857.1c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2260.7,1858.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5h0s1.9,4.3,1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM2262,1806.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2249.7,1833.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2017,1920.9c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l26.8-28.5c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM2025.8,1877.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2031.1,1908c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2018.3,1836.2c-1,0-2-.3-3-.8l-29.5-17.7h0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1987.7,1816.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2002.9,1827.8c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,1826.1c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3s.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1998.4,1893.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2287.6,1747.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2189.5,1945.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2122.6,1670.8c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2207.1,1852.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,1642c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2174.6,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,2319.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,2319.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.7,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM2047.5,2304.3l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,2313.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2298.5,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2315.8,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2301.6,2103.9c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM2301.6,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.5,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2285.8,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM2267.8,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2295.9,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2264.1,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2248.4,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2295.6,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2310.8,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2300.5,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2290.2,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2279.9,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2269.6,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2259.3,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2249,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2238.7,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2228.4,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2321.4,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2288.9,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2322.8,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM2289.1,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2309.3,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2063.3,2038.2c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2067.6,2055.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2071.9,2072.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2076.1,2089.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2003.6,2111.8c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2054.8,2004c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1,1.1.7,2.5.9,3.8.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2059,2021.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1985.7,2012.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1988.5,2024c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1991.3,2035.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1994.1,2046.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1996.9,2057.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1999.7,2068.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2002.5,2080c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2005.3,2091.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2008.1,2102.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2201.1,2124.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,2141.1h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,2157.5h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2182.1,2173.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180.6,2177.9c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2213.8,2091.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2207.4,2108.2h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2200.7,2078.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2196.6,2088.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2192.4,2099.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2188.3,2110.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2184.1,2121.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2180,2131.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2175.8,2142.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2171.7,2153.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2167.5,2164.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2102.6,2288.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2086.4,2281.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2070.1,2275c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.8,2268.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,2304.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,2301.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2118.9,2295.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2138.8,2284.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2128.1,2280.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2117.4,2276.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2106.8,2271.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2096.1,2267.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2085.4,2262.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2074.8,2258.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2064.1,2254c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2053.5,2249.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2113.8,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2095.4,2025c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.8,2022c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2112.2,1980.9c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2099.4,2031.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2086.9,2046.6c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM2078.3,2029.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2310.7,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2304.1,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2319.5,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2267.3,1995.9c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2259.9,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM2259.3,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2092.3,2131.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.7,2127.2c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2053.3,2118.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2100.9,2126.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2048.9,2133.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2040.8,2134c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.4-4.6,11.1-3.3,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM2040.6,2112.3c-2,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.3,2077.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2265.9,2098.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2258.8,2103.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2228.6,2067.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2257.1,2111.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2270.1,2122.4c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8s.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM2261.5,2114.7c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2126.6,2053.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2153.1,2040.5c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.5,2049.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2131.2,2029.9c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2h0c.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.5-1.9.5ZM2131.2,2023.1c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4s-.2,1.2.1,1.8c.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2132.2,2042.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2222.1,2042.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2250.8,2050.1c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c.1-.5.7-.9,1.2-.7l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.8,2045.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2243.1,2028.7c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2s-.8-2.2-.5-3.3c.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM2243.1,2021.9c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM2246.3,2025.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2246,2041.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,2093.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,2103.9c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM1973.2,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2096,2101.5c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM2106.2,2050.4c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,2074.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2203.2,2023.1c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM2208,1979l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2216.4,2008.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1939.4,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2079.8,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM2047.5,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2064,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1983,2262c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2236.7,2194.5c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2202.3,2212.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196.9,2205.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2240.9,2185.4c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,2220.4c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.1,2227.6c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.4,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM2184.9,2206.8c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2158.1,2018.8c-.4,0-.8-.3-1-.7l-6.6-21.3c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2196,2035c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2189.6,2041.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2155.1,2009.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.9,2049.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2201.9,2058.5c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.2,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM2193.8,2052c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2016.5,2195.7c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1998.7,2175.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2004.4,2193.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.6,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,2313.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,2310.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-1,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,2304s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,2298.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.3-2.4,2.4-4.1,4.8-3.8,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2174.6,2292c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,2303.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1984.7,2289c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,2307.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1992.5,2295.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2011.2,2285c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM2011.2,2278.3c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2007.2,2297.3c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2056.3,2176.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045,2192.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2037.6,2186.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2045.8,2171.4c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM2045.8,2164.6s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM2043.3,2164.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2028.1,2177.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2161.7,2224c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140,2206.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.5.1-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2168.1,2216.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2154.8,2200.8c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9s.2-2.3,1-3.2c1.5-1.9,4.3-2.1,6.1-.6t0,0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM2154.8,2194.1c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2162.2,2211.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2205.7,2135.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2231.5,2150.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2214.4,2140.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2230.6,2127.5c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3h0c-.8,1.4-2.3,2.2-3.8,2.2ZM2230.6,2120.8c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3ZM2233.5,2124.8h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2229.2,2140.2c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2124.5,2190.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM2086.7,2157.7c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2097.9,2185.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2260.7,2187.2c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5h0s1.9,4.3,1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM2262,2135.1c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2249.7,2162.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2017,2249.2c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l26.8-28.5c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM2025.8,2205.9l-22.9,24.4c-.7.7-1.1,1.7-1,2.7s.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2031.1,2236.4c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2018.3,2164.6c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1987.7,2144.9l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2002.9,2156.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2089.9,2154.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3s.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1998.4,2222.3c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2287.6,2075.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2189.5,2273.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2122.6,1999.2c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2207.1,2180.7c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2232.7,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2228.9,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2140.7,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2135.2,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2194.8,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2166.9,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2195.9,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2174.6,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM2174.6,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2188.4,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M2007.8,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2s.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,349.3c-.7,0-1.5-.1-2.2-.4l-36.1-15c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1s.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,334.2l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1971.1,130.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM1939.4,68l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735,68.1c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1739.2,85.2c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1743.5,102.3c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1747.8,119.4c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1675.3,141.7c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1726.4,33.9c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1730.7,51c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1657.3,42.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1660.1,53.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,65.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1665.7,76.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1668.5,87.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1671.3,98.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1674.1,109.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1676.9,121.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1679.7,132.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.7,154.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1866.4,171h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,187.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1853.7,203.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1852.2,207.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1885.4,121.6h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.4.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1879.1,138.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.4,108c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1868.2,118.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1864.1,129.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1859.9,140.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1855.8,151c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1851.6,161.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1847.5,172.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1843.3,183.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1839.2,194.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1774.3,318.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1758,311.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1741.7,304.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.4,298.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1790.6,325.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1810.4,314.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1799.8,310.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1789.1,306c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1778.4,301.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1767.8,297.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1757.1,292.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1746.4,288.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735.8,283.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.1,279.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,20.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.1,54.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,51.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1783.8,10.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1771.1,61.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,76.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1749.9,59.6c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1939,25.8c-.6,0-1-.4-1-1V3.6c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1763.9,161.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1725.3,157.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1724.9,148c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1772.5,156.3c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1720.6,163.3c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1712.5,163.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM1712.2,142.2c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902,107.3c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1937.6,128.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1930.4,133.7c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.2,97.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1928.8,141.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1941.7,152.3c-.5,0-1,0-1.5-.1-6.3-.9-9.2-7.1-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1933.2,144.6c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1798.2,83.4c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1824.7,70.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1807.2,79c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1802.9,59.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.4-1.9.4ZM1802.8,53c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1803.8,72.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1893.7,72.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1922.4,80c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.4,75.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1914.7,58.6c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3-.5,2-2.3,3.3-4.2,3.3ZM1914.7,51.8c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1917.6,71c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,123.4c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3s2,1.1,2.6,2.1c.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1642.8,130.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.7,131.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2s.5-1.3.3-2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM1777.9,80.3c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,104.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874.9,53c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l23.6-31.2c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1879.6,8.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1888.1,38.6c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.1,83.7c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1611.1,68l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,21c-.7,0-1.5-.1-2.2-.4l-36.1-15c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,5.9l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,14.8c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.6,291.9c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1908.3,224.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874,242.5c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1868.5,235.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2s.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1912.6,215.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,250.3c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.7,257.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM1856.6,236.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1829.7,48.7c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,64.9c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1861.3,71.4c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.8,39.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.6,79.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,88.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1865.5,81.9c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.1,225.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1670.4,205.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1676,223.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,328.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2c.3-2.4,2.4-4.1,4.8-3.8h0c2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM1846.2,321.9c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,318.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.2,325.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1682.8,314.9c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.9,1.1-2.1,1.6-3.4,1.6ZM1682.8,308.2c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4s.6-2.5-.4-3.3c-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1678.9,327.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.6c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1728,206.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1716.7,222.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1709.2,216.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1717.5,201.3c-1.1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3,0-1.2.3-2.3,1.1-3.1,1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3s-.3,2.3-1.1,3.1-1.8,1.4-3,1.4c0,0-.2,0-.2,0ZM1717.5,194.5c-.6,0-1.3.3-1.8.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-.5-.4-1-.6-1.6-.6ZM1715,194.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1699.7,207.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.4,253.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1811.6,236c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1839.7,246.1c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.4,230.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,0,0,0,0,0,0,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM1826.4,224c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.9,241.3c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1877.3,165.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.1,180c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4s-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.2.1.4.3.5.6s0,.5,0,.8l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1886.1,170.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902.3,157.4c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1902.3,150.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.9,170.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1796.2,220.8c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1758.3,187.6c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1769.5,215.3c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1932.4,217.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM1933.7,165c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1921.4,192.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.6,279.2c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1697.4,235.8l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1702.8,266.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1690,194.5c-1,0-2.1-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5s.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1659.4,174.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1674.6,186c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,184.3c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1670,252.2c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1959.3,105.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1861.1,303.8c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1794.3,29.1c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1878.8,210.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1654,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,3.4c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,5.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,3.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,15.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,12.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,5.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2.3c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2c.3-2.4,2.4-4.1,4.8-3.8h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1846.2-6.4c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,4.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,677.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1s.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,662.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1973.2,455.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM1939.4,396.3l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1s-1.7,2-1.9,3.3c-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735,396.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1739.2,413.6c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1743.5,430.7c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1747.8,447.8c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1675.3,470.1c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1726.4,362.3c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1730.7,379.4c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1657.3,371.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1660.1,382.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,393.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1665.7,404.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1668.5,415.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1671.3,427.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1674.1,438.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1676.9,449.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1679.7,460.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.7,482.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1866.4,499.3h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,515.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1853.7,532.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1852.2,536.1c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1885.4,450h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1879.1,466.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.4,436.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1868.2,447.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1864.1,457.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1859.9,468.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1855.8,479.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1851.6,490.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1847.5,500.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1843.3,511.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1839.2,522.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1774.3,646.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1758,640c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1741.7,633.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.4,626.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1790.6,653.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1810.4,643.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1799.8,638.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1789.1,634.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1778.4,629.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1767.8,625.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1757.1,621.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1746.4,616.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735.8,612.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.1,607.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2s.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.1,383.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,380.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1783.8,339.1c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1771.1,390c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,404.8c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1749.9,388c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1939,354.1c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1763.9,489.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1725.3,485.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1724.9,476.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1772.5,484.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1720.6,491.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1712.5,492.3c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM1712.2,470.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902,435.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1937.6,456.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1930.4,462.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.2,425.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1928.8,469.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1941.7,480.6c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1933.2,473c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1798.2,411.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1824.7,398.7c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1807.2,407.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1802.9,388.1c-1.6,0-3.2-.9-3.9-2.4-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM1802.8,381.4c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4s-.2,1.2.1,1.8c.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.6-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1803.8,400.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1893.7,401.2c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1922.4,408.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.4,403.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1914.7,386.9c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3-.5,2-2.3,3.3-4.2,3.3ZM1914.7,380.2c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7h0c.2-.6,0-1.2-.3-1.8s-.8-.9-1.5-1.1c-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1917.6,399.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,451.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,481.9c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3s2,1.1,2.6,2.1c.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1644.9,455.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.7,459.7c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2s.5-1.3.3-2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5-.7,1.1-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM1777.9,408.7c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,433.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874.9,381.3c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1879.6,337.2l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1888.1,367c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.1,412c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1611.1,396.3l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,349.3c-.7,0-1.5-.1-2.2-.4l-36.1-15c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1s.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,334.2l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1s-1.7,2-1.9,3.3c-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.6,620.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1908.3,552.7c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874,570.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1868.5,563.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1912.6,543.6c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,578.7c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.7,585.8c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM1856.6,565c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1829.7,377.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,393.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1861.3,399.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.8,367.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.6,407.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,416.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1865.5,410.2c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.1,553.9c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1670.4,534c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1676,551.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,657c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.3-2.4,2.4-4.1,4.8-3.8h0c2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM1846.2,650.3c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,647.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.2,653.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1682.8,643.3c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2s1.8-1.5,2.9-1.6c1.2-.1,2.3.2,3.2.9.9.7,1.5,1.8,1.6,2.9s-.2,2.3-.9,3.2c-.9,1.1-2.1,1.6-3.4,1.6ZM1682.8,636.6c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1678.9,655.5c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1728,534.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1716.7,551.2c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1709.2,544.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1717.5,529.6c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2,1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.9,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1717.5,522.9c-.6,0-1.3.3-1.8.8-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-.5-.4-1-.6-1.6-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1699.7,536c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.4,582.2c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4s1-.5,1.4,0c2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1811.6,564.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1839.7,574.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.4,559c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2,1.5-1.9,4.3-2.1,6.1-.6h0c.9.7,1.5,1.8,1.6,2.9s-.2,2.3-1,3.2c-.9,1-2.1,1.6-3.4,1.6ZM1826.4,552.3c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.9,569.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1877.3,493.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.1,508.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4s-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.2.1.4.3.5.6s0,.5,0,.8l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1886.1,498.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902.3,485.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9h0c-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1902.3,479c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3ZM1905.2,483.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.9,498.4c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1796.2,549.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9h0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1758.3,515.9c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4.1,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1769.5,543.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1932.4,545.4c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1933.7,493.3c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1921.4,520.5c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.6,607.5c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1697.4,564.1l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1702.8,594.6c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1690,522.8c-1,0-2-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1659.4,503.1l28.7,17.2c1.8,1.1,4.1.5,5.2-1.3l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1674.6,514.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,512.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1670,580.5c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1959.3,434.2h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1861.1,632.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1794.3,357.4c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1878.8,538.9c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1654,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,328.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2c.3-2.4,2.4-4.1,4.8-3.8h0c2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM1846.2,321.9c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,1006c-.7,0-1.5-.1-2.2-.4l-36.1-15c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,990.9l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM1973.2,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1939.4,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735,724.8c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1739.2,741.9c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1743.5,759c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1747.8,776.1c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1675.3,798.4c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1726.4,690.6c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1730.7,707.7c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1657.3,699.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1660.1,710.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,721.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1665.7,733c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1668.5,744.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1671.3,755.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1674.1,766.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1676.9,777.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1679.7,789c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.7,811.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1866.4,827.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,844.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1853.7,860.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1852.2,864.5c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1885.4,778.3h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.4.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1879.1,794.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.4,764.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1868.2,775.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1864.1,786.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1859.9,797c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1855.8,807.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1851.6,818.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1847.5,829.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1843.3,840c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1839.2,850.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1774.3,975c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1758,968.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1741.7,961.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.4,954.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1790.6,981.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1810.4,971.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1799.8,967.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1789.1,962.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1778.4,958.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1767.8,953.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1757.1,949.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1746.4,945c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735.8,940.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.1,936.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.1,711.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,708.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1783.8,667.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1771.1,718.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,733.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1749.9,716.3c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1939,682.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1763.9,818.1c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1725.3,813.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1724.9,804.7c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1772.5,813c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1720.6,820c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1712.5,820.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8,0,.3-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM1712.2,798.9c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902,764c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1937.6,784.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1930.4,790.4c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.2,754.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1928.8,798.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1941.7,809c-.5,0-1,0-1.5-.1-6.3-.9-9.2-7.1-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1933.2,801.3c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1798.2,740.1c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1824.7,727.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1807.2,735.7c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1802.9,716.5c-1.6,0-3.2-.9-3.9-2.4-.5-1-.6-2.2-.2-3.3s1.2-2,2.2-2.5c2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.4-1.9.4ZM1802.8,709.7c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1803.8,728.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1893.7,729.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1922.4,736.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.4,732c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1914.7,715.3c-.4,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM1914.7,708.5c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1917.6,727.7c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,780.1c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,810.2c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6s2.2-.6,3.3-.3c1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM1644.9,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.7,788.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1777.9,737c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,761.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874.9,709.7c-1.2,0-2.4-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-1.1,1.5-2.9,2.3-4.6,2.3ZM1879.6,665.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c1.7,1.3,4,.9,5.3-.7l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1888.1,695.3c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.1,740.4c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1611.1,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,677.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,662.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.6,948.6c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1908.3,881.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874,899.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1868.5,891.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1912.6,872c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,907c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.7,914.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1856.6,893.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1829.7,705.4c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,721.6c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1861.3,728.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.8,695.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.6,735.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,745.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1865.5,738.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.1,882.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1670.4,862.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1676,880.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,985.3c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2c.3-2.4,2.4-4.1,4.8-3.8h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1846.2,978.6c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,975.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.2,981.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1682.8,971.6c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM1682.8,964.9c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4s.6-2.5-.4-3.3c-.4-.3-1-.5-1.5-.5ZM1685.5,969.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1678.9,983.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1728,863c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1716.7,879.5c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1709.2,872.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1717.5,858c-1.1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3,0-1.2.3-2.3,1.1-3.1h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1715.7,852h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6s1.1.6,1.7.6c.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1699.7,864.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.4,910.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1811.6,892.7c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1839.7,902.8c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.4,887.4c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2,1.5-1.9,4.3-2.1,6.1-.6h0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM1826.4,880.7c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.9,898c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1877.3,822.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.1,836.7c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.2.1.4.3.5.6s0,.5,0,.8l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1886.1,827.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902.3,814.1c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1902.3,807.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.9,826.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1796.2,877.5c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1758.3,844.3c-.7,0-1.4.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1769.5,872c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1932.4,873.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1933.7,821.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1921.4,848.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.6,935.9c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1697.4,892.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1702.8,923c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1690,851.2c-1,0-2-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5s.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1659.4,831.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1674.6,842.7c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,841c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1670,908.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1959.3,762.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1861.1,960.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1794.3,685.8c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1878.8,867.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1654,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,657c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.3-2.4,2.4-4.1,4.8-3.8h0c2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM1846.2,650.3c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5s-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1971.1,1115.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM1939.4,1053l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735,1053.2c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1739.2,1070.3c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1743.5,1087.4c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1747.8,1104.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1675.3,1126.8c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1726.4,1019c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3s-.9,2.5-.6,3.8c.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1730.7,1036.1c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1657.3,1027.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1660.1,1039c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,1050.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1665.7,1061.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1668.5,1072.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1671.3,1083.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1674.1,1095c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1676.9,1106.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1679.7,1117.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.7,1139.6h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1866.4,1156h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,1172.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1853.7,1188.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1852.2,1192.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1885.4,1106.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.4.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1879.1,1123.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.4,1093c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1868.2,1103.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1864.1,1114.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1859.9,1125.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1855.8,1136.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1851.6,1146.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1847.5,1157.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1843.3,1168.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1839.2,1179.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1774.3,1303.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1758,1296.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1741.7,1289.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.4,1283.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1790.6,1310.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1810.4,1299.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1799.8,1295.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1789.1,1291c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1778.4,1286.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1767.8,1282.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1757.1,1277.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1746.4,1273.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735.8,1269c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.1,1264.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.1,1040c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,1037c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1783.8,995.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1771.1,1046.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,1061.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2s-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1749.9,1044.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1939,1010.8c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1763.9,1146.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1725.3,1142.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1724.9,1133.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1772.5,1141.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1720.6,1148.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1712.5,1148.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM1712.2,1127.3c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902,1092.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1937.6,1113.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1930.4,1118.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.2,1082.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1928.8,1126.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1941.7,1137.3c-.5,0-1,0-1.5-.1-6.3-.9-9.2-7.1-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1933.2,1129.7c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1798.2,1068.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1824.7,1055.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1807.2,1064.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1802.9,1044.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.4-1.9.4ZM1802.8,1038.1c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1803.8,1057.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1893.7,1057.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1922.4,1065c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.4,1060.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1914.7,1043.6c-.4,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM1914.7,1036.9c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1917.6,1056.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,1108.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3s2,1.1,2.6,2.1c.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1642.8,1115.5c.3.6.8,1,1.4,1.2s1.2.1,1.8-.2c.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.7,1116.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2s.5-1.3.3-2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM1777.9,1065.4c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,1089.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874.9,1038c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l23.6-31.2c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1879.6,993.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1888.1,1023.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.1,1068.7c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1611.1,1053l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,1006c-.7,0-1.5-.1-2.2-.4l-36.1-15c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,990.9l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.6,1277c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1908.3,1209.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874,1227.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1868.5,1220.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1912.6,1200.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,1235.4c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.7,1242.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1856.6,1221.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1829.7,1033.8c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,1050c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1861.3,1056.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.8,1024.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.6,1064.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,1073.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1865.5,1066.9c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.1,1210.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1670.4,1190.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1676,1208.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,1325.7s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,1313.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9h0c2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM1846.2,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,1303.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.2,1310.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1682.8,1300c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM1682.8,1293.3c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5ZM1685.5,1297.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1678.9,1312.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1728,1191.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1716.7,1207.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1709.2,1201.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1717.5,1186.3c-1.1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3,0-1.2.3-2.3,1.1-3.1,1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3s-.3,2.3-1.1,3.1-1.8,1.4-3,1.4c0,0-.2,0-.2,0ZM1717.5,1179.6c-.6,0-1.3.3-1.8.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6s1.1.6,1.7.6c.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-.5-.4-1-.6-1.6-.6ZM1715,1179.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1699.7,1192.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.4,1238.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1811.6,1221.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1839.7,1231.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.4,1215.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,0,0,0,0,0,0,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM1826.4,1209c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.9,1226.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1877.3,1150.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.1,1165.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.2.1.4.3.5.6s0,.5,0,.8l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1886.1,1155.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902.3,1142.5c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1902.3,1135.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.9,1155.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1796.2,1205.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1758.3,1172.6c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1769.5,1200.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1932.4,1202.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM1933.7,1150c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1921.4,1177.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.6,1264.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM1697.4,1220.8l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1702.8,1251.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1690,1179.5c-1,0-2-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5s.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1659.4,1159.8l28.7,17.2c.9.5,1.9.7,2.9.4s1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1674.6,1171.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,1169.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1670,1237.2c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1959.3,1090.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1861.1,1288.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1794.3,1014.1c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1878.8,1195.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1654,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,985.3c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2c.3-2.4,2.4-4.1,4.8-3.8h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1846.2,978.6c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.7,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,1447.2c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM1973.2,1440.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM1939.4,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735,1381.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1739.2,1398.6c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1743.5,1415.7c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1747.8,1432.8c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1675.3,1455.1c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1726.4,1347.3c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1730.7,1364.4c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1657.3,1356.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1660.1,1367.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,1378.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1665.7,1389.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1668.5,1400.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1671.3,1412.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1674.1,1423.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1676.9,1434.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1679.7,1445.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.7,1467.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1866.4,1484.4h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,1500.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1853.7,1517.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1852.2,1521.2c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1885.4,1435h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.4.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1879.1,1451.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.4,1421.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1868.2,1432.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1864.1,1442.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1859.9,1453.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1855.8,1464.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1851.6,1475.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1847.5,1486c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1843.3,1496.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1839.2,1507.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1774.3,1631.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1758,1625c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1741.7,1618.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.4,1611.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1790.6,1638.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1810.4,1628.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1799.8,1623.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1789.1,1619.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1778.4,1615c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1767.8,1610.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1757.1,1606.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1746.4,1601.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735.8,1597.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.1,1592.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.1,1368.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,1365.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1783.8,1324.2c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1771.1,1375c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,1389.9c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1749.9,1373c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1939,1339.2c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1763.9,1474.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1725.3,1470.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1724.9,1461.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1772.5,1469.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1720.6,1476.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1712.5,1477.3c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1712.2,1455.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902,1420.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1937.6,1441.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1930.4,1447.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.2,1410.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1928.8,1454.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1941.7,1465.7c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1933.2,1458c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1798.2,1396.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1824.7,1383.8c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1807.2,1392.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1802.9,1373.2c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.5-1.9.5ZM1802.8,1366.4c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1803.8,1385.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1893.7,1386.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1922.4,1393.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.4,1388.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1914.7,1372c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM1914.7,1365.2c-1.1,0-2,.7-2.3,1.8-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1917.6,1384.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,1436.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,1447.2c-1.6,0-3.1-.9-3.9-2.3-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM1644.9,1440.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.7,1444.8c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1777.9,1393.7c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,1418.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874.9,1366.4c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l20.7-27.5h0s2.8-3.7,2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1879.6,1322.3l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1888.1,1352c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.2,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM1611.1,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.6,1605.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1908.3,1537.8c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874,1555.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1868.5,1548.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2s.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1912.6,1528.7c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,1563.7c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.7,1570.9c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5.9-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1856.6,1550.1c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1829.7,1362.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,1378.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1861.3,1384.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.8,1352.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.6,1392.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,1401.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1865.5,1395.3c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.1,1539c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1670.4,1519.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1676,1536.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,1642c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9h0c2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM1846.2,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,1632.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1s.5,1,.1,1.4c-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.2,1638.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1682.8,1628.3c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM1682.8,1621.6c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5ZM1685.5,1626.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1678.9,1640.6c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1728,1519.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1716.7,1536.2c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1709.2,1529.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1717.5,1514.7c-1.1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3,0-1.2.3-2.3,1.1-3.1h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1s-1.8,1.4-3,1.4c0,0-.2,0-.2,0ZM1715.7,1508.7h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1699.7,1521c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.4,1567.3c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1811.6,1549.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1839.7,1559.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.4,1544.1c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6t0,0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM1826.4,1537.4c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.9,1554.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1877.3,1478.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.1,1493.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.2.1.4.3.5.6,0,.3,0,.5,0,.8l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1886.1,1483.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902.3,1470.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1902.3,1464.1c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.9,1483.5c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1796.2,1534.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1758.3,1501c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1769.5,1528.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1932.4,1530.5c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4s1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1933.7,1478.4c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1921.4,1505.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.6,1592.5c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l26.8-28.5c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1697.4,1549.2l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1702.8,1579.7c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1690,1507.9c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1659.4,1488.2l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1674.6,1499.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,1497.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1670,1565.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1959.3,1419.3h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1861.1,1617.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1794.3,1342.5c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1878.8,1524c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1654,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,1325.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,1313.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9h0c2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM1846.2,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,1775.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM1973.2,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1939.4,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.6,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735,1709.9c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1739.2,1727c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1743.5,1744.1c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1747.8,1761.2c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1675.3,1783.5c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1726.4,1675.7c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1s-.5.9-1.1.9c-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3s-.9,2.5-.6,3.8c.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1730.7,1692.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8s1.6,2.1,2.8,2.6c1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1657.3,1684.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1660.1,1695.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,1706.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1665.7,1718.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1668.5,1729.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1671.3,1740.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1674.1,1751.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1676.9,1762.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1679.7,1774c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.7,1796.3h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1866.4,1812.7h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,1829.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1853.7,1845.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1852.2,1849.5c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1885.4,1763.4h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.4.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1879.1,1779.8h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.4,1749.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1868.2,1760.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1864.1,1771.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1859.9,1782c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1855.8,1792.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1851.6,1803.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1847.5,1814.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1843.3,1825.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1839.2,1835.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1774.3,1960.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1758,1953.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1741.7,1946.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.4,1939.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1790.6,1966.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1810.4,1956.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1799.8,1952.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1789.1,1947.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1778.4,1943.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1767.8,1938.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1757.1,1934.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1746.4,1930.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735.8,1925.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.1,1921.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.1,1696.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,1693.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1783.8,1652.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1771.1,1703.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,1718.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1749.9,1701.4c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1939,1667.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1763.9,1803.2c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1725.3,1798.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1724.9,1789.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1772.5,1798.1c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1720.6,1805.1c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1712.5,1805.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1712.2,1784c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902,1749.1c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1937.6,1769.9c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1930.4,1775.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.2,1739.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1928.8,1783.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1941.7,1794c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1933.2,1786.4c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1798.2,1725.2c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1824.7,1712.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1807.2,1720.8c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1802.9,1701.5c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3s1.2-2,2.2-2.5c1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2,1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM1802.8,1694.8c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1803.8,1714c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1893.7,1714.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1922.4,1721.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.4,1717c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1914.7,1700.3c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1914.7,1693.6c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1917.6,1712.8c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,1765.2c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,1775.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM1644.9,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.7,1773.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM1777.9,1722.1c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,1746.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874.9,1694.7c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1879.6,1650.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1888.1,1680.4c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.1,1725.4c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1611.1,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.7,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.6,1933.7c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3s-2.1,1.6-2.5,2.9c-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1908.3,1866.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874,1884.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1868.5,1877c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2s.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1912.6,1857c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,1892.1c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.7,1899.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1856.6,1878.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1829.7,1690.5c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,1706.7c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1861.3,1713.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.8,1680.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.6,1721c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,1730.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1865.5,1723.6c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.1,1867.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1670.4,1847.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1676,1865.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9h0c2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM1846.2,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,1960.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.2,1966.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1682.8,1956.7c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.9,1.1-2.1,1.6-3.4,1.6ZM1682.8,1950c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4s.6-2.5-.4-3.3c-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1678.9,1968.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1728,1848c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1716.7,1864.6c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1709.2,1857.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1717.5,1843c-1.1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3,0-1.2.3-2.3,1.1-3.1h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1717.5,1836.3c-.6,0-1.3.3-1.8.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-.5-.4-1-.6-1.6-.6ZM1715,1836.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1699.7,1849.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.4,1895.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1811.6,1877.8c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1839.7,1887.9c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.4,1872.4c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6t0,0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM1826.4,1865.7c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.9,1883.1c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1877.3,1807.3c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.1,1821.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.2.1.4.3.5.6,0,.3,0,.5,0,.8l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1886.1,1812.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902.3,1799.2c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1902.3,1792.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.9,1811.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1796.2,1862.6c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1758.3,1829.3c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1769.5,1857.1c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1932.4,1858.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0s2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1933.7,1806.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1921.4,1833.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.6,1920.9c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l26.8-28.5c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1697.4,1877.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1702.8,1908c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1690,1836.2c-1,0-2-.3-3-.8l-29.5-17.7h0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1659.4,1816.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1674.6,1827.8c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,1826.1c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1670,1893.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3s-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1959.3,1747.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1861.1,1945.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1794.3,1670.8c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1878.8,1852.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1654,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,1642c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9h0c2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM1846.2,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,2319.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,2319.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.7,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM1719.2,2304.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,2313.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1970.1,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1987.5,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1973.2,2103.9c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM1973.2,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1976.2,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1957.5,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1939.4,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1967.6,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1935.8,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.1,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1967.2,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1972.1,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1961.8,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1951.5,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1941.3,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1931,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1920.7,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1910.4,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1900.1,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1993.1,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1960.5,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1994.5,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.6,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1960.8,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1981,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735,2038.2c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1739.2,2055.3c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1743.5,2072.4c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1747.8,2089.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1675.3,2111.8c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1726.4,2004c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1s-.5.9-1.1.9c-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1s2.5.9,3.8.6c.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1730.7,2021.1c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1657.3,2012.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1660.1,2024c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,2035.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1665.7,2046.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1668.5,2057.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1671.3,2068.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1674.1,2080c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1676.9,2091.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1679.7,2102.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.7,2124.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.7-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1866.4,2141.1h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,2157.5h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1853.7,2173.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1852.2,2177.9c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1885.4,2091.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.4.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1879.1,2108.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1872.4,2078.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1868.2,2088.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1864.1,2099.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1859.9,2110.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1855.8,2121.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1851.6,2131.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1847.5,2142.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1843.3,2153.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1839.2,2164.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1774.3,2288.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1758,2281.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1741.7,2275c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.4,2268.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,2304.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,2301.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7s-2.6-.5-3.8,0c-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1790.6,2295.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1810.4,2284.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1799.8,2280.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1789.1,2276.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1778.4,2271.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1767.8,2267.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1757.1,2262.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1746.4,2258.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1735.8,2254c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1725.1,2249.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1785.4,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.1,2025c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,2022c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1783.8,1980.9c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1771.1,2031.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1758.5,2046.6c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1749.9,2029.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1982.4,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1975.8,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1991.2,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1939,1995.9c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1931.5,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1930.9,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1763.9,2131.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1725.3,2127.2c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1724.9,2118.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1772.5,2126.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1720.6,2133.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1712.5,2134c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.4-4.6,11.1-3.3,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM1712.2,2112.3c-2,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902,2077.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1937.6,2098.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1930.4,2103.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.2,2067.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1928.8,2111.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1941.7,2122.4c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1933.2,2114.7c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1798.2,2053.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1824.7,2040.5c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1807.2,2049.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1802.9,2029.9c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,2.2-1.1,4.8-.2,5.8,2,.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.5-1.9.5ZM1802.8,2023.1c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4s-.2,1.2.1,1.8c.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1803.8,2042.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1893.7,2042.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1922.4,2050.1c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c.1-.5.7-.9,1.2-.7l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.4,2045.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1914.7,2028.7c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1914.7,2021.9c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM1918,2025.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1917.6,2041.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,2093.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,2123.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,2103.9c-1.6,0-3.1-.9-3.9-2.3-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1644.9,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1767.7,2101.5c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1777.9,2050.4c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,2074.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874.9,2023.1c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1879.6,1979l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1888.1,2008.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.1,2053.8c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1611.1,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1751.5,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1719.2,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1735.7,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.6,2262c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1908.3,2194.5c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1874,2212.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1868.5,2205.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2s.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1912.6,2185.4c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,2220.4c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.7,2227.6c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1s-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1856.6,2206.8c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1829.7,2018.8c-.4,0-.8-.3-1-.7l-6.6-21.3c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,2035c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1861.3,2041.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.8,2009.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.6,2049.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1873.6,2058.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1865.5,2052c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.1,2195.7c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1670.4,2175.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1676,2193.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,2313.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,2310.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-1,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,2304s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,2298.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.3-2.4,2.4-4.1,4.8-3.8h0c2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM1846.2,2292c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,2303.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1656.4,2289c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,2307.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.2,2295.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1682.8,2285c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2-.9,1.1-2.1,1.6-3.4,1.6ZM1682.8,2278.3c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1678.9,2297.3c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1728,2176.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1716.7,2192.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1709.2,2186.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1717.5,2171.4c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.9,1-2,1.4-3.2,1.4ZM1717.5,2164.6s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-.4-.4-1-.6-1.6-.6ZM1715,2164.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1699.7,2177.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.4,2224c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1811.6,2206.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1839.7,2216.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1826.4,2200.8c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9s.2-2.3,1-3.2c1.5-1.9,4.3-2.1,6.1-.6t0,0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM1826.4,2194.1c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1833.9,2211.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1877.3,2135.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1903.1,2150.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.2.1.4.3.5.6,0,.3,0,.5,0,.8l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1886.1,2140.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1902.3,2127.5c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1902.3,2120.8c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1900.9,2140.2c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1796.2,2190.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1758.3,2157.7c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1769.5,2185.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1932.4,2187.2c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1933.7,2135.1c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1921.4,2162.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1688.6,2249.2c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l26.8-28.5c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1697.4,2205.9l-22.9,24.4c-.7.7-1.1,1.7-1,2.7s.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1702.8,2236.4c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1690,2164.6c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1659.4,2144.9l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1674.6,2156.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1761.6,2154.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1670,2222.3c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.2,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1959.3,2075.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1861.1,2273.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1794.3,1999.2c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1878.8,2180.7c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1654,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1904.4,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1900.6,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1812.4,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1806.9,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1866.5,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1838.5,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1867.6,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1846.2,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9h0c2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM1846.2,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1860.1,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1679.5,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2s.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,349.3c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,334.2l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3s2,1.1,2.6,2.1c.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1642.8,130.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.1,83.7c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1611.1,68l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1406.6,68.1c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1410.9,85.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8s1.6,2.1,2.8,2.6c1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1415.2,102.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1419.5,119.4c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1346.9,141.7c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1398.1,34c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1s2.5.9,3.8.6,1.1-.4,1.6-.7c.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1402.3,51c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1329,42.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1331.8,53.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1334.6,65.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1337.4,76.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1340.2,87.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1343,98.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1345.8,109.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1348.6,121.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1351.4,132.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544.4,154.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,171h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,187.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1525.4,203.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.9,207.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1557.1,121.6h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.4.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1550.7,138.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544,108c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1539.9,118.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1535.7,129.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.6,140.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1527.4,151c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.3,161.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1519.1,172.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1515,183.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1510.8,194.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1445.9,318.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1429.7,311.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1413.4,304.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1397.1,298.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1484,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1462.2,325.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1482.1,314.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1471.4,310.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1460.7,306c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1450.1,301.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1439.4,297.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1428.8,292.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1418.1,288.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,283.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1396.8,279.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,20.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1438.7,54.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.1,51.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1455.5,10.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1442.7,61.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.2,76.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.1.9-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1421.6,59.6c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1654,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1610.6,25.8c-.6,0-1-.4-1-1V3.6c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1435.6,161.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1397,157.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1396.6,148c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1444.2,156.3c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1392.2,163.3c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1384.1,163.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1383.9,142.2c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.6,107.3c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1609.2,128.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1602.1,133.7c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1571.9,97.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1600.4,141.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1613.4,152.3c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1604.8,144.6c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1469.9,83.4c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1496.4,70.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.8,79c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1474.5,59.8c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.5-1.9.5ZM1474.5,53c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1475.5,72.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1565.4,72.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1594.1,80c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1575.1,75.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1586.4,58.6c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1586.4,51.8c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM1589.6,55h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1589.3,71c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,123.4c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1314.4,130.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1439.3,131.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1449.5,80.3c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,104.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1546.5,53c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1551.3,8.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1559.7,38.6c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1282.7,68l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,21c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,5.9l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,14.8c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1326.3,291.9c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-1.7-3.4-.4-7.7,3.1-9.4,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-2.5,1.2-3.4,4.3-2.2,6.7.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1580,224.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.6,242.5c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1540.2,235.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1584.2,215.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,250.3c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.4,257.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM1528.2,236.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1501.4,48.7c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.3,64.9c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.9,71.4c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.4,39.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.2,79.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,88.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1537.1,81.9c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1359.8,225.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1342,205.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1347.7,223.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6s1,2.1.9,3.2c-.3,2.2-2.1,3.9-4.3,3.9ZM1517.9,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,318.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1335.8,325.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1354.5,314.9c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM1354.5,308.2c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6s1.1.6,1.7.5c.6,0,1.2-.4,1.6-.9.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1350.5,327.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.6c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1399.6,206.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1388.3,222.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1380.9,216.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1389.1,201.3c-1.1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3s.3-2.3,1.1-3.1c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1389.1,194.5s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-.4-.4-1-.6-1.6-.6ZM1386.6,194.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1371.4,207.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505,253.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1483.3,236c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.6,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1511.4,246.1c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.1,230.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1,0,0,0,0,0,0,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM1498.1,224c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505.5,241.3c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1549,165.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1574.8,180c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4s-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1557.7,170.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.9,157.4c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c1.2-2.1,3.8-2.8,5.9-1.7,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1573.9,150.7c-.8,0-1.6.4-2.1,1.2-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1572.5,170.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1467.8,220.8c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1430,187.6c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1441.2,215.3c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1604,217.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1605.3,165c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1593,192.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1360.3,279.2c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1369.1,235.8l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1374.4,266.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1361.6,194.5c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5s.5,0,.8.1l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1331,174.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1346.2,186c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,184.3c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1341.7,252.2c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1630.9,105.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1532.8,303.8c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1465.9,29.1c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1550.4,210.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1576,3.4c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1484,5.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,3.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,15.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,12.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,5.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1517.9-6.4c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,4.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,677.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,662.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,481.9c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3s2,1.1,2.6,2.1c.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1644.9,455.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.1,412c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1611.1,396.3l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1s-1.7,2-1.9,3.3c-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1406.6,396.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1410.9,413.6c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1415.2,430.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1419.5,447.8c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1346.9,470.1c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1398.1,362.3c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1s-.5,1-1.1.9c-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.3,1.3,1.1,2.4,2.3,3.1,1.1.7,2.5.9,3.8.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1402.3,379.4c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1329,371.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1331.8,382.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1334.6,393.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1337.4,404.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1340.2,415.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1343,427.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1345.8,438.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1348.6,449.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1351.4,460.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544.4,482.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,499.3h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,515.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1525.4,532.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.9,536.1c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1557.1,450h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1550.7,466.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544,436.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1539.9,447.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1535.7,457.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.6,468.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1527.4,479.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.3,490.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1519.1,500.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1515,511.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1510.8,522.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1445.9,646.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1429.7,640c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1413.4,633.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1397.1,626.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1484,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8s3.6-.7,5.4,0c3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1462.2,653.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1482.1,643.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1471.4,638.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1460.7,634.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1450.1,629.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1439.4,625.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1428.8,621.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1418.1,616.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,612.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1396.8,607.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2s.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1438.7,383.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.1,380.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1455.5,339.1c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1442.7,390c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.2,404.8c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.1.9-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1421.6,388c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1654,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1610.6,354.1c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1435.6,489.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1397,485.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1396.6,476.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1444.2,484.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1392.2,491.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1384.1,492.3c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1383.9,470.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.6,435.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1s.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1609.2,456.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1602.1,462.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1571.9,425.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1600.4,469.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1613.4,480.6c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1604.8,473c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1469.9,411.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1496.4,398.7c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.8,407.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1474.5,388.1c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM1474.5,381.4c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4s-.2,1.2.1,1.8c.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1,1.2-.6,1.6-2,1.1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1475.5,400.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1565.4,401.2c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1594.1,408.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1575.1,403.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1586.4,386.9c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1586.4,380.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0ZM1589.6,383.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1589.3,399.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,451.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,481.9c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1316.5,455.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1439.3,459.7c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1449.5,408.7c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,433.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1546.5,381.3c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1551.3,337.2l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1559.7,367c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM1282.7,396.3l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,349.3c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,334.2l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1s-1.7,2-1.9,3.3c-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1326.3,620.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-1.7-3.4-.4-7.7,3.1-9.4,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5s-2.6-.3-3.8.3c-2.5,1.2-3.4,4.3-2.2,6.7.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1580,552.7c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.6,570.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1540.2,563.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1584.2,543.6c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,578.7c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.4,585.8c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM1528.2,565c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1501.4,377.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.3,393.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.9,399.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.4,367.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.2,407.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,416.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1537.1,410.2c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1359.8,553.9c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1342,534c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1347.7,551.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,1.2.1,2.2.7,2.9,1.6s1,2.1.9,3.2c-.3,2.2-2.1,3.9-4.3,3.9ZM1517.9,650.3c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,647.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1335.8,653.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1354.5,643.3c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2s1.8-1.5,2.9-1.6c1.2-.1,2.3.2,3.2.9.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2h0c-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM1354.5,636.6c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1350.5,655.5c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1399.6,534.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1388.3,551.2c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1380.9,544.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1389.1,529.6c-1.1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.9-1.4-3,0-1.2.3-2.3,1.1-3.1.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1389.1,522.9s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6s1.1.6,1.7.6c.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM1386.6,523h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1371.4,536c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505,582.2c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4s1-.5,1.4,0c2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1483.3,564.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1511.4,574.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.1,559c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1h0c.9.7,1.5,1.8,1.6,2.9s-.2,2.3-1,3.2c-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM1498.1,552.3c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505.5,569.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1549,493.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1574.8,508.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4s-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1557.7,498.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.9,485.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3,1.2-2.1,3.8-2.8,5.9-1.7,2.1,1.2,2.8,3.8,1.7,5.9h0c-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1573.9,479c-.8,0-1.6.4-2.1,1.2-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4s1.2.4,1.8.2c.6-.2,1.1-.6,1.4-1.1.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3ZM1576.8,483.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1572.5,498.4c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1467.8,549.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9h0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1430,515.9c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4.1,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1441.2,543.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1604,545.4c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1605.3,493.3c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1593,520.5c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1360.3,607.5c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4s.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1369.1,564.1l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1374.4,594.6c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1361.6,522.8c-1,0-2-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1331,503.1l28.7,17.2c1.8,1.1,4.1.5,5.2-1.3l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1346.2,514.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,512.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1341.7,580.5c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1630.9,434.2h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1532.8,632.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1465.9,357.4c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1550.4,538.9c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1576,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1484,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6s1,2.1.9,3.2c-.3,2.2-2.1,3.9-4.3,3.9ZM1517.9,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,1006c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,990.9l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,810.2c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6s2.2-.6,3.3-.3c1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM1644.9,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.1,740.4c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM1611.1,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1406.6,724.8c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1410.9,741.9c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1415.2,759c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1419.5,776.1c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1346.9,798.4c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1398.1,690.6c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.3,1.3,1.1,2.4,2.3,3.1,1.1.7,2.5.9,3.8.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1402.3,707.7c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1329,699.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1331.8,710.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1334.6,721.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1337.4,733c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1340.2,744.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1343,755.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1345.8,766.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1348.6,777.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1351.4,789c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544.4,811.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,827.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,844.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1525.4,860.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.9,864.5c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1557.1,778.3h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.4.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1550.7,794.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544,764.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1539.9,775.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1535.7,786.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.6,797c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1527.4,807.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.3,818.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1519.1,829.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1515,840c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1510.8,850.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1445.9,975c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1429.7,968.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1413.4,961.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1397.1,954.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1484,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1462.2,981.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1482.1,971.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1471.4,967.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1460.7,962.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1450.1,958.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1439.4,953.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1428.8,949.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1418.1,945c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,940.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1396.8,936.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1438.7,711.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.1,708.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1455.5,667.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1442.7,718.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.2,733.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.1.9-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1421.6,716.3c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1654,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1610.6,682.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1435.6,818.1c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1397,813.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1396.6,804.7c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1444.2,813c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1392.2,820c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1384.1,820.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8,0,.3-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1383.9,798.9c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.6,764c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1s.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1609.2,784.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1602.1,790.4c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1571.9,754.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1600.4,798.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1613.4,809c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1604.8,801.3c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1469.9,740.1c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1496.4,727.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.8,735.7c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1474.5,716.5c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.5-1.9.5ZM1474.5,709.7c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1475.5,728.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1565.4,729.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1594.1,736.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1575.1,732c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1586.4,715.3c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1586.4,708.5c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM1589.6,711.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1589.3,727.7c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,780.1c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,810.2c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM1316.5,783.7c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1439.3,788.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1449.5,737c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,761.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1546.5,709.7c-1.2,0-2.4-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-1.1,1.5-2.9,2.3-4.6,2.3ZM1551.3,665.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c1.7,1.3,4,.9,5.3-.7l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1559.7,695.3c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM1282.7,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,677.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,662.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1326.3,948.6c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-1.7-3.4-.4-7.7,3.1-9.4,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5s-2.6-.3-3.8.3c-2.5,1.2-3.4,4.3-2.2,6.7.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1580,881.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.6,899.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1540.2,891.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1584.2,872c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,907c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4s1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.4,914.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1528.2,893.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1501.4,705.4c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.3,721.6c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.9,728.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.4,695.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.2,735.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,745.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1537.1,738.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1359.8,882.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1342,862.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1347.7,880.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1517.9,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,975.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1335.8,981.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1354.5,971.6c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM1354.5,964.9c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM1357.1,969.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1350.5,983.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1399.6,863c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1388.3,879.5c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1380.9,872.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1389.1,858c-1.1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3,0-1.2.3-2.3,1.1-3.1h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1389.1,851.2s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6s1.1.6,1.7.6c.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-.4-.4-1-.6-1.6-.6ZM1386.6,851.3h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1371.4,864.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505,910.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1483.3,892.7c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1511.4,902.8c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.1,887.4c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1h0c1.9,1.5,2.1,4.3.6,6.1-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM1498.1,880.7c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.7-2.5-.3-3.3h0c-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505.5,898c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1549,822.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1574.8,836.7c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1557.7,827.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.9,814.1c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3,1.2-2.1,3.8-2.8,5.9-1.7,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1573.9,807.4c-.8,0-1.6.4-2.1,1.2-.3.6-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1572.5,826.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1467.8,877.5c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1430,844.3c-.7,0-1.4.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1441.2,872c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1604,873.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1605.3,821.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1593,848.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1360.3,935.9c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1369.1,892.5l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1374.4,923c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1361.6,851.2c-1,0-2-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5s.5,0,.8.1l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1331,831.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1346.2,842.7c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,841c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1341.7,908.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1630.9,762.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1532.8,960.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1465.9,685.8c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1550.4,867.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1576,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1484,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8s3.6-.7,5.4,0c3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,1.2.1,2.2.7,2.9,1.6s1,2.1.9,3.2c-.3,2.2-2.1,3.9-4.3,3.9ZM1517.9,650.3c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5s-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3s2,1.1,2.6,2.1c.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1642.8,1115.5c.3.6.8,1,1.4,1.2s1.2.1,1.8-.2c.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.1,1068.7c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1611.1,1053l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1406.6,1053.2c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1410.9,1070.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8s1.6,2.1,2.8,2.6c1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1415.2,1087.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1419.5,1104.5c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1346.9,1126.8c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1398.1,1019c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3s-.9,2.5-.6,3.8c.3,1.3,1.1,2.4,2.3,3.1,1.1.7,2.5.9,3.8.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1402.3,1036.1c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1329,1027.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1331.8,1039c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1334.6,1050.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1337.4,1061.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1340.2,1072.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1343,1083.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1345.8,1095c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1348.6,1106.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1351.4,1117.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544.4,1139.6h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,1156h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,1172.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1525.4,1188.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.9,1192.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1557.1,1106.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.4.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1550.7,1123.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544,1093c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1539.9,1103.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1535.7,1114.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.6,1125.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1527.4,1136.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.3,1146.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1519.1,1157.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1515,1168.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1510.8,1179.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1445.9,1303.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1429.7,1296.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1413.4,1289.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1397.1,1283.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1484,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1462.2,1310.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1482.1,1299.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1471.4,1295.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1460.7,1291c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1450.1,1286.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1439.4,1282.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1428.8,1277.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1418.1,1273.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,1269c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1396.8,1264.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1438.7,1040c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.1,1037c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1455.5,995.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1442.7,1046.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.2,1061.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2s-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.1.9-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1421.6,1044.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1654,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1610.6,1010.8c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1435.6,1146.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1397,1142.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1396.6,1133.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1444.2,1141.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1392.2,1148.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1384.1,1148.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1383.9,1127.3c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.6,1092.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1609.2,1113.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1602.1,1118.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1571.9,1082.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1600.4,1126.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1613.4,1137.3c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1604.8,1129.7c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1469.9,1068.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1496.4,1055.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.8,1064.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1474.5,1044.8c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.5-1.9.5ZM1474.5,1038.1c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1475.5,1057.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1565.4,1057.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1594.1,1065c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1575.1,1060.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1586.4,1043.6c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1586.4,1036.9c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM1589.6,1040.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1589.3,1056.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,1108.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1314.4,1115.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1439.3,1116.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1449.5,1065.4c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,1089.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1546.5,1038c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1551.3,993.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1559.7,1023.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1282.7,1053l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,1006c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,990.9l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1326.3,1277c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-1.7-3.4-.4-7.7,3.1-9.4,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-2.5,1.2-3.4,4.3-2.2,6.7.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1580,1209.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.6,1227.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1540.2,1220.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1584.2,1200.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,1235.4c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.4,1242.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1528.2,1221.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.7-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1501.4,1033.8c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.3,1050c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.9,1056.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.4,1024.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.2,1064.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,1073.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1537.1,1066.9c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1359.8,1210.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1342,1190.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1347.7,1208.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,1325.7s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM1517.9,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6s-.1-1.2-.5-1.7c-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,1303.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1335.8,1310.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1354.5,1300c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM1354.5,1293.3c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM1357.1,1297.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1350.5,1312.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1399.6,1191.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1388.3,1207.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1380.9,1201.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1389.1,1186.3c-1.1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3s.3-2.3,1.1-3.1c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1389.1,1179.6s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6s1.1.6,1.7.6c.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-.4-.4-1-.6-1.6-.6ZM1386.6,1179.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1371.4,1192.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505,1238.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1483.3,1221.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1511.4,1231.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.1,1215.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1,0,0,0,0,0,0,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM1498.1,1209c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505.5,1226.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1549,1150.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1574.8,1165.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1557.7,1155.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.9,1142.5c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c1.2-2.1,3.8-2.8,5.9-1.7,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1573.9,1135.7c-.8,0-1.6.4-2.1,1.2-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1572.5,1155.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1467.8,1205.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1430,1172.6c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1441.2,1200.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1604,1202.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1605.3,1150c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1593,1177.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1360.3,1264.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM1369.1,1220.8l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1374.4,1251.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1361.6,1179.5c-1,0-2-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5s.5,0,.8.1l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1331,1159.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1346.2,1171.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,1169.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1341.7,1237.2c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1630.9,1090.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1532.8,1288.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1465.9,1014.1c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1550.4,1195.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1576,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1484,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1517.9,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,1447.2c-1.6,0-3.1-.9-3.9-2.3-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM1644.9,1440.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.2,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-9.4,33.1h0s-1.3,4.5-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM1611.1,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1406.6,1381.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1410.9,1398.6c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1415.2,1415.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1419.5,1432.8c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1346.9,1455.1c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1398.1,1347.3c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1s2.5.9,3.8.6c.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1402.3,1364.4c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1329,1356.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1331.8,1367.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1334.6,1378.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1337.4,1389.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1340.2,1400.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1343,1412.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1345.8,1423.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1348.6,1434.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1351.4,1445.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544.4,1467.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,1484.4h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,1500.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1525.4,1517.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.9,1521.2c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1557.1,1435h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.4.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1550.7,1451.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544,1421.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1539.9,1432.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1535.7,1442.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.6,1453.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1527.4,1464.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.3,1475.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1519.1,1486c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1515,1496.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1510.8,1507.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1445.9,1631.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1429.7,1625c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1413.4,1618.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1397.1,1611.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1484,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1462.2,1638.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1482.1,1628.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1471.4,1623.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1460.7,1619.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1450.1,1615c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1439.4,1610.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1428.8,1606.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1418.1,1601.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,1597.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1396.8,1592.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1438.7,1368.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.1,1365.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1455.5,1324.2c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1442.7,1375c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.2,1389.9c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.1.9-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1421.6,1373c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1654,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1610.6,1339.2c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1435.6,1474.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1397,1470.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1396.6,1461.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1444.2,1469.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1392.2,1476.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1384.1,1477.3c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1383.9,1455.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.6,1420.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1s.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1609.2,1441.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1602.1,1447.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1571.9,1410.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1600.4,1454.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1613.4,1465.7c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1604.8,1458c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1469.9,1396.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1496.4,1383.8c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.8,1392.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1474.5,1373.2c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.5-1.9.5ZM1474.5,1366.4c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1,1.2-.6,1.6-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1475.5,1385.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1565.4,1386.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1594.1,1393.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1575.1,1388.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1586.4,1372c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM1586.4,1365.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5s0,1.2.3,1.8c.3.5.8.9,1.5,1.1,1.3.3,2.5-.5,2.9-1.7.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0ZM1589.6,1368.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1589.3,1384.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,1436.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,1447.2c-1.6,0-3.1-.9-3.9-2.3-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM1316.5,1440.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1439.3,1444.8c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1449.5,1393.7c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,1418.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1546.5,1366.4c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l20.7-27.5h0s2.8-3.7,2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1551.3,1322.3l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1559.7,1352c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1h0s-1.3,4.5-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM1282.7,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1326.3,1605.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-2.5,1.2-3.4,4.3-2.2,6.7.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1580,1537.8c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.6,1555.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1540.2,1548.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1584.2,1528.7c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,1563.7c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.4,1570.9c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1528.2,1550.1c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.7-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1501.4,1362.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.3,1378.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.9,1384.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.4,1352.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.2,1392.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,1401.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.4,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1537.1,1395.3c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1359.8,1539c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1342,1519.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1347.7,1536.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1517.9,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.2,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,1632.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1s.5,1,.1,1.4c-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1335.8,1638.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1354.5,1628.3c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6s2.3.2,3.2.9c1.9,1.5,2.2,4.3.7,6.1h0c-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM1354.5,1621.6c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM1357.1,1626.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1350.5,1640.6c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1399.6,1519.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1388.3,1536.2c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1380.9,1529.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1389.1,1514.7c-1.1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3s.3-2.3,1.1-3.1c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1389.1,1507.9c-.6,0-1.3.3-1.8.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6s1.1.6,1.7.6c.6,0,1.2-.3,1.6-.8s.6-1.1.6-1.7c0-.6-.3-1.2-.8-1.6-.5-.4-1-.6-1.6-.6ZM1386.6,1508h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1371.4,1521c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505,1567.3c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1483.3,1549.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1511.4,1559.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.1,1544.1c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1t0,0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM1498.1,1537.4c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505.5,1554.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1549,1478.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1574.8,1493.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1557.7,1483.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.9,1470.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3,1.2-2.1,3.8-2.8,5.9-1.7,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1573.9,1464.1c-.8,0-1.6.4-2.1,1.2-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1572.5,1483.5c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1467.8,1534.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1430,1501c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1441.2,1528.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1604,1530.5c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4s1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1605.3,1478.4c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1593,1505.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1360.3,1592.5c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l26.8-28.5c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1369.1,1549.2l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1374.4,1579.7c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1361.6,1507.9c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1331,1488.2l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1346.2,1499.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,1497.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1341.7,1565.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1630.9,1419.3h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1532.8,1617.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1465.9,1342.5c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1550.4,1524c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1576,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1484,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,1325.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM1517.9,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6s-.1-1.2-.5-1.7c-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,1775.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM1644.9,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.1,1725.4c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1611.1,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1406.6,1709.9c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1410.9,1727c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1415.2,1744.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1419.5,1761.2c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1346.9,1783.5c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1398.1,1675.7c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3s-.9,2.5-.6,3.8,1.1,2.4,2.3,3.1,2.5.9,3.8.6c.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1402.3,1692.8c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1329,1684.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1331.8,1695.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1334.6,1706.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1337.4,1718.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1340.2,1729.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1343,1740.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1345.8,1751.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1348.6,1762.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1351.4,1774c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544.4,1796.3h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,1812.7h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,1829.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1525.4,1845.6h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.9,1849.5c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1557.1,1763.4h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.4.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1550.7,1779.8h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544,1749.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1539.9,1760.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1535.7,1771.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.6,1782c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1527.4,1792.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.3,1803.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1519.1,1814.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1515,1825.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1510.8,1835.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1445.9,1960.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1429.7,1953.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1413.4,1946.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1397.1,1939.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1484,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1462.2,1966.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1482.1,1956.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1471.4,1952.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1460.7,1947.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1450.1,1943.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1439.4,1938.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1428.8,1934.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1418.1,1930.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,1925.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1396.8,1921.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1438.7,1696.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.1,1693.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1455.5,1652.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1442.7,1703.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.2,1718.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.1.9-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1421.6,1701.4c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1654,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1610.6,1667.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1435.6,1803.2c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1397,1798.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1396.6,1789.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1444.2,1798.1c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1392.2,1805.1c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1384.1,1805.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1383.9,1784c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.6,1749.1c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1609.2,1769.9c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1602.1,1775.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1571.9,1739.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1600.4,1783.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1613.4,1794c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1604.8,1786.4c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1469.9,1725.2c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1496.4,1712.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.8,1720.8c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1474.5,1701.5c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2s-.6-2.2-.2-3.3,1.2-2,2.2-2.5c1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.5-1.9.5ZM1474.5,1694.8c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1,1.2-.6,1.6-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1475.5,1714c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1565.4,1714.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1594.1,1721.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1575.1,1717c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1586.4,1700.3c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1586.4,1693.6c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0ZM1589.6,1696.8h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1589.3,1712.8c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,1765.2c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,1775.5c-1.6,0-3.1-.9-3.9-2.3-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM1316.5,1768.8c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1439.3,1773.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1449.5,1722.1c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,1746.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1546.5,1694.7c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1551.3,1650.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1559.7,1680.4c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1282.7,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1326.3,1933.7c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-2.5,1.2-3.4,4.3-2.2,6.7.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1580,1866.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.6,1884.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1540.2,1877c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1584.2,1857c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,1892.1c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.4,1899.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1528.2,1878.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1501.4,1690.5c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.3,1706.7c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.9,1713.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.4,1680.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.2,1721c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,1730.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1537.1,1723.6c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1359.8,1867.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1342,1847.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1347.7,1865.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1517.9,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,1960.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1335.8,1966.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1354.5,1956.7c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM1354.5,1950c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1350.5,1968.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1399.6,1848c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1388.3,1864.6c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1380.9,1857.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1389.1,1843c-1.1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3s.3-2.3,1.1-3.1h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1389.1,1836.3s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-.4-.4-1-.6-1.6-.6ZM1386.6,1836.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1371.4,1849.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505,1895.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1483.3,1877.8c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1511.4,1887.9c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.1,1872.4c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1t0,0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM1498.1,1865.7c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505.5,1883.1c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1549,1807.3c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1574.8,1821.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1557.7,1812.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.9,1799.2c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3,1.2-2.1,3.8-2.8,5.9-1.7,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1573.9,1792.4c-.8,0-1.6.4-2.1,1.2-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4s1.2.4,1.8.2c.6-.2,1.1-.6,1.4-1.1.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1572.5,1811.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1467.8,1862.6c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1430,1829.3c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1441.2,1857.1c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1604,1858.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0s2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1605.3,1806.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1593,1833.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1360.3,1920.9c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4s.5-3,1.6-4.1l26.8-28.5c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1369.1,1877.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1374.4,1908c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1361.6,1836.2c-1,0-2-.3-3-.8l-29.5-17.7h0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1331,1816.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1346.2,1827.8c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,1826.1c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1341.7,1893.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1630.9,1747.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1532.8,1945.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1465.9,1670.8c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1550.4,1852.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1576,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1484,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1517.9,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.2,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,2319.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,2319.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM1390.8,2304.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,2313.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1641.8,2123.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1659.1,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1644.9,2103.9c-1.6,0-3.1-.9-3.9-2.3-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1644.9,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.8,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1629.1,2053.8c-.4,0-.8,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1611.1,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1639.2,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1607.5,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1591.7,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1576,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1638.9,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1654.1,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1643.8,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1633.5,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1623.2,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1612.9,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1602.6,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1592.3,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1582,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1571.7,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1664.7,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1632.2,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1666.1,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1632.4,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1652.6,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1406.6,2038.2c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1410.9,2055.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1415.2,2072.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1419.5,2089.5c-.8,0-1.6-.1-2.4-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1346.9,2111.8c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1398.1,2004c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1,1.1.7,2.5.9,3.8.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1402.3,2021.1c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1329,2012.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1331.8,2024c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1334.6,2035.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1337.4,2046.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1340.2,2057.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1343,2068.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1345.8,2080c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1348.6,2091.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1351.4,2102.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544.4,2124.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,2141.1h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,2157.5h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1525.4,2173.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.9,2177.9c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1557.1,2091.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.4.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1550.7,2108.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1544,2078.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1539.9,2088.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1535.7,2099.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1531.6,2110.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1527.4,2121.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1523.3,2131.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1519.1,2142.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1515,2153.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1510.8,2164.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1445.9,2288.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1429.7,2281.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1413.4,2275c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1397.1,2268.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1484,2304.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,2301.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1462.2,2295.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1482.1,2284.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1471.4,2280.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1460.7,2276.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1450.1,2271.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1439.4,2267.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1428.8,2262.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1418.1,2258.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,2254c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1396.8,2249.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1457.1,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1438.7,2025c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.1,2022c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1455.5,1980.9c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1442.7,2031.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1430.2,2046.6c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.3,9.4c-.1.9-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1421.6,2029.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1654,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1647.4,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1662.9,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1610.6,1995.9c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1603.2,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1602.6,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1435.6,2131.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1397,2127.2c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1396.6,2118.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1444.2,2126.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1392.2,2133.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1384.1,2134c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.4-4.6,11.1-3.3,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM1383.9,2112.3c-2,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.6,2077.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1609.2,2098.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1602.1,2103.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1571.9,2067.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1600.4,2111.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1613.4,2122.4c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1604.8,2114.7c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1469.9,2053.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1496.4,2040.5c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.8,2049.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1474.5,2029.9c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.5-1.9.5ZM1474.5,2023.1c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4s-.2,1.2.1,1.8c.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1,1.2-.6,1.6-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1475.5,2042.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1565.4,2042.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1594.1,2050.1c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c.1-.5.7-.9,1.2-.7l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1575.1,2045.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1586.4,2028.7c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1586.4,2021.9c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM1589.6,2025.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1589.3,2041.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,2093.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,2123.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,2103.9c-1.6,0-3.1-.9-3.9-2.3-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM1316.5,2097.1c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2h0c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1439.3,2101.5c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1449.5,2050.4c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,2074.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1546.5,2023.1c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1551.3,1979l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1559.7,2008.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1282.7,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1423.1,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1390.8,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1407.4,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1326.3,2262c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-1.7-3.4-.4-7.7,3.1-9.4,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-2.5,1.2-3.4,4.3-2.2,6.7.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1580,2194.5c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.6,2212.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1540.2,2205.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1584.2,2185.4c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,2220.4c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.4,2227.6c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.3-.4-.2-1.1.2-1.4l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1528.2,2206.8c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1501.4,2018.8c-.4,0-.8-.3-1-.7l-6.6-21.3c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.3,2035c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.9,2041.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.4,2009.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1532.2,2049.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1545.2,2058.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1537.1,2052c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1359.8,2195.7c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1342,2175.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1347.7,2193.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,2313.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,2310.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-1,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,2304s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,2298.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1517.9,2292c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,2303.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1328,2289c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,2307.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1335.8,2295.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1354.5,2285c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2-.9,1.1-2.1,1.6-3.4,1.6ZM1354.5,2278.3c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1350.5,2297.3c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1399.6,2176.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1388.3,2192.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1380.9,2186.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1389.1,2171.4c-1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.9-1.4-3s.3-2.3,1.1-3.1h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.9,1-2,1.4-3.2,1.4ZM1389.1,2164.6s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6,1,.9,2.5.8,3.3-.2.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-.4-.4-1-.6-1.6-.6ZM1386.6,2164.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1371.4,2177.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505,2224c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1483.3,2206.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1511.4,2216.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1498.1,2200.8c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9s.2-2.3,1-3.2c.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1t0,0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM1498.1,2194.1c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1505.5,2211.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1549,2135.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1574.8,2150.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1557.7,2140.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1573.9,2127.5c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3,1.2-2.1,3.8-2.8,5.9-1.7,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1573.9,2120.8c-.8,0-1.6.4-2.1,1.2-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4s1.2.4,1.8.2c.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1572.5,2140.2c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1467.8,2190.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM1430,2157.7c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1441.2,2185.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1604,2187.2c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1605.3,2135.1c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1593,2162.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1360.3,2249.2c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4s.5-3,1.6-4.1l26.8-28.5c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1369.1,2205.9l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1374.4,2236.4c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1361.6,2164.6c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1331,2144.9l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1346.2,2156.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1433.2,2154.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1341.7,2222.3c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1630.9,2075.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1532.8,2273.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1465.9,1999.2c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1550.4,2180.7c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1576,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1572.2,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1484,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1478.5,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1538.1,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1510.2,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1539.2,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1517.9,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1517.9,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1531.7,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1351.1,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,349.3c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2,.3.7.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM1062.5,334.2l30.9,12.8c.9.4,2,.4,2.9,0s1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1079,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1316.5,127c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1282.7,68l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1264,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1078.3,68.1c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1082.5,85.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1086.8,102.3c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1091.1,119.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1018.6,141.7c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1069.7,33.9c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1074,51c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1000.6,42.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1003.4,53.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,65.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009,76.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1011.8,87.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1014.6,98.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1017.4,109.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1020.2,121.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1023,132.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216,154.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.7,171h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,187.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1197,203.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1195.5,207.8c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1228.7,121.6h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1222.4,138.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1215.7,108c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1211.5,118.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1207.4,129.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.2,140.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1199.1,151c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1194.9,161.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1190.8,172.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1186.6,183.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1182.5,194.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1117.6,318.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.3,311.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1085,304.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.7,298.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1133.9,325.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1153.7,314.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1143.1,310.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1132.4,306c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1121.7,301.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1111.1,297.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1100.4,292.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1089.7,288.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079.1,283.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.4,279.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,20.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1110.4,54.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,51.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1127.1,10.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1114.4,61.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,76.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0s-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1093.2,59.6c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1282.3,25.8c-.6,0-1-.4-1-1V3.6c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1107.2,161.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1068.6,157.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1068.2,148c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1115.8,156.3c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1063.9,163.3c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1055.8,163.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1055.5,142.2c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1245.3,107.3c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1280.9,128.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1273.7,133.7c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.5,97.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1272.1,141.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1285,152.3c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1276.5,144.6c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1141.5,83.4c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1168,70.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1150.5,79c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1146.2,59.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.4-1.9.4ZM1146.1,53c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8c-.4-.8-1.2-1.3-2.1-1.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1147.1,72.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1237,72.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1265.7,80c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1246.7,75.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1258,58.6c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1258,51.8c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM1261.3,55h0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1260.9,71c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,123.4c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM988.2,127c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1111,131.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1121.2,80.3c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,104.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1218.2,53c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1222.9,8.9l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1231.4,38.6c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M972.4,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM954.4,68l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,21c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2,.3.7.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM1062.5,5.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1079,14.8c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M935,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M997.9,291.9c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1251.6,224.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1217.3,242.5c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1211.8,235.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1255.9,215.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,250.3c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1204,257.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM1199.9,236.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.7-1.9,9-3.1l-11.6-15.6Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1173,48.7c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,64.9c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1204.6,71.4c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1170.1,39.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1203.9,79.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,88.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1208.8,81.9c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1031.4,225.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1008,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1013.7,205.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1019.3,223.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1189.5,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6s1,2.1.9,3.2c-.3,2.2-2.1,3.9-4.3,3.9ZM1189.5,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,318.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1007.5,325.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1026.1,314.9c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.9,1.1-2.1,1.6-3.4,1.6ZM1026.1,308.2c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1022.2,327.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1071.3,206.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1060,222.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1052.5,216.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1060.8,201.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1059,195.3c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1043,207.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1176.7,253.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1154.9,236c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1183,246.1c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1169.7,230.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.9,1-2.1,1.6-3.4,1.6ZM1169.7,224c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6h0c-.4-.4-1-.5-1.5-.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1177.2,241.3c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1220.7,165.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1246.4,180c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4s-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1229.4,170.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1245.6,157.4c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.8,1.4-2.3,2.2-3.8,2.2ZM1245.6,150.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9h0c.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1244.2,170.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1139.5,220.8c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM1101.7,187.6c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1112.8,215.3c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1275.7,217.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM1277,165c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1264.7,192.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1031.9,279.2c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.5-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1040.7,235.8l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1046.1,266.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1033.3,194.5c-1,0-2.1-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.7,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1002.7,174.8l28.7,17.2c.9.5,1.9.7,2.9.4s1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1017.9,186c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,184.3c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M1013.3,252.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M1302.6,105.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M1204.4,303.8c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M1137.6,29.1c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M1222.1,210.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,3.4c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,5.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5s.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,3.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6L916.4,3.9c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,15.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,12.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,5.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1189.5.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1189.5-6.4c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,4.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM1062.5,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,481.9c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1316.5,455.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM1282.7,396.3l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1s-1.7,2-1.9,3.3c-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1078.3,396.5c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1082.5,413.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1086.8,430.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1091.1,447.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1018.6,470.1c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1069.7,362.3c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3s2.5-2.7,4.3-3.2c.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1s-.5,1-1.1.9c-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1074,379.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1000.6,371.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1003.4,382.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,393.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1009,404.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1011.8,415.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1014.6,427.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1017.4,438.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1020.2,449.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1023,460.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1216,482.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1209.7,499.3h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,515.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1197,532.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1195.5,536.1c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1228.7,450h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1222.4,466.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1215.7,436.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1211.5,447.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1207.4,457.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1203.2,468.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1199.1,479.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1194.9,490.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1190.8,500.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1186.6,511.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1182.5,522.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1117.6,646.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1101.3,640c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1085,633.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1068.7,626.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1133.9,653.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1s-2.6,0-3.8.8c-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1153.7,643.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1143.1,638.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1132.4,634.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1121.7,629.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1111.1,625.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1100.4,621.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1089.7,616.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1079.1,612.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1068.4,607.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1110.4,383.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,380.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1127.1,339.1c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1114.4,390c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,404.8c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1093.2,388c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1282.3,354.1c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1107.2,489.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.6,485.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.2,476.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1115.8,484.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1063.9,491.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1055.8,492.3c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1055.5,470.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1245.3,435.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1280.9,456.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1273.7,462.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1243.5,425.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1272.1,469.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1285,480.6c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1276.5,473c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1141.5,411.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1168,398.7c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.5,407.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1146.2,388.1c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM1146.1,381.4c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.6-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1147.1,400.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1237,401.2c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1265.7,408.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1246.7,403.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1258,386.9c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1258,380.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0ZM1261.3,383.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1260.9,399.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,451.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM988.2,455.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1111,459.7c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1121.2,408.7c-.3,0-.6,0-.9,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,433.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1218.2,381.3c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1222.9,337.2l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1231.4,367c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M972.5,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM954.4,396.3l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,349.3c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2,.3.7.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM1062.5,334.2l30.9,12.8c.9.4,2,.4,2.9,0s1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1s-1.7,2-1.9,3.3c-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.9,620.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1251.6,552.7c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1217.3,570.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1211.8,563.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1255.9,543.6c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,578.7c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1204,585.8c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM1199.9,565c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.7-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1173,377.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,393.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1204.6,399.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1170.1,367.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.9,407.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,416.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1208.8,410.2c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1031.4,553.9c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1008,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1013.7,534c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1019.3,551.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1189.5,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,1.2.1,2.2.7,2.9,1.6s1,2.1.9,3.2c-.3,2.2-2.1,3.9-4.3,3.9ZM1189.5,650.3c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,647.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1007.5,653.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1026.1,643.3c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9s.2-2.3.9-3.2,1.8-1.5,2.9-1.6c1.2-.1,2.3.2,3.2.9.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2-.9,1.1-2.1,1.6-3.4,1.6ZM1026.1,636.6c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1022.2,655.5c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1071.3,534.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1060,551.2c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1052.5,544.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1060.8,529.6c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM1059,523.7c-.9,1-.8,2.5.2,3.3s2.5.8,3.3-.2c.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1043,536c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1176.7,582.2c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1154.9,564.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1183,574.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1169.7,559c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1,.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM1169.7,552.3c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.8,1-.7,2.5.3,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6,0,0,0,0,0,0-.4-.4-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1177.2,569.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1220.7,493.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1246.4,508.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4s-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1229.4,498.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1245.6,485.8c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3h0c-.8,1.4-2.3,2.2-3.8,2.2ZM1245.6,479c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.3-.6.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3ZM1248.5,483.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1244.2,498.4c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1139.5,549.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9h0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM1101.7,515.9c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1112.8,543.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1275.7,545.4c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1277,493.3c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1264.7,520.5c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1031.9,607.5c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4s.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1040.7,564.1l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1046.1,594.6c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1033.3,522.8c-1,0-2-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.7,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM1002.7,503.1l28.7,17.2c1.8,1.1,4.1.5,5.2-1.3l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1017.9,514.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,512.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1013.3,580.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1302.6,434.2h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1204.4,632.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1137.6,357.4c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1222.1,538.9c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1189.5,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6s1,2.1.9,3.2c-.3,2.2-2.1,3.9-4.3,3.9ZM1189.5,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,1006c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2,.3.7.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM1062.5,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,810.2c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM1316.5,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM1282.7,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1078.3,724.8c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1082.5,741.9c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1086.8,759c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3s.2,1.1-.3,1.4c-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1091.1,776.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1018.6,798.4c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1069.7,690.6c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3s-.2-3.7.8-5.3c1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.3,1.3,1.1,2.4,2.3,3.1,1.1.7,2.5.9,3.8.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1074,707.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1000.6,699.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1003.4,710.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,721.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1009,733c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1011.8,744.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1014.6,755.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1017.4,766.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1020.2,777.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1023,789c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1216,811.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1209.7,827.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,844.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1197,860.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1195.5,864.5c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1228.7,778.3h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1222.4,794.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1215.7,764.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1211.5,775.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1207.4,786.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1203.2,797c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1199.1,807.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1194.9,818.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1190.8,829.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1186.6,840c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1182.5,850.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1117.6,975c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1101.3,968.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1085,961.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1068.7,954.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,1.7.7,3.1,2.1,3.8,3.8.7,1.7.7,3.6,0,5.4-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1133.9,981.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1s-2.6,0-3.8.8c-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1153.7,971.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1143.1,967.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1132.4,962.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1121.7,958.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1111.1,953.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1100.4,949.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1089.7,945c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1079.1,940.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1068.4,936.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1110.4,711.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,708.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1127.1,667.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1114.4,718.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,733.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0s-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1093.2,716.3c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1282.3,682.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1107.2,818.1c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.6,813.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.2,804.7c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1115.8,813c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1063.9,820c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1055.8,820.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1055.5,798.9c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1245.3,764c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1280.9,784.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1273.7,790.4c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1243.5,754.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1272.1,798.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1285,809c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1276.5,801.3c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1141.5,740.1c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1168,727.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.5,735.7c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1146.2,716.5c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.4-1.9.4ZM1146.1,709.7c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1147.1,728.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1237,729.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1265.7,736.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1246.7,732c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1258,715.3c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1258,708.5c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM1261.3,711.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1260.9,727.7c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,780.1c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM988.2,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1111,788.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1s-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1121.2,737c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,761.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1218.2,709.7c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1222.9,665.6l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1231.4,695.3c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M972.5,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM954.4,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM1062.5,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.9,948.6c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1251.6,881.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1217.3,899.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1211.8,891.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1255.9,872c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,907c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4s1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1204,914.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1199.9,893.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1173,705.4c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,721.6c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1204.6,728.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1170.1,695.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.9,735.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,745.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1208.8,738.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1031.4,882.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1008,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1013.7,862.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1019.3,880.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1189.5,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1189.5,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,975.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1007.5,981.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1026.1,971.6c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM1026.1,964.9c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM1028.8,969.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1022.2,983.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1071.3,863c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1060,879.5c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1052.5,872.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1060.8,858c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.9,1-2,1.4-3.2,1.4ZM1059,852h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1043,864.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1176.7,910.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1154.9,892.7c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1183,902.8c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1169.7,887.4c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1,.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM1169.7,880.7c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6h0c-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1177.2,898c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1220.7,822.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1246.4,836.7c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1229.4,827.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1245.6,814.1c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.8,1.4-2.3,2.2-3.8,2.2ZM1245.6,807.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1244.2,826.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1139.5,877.5c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7s2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM1101.6,844.3c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1112.8,872c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1275.7,873.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1277,821.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1264.7,848.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1031.9,935.9c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1040.7,892.5l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1046.1,923c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1033.3,851.2c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.7,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1002.7,831.5l28.7,17.2c.9.5,1.9.7,2.9.4s1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1017.9,842.7c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,841c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1013.3,908.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1302.6,762.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1204.4,960.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1137.6,685.8c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1222.1,867.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1189.5,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,1.2.1,2.2.7,2.9,1.6s1,2.1.9,3.2c-.3,2.2-2.1,3.9-4.3,3.9ZM1189.5,650.3c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1062.5,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1316.5,1112.1c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1282.7,1053l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1078.3,1053.2c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1082.5,1070.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1086.8,1087.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1091.1,1104.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1018.6,1126.8c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1069.7,1019c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3s2.5-2.7,4.3-3.2c.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1074,1036.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1000.6,1027.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1003.4,1039c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,1050.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1009,1061.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1011.8,1072.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1014.6,1083.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1017.4,1095c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1020.2,1106.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1023,1117.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1216,1139.6h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1209.7,1156h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,1172.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1197,1188.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1195.5,1192.8c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1228.7,1106.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1222.4,1123.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1215.7,1093c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1211.5,1103.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1207.4,1114.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1203.2,1125.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1199.1,1136.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1194.9,1146.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1190.8,1157.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1186.6,1168.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1182.5,1179.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1117.6,1303.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1101.3,1296.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1085,1289.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1068.7,1283.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1s-2.6,0-3.8.8c-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,1.7.7,3.1,2.1,3.8,3.8.7,1.7.7,3.6,0,5.4-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1133.9,1310.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1153.7,1299.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1143.1,1295.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1132.4,1291c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1121.7,1286.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1111.1,1282.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1100.4,1277.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1089.7,1273.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1079.1,1269c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1068.4,1264.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1110.4,1040c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,1037c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1127.1,995.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1114.4,1046.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,1061.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2s-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1093.2,1044.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1282.3,1010.8c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1107.2,1146.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.6,1142.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.2,1133.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1115.8,1141.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1063.9,1148.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1055.8,1148.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1055.5,1127.3c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1245.3,1092.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1280.9,1113.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1273.7,1118.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1243.5,1082.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1272.1,1126.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1285,1137.3c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1276.5,1129.7c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1141.5,1068.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1168,1055.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.5,1064.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1146.2,1044.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.4-1.9.4ZM1146.1,1038.1c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1147.1,1057.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1237,1057.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1265.7,1065c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1246.7,1060.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1258,1043.6c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1258,1036.9c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM1261.3,1040.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1260.9,1056.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,1108.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM988.2,1112.1c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1111,1116.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1121.2,1065.4c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,1089.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1218.2,1038c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.4.3.5,1,.2,1.4l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1222.9,993.9l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1231.4,1023.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M972.4,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM954.4,1053l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,1006c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2,.3.7.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM1062.5,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.9,1277c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1251.6,1209.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1217.3,1227.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1211.8,1220.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1255.9,1200.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,1235.4c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1204.1,1242.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1199.9,1221.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1173,1033.8c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,1050c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1204.6,1056.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1170.1,1024.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.9,1064.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,1073.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1208.8,1066.9c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1031.4,1210.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1008,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1013.7,1190.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1019.3,1208.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,1325.7s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1189.6,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9s2.2.7,2.9,1.6c.7.9,1,2.1.9,3.2-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM1189.5,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6s-.1-1.2-.5-1.7c-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,1303.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1007.5,1310.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1026.1,1300c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM1026.1,1293.3c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM1028.8,1297.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1022.2,1312.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1071.3,1191.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1060,1207.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1052.5,1201.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1060.8,1186.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1059,1180.4c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1043,1192.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1176.7,1238.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1154.9,1221.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1183,1231.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1169.7,1215.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.9,1-2.1,1.6-3.4,1.6ZM1169.7,1209c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1177.2,1226.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1220.7,1150.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1246.4,1165.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1229.4,1155.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1245.6,1142.5c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.8,1.4-2.3,2.2-3.8,2.2ZM1245.6,1135.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9h0c.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1244.2,1155.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1139.5,1205.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM1101.7,1172.6c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1112.8,1200.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1275.7,1202.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM1277,1150c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1264.7,1177.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1031.9,1264.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM1040.7,1220.8l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1046.1,1251.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1033.3,1179.5c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.7,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1002.7,1159.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1017.9,1171.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,1169.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1013.3,1237.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1302.6,1090.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1204.4,1288.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1137.6,1014.1c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1222.1,1195.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,1.7.7,3.1,2.1,3.8,3.8.7,1.7.7,3.6,0,5.4-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1189.5,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1.1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1189.5,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1062.5,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,1447.2c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM1316.5,1440.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4s-.1,1.2.2,1.8h0c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1h0s-1.3,4.5-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM1282.7,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1078.3,1381.5c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1082.5,1398.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1086.8,1415.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1091.1,1432.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1018.6,1455.1c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1069.7,1347.3c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1s2.5.9,3.8.6c.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1074,1364.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1000.6,1356.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1003.4,1367.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,1378.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1009,1389.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1011.8,1400.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1014.6,1412.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1017.4,1423.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1020.2,1434.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1023,1445.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1216,1467.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1209.7,1484.4h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,1500.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1197,1517.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1195.5,1521.2c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1228.7,1435h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1222.4,1451.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1215.7,1421.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1211.5,1432.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1207.4,1442.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1203.2,1453.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1199.1,1464.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1194.9,1475.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1190.8,1486c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1186.6,1496.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1182.5,1507.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1117.6,1631.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1101.3,1625c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1085,1618.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1068.7,1611.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1133.9,1638.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1153.7,1628.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1143.1,1623.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1132.4,1619.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1121.7,1615c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1111.1,1610.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1100.4,1606.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1089.7,1601.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1079.1,1597.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1068.4,1592.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1110.4,1368.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,1365.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1127.1,1324.2c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1114.4,1375c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,1389.9c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1093.2,1373c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1282.3,1339.2c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1107.2,1474.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.6,1470.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.2,1461.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1115.8,1469.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1063.9,1476.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1055.8,1477.3c-2.5,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1055.5,1455.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1245.3,1420.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1280.9,1441.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1273.7,1447.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1243.5,1410.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1272.1,1454.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1285,1465.7c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1276.5,1458c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1141.5,1396.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1168,1383.8c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.5,1392.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1146.2,1373.2c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.4-1.9.4ZM1146.1,1366.4c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.6-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1147.1,1385.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1237,1386.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1265.7,1393.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1246.7,1388.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1258,1372c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM1258,1365.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5s0,1.2.3,1.8c.3.5.8.9,1.5,1.1,1.3.3,2.5-.5,2.9-1.7.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0ZM1261.3,1368.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1260.9,1384.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,1436.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,1447.2c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM988.2,1440.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4s-.1,1.2.2,1.8h0c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1111,1444.8c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1s-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1121.2,1393.7c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7s-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,1418.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1218.2,1366.4c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1222.9,1322.3l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1231.4,1352c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M972.5,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM954.4,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1062.5,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.9,1605.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1251.6,1537.8c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1217.3,1555.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1211.8,1548.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1255.9,1528.7c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,1563.7c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1204.1,1570.9c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1199.9,1550.1c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1173,1362.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,1378.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1204.6,1384.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1170.1,1352.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.9,1392.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,1401.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.4,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1208.8,1395.3c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1031.4,1539c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1008,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1013.7,1519.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1019.3,1536.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1189.5,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1189.5,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.2,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,1632.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1007.5,1638.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1026.1,1628.3c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM1026.1,1621.6c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM1028.8,1626.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1022.2,1640.6c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1071.3,1519.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1060,1536.2c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1052.5,1529.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1060.8,1514.7c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1059,1508.7h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2s.6-1.1.6-1.7c0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1043,1521c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1176.7,1567.3c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1154.9,1549.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1183,1559.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1169.7,1544.1c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM1169.7,1537.4c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1177.2,1554.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1220.7,1478.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1246.4,1493.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1229.4,1483.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1245.6,1470.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4s1.7,1.5,2.1,2.6c.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM1245.6,1464.1c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1244.2,1483.5c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1139.5,1534.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5s-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM1101.7,1501c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8s1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1112.8,1528.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1275.7,1530.5c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4s1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1277,1478.4c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1264.7,1505.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1031.9,1592.5c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1040.7,1549.2l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1046.1,1579.7c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1033.3,1507.9c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1002.7,1488.2l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1017.9,1499.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,1497.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1013.3,1565.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1302.6,1419.3h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1204.4,1617.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1137.6,1342.5c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1222.1,1524c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,1.7.7,3.1,2.1,3.8,3.8.7,1.7.7,3.6,0,5.4-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,1325.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1189.6,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9s2.2.7,2.9,1.6c.7.9,1,2.1.9,3.2-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM1189.5,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6s-.1-1.2-.5-1.7c-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1062.5,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,1775.5c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM1316.5,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4s-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1282.7,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1078.3,1709.9c-.8,0-1.6-.1-2.4-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1082.5,1727c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1086.8,1744.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1091.1,1761.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1018.6,1783.5c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1069.7,1675.7c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.5-1.8-.2-3.7.8-5.3s2.5-2.7,4.3-3.2c.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.3,1.3,1.1,2.4,2.3,3.1s2.5.9,3.8.6c.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1074,1692.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1000.6,1684.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1003.4,1695.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,1706.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1009,1718.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1011.8,1729.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1014.6,1740.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1017.4,1751.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1020.2,1762.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1023,1774c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1216,1796.3h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1209.7,1812.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,1829.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1197,1845.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1195.5,1849.5c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1228.7,1763.4h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1222.4,1779.8h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1215.7,1749.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1211.5,1760.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1207.4,1771.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1203.2,1782c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1199.1,1792.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1194.9,1803.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1190.8,1814.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1186.6,1825.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1182.5,1835.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1117.6,1960.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1101.3,1953.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1085,1946.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1068.7,1939.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1133.9,1966.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1153.7,1956.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1143.1,1952.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1132.4,1947.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1121.7,1943.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1111.1,1938.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1100.4,1934.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1089.7,1930.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1079.1,1925.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1068.4,1921.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1110.4,1696.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,1693.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1127.1,1652.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1114.4,1703.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,1718.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1093.2,1701.4c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1282.3,1667.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1107.2,1803.2c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.6,1798.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.2,1789.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1115.8,1798.1c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1063.9,1805.1c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1055.8,1805.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM1055.5,1784c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1245.3,1749.1c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1280.9,1769.9c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1273.7,1775.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1243.5,1739.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1272.1,1783.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1285,1794c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1276.5,1786.4c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1141.5,1725.2c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1168,1712.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.5,1720.8c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1146.2,1701.5c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2,1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM1146.1,1694.8c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.6-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1147.1,1714c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1237,1714.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1265.7,1721.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1246.7,1717c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1258,1700.3c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1258,1693.6c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0ZM1261.3,1696.8h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1260.9,1712.8c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,1765.2c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,1775.5c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM988.2,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4s-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1111,1773.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1-.2-.1-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1121.2,1722.1c-.3,0-.6,0-.9,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7s-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,1746.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1218.2,1694.7c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5h0s2.8-3.7,2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1222.9,1650.6l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1231.4,1680.4c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M972.4,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM954.4,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1062.5,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.9,1933.7c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3s-2.1,1.6-2.5,2.9c-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1251.6,1866.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1217.3,1884.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1211.8,1877c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1255.9,1857c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,1892.1c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1204,1899.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1199.9,1878.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.2.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1173,1690.5c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,1706.7c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1204.6,1713.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1170.1,1680.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.9,1721c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,1730.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1208.8,1723.6c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1031.4,1867.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1008,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1013.7,1847.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1019.3,1865.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1189.5,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1189.5,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,1960.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1007.5,1966.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1026.1,1956.7c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.9,1.1-2.1,1.6-3.4,1.6ZM1026.1,1950c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1022.2,1968.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1071.3,1848c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1060,1864.6c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1052.5,1857.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1060.8,1843c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3.9.8,1.4,1.8,1.4,3,0,1.2-.3,2.3-1.1,3.1-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM1059,1837.1c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1043,1849.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1176.7,1895.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1154.9,1877.8c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1183,1887.9c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1169.7,1872.4c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.9,1-2.1,1.6-3.4,1.6ZM1169.7,1865.7c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1177.2,1883.1c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1220.7,1807.3c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1246.4,1821.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1229.4,1812.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1245.6,1799.2c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.8,1.4-2.3,2.2-3.8,2.2ZM1245.6,1792.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9s.2-2.6-.9-3.2c-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1244.2,1811.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1139.5,1862.6c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM1101.7,1829.3c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1112.8,1857.1c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1275.7,1858.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0s2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1277,1806.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1264.7,1833.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1031.9,1920.9c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4s.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1040.7,1877.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1046.1,1908c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1033.3,1836.2c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.7,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1002.7,1816.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9s-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1017.9,1827.8c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,1826.1c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6s-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1013.3,1893.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1302.6,1747.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1204.4,1945.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1137.6,1670.8c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1222.1,1852.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1189.5,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1189.5,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.2,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,2319.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,2319.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM1062.5,2304.3l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079,2313.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1313.4,2123.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1330.8,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1316.5,2103.9c-1.6,0-3.1-.9-3.9-2.3-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM1316.5,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8,0,0,0,0,0,0,.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.5,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1300.8,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.4-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM1282.7,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1310.9,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1279.1,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1263.4,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1310.5,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1315.4,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1305.1,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1294.8,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1284.6,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1274.3,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1264,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1253.7,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1243.4,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1336.4,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1303.8,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1337.8,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM1304.1,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1324.3,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1078.3,2038.2c-.8,0-1.6-.1-2.4-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1082.5,2055.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1086.8,2072.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1091.1,2089.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1018.6,2111.8c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1069.7,2004c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1,1.1.7,2.5.9,3.8.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1074,2021.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1000.6,2012.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1003.4,2024c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,2035.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1009,2046.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1011.8,2057.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1014.6,2068.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1017.4,2080c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1020.2,2091.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1023,2102.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1216,2124.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.7-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1209.7,2141.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,2157.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1197,2173.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1195.5,2177.9c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1228.7,2091.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.5-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1222.4,2108.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1215.7,2078.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1211.5,2088.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1207.4,2099.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1203.2,2110.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1199.1,2121.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1194.9,2131.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1190.8,2142.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1186.6,2153.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1182.5,2164.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1117.6,2288.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1101.3,2281.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1085,2275c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1068.7,2268.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1s-2.6,0-3.8.8c-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,2304.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,2301.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7s-2.6-.5-3.8,0c-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8.7,1.7.7,3.6,0,5.4-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1133.9,2295.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1153.7,2284.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1143.1,2280.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1132.4,2276.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1121.7,2271.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1111.1,2267.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1100.4,2262.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1089.7,2258.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1079.1,2254c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M1068.4,2249.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1128.7,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1110.4,2025c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,2022c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1127.1,1980.9c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1114.4,2031.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1101.8,2046.6c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM1093.2,2029.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1325.7,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1319.1,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1334.5,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1282.3,1995.9c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1274.8,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM1274.2,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1107.2,2131.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.6,2127.2c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1068.2,2118.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1115.8,2126.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.6,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1063.9,2133.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1055.8,2134c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.4-4.6,11.1-3.3,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM1055.5,2112.3c-2,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.9-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1245.3,2077.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1280.9,2098.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1273.7,2103.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1243.5,2067.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1272.1,2111.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1285,2122.4c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.6,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.7.6,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM1276.5,2114.7c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1141.5,2053.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1168,2040.5c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.5,2049.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1146.2,2029.9c-1.6,0-3.2-.9-3.9-2.4-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,2.2-1.1,4.8-.2,5.8,2,.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.4-1.9.4ZM1146.1,2023.1c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.6-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1147.1,2042.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1237,2042.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1265.7,2050.1c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c.1-.5.7-.9,1.2-.7l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1246.7,2045.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1258,2028.7c-.3,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3h0c-.5,2-2.3,3.3-4.2,3.3ZM1258,2021.9c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9,1.3.3,2.5-.5,2.9-1.7.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM1261.3,2025.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1260.9,2041.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,2093.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,2103.9c-1.6,0-3.1-.9-3.9-2.3-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM988.2,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8,0,0,0,0,0,0,.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1111,2101.5c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1s-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM1121.2,2050.4c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,2074.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1218.2,2023.1c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-2.5-1.9-3.1-5.6-1.1-8.1l20.7-27.5h0s2.8-3.7,2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM1222.9,1979l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1231.4,2008.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M972.4,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM954.4,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1094.8,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM1062.5,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1079,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.9,2262c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3s-2.1,1.6-2.5,2.9c-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1251.6,2194.5c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1217.3,2212.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1211.8,2205.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1255.9,2185.4c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,2220.4c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1204,2227.6c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM1199.9,2206.8c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.2.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1173,2018.8c-.4,0-.8-.3-1-.7l-6.6-21.3c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,2035c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1204.6,2041.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1170.1,2009.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.9,2049.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1216.9,2058.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM1208.8,2052c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1031.4,2195.7c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1008,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1013.7,2175.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1019.3,2193.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,2313.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,2310.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-1,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,2304s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1189.5,2298.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1189.5,2292c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,2303.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M999.7,2289c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,2307.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1007.5,2295.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1026.1,2285c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2-.9,1.1-2.1,1.6-3.4,1.6ZM1026.1,2278.3c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1022.2,2297.3c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1071.3,2176.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1060,2192.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1052.5,2186.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1060.8,2171.4c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3s1.9,4.4.3,6.2c-.9,1-2,1.4-3.2,1.4ZM1059,2165.4c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.4-.5.6-1.1.6-1.7,0-.6-.3-1.2-.8-1.6-1-.9-2.5-.8-3.3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1043,2177.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1176.7,2224c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1154.9,2206.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.7,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1183,2216.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1169.7,2200.8c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-1,3.2-.9,1-2.1,1.6-3.4,1.6ZM1169.7,2194.1c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1177.2,2211.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1220.7,2135.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1246.4,2150.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1229.4,2140.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1245.6,2127.5c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.8,1.4-2.3,2.2-3.8,2.2ZM1245.6,2120.8c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1244.2,2140.2c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1139.5,2190.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM1101.7,2157.7c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1112.8,2185.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1275.7,2187.2c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM1277,2135.1c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1264.7,2162.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1031.9,2249.2c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4s.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.2,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.4.4.4,1,0,1.4l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM1040.7,2205.9l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c1.5,1.4,3.9,1.4,5.4-.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1046.1,2236.4c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1033.3,2164.6c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.3c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.7,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM1002.7,2144.9l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1017.9,2156.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1104.9,2154.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1013.3,2222.3c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1302.6,2075.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1204.4,2273.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1137.6,1999.2c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1222.1,2180.7c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1247.7,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1243.9,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1155.7,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1150.2,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.5-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1209.8,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1181.8,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1210.9,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1189.5,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM1189.5,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1203.4,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1022.8,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.3-.4,1-.5,1.4-.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2s.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,349.3c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,334.2l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM988.2,127c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M972.4,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM954.4,68l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M935,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1008,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M749.9,68.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M754.2,85.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M758.5,102.3c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M762.8,119.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M690.3,141.7c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M741.4,33.9c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M745.6,51c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M672.3,42.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M675.1,53.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M677.9,65.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M680.7,76.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M683.5,87.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M686.3,98.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M689.1,109.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M691.9,121.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M694.7,132.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M887.7,154.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,171h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,187.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M868.7,203.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M867.2,207.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M900.4,121.6h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M894,138.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M887.3,108c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M883.2,118.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M879,129.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M874.9,140.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M870.7,151c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M866.6,161.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M862.4,172.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M858.3,183.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M854.1,194.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M789.2,318.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773,311.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M756.7,304.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M740.4,298.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M805.5,325.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M825.4,314.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M814.7,310.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M804,306c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M793.4,301.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782.7,297.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M772.1,292.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M761.4,288.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,283.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M740.1,279.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,20.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M782,54.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M773.4,51.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M798.8,10.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M786,61.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M773.5,76.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM764.9,59.6c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M953.9,25.8c-.6,0-1-.4-1-1V3.6c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M778.9,161.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M740.3,157.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M739.9,148c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M787.5,156.3c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M735.5,163.3c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M727.4,163.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM727.2,142.2c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M916.9,107.3c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M952.5,128.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M945.4,133.7c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.2,97.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M943.7,141.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M956.7,152.3c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM948.1,144.6c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M813.2,83.4c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M839.7,70.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M822.1,79c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M817.8,59.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM817.8,53c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2s2,1.6,3.2,1.1c1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M818.8,72.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M908.7,72.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M937.4,80c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M918.4,75.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M929.7,58.6c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM929.7,51.8c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8s-.8-.9-1.5-1.1c-.2,0-.4,0-.6,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M932.6,71c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,123.4c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,133.8c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM657.7,130.5c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M782.6,131.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM792.8,80.3c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,104.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M889.8,53c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM894.6,8.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8s.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M903,38.6c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.3-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM626,68l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,21c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3L727.4.2c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,5.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,14.8c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M591,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M669.6,291.9c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-1.7-3.4-.4-7.7,3.1-9.4,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-2.5,1.2-3.4,4.3-2.2,6.7.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M669,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M597,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M923.3,224.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M888.9,242.5c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M883.5,235.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M927.5,215.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,250.3c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M875.7,257.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM871.5,236.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.7-1.9,9-3.1l-11.6-15.6Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M844.7,48.7c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,64.9c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M876.2,71.4c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M841.7,39.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M875.5,79.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,88.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM880.4,81.9c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M703.1,225.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M685.3,205.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M691,223.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM861.2,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M875,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,318.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M679.1,325.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M697.8,314.9c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM697.8,308.2c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5ZM700.4,312.7h0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M693.8,327.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M743,206.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M731.6,222.9c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M724.2,216.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M732.4,201.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM732.4,194.5s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM729.9,194.7h0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M714.7,207.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M848.3,253.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M826.6,236c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M854.7,246.1c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M841.4,230.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM841.4,224c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M848.8,241.3c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M892.3,165.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M918.1,180c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M901,170.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M917.2,157.4c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.8,1.4-2.3,2.2-3.8,2.2ZM917.2,150.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9h0c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.9,170.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M811.1,220.8c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c0,.3-.2.5-.4.6-.2.1-.5.2-.8.1l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM773.3,187.6c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M784.5,215.3c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M947.3,217.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM948.6,165c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M936.3,192.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M703.6,279.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM712.4,235.8l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M717.7,266.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M704.9,194.5c-1,0-2.1-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM674.3,174.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M689.5,186c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,184.3c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c-.1.4-.5.7-.9.7Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M685,252.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M974.3,105.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M876.1,303.8c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M809.2,29.1c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M893.7,210.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M669,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,3.4c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6L916.4,3.9c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,5.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5s.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,3.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6L588.1,3.9c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,15.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,12.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,5.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M861.2.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM861.2-6.4c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M875,4.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM988.2,455.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M972.5,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM954.4,396.3l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1008,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M749.9,396.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M754.2,413.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M758.5,430.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M762.8,447.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M690.3,470.1c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M741.4,362.3c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3s2.5-2.7,4.3-3.2c.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M745.6,379.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M672.3,371.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M675.1,382.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M677.9,393.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M680.7,404.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M683.5,415.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M686.3,427.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M689.1,438.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M691.9,449.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M694.7,460.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M887.7,482.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,499.3h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M875,515.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M868.7,532.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M867.2,536.1c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M900.4,450h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M894,466.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M887.3,436.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M883.2,447.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M879,457.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M874.9,468.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M870.7,479.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M866.6,490.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M862.4,500.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M858.3,511.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M854.1,522.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M789.2,646.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M773,640c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M756.7,633.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M740.4,626.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8s3.6-.7,5.4,0c1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M805.5,653.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M825.4,643.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M814.7,638.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M804,634.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M793.4,629.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M782.7,625.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M772.1,621.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M761.4,616.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,612.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M740.1,607.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2s.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782,383.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773.4,380.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M798.8,339.1c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M786,390c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773.5,404.8c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM764.9,388c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M953.9,354.1c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M778.9,489.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M740.3,485.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M739.9,476.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M787.5,484.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M735.5,491.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M727.4,492.3c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM727.2,470.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M916.9,435.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M952.5,456.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M945.4,462.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.2,425.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M943.7,469.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M956.7,480.6c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM948.1,473c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M813.2,411.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M839.7,398.7c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M822.1,407.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M817.8,388.1c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM817.8,381.4c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M818.8,400.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M908.7,401.2c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M937.4,408.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M918.4,403.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M929.7,386.9c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2s.8,2.2.5,3.3c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM929.7,380.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M932.6,399.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,451.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,462.1c-1.6,0-3.1-.9-3.9-2.3,0,0,0,0,0,0-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM657.7,458.8c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782.6,459.7c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM792.8,408.7c-.3,0-.6,0-.9,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,433.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M889.8,381.3c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM894.6,337.2l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M903,367c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.3-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM626,396.3l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3s-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,349.3c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,334.2l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M591,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669.6,620.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-1.7-3.4-.4-7.7,3.1-9.4,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-2.5,1.2-3.4,4.3-2.2,6.7.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M597,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M923.3,552.7c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.9,570.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M883.5,563.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M927.5,543.6c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,578.7c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875.7,585.8c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM871.5,565c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.7-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M844.7,377.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,393.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M876.2,399.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M841.7,367.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875.5,407.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,416.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM880.4,410.2c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M703.1,553.9c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M685.3,534c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M691,551.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM861.2,650.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,647.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.1,653.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M697.8,643.3c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9s.2-2.3.9-3.2c1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM697.8,636.6c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5ZM700.4,641h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M693.8,655.5c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M743,534.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M731.6,551.2c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M724.2,544.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M732.4,529.6c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM732.4,522.9s0,0-.1,0c-.6,0-1.2.3-1.6.8-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M714.7,536c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M848.3,582.2c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M826.6,564.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M854.7,574.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M841.4,559c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1h0c1.9,1.5,2.1,4.3.6,6.1-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM841.4,552.3c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.8,1-.7,2.5.3,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.7-2.5-.3-3.3,0,0,0,0,0,0-.4-.4-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M848.8,569.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M892.3,493.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M918.1,508.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M901,498.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M917.2,485.8c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9h0c-.8,1.4-2.3,2.2-3.8,2.2ZM917.2,479c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3ZM920.1,483.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.9,498.4c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M811.1,549.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c0,.3-.2.5-.4.6-.2.1-.5.2-.8.1l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9h0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM773.3,515.9c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.2,1,0,2,.5,2.8s1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M784.5,543.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M947.3,545.4c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM948.6,493.3c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M936.3,520.5c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M703.6,607.5c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM712.4,564.1l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M717.7,594.6c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M704.9,522.8c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM674.3,503.1l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M689.5,514.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,512.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M685,580.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M974.3,434.2h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M876.1,632.2c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M809.2,357.4c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M893.7,538.9c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M669,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM861.2,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,1006c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM988.2,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M972.5,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM954.4,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1008,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M749.9,724.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M754.2,741.9c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M758.5,759c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M762.8,776.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M690.3,798.4c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M741.4,690.6c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M745.6,707.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M672.3,699.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M675.1,710.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M677.9,721.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M680.7,733c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M683.5,744.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M686.3,755.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M689.1,766.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M691.9,777.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M694.7,789c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M887.7,811.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,827.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M875,844.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M868.7,860.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M867.2,864.5c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6s.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M900.4,778.3h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M894,794.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M887.3,764.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M883.2,775.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M879,786.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M874.9,797c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M870.7,807.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M866.6,818.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M862.4,829.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M858.3,840c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M854.1,850.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M789.2,975c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M773,968.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M756.7,961.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M740.4,954.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M805.5,981.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M825.4,971.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M814.7,967.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M804,962.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M793.4,958.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M782.7,953.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M772.1,949.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M761.4,945c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,940.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M740.1,936.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782,711.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773.4,708.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M798.8,667.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M786,718.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773.5,733.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM764.9,716.3c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M953.9,682.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M778.9,818.1c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M740.3,813.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M739.9,804.7c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M787.5,813c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M735.5,820c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M727.4,820.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM727.2,798.9c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M916.9,764c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M952.5,784.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M945.4,790.4c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.2,754.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M943.7,798.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M956.7,809c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM948.1,801.3c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M813.2,740.1c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M839.7,727.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M822.1,735.7c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M817.8,716.5c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM817.8,709.7c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M818.8,728.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M908.7,729.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M937.4,736.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M918.4,732c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M929.7,715.3c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM929.7,708.5c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8s-.8-.9-1.5-1.1c-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M932.6,727.7c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,780.1c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,790.5c-1.6,0-3.1-.9-3.9-2.3t0,0c-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM657.7,787.2c.6,1.2,2,1.6,3.2,1,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782.6,788.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1s-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM792.8,737c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,761.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M889.8,709.7c-1.2,0-2.4-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5h0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-1.1,1.5-2.9,2.3-4.6,2.3ZM894.6,665.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8s.7,1.9,1.5,2.5l12.4,9.3c1.7,1.3,4,.9,5.3-.7l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M903,695.3c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM626,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M591,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669.6,948.6c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-1.7-3.4-.4-7.7,3.1-9.4,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-2.5,1.2-3.4,4.3-2.2,6.7.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M597,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M923.3,881.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.9,899.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M883.5,891.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M927.5,872c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,907c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875.7,914.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM871.5,893.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.2.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M844.7,705.4c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,721.6c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M876.2,728.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M841.7,695.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875.5,735.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,745.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM880.4,738.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M703.1,882.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M685.3,862.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M691,880.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9s2.1-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM861.2,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,975.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.1,981.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M697.8,971.6c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM697.8,964.9c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5ZM700.4,969.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M693.8,983.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M743,863c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M731.6,879.5c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M724.2,872.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M732.4,858c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM730.7,852h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-.5-.4-1-.6-1.7-.6-.6,0-1.2.3-1.6.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M714.7,864.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M848.3,910.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M826.6,892.7c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M854.7,902.8c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M841.4,887.4c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1,1.9,1.5,2.1,4.3.6,6.1-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM841.4,880.7c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.7-2.5-.3-3.3h0c-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M848.8,898c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M892.3,822.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M918.1,836.7c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M901,827.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M917.2,814.1c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.8,1.4-2.3,2.2-3.8,2.2ZM917.2,807.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.9,826.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M811.1,877.5c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c0,.3-.2.5-.4.6-.2.1-.5.2-.8.1l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM773.3,844.3c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.2,1,0,2,.5,2.8s1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M784.5,872c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M947.3,873.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5h0s1.9,4.3,1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM948.6,821.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M936.3,848.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M703.6,935.9c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM712.4,892.5l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M717.7,923c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M704.9,851.2c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM674.3,831.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M689.5,842.7c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,841c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M685,908.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M974.3,762.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M876.1,960.5c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M809.2,685.8c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M893.7,867.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M669,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8s3.6-.7,5.4,0c1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM861.2,650.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,1334.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5s-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,1319.3l30.9,12.8c.9.4,2,.4,2.9,0s1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM988.2,1112.1c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2,0,0,0,0,0,0,.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M972.4,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM954.4,1053l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1008,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M749.9,1053.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M754.2,1070.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M758.5,1087.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M762.8,1104.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M690.3,1126.8c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M741.4,1019c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3s2.5-2.7,4.3-3.2c.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M745.6,1036.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M672.3,1027.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M675.1,1039c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M677.9,1050.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M680.7,1061.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M683.5,1072.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M686.3,1083.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M689.1,1095c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M691.9,1106.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M694.7,1117.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M887.7,1139.6h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,1156h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M875,1172.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M868.7,1188.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M867.2,1192.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M900.4,1106.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M894,1123.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M887.3,1093c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M883.2,1103.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M879,1114.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M874.9,1125.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M870.7,1136.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M866.6,1146.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M862.4,1157.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M858.3,1168.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M854.1,1179.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M789.2,1303.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M773,1296.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M756.7,1289.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M740.4,1283.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M805.5,1310.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M825.4,1299.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M814.7,1295.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M804,1291c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M793.4,1286.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M782.7,1282.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M772.1,1277.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M761.4,1273.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,1269c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M740.1,1264.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782,1040c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773.4,1037c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6s.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M798.8,995.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M786,1046.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773.5,1061.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2s-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM764.9,1044.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M953.9,1010.8c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M778.9,1146.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M740.3,1142.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M739.9,1133.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M787.5,1141.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M735.5,1148.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M727.4,1148.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM727.2,1127.3c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M916.9,1092.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M952.5,1113.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M945.4,1118.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.2,1082.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M943.7,1126.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M956.7,1137.3c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM948.1,1129.7c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M813.2,1068.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M839.7,1055.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M822.1,1064.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M817.8,1044.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM817.8,1038.1c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2s2,1.6,3.2,1.1c1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M818.8,1057.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M908.7,1057.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M937.4,1065c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M918.4,1060.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M929.7,1043.6c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM929.7,1036.9c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8s-.8-.9-1.5-1.1c-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M932.6,1056.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,1108.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,1118.8c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM657.7,1115.5c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782.6,1116.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM792.8,1065.4c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,1089.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M889.8,1038c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM894.6,993.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8s.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M903,1023.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.3-.8,2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7.8,1.3.9,2.9.5,4.4l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM626,1053l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,1006c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M591,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669.6,1277c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-1.7-3.4-.4-7.7,3.1-9.4,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2s-3.4,4.3-2.2,6.7c.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M597,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M923.3,1209.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.9,1227.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M883.5,1220.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M927.5,1200.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,1235.4c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875.7,1242.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.3.4.2,1.1-.2,1.4l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM871.5,1221.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.7-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M844.7,1033.8c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,1050c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M876.2,1056.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M841.7,1024.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875.5,1064.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,1073.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM880.4,1066.9c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M703.1,1210.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M685.3,1190.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M691,1208.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,1325.7s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM861.2,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,1303.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.1,1310.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M697.8,1300c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM697.8,1293.3c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5ZM700.4,1297.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M693.8,1312.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M743,1191.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M731.6,1207.9c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M724.2,1201.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M732.4,1186.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM732.4,1179.6s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM729.9,1179.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M714.7,1192.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M848.3,1238.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M826.6,1221.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M854.7,1231.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M841.4,1215.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM841.4,1209c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M848.8,1226.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M892.3,1150.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M918.1,1165.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M901,1155.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M917.2,1142.5c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.8,1.4-2.3,2.2-3.8,2.2ZM917.2,1135.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2,1.1.6,2.6.2,3.2-.9h0c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.9,1155.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M811.1,1205.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c0,.3-.2.5-.4.6-.2.1-.5.2-.8.1l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM773.3,1172.6c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M784.5,1200.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M947.3,1202.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM948.6,1150c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M936.3,1177.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M703.6,1264.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM712.4,1220.8l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M717.7,1251.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M704.9,1179.5c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2s.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM674.3,1159.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M689.5,1171.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,1169.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M685,1237.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M974.3,1090.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M876.1,1288.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M809.2,1014.1c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.7.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M893.7,1195.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M669,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9s2.1-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM861.2,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,1662.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,1647.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,1447.2c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM988.2,1440.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4s-.1,1.2.2,1.8h0c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M972.5,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM954.4,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1008,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M749.9,1381.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M754.2,1398.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M758.5,1415.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M762.8,1432.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M690.3,1455.1c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M741.4,1347.3c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M745.6,1364.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M672.3,1356.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M675.1,1367.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M677.9,1378.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M680.7,1389.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M683.5,1400.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M686.3,1412.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M689.1,1423.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M691.9,1434.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M694.7,1445.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M887.7,1467.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,1484.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M875,1500.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M868.7,1517.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M867.2,1521.2c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M900.4,1435h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M894,1451.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M887.3,1421.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M883.2,1432.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M879,1442.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M874.9,1453.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M870.7,1464.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M866.6,1475.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M862.4,1486c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M858.3,1496.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M854.1,1507.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M789.2,1631.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M773,1625c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M756.7,1618.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1s3.4,1.4,4.5,3c1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M740.4,1611.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M805.5,1638.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M825.4,1628.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M814.7,1623.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M804,1619.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M793.4,1615c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M782.7,1610.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M772.1,1606.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M761.4,1601.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,1597.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M740.1,1592.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782,1368.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773.4,1365.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M798.8,1324.2c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M786,1375c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773.5,1389.9c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM764.9,1373c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M953.9,1339.2c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M778.9,1474.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M740.3,1470.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M739.9,1461.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M787.5,1469.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M735.5,1476.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M727.4,1477.3c-2.5,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM727.2,1455.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M916.9,1420.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M952.5,1441.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M945.4,1447.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.2,1410.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M943.7,1454.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M956.7,1465.7c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8s.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM948.1,1458c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M813.2,1396.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M839.7,1383.8c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M822.1,1392.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M817.8,1373.2c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM817.8,1366.4c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M818.8,1385.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M908.7,1386.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M937.4,1393.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M918.4,1388.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M929.7,1372c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM929.7,1365.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M932.6,1384.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,1436.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,1447.2c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM657.7,1443.9c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782.6,1444.8c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM792.8,1393.7c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,1418.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M889.8,1366.4c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5h0s2.8-3.7,2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM894.6,1322.3l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M903,1352c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM626,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,1334.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,1319.3l30.9,12.8c.9.4,2,.4,2.9,0s1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M591,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669.6,1605.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M597,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M923.3,1537.8c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.9,1555.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M883.5,1548.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M927.5,1528.7c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,1563.7c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875.7,1570.9c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM871.5,1550.1c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.7-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M844.7,1362.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,1378.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M876.2,1384.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M841.7,1352.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875.5,1392.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,1401.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM880.4,1395.3c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M703.1,1539c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M685.3,1519.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M691,1536.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM861.2,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,1632.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1s.5,1,.1,1.4c-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.1,1638.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M697.8,1628.3c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM697.8,1621.6c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5ZM700.4,1626.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M693.8,1640.6c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M743,1519.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M731.6,1536.2c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M724.2,1529.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M732.4,1514.7c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM730.7,1508.7h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M714.7,1521c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M848.3,1567.3c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M826.6,1549.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M854.7,1559.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M841.4,1544.1c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM841.4,1537.4c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M848.8,1554.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M892.3,1478.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M918.1,1493.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M901,1483.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M917.2,1470.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM917.2,1464.1c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.9,1483.5c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M811.1,1534.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM773.3,1501c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M784.5,1528.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M947.3,1530.5c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM948.6,1478.4c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M936.3,1505.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M703.6,1592.6c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1h0s3.2-3.4,3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM712.4,1549.2l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M717.7,1579.7c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M704.9,1507.9c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM674.3,1488.2l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M689.5,1499.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,1497.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M685,1565.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M974.3,1419.3h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M876.1,1617.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M809.2,1342.5c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M893.7,1524c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M669,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7c-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,1325.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM861.2,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,1991.1c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,1976l30.9,12.8c.9.4,2,.4,2.9,0s1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,1775.5c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM988.2,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4s-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M972.4,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM954.4,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1008,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M749.9,1709.9c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M754.2,1727c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M758.5,1744.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M762.8,1761.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M690.3,1783.5c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M741.4,1675.7c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3s2.5-2.7,4.3-3.2c.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M745.6,1692.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M672.3,1684.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M675.1,1695.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M677.9,1706.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M680.7,1718.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M683.5,1729.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M686.3,1740.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M689.1,1751.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M691.9,1762.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M694.7,1774c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M887.7,1796.3h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,1812.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M875,1829.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M868.7,1845.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M867.2,1849.5c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M900.4,1763.4h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M894,1779.8h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M887.3,1749.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M883.2,1760.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M879,1771.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M874.9,1782c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M870.7,1792.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M866.6,1803.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M862.4,1814.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M858.3,1825.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M854.1,1835.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M789.2,1960.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M773,1953.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M756.7,1946.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M740.4,1939.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0s-2.2,1.5-2.7,2.7c-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M805.5,1966.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M825.4,1956.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M814.7,1952.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M804,1947.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M793.4,1943.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M782.7,1938.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M772.1,1934.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M761.4,1930.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,1925.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M740.1,1921.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782,1696.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773.4,1693.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M798.8,1652.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M786,1703.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773.5,1718.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM764.9,1701.4c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M953.9,1667.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M778.9,1803.2c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M740.3,1798.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M739.9,1789.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M787.5,1798.1c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M735.5,1805.1c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M727.4,1805.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM727.2,1784c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M916.9,1749.1c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M952.5,1769.9c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M945.4,1775.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.2,1739.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M943.7,1783.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M956.7,1794c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8s.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM948.1,1786.4c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M813.2,1725.2c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M839.7,1712.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M822.1,1720.8c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M817.8,1701.5c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM817.8,1694.8c-.3,0-.7,0-1,.2-1.2.6-1.7,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M818.8,1714c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M908.7,1714.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M937.4,1721.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M918.4,1717c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M929.7,1700.3c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2s-.8-2.2-.5-3.3c.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM929.7,1693.6c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M932.6,1712.8c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,1765.2c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,1775.5c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM657.7,1772.2c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.6-1.2-2-1.6-3.2-1-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782.6,1773.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM792.8,1722.1c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,1746.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M889.8,1694.7c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM894.6,1650.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M903,1680.4c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM626,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,1662.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,1647.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9s-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M591,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669.6,1933.7c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3s-2.1,1.6-2.5,2.9c-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M597,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M923.3,1866.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.9,1884.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M883.5,1877c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M927.5,1857c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,1892.1c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875.7,1899.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM871.5,1878.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M844.7,1690.5c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,1706.7c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M876.2,1713.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M841.7,1680.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875.5,1721c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,1730.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM880.4,1723.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M703.1,1867.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M685.3,1847.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M691,1865.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM861.2,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,1960.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.1,1966.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M697.8,1956.7c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM697.8,1950c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5ZM700.4,1954.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M693.8,1968.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M743,1848c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M731.6,1864.6c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M724.2,1857.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M732.4,1843c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM732.4,1836.3s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM729.9,1836.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M714.7,1849.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.2,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M848.3,1895.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M826.6,1877.8c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M854.7,1887.9c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M841.4,1872.4c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM841.4,1865.7c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M848.8,1883.1c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M892.3,1807.3c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M918.1,1821.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M901,1812.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M917.2,1799.2c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.8,1.4-2.3,2.2-3.8,2.2ZM917.2,1792.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.9,1811.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M811.1,1862.6c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM773.3,1829.3c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M784.5,1857.1c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M947.3,1858.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0s2.6,1.6,3.2,3.1l13.9,31.5h0s1.9,4.3,1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM948.6,1806.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M936.3,1833.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M703.6,1920.9c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1h0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM712.4,1877.5l-22.9,24.4c-.7.7-1.1,1.7-1,2.7,0,1,.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M717.7,1908c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M704.9,1836.2c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM674.3,1816.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M689.5,1827.8c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,1826.1c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M685,1893.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M974.3,1747.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M876.1,1945.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M809.2,1670.8c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M893.7,1852.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M669,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM861.2,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,2319.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,2319.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,2304.3l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,2313.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M985.1,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.5,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1002.4,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M988.2,2103.9c-1.6,0-3.1-.9-3.9-2.3-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM988.2,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8,0,0,0,0,0,0,.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M991.1,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.3-.5,0-1.1.4-1.3l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M972.4,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM954.4,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M982.5,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M950.8,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M982.2,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M997.4,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M987.1,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M976.8,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M966.5,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M956.2,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M945.9,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M935.6,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M925.3,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M915,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1008,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M975.5,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1009.4,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM975.7,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M995.9,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M749.9,2038.2c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M754.2,2055.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M758.5,2072.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M762.8,2089.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M690.3,2111.8c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M741.4,2004c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1,1.1.7,2.5.9,3.8.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M745.6,2021.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M672.3,2012.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M675.1,2024c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M677.9,2035.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M680.7,2046.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M683.5,2057.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M686.3,2068.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M689.1,2080c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M691.9,2091.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M694.7,2102.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M887.7,2124.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,2141.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M875,2157.5h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M868.7,2173.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M867.2,2177.9c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M900.4,2091.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M894,2108.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M887.3,2078.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M883.2,2088.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M879,2099.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M874.9,2110.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M870.7,2121.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M866.6,2131.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M862.4,2142.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M858.3,2153.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M854.1,2164.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M789.2,2288.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M773,2281.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M756.7,2275c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M740.4,2268.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,2304.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,2301.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8s-1.5-2.2-2.7-2.7-2.6-.5-3.8,0c-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M805.5,2295.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M825.4,2284.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M814.7,2280.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M804,2276.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M793.4,2271.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M782.7,2267.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M772.1,2262.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M761.4,2258.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,2254c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M740.1,2249.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M800.4,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782,2025c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773.4,2022c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M798.8,1980.9c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M786,2031.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M773.5,2046.6c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM764.9,2029.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M997.3,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M990.7,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M1006.2,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M953.9,1995.9c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M946.5,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM945.9,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M778.9,2131.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M740.3,2127.2c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M739.9,2118.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M787.5,2126.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M735.5,2133.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.6,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M727.4,2134c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.4-4.6,11.1-3.3,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.6.4-1,1-1l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM727.2,2112.3c-2,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M916.9,2077.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M952.5,2098.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M945.4,2103.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.2,2067.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M943.7,2111.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M956.7,2122.4c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8s.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM948.1,2114.7c.9,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M813.2,2053.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M839.7,2040.5c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M822.1,2049.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M817.8,2029.9c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM817.8,2023.1c-.3,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M818.8,2042.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M908.7,2042.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M937.4,2050.1c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c.1-.5.7-.9,1.2-.7l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M918.4,2045.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M929.7,2028.7c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2s-.8-2.2-.5-3.3c.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM929.7,2021.9c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M932.6,2041.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,2093.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,2103.9c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM657.7,2100.6c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4s.1-1.2-.2-1.8c-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M782.6,2101.5c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM792.8,2050.4c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,2074.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M889.8,2023.1c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5h0s2.8-3.7,2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM894.6,1979l-20.1,26.7c-1.3,1.7-.9,4,.7,5.3l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M903,2008.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM626,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M766.4,1991.1c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c2.9,1.2,4.3,4.6,3.1,7.6l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM734.1,1976l30.9,12.8c.9.4,2,.4,2.9,0s1.7-1.1,2-2l5.9-14.3c.8-1.9-.1-4.1-2-4.9l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M750.7,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M591,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669.6,2262c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-1.7-3.4-.4-7.7,3.1-9.4,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-2.5,1.2-3.4,4.3-2.2,6.7.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M597,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M923.3,2194.5c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.9,2212.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M883.5,2205.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M927.5,2185.4c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,2220.4c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875.7,2227.6c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM871.5,2206.8c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M844.7,2018.8c-.4,0-.8-.3-1-.7l-6.6-21.3c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,2035c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M876.2,2041.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M841.7,2009.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875.5,2049.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M888.5,2058.5c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.4.4-1,.4-1.4,0l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM880.4,2052c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M703.1,2195.7c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M685.3,2175.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M691,2193.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,2313.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,2310.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-1,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,2304s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,2298.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM861.2,2292c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,2303.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M671.3,2289c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,2307.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.1,2295.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M697.8,2285c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2h0c-.9,1.1-2.1,1.6-3.4,1.6ZM697.8,2278.3c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.4-.5.6-1.1.5-1.7s-.4-1.2-.9-1.6c-.4-.3-1-.5-1.5-.5ZM700.4,2282.8h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M693.8,2297.3c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M743,2176.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M731.6,2192.9c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M724.2,2186.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M732.4,2171.4c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM732.4,2164.6s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM729.9,2164.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M714.7,2177.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M848.3,2224c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M826.6,2206.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5s3.5,1,4.7.9c.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M854.7,2216.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M841.4,2200.8c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM841.4,2194.1c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3t0,0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M848.8,2211.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M892.3,2135.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M918.1,2150.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M901,2140.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M917.2,2127.5c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.8,1.4-2.3,2.2-3.8,2.2ZM917.2,2120.8c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M915.9,2140.2c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M811.1,2190.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM773.3,2157.7c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M784.5,2185.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M947.3,2187.2c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5h0s1.9,4.3,1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM948.6,2135.1c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M936.3,2162.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M703.6,2249.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l23.6-25.1h0s3.2-3.4,3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM712.4,2205.9l-22.9,24.4c-.7.7-1.1,1.7-1,2.7s.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M717.7,2236.4c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M704.9,2164.6c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.3c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM674.3,2144.9l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M689.5,2156.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M776.5,2154.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M685,2222.3c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M974.3,2075.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M876.1,2273.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M809.2,1999.2c-.5,0-.9-.3-1-.8l-2.6-13.5c0-.3,0-.5.2-.8.1-.2.4-.4.6-.4l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M893.7,2180.7c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M669,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M919.3,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M915.5,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0s.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M827.3,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M821.8,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0s-2.2,1.5-2.7,2.7c-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4,1.5-3.6,5.6-5.3,9.1-3.8,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M881.4,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M853.5,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M882.6,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M861.2,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2.1-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM861.2,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M875,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M694.4,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M472,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,349.3c-.8,0-1.5-.1-2.2-.4l-36.1-15c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM405.8,334.2l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,133.8c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM657.7,130.5c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.3-.8,2.9-.9,4.4-.5l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM626,68l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M591,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M669,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M597,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M421.6,68.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M425.8,85.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M430.1,102.3c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M434.4,119.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M361.9,141.7c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M413,33.9c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3s.2,1.1-.3,1.4c-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M417.3,51c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343.9,42.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M346.7,53.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,65.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M352.3,76.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M355.1,87.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M357.9,98.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M360.7,109.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M363.5,121.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M366.3,132.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M559.3,154.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553,171h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,187.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M540.3,203.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M538.8,207.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M572,121.6h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M565.7,138.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M559,108c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.8,118.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M550.7,129.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.5,140.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M542.4,151c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M538.2,161.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M534.1,172.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M529.9,183.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.8,194.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M460.9,318.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M444.6,311.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M428.3,304.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M412,298.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M499,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M477.2,325.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M497,314.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M486.4,310.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M475.7,306c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M465,301.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M454.4,297.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M443.7,292.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M433,288.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.4,283.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.7,279.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M472,20.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M453.7,54.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,51.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M470.4,10.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M457.7,61.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,76.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM436.5,59.6c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M669,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M625.6,25.8c-.6,0-1-.4-1-1V3.6c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M450.5,161.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M411.9,157.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M411.5,148c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M459.1,156.3c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M407.2,163.3c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M399.1,163.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM398.8,142.2c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M588.6,107.3c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M624.2,128.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M617,133.7c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M586.8,97.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M615.4,141.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M628.3,152.3c-.5,0-1,0-1.5-.1-6.3-.9-9.2-7.1-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM619.8,144.6c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M484.8,83.4c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M511.3,70.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M493.8,79c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M489.5,59.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM489.4,53c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.3-1.3-2.1-1.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M490.4,72.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M580.3,72.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M609,80c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M590,75.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M601.3,58.6c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM601.3,51.8c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8s.8.9,1.5,1.1c.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M604.2,71c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M343,123.4c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM329.4,130.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M454.3,131.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM464.5,80.3c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,104.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M561.5,53c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM566.2,8.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8s.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M574.7,38.6c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M315.7,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM297.7,68l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,21c-.8,0-1.5-.1-2.2-.4l-36.1-15c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3L399.1.2c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM405.8,5.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,14.8c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M341.2,291.9c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M594.9,224.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M560.6,242.5c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M555.1,235.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M599.2,215.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,250.3c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M547.4,257.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM543.2,236.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M516.3,48.7c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,64.9c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M547.9,71.4c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M513.4,39.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M547.2,79.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,88.5c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.2,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM552.1,81.9c1,1.4,3.8,4.5,8,4.6,5.1,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M374.7,225.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M357,205.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M362.6,223.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M343,318.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M350.8,325.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M369.4,314.9c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM369.4,308.2c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM372.1,312.7h0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M365.5,327.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.6c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M414.6,206.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M403.3,222.9c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M395.8,216.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M404.1,201.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM404.1,194.5s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM401.6,194.7h0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M386.4,207.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M520,253.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M498.2,236c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M526.3,246.1c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M513,230.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c1.9,1.5,2.1,4.3.6,6.1-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM513,224c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M520.5,241.3c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M564,165.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M589.7,180c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M572.7,170.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M588.9,157.4c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM588.9,150.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.6-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.6.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.5,170.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M482.8,220.8c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM445,187.6c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M456.1,215.3c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M619,217.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM620.3,165c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M608,192.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M375.2,279.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM384,235.8l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M389.4,266.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M376.6,194.5c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM346,174.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M361.2,186c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,184.3c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M356.6,252.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M645.9,105.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M547.7,303.8c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M480.9,29.1c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M565.4,210.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M334,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M334,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M591,3.4c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6L588.1,3.9c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M499,5.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5s.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,3.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6L259.7,3.9c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,15.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,12.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,5.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M532.9.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8-6.4c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5s.8-1,.9-1.6c0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,4.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M472,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM405.8,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,462.1c-1.6,0-3.1-.9-3.9-2.3,0,0,0,0,0,0-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM657.7,458.8c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.3-.8,2.9-.9,4.4-.5l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM626,396.3l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3s-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M591,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M597,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M421.6,396.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M425.8,413.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M430.1,430.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3.1,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M434.4,447.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M361.9,470.1c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M413,362.3c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3s.2,1.1-.3,1.4c-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M417.3,379.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M343.9,371.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M346.7,382.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,393.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M352.3,404.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M355.1,415.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M357.9,427.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M360.7,438.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M363.5,449.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M366.3,460.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M559.3,482.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M553,499.3h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,515.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M540.3,532.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M538.8,536.1c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M572,450h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M565.7,466.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M559,436.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M554.8,447.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M550.7,457.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M546.5,468.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M542.4,479.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M538.2,490.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M534.1,500.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M529.9,511.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M525.8,522.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M460.9,646.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M444.6,640c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M428.3,633.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M412,626.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M499,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8s3.6-.7,5.4,0c3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M477.2,653.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M497,643.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M486.4,638.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M475.7,634.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M465,629.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M454.4,625.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M443.7,621.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M433,616.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M422.4,612.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M411.7,607.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M472,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M453.7,383.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,380.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M470.4,339.1c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M457.7,390c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,404.8c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM436.5,388c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M669,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M625.6,354.1c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M450.5,489.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.9,485.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.5,476.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M459.1,484.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M407.2,491.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M399.1,492.3c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM398.8,470.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M588.6,435.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1s.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M624.2,456.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M617,462.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M586.8,425.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M615.4,469.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M628.3,480.6c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM619.8,473c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M484.8,411.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M511.3,398.7c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.8,407.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M489.5,388.1c-1.6,0-3.2-.9-3.9-2.4-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM489.4,381.4c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4s-.2,1.2.1,1.8c.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M490.4,400.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M580.3,401.2c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M609,408.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M590,403.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M601.3,386.9c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3s-1,2.1-2,2.7c-.7.4-1.5.6-2.2.6ZM601.3,380.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8s.8.9,1.5,1.1c.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M604.2,399.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343,451.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM331.5,455.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M454.3,459.7c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5-.7,1.1-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM464.5,408.7c-.3,0-.6,0-.9,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,433.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M561.5,381.3c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM566.2,337.2l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M574.7,367c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M315.8,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM297.7,396.3l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,349.3c-.8,0-1.5-.1-2.2-.4l-36.1-15c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM405.8,334.2l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M341.2,620.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M594.9,552.7c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.6,570.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M555.1,563.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M599.2,543.6c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,578.7c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.4,585.8c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM543.2,565c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M516.3,377.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,393.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.9,399.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M513.4,367.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.2,407.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,416.8c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.2,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM552.1,410.2c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M374.7,553.9c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M357,534c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M362.6,551.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,650.3c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343,647.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M350.8,653.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M369.4,643.3c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM369.4,636.6c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM372.1,641h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M365.5,655.5c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M414.6,534.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M403.3,551.2c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M395.8,544.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M404.1,529.6c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM404.1,522.9s0,0-.1,0c-.6,0-1.2.3-1.6.8-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M386.4,536c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M520,582.2c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M498.2,564.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M526.3,574.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M513,559c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1,.9.7,1.5,1.8,1.6,2.9s-.2,2.3-1,3.2-1.8,1.5-2.9,1.6c-.1,0-.3,0-.4,0ZM513,552.3c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6h0c-.4-.4-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M520.5,569.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M564,493.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M589.7,508.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M572.7,498.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M588.9,485.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9h0c-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM588.9,479c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.6-.4,1.2-.2,1.8s.6,1.1,1.1,1.4c.6.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3ZM591.8,483.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M587.5,498.4c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M482.8,549.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9h0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM445,515.9c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4.1,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M456.1,543.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M619,545.4c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM620.3,493.3c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M608,520.5c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M375.2,607.5c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM384,564.1l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M389.4,594.6c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M376.6,522.8c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM346,503.1l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M361.2,514.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,512.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M356.6,580.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M645.9,434.2h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M547.7,632.2c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M480.9,357.4c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M565.4,538.9c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M591,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M499,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,328.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M472,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,1006c-.8,0-1.5-.1-2.2-.4l-36.1-15c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM405.8,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,790.5c-1.6,0-3.1-.9-3.9-2.3t0,0c-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM657.7,787.2c.6,1.2,2,1.6,3.2,1,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM626,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M591,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M597,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.4.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M421.6,724.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M425.8,741.9c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3.1,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M430.1,759c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M434.4,776.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M361.9,798.4c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M413,690.6c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3s-.9,2.5-.6,3.8c.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3s.2,1.1-.3,1.4c-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M417.3,707.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M343.9,699.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M346.7,710.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,721.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M352.3,733c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M355.1,744.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M357.9,755.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M360.7,766.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M363.5,777.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M366.3,789c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M559.3,811.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M553,827.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,844.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M540.3,860.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M538.8,864.5c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M572,778.3h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M565.7,794.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M559,764.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M554.8,775.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M550.7,786.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M546.5,797c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M542.4,807.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M538.2,818.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M534.1,829.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M529.9,840c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M525.8,850.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M460.9,975c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M444.6,968.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M428.3,961.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M412,954.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M499,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M477.2,981.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M497,971.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M486.4,967.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M475.7,962.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M465,958.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M454.4,953.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M443.7,949.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M433,945c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M422.4,940.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M411.7,936.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M472,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M453.7,711.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,708.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M470.4,667.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M457.7,718.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,733.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM436.5,716.3c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M669,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M625.6,682.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M450.5,818.1c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.9,813.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.5,804.7c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M459.1,813c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M407.2,820c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M399.1,820.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8,0,.3-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM398.8,798.9c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M588.6,764c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1s.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M624.2,784.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M617,790.4c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M586.8,754.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M615.4,798.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M628.3,809c-.5,0-1,0-1.5-.1-6.3-.9-9.2-7.1-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM619.8,801.3c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M484.8,740.1c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M511.3,727.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.8,735.7c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M489.5,716.5c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM489.4,709.7c-.4,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M490.4,728.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M580.3,729.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M609,736.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M590,732c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M601.3,715.3c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM601.3,708.5c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8s.8.9,1.5,1.1c.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M604.2,727.7c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343,780.1c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM331.5,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M454.3,788.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1s-.4-.4-.4-.6l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5-.7,1.1-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM464.5,737c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,761.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M561.5,709.7c-1.2,0-2.4-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-1.1,1.5-2.9,2.3-4.6,2.3ZM566.2,665.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8s.7,1.9,1.5,2.5l12.4,9.3c1.7,1.3,4,.9,5.3-.7l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M574.7,695.3c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M315.8,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM297.7,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2h0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM405.8,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M341.2,948.6c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M594.9,881.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.6,899.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M555.1,891.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M599.2,872c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,907c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.3,914.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM543.2,893.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M516.3,705.4c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,721.6c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.9,728.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M513.4,695.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.2,735.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,745.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1s.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM552.1,738.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M374.7,882.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M357,862.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M362.6,880.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.4.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5s.8-1,.9-1.6c0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343,975.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M350.8,981.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M369.4,971.6c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM369.4,964.9c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3s2.5.6,3.3-.4c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM372.1,969.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M365.5,983.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M414.6,863c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M403.3,879.5c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M395.8,872.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M404.1,858c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM402.3,852h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.5-.4-1.1-.6-1.7-.6-.6,0-1.2.3-1.6.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M386.4,864.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M520,910.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M498.2,892.7c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M526.3,902.8c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M513,887.4c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2,1,1.9,1.5,2.1,4.3.6,6.1-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM513,880.7c0,0-.2,0-.2,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.7-2.5-.3-3.3-.4-.3-.9-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M520.5,898c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M564,822.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M589.7,836.7c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M572.7,827.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M588.9,814.1c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM588.9,807.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.6-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.6.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M587.5,826.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M482.8,877.5c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM445,844.3c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M456.1,872c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M619,873.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5h0s1.9,4.3,1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM620.3,821.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M608,848.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M375.2,935.9c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM384,892.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M389.4,923c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M376.6,851.2c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM346,831.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M361.2,842.7c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,841c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M356.6,908.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M645.9,762.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M547.7,960.5c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M480.9,685.8c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M565.4,867.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M591,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M499,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8s3.6-.7,5.4,0c3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,657c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,650.3c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M472,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5s-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM405.8,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,1118.8c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM657.7,1115.5c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.3-.8,2.9-.9,4.4-.5l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM626,1053l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M591,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M597,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M421.6,1053.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M425.8,1070.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M430.1,1087.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M434.4,1104.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M361.9,1126.8c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M413,1019c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3s.2,1.1-.3,1.4c-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M417.3,1036.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M343.9,1027.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M346.7,1039c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,1050.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M352.3,1061.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M355.1,1072.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M357.9,1083.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M360.7,1095c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M363.5,1106.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M366.3,1117.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M559.3,1139.6h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M553,1156h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,1172.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M540.3,1188.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M538.8,1192.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M572,1106.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M565.7,1123.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M559,1093c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M554.8,1103.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M550.7,1114.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M546.5,1125.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M542.4,1136.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M538.2,1146.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M534.1,1157.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M529.9,1168.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M525.8,1179.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M460.9,1303.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M444.6,1296.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M428.3,1289.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M412,1283.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1s-2.6,0-3.8.8c-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M499,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M477.2,1310.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M497,1299.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M486.4,1295.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M475.7,1291c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M465,1286.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M454.4,1282.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M443.7,1277.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M433,1273.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M422.4,1269c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M411.7,1264.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M472,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M453.7,1040c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,1037c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M470.4,995.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M457.7,1046.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,1061.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2s-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM436.5,1044.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M669,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M625.6,1010.8c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M450.5,1146.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.9,1142.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.5,1133.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M459.1,1141.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M407.2,1148.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M399.1,1148.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM398.8,1127.3c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M588.6,1092.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M624.2,1113.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M617,1118.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M586.8,1082.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M615.4,1126.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M628.3,1137.3c-.5,0-1,0-1.5-.1-6.3-.9-9.2-7.1-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM619.8,1129.7c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M484.8,1068.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M511.3,1055.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.8,1064.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M489.5,1044.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM489.4,1038.1c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.3-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M490.4,1057.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M580.3,1057.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M609,1065c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M590,1060.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M601.3,1043.6c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM601.3,1036.9c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M604.2,1056.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343,1108.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM329.4,1115.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M454.3,1116.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM464.5,1065.4c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.7-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,1089.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M561.5,1038c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM566.2,993.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8s.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M574.7,1023.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M315.7,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM297.7,1053l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,1006c-.8,0-1.5-.1-2.2-.4l-36.1-15c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2.1s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM405.8,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M341.2,1277c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M594.9,1209.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.6,1227.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M555.1,1220.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M599.2,1200.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,1235.4c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.4,1242.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM543.2,1221.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M516.3,1033.8c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,1050c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.9,1056.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M513.4,1024.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.2,1064.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,1073.5c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.2,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM552.1,1066.9c1,1.4,3.8,4.5,8,4.6,5.1,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M374.7,1210.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M357,1190.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M362.6,1208.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4s-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,1325.7s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343,1303.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M350.8,1310.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M369.4,1300c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM369.4,1293.3c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM372.1,1297.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M365.5,1312.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M414.6,1191.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M403.3,1207.9c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M395.8,1201.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M404.1,1186.3c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM404.1,1179.6s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM401.6,1179.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M386.4,1192.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M520,1238.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M498.2,1221.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M526.3,1231.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M513,1215.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6h0c1.9,1.5,2.1,4.3.6,6.1-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM513,1209c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M520.5,1226.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M564,1150.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M589.7,1165.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.3-.5.9-.7,1.4-.4l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M572.7,1155.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M588.9,1142.5c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM588.9,1135.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.6-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.6.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M587.5,1155.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M482.8,1205.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM445,1172.6c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M456.1,1200.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M619,1202.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM620.3,1150c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M608,1177.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M375.2,1264.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM384,1220.8l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M389.4,1251.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M376.6,1179.5c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM346,1159.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M361.2,1171.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,1169.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M356.6,1237.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M645.9,1090.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M547.7,1288.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M480.9,1014.1c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M565.4,1195.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.2,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M591,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M499,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,985.3c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5s.8-1,.9-1.6c0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M472,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM405.8,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,1447.2c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM657.7,1443.9c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM626,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M591,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M597,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M421.6,1381.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M425.8,1398.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3.1,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M430.1,1415.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M434.4,1432.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M361.9,1455.1c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M413,1347.3c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M417.3,1364.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M343.9,1356.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M346.7,1367.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,1378.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M352.3,1389.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M355.1,1400.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M357.9,1412.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M360.7,1423.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M363.5,1434.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M366.3,1445.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M559.3,1467.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M553,1484.4h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,1500.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M540.3,1517.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M538.8,1521.2c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M572,1435h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M565.7,1451.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M559,1421.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M554.8,1432.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M550.7,1442.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M546.5,1453.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M542.4,1464.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M538.2,1475.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M534.1,1486c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M529.9,1496.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M525.8,1507.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M460.9,1631.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M444.6,1625c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M428.3,1618.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.5-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M412,1611.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M499,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M477.2,1638.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M497,1628.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M486.4,1623.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M475.7,1619.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M465,1615c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M454.4,1610.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M443.7,1606.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M433,1601.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M422.4,1597.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M411.7,1592.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M472,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M453.7,1368.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,1365.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M470.4,1324.2c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M457.7,1375c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,1389.9c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM436.5,1373c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M669,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M625.6,1339.2c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M450.5,1474.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.9,1470.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.5,1461.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M459.1,1469.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M407.2,1476.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M399.1,1477.3c-2.5,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM398.8,1455.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M588.6,1420.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1s.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M624.2,1441.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M617,1447.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M586.8,1410.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M615.4,1454.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M628.3,1465.7c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8s.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM619.8,1458c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M484.8,1396.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M511.3,1383.8c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.8,1392.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M489.5,1373.2c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM489.4,1366.4c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.3-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M490.4,1385.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M580.3,1386.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M609,1393.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M590,1388.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M601.3,1372c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM601.3,1365.2c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M604.2,1384.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343,1436.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,1447.2c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM331.5,1440.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M454.3,1444.8c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM464.5,1393.7c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,1418.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M561.5,1366.4c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5h0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM566.2,1322.3l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M574.7,1352c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M315.8,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM297.7,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM405.8,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M341.2,1605.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M594.9,1537.8c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.6,1555.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M555.1,1548.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M599.2,1528.7c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,1563.7c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.4,1570.9c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM543.2,1550.1c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M516.3,1362.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,1378.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.9,1384.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M513.4,1352.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.2,1392.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,1401.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.2,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM552.1,1395.3c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M374.7,1539c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M357,1519.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M362.6,1536.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5s.8-1,.9-1.6c.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343,1632.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M350.8,1638.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M369.4,1628.3c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM369.4,1621.6c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM372.1,1626.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M365.5,1640.6c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M414.6,1519.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M403.3,1536.2c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M395.8,1529.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M404.1,1514.7c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM402.3,1508.7h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.5-.4-1.1-.7-1.7-.6-.6,0-1.2.3-1.6.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M386.4,1521c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M520,1567.3c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M498.2,1549.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.3.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M526.3,1559.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M513,1544.1c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,1.9,1.5,2.1,4.3.6,6.1-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM513,1537.4c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.7-2.5-.3-3.3-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M520.5,1554.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M564,1478.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M589.7,1493.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M572.7,1483.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M588.9,1470.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM588.9,1464.1c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M587.5,1483.5c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M482.8,1534.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM445,1501c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M456.1,1528.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M619,1530.5c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM620.3,1478.4c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M608,1505.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M375.2,1592.5c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l26.8-28.5c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM384,1549.2l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M389.4,1579.7c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M376.6,1507.9c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM346,1488.2l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M361.2,1499.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,1497.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M356.6,1565.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7s0,.5-.3.7l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M645.9,1419.3h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M547.7,1617.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M480.9,1342.5c-.5,0-.9-.3-1-.8l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M565.4,1524c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M591,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M499,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,1325.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,1313.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M472,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM405.8,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,1775.5c-1.6,0-3.1-.9-3.9-2.3h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM657.7,1772.2c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.6-1.2-2-1.6-3.2-1-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM626,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M591,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,4-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M597,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M421.6,1709.9c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3.1,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M425.8,1727c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M430.1,1744.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M434.4,1761.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M361.9,1783.5c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M413,1675.7c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M417.3,1692.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M343.9,1684.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M346.7,1695.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,1706.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M352.3,1718.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M355.1,1729.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M357.9,1740.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M360.7,1751.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M363.5,1762.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M366.3,1774c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M559.3,1796.3h0c-2.3,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M553,1812.7h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,1829.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M540.3,1845.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M538.8,1849.5c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M572,1763.4h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M565.7,1779.8h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M559,1749.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M554.8,1760.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M550.7,1771.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M546.5,1782c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M542.4,1792.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M538.2,1803.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M534.1,1814.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M529.9,1825.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M525.8,1835.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M460.9,1960.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M444.6,1953.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M428.3,1946.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M412,1939.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M499,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M477.2,1966.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M497,1956.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M486.4,1952.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M475.7,1947.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M465,1943.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M454.4,1938.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M443.7,1934.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M433,1930.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M422.4,1925.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M411.7,1921.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M472,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M453.7,1696.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,1693.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M470.4,1652.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M457.7,1703.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,1718.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM436.5,1701.4c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M669,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M625.6,1667.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M450.5,1803.2c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.9,1798.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.5,1789.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M459.1,1798.1c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M407.2,1805.1c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M399.1,1805.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM398.8,1784c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M588.6,1749.1c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M624.2,1769.9c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M617,1775.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M586.8,1739.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M615.4,1783.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M628.3,1794c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8s.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM619.8,1786.4c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M484.8,1725.2c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M511.3,1712.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.8,1720.8c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M489.5,1701.5c-1.6,0-3.2-.9-3.9-2.4-.5-1-.6-2.2-.2-3.3s1.2-2,2.2-2.5c2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM489.4,1694.8c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M490.4,1714c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M580.3,1714.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M609,1721.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M590,1717c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M601.3,1700.3c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2s-.8-2.2-.5-3.3c.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM601.3,1693.6c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M604.2,1712.8c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343,1765.2c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,1775.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM331.5,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M454.3,1773.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM464.5,1722.1c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,1746.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M561.5,1694.7c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM566.2,1650.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M574.7,1680.4c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M315.7,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM297.7,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM405.8,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M341.2,1933.7c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3s-2.1,1.6-2.5,2.9c-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M594.9,1866.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.6,1884.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M555.1,1877c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M599.2,1857c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,1892.1c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.4,1899.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM543.2,1878.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M516.3,1690.5c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,1706.7c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.9,1713.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M513.4,1680.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.2,1721c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,1730.2c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM552.1,1723.6c1,1.4,3.8,4.5,8,4.6,5.1,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M374.7,1867.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M357,1847.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M362.6,1865.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5s.8-1,.9-1.6c.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343,1960.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M350.8,1966.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M369.4,1956.7c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9,1.9,1.5,2.2,4.3.7,6.1h0c-.9,1.1-2.1,1.6-3.4,1.6ZM369.4,1950c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.6,3.3-.4h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5ZM372.1,1954.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M365.5,1968.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M414.6,1848c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M403.3,1864.6c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M395.8,1857.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M404.1,1843c-1.1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.8,1.4-3,1.4,0,0-.2,0-.2,0ZM404.1,1836.3s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3.5.4,1.1.6,1.7.6.6,0,1.2-.3,1.6-.8.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM401.6,1836.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M386.4,1849.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M520,1895.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M498.2,1877.8c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M526.3,1887.9c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M513,1872.4c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,1.9,1.5,2.1,4.3.6,6.1-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM513,1865.7c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.7-2.5-.3-3.3-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M520.5,1883.1c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M564,1807.3c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M589.7,1821.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M572.7,1812.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M588.9,1799.2c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,2.1,1.2,2.8,3.8,1.7,5.9-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM588.9,1792.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2s1.1-.6,1.4-1.1c.6-1.1.2-2.6-.9-3.2-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M587.5,1811.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M482.8,1862.6c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM445,1829.3c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M456.1,1857.1c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M619,1858.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5h0s1.9,4.3,1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM620.3,1806.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M608,1833.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M375.2,1920.9c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l26.8-28.5c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM384,1877.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M389.4,1908c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M376.6,1836.2c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM346,1816.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M361.2,1827.8c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,1826.1c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6s-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M356.6,1893.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M645.9,1747.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M547.7,1945.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M480.9,1670.8c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M565.4,1852.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M591,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M499,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,1642c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5s.8-1,.9-1.6c.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M472,2319.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,2319.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM405.8,2304.3l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,2313.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M656.7,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.3.5,0,1.1-.4,1.3l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M674.1,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M659.8,2103.9c-1.6,0-3.1-.9-3.9-2.3h0c-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM657.7,2100.6c.6,1.2,2,1.6,3.2,1,.6-.3,1-.8,1.2-1.4s.1-1.2-.2-1.8c-.3-.6-.8-1-1.4-1.2-.6-.2-1.2-.1-1.8.2-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.8,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M644.1,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM626,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M654.2,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M622.4,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M606.7,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M591,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M653.8,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M669,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M658.7,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M648.4,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M638.1,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M627.9,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M617.6,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M607.3,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M597,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M586.7,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M679.7,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M647.1,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M681.1,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9s-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM647.4,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M667.6,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M421.6,2038.2c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M425.8,2055.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M430.1,2072.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M434.4,2089.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M361.9,2111.8c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M413,2004c-3.1,0-6-2.1-6.8-5.3-.9-3.7,1.3-7.6,5.1-8.5.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1s2.5.9,3.8.6c.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M417.3,2021.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M343.9,2012.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M346.7,2024c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,2035.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M352.3,2046.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M355.1,2057.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M357.9,2068.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M360.7,2080c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M363.5,2091.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M366.3,2102.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M559.3,2124.6h0c-2.3,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M553,2141.1h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,2157.5h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M540.3,2173.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M538.8,2177.9c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M572,2091.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M565.7,2108.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2s1.3-3.5,2.9-4.5c2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M559,2078.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M554.8,2088.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M550.7,2099.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M546.5,2110.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M542.4,2121.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M538.2,2131.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M534.1,2142.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M529.9,2153.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M525.8,2164.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M460.9,2288.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M444.6,2281.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,.1-2.2,1.2-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M428.3,2275c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M412,2268.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M499,2304.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,2301.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M477.2,2295.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M497,2284.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M486.4,2280.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M475.7,2276.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M465,2271.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M454.4,2267.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M443.7,2262.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M433,2258.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M422.4,2254c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M411.7,2249.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M472,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M453.7,2025c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,2022c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M470.4,1980.9c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M457.7,2031.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M445.1,2046.6c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM436.5,2029.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M669,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M662.4,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M677.8,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M625.6,1995.9c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M618.1,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM617.5,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M450.5,2131.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.9,2127.2c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M411.5,2118.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.6,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M459.1,2126.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M407.2,2133.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M399.1,2134c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.4-4.6,11.1-3.3,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM398.8,2112.3c-2,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M588.6,2077.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M624.2,2098.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M617,2103.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M586.8,2067.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M615.4,2111.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M628.3,2122.4c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8s.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM619.8,2114.7c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M484.8,2053.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M511.3,2040.5c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.8,2049.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M489.5,2029.9c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,2.2-1.1,4.8-.2,5.8,2h0c1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM489.4,2023.1c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4s-.2,1.2.1,1.8c.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M490.4,2042.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.5-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-11.5,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M580.3,2042.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.1-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M609,2050.1c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c.1-.5.7-.9,1.2-.7l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M590,2045.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M601.3,2028.7c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2s-.8-2.2-.5-3.3c.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,2.3.6,3.8,3,3.2,5.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM601.3,2021.9c-.4,0-.8.1-1.2.3-.5.3-.9.8-1.1,1.5-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM604.6,2025.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M604.2,2041.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c-.1.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343,2093.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,2103.9c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM331.5,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M454.3,2101.5c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.3-1.5,0-3.1.7-4.4.8-1.3,2.1-2.2,3.6-2.6l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM464.5,2050.4c-.3,0-.6,0-.8,0l-15.1,3.5c-1,.2-1.8.8-2.4,1.7-.5.9-.7,1.9-.5,2.9l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,2074.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M561.5,2023.1c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5h0s2.8-3.7,2.8-3.7c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM566.2,1979l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M574.7,2008.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M315.7,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM297.7,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M438.1,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5-.5-1.2-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM405.8,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M422.3,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M341.2,2262c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M594.9,2194.5c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4s.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.6,2212.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M555.1,2205.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M599.2,2185.4c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,2220.4c-.3,0-.6-.1-.8-.4l-12.6-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.6,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.4,2227.6c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM543.2,2206.8c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M516.3,2018.8c-.4,0-.8-.3-1-.7l-6.6-21.3c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,2035c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.9,2041.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M513.4,2009.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M547.2,2049.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M560.2,2058.5c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3-.2,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.5-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM552.1,2052c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M374.7,2195.7c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M357,2175.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M362.6,2193.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,2313.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,2310.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-1,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,2304s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,2298.7c-.2,0-.3,0-.5,0-2.4-.3-4.1-2.4-3.8-4.8.3-2.4,2.4-4.1,4.8-3.8,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,2292c-1.2,0-2.2.9-2.3,2.1-.1,1.3.8,2.5,2.1,2.6.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,2303.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M343,2289c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,2307.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M350.8,2295.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M369.4,2285c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1.7-.9,1.8-1.5,2.9-1.6,1.2-.1,2.3.2,3.2.9.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2h0c-.9,1.1-2.1,1.6-3.4,1.6ZM369.4,2278.3c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3s2.5.6,3.3-.4h0c.4-.5.6-1.1.5-1.7s-.4-1.2-.9-1.6c-.4-.3-.9-.5-1.5-.5ZM372.1,2282.8h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M365.5,2297.3c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M414.6,2176.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M403.3,2192.9c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M395.8,2186.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M404.1,2171.4c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM404.1,2164.6s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM401.6,2164.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M386.4,2177.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M520,2224c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M498.2,2206.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.4-.4-.5-1-.1-1.4l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.5.1-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M526.3,2216.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M513,2200.8c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9s.2-2.3,1-3.2c1.5-1.9,4.3-2.1,6.1-.6,1.9,1.5,2.1,4.3.6,6.1-.7.9-1.8,1.5-2.9,1.6-.1,0-.3,0-.4,0ZM513,2194.1c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9.8-1,.7-2.5-.3-3.3-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M520.5,2211.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M564,2135.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M589.7,2150.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5s.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M572.7,2140.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M588.9,2127.5c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM588.9,2120.8c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2s1.1-.6,1.4-1.1c.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M587.5,2140.2c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.2.3-.5.5-.9.5Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M482.8,2190.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM445,2157.7c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M456.1,2185.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M619,2187.2c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c.7-.3,1.2-.8,1.4-1.5.3-.7.2-1.4,0-2l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4.6-1.4,1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5h0s1.9,4.3,1.9,4.3c.5,1.1.5,2.4,0,3.6-.5,1.2-1.3,2.1-2.5,2.6l-19.6,8.6c-.1,0-.3,0-.4,0ZM620.3,2135.1c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M608,2162.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M375.2,2249.2c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l26.8-28.5c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM384,2205.9l-22.9,24.4c-.7.7-1.1,1.7-1,2.7s.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M389.4,2236.4c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M376.6,2164.6c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM346,2144.9l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M361.2,2156.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M448.2,2154.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M356.6,2222.3c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M645.9,2075.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M547.7,2273.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M480.9,1999.2c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M565.4,2180.7c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.5.2.7.8.5,1.3l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M591,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M587.2,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-95.9,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M499,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M493.5,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M553.1,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M525.1,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M554.2,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M532.9,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM532.8,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5s.8-1,.9-1.6c.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M546.7,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M366.1,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.4,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2s.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,349.3c-.8,0-1.5-.1-2.2-.4l-36.1-15c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1s.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM77.4,334.2l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M94,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,144.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM329.4,130.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M315.7,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM297.7,68l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M93.2,68.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M97.5,85.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M101.8,102.3c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M106.1,119.4c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M33.6,141.7c-.4,0-.9-.3-1-.8L6,34.7c-.1-.5.2-1.1.7-1.2L83.6,14.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2L8.2,35.2l26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M84.7,33.9c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M88.9,51c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M15.6,42.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M18.4,53.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M21.2,65.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M24,76.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M26.8,87.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M29.6,98.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M32.4,109.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M35.2,121.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M38,132.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M231,154.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,171h0c-2.2,0-4.3-1.1-5.6-2.9-2.2-3.2-1.5-7.5,1.7-9.8,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,187.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M212,203.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M210.5,207.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M243.7,121.6h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M237.3,138.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M230.6,108c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M226.5,118.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M222.3,129.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.2,140.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M214,151c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M209.9,161.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M205.7,172.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M201.6,183.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M197.4,194.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M132.5,318.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M116.3,311.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M100,304.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.7,298.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M148.8,325.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M168.7,314.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M158,310.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M147.3,306c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M136.7,301.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M126,297.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M115.4,292.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M104.7,288.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M94,283.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.4,279.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,20.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M125.3,54.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M116.7,51.9c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M142.1,10.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M129.3,61.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M116.8,76.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0s-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM108.2,59.6c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M297.2,25.8c-.6,0-1-.4-1-1V3.6c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M122.2,161.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M83.6,157.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M83.2,148c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M130.8,156.3c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M78.8,163.3c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M70.7,163.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM70.5,142.2c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M260.2,107.3c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M295.8,128.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M288.7,133.7c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.5,97.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M287,141.4c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M300,152.3c-.5,0-1,0-1.5-.1-6.3-.9-9.2-7.1-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM291.4,144.6c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M156.5,83.4c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M183,70.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M165.4,79c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M161.1,59.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2s.6,2.2.2,3.3-1.2,2-2.2,2.5c-.6.3-1.3.4-1.9.4ZM161.1,53c-.4,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M162.1,72.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M252,72.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M280.7,80c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M261.7,75.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M273,58.6c-.4,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM273,51.8c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M275.9,71c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,123.4c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M0,153.5c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6L.5,153.4c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M17.4,144.5c-.4,0-.7-.2-.9-.5L-2.6,107.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3.1,133.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9s4.8-.3,5.9,1.8c.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1,130.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.6-1.2-2-1.6-3.2-1-1.2.6-1.6,2-1,3.2h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M6.1,150.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M125.9,131.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM136.1,80.3c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,104.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M233.1,53c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l23.6-31.2c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM237.9,8.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M246.3,38.6c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-12.6,83.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM-30.7,68l22.2,6.3L.7,42.1c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-2.5,57.9c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,21c-.8,0-1.5-.1-2.2-.4l-36.1-15c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3L70.7.2c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM77.4,5.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M94,14.8c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-18.6,307.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-34.3,315.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-50,323.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-65.7,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3L-8,211.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4L-8,213.8l-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M12.9,291.9c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-2.9,299.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,273.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2,278.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-8.3,283.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-18.5,288.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-28.8,293.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-39.1,299.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-49.4,304.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-59.7,309.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-70,314.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M266.6,224.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M232.2,242.5c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M226.8,235.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M270.8,215.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,250.3c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M219,257.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM214.8,236.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M188,48.7c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,64.9c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M219.5,71.4c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M185,39.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M218.8,79.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,88.5c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM223.7,81.9c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M46.4,225.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M23,212.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M28.6,205.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M34.3,223.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-9.6,194.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M24.4,221.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM-9.3,170.2c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,328.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2.7-2.2,1.6-2.9c.9-.7,2-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM204.5,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,318.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M22.4,325.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M41.1,314.9c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM41.1,308.2c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-1-.5-1.5-.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M37.1,327.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.6c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M86.3,206.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M74.9,222.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M67.5,216.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M75.7,201.3c-1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3,0-1.2.3-2.3,1.1-3.1,1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM75.7,194.5c-.6,0-1.3.3-1.8.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-.5-.4-1-.6-1.6-.6ZM73.2,194.7h0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M58,207.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M191.6,253.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M169.9,236c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M198,246.1c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M184.7,230.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,0,0,0,0,0,0,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM184.7,224c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M192.1,241.3c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M235.6,165.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M261.4,180c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4s-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.2.1.4.3.5.6s0,.5,0,.8l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M244.3,170.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M260.5,157.4c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM260.5,150.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.3-.6.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M259.2,170.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M154.4,220.8c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM116.6,187.6c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M127.8,215.3c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M290.6,217.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5h0s1.9,4.3,1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM291.9,165c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M279.6,192.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M46.9,279.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.7-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM55.7,235.8l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M61,266.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M48.2,194.5c-1,0-2.1-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM17.6,174.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M32.8,186c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,184.3c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M28.3,252.2c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M317.6,105.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M219.4,303.8c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M152.5,29.1c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M237.1,210.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M10.9,323.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3H4c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2H-15.9v7.4H9.9v-11.7H-8.7c-.6,0-1-.4-1-1s.4-1,1-1H10.9c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,25.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7L11.8,4.5c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,19.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,10.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M21.1,21c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M-38.6,26c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8V3.6c0-.6.4-1,1-1H12.3c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3H-25.1v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM-39.2,4.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9V4.5c-.5,0-1.2-.2-2-.2Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,3.4c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6L259.7,3.9c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,5.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3L171.6,5.1c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,3.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,5.3c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6L-8-116.8c.5-.3,1.1,0,1.3.4L29.1-45.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4L-8-114.6l-96,48.6L-68.6,3.9c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,15.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,12.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,5.6s0,0-.1,0L184.7.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M204.5.3c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2.7-2.2,1.6-2.9c.9-.7,2-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM204.5-6.4c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,4.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1s.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM77.4,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M94,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM331.5,455.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M315.8,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM297.7,396.3l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M93.2,396.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M97.5,413.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M101.8,430.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3.1,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M106.1,447.8c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M33.6,470.1c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M84.7,362.3c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1s-.5,1-1.1.9c-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M88.9,379.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M15.6,371.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M18.4,382.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M21.2,393.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M24,404.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M26.8,415.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M29.6,427.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M32.4,438.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M35.2,449.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M38,460.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M231,482.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,499.3h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,515.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M212,532.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M210.5,536.1c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M243.7,450h0c-.8,0-1.6-.2-2.4-.5-3.6-1.4-5.4-5.4-4-9,.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-1,2.6.3,5.5,2.9,6.5.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M237.3,466.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M230.6,436.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M226.5,447.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M222.3,457.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.2,468.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M214,479.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M209.9,490.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M205.7,500.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M201.6,511.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M197.4,522.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M132.5,646.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M116.3,640c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M100,633.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.7,626.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M148.8,653.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M168.7,643.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M158,638.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M147.3,634.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M136.7,629.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M126,625.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M115.4,621.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M104.7,616.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M94,612.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.4,607.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,349c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2s.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M125.3,383.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M116.7,380.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M142.1,339.1c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M129.3,390c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M116.8,404.8c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM108.2,388c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M297.2,354.1c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M122.2,489.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M83.6,485.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M83.2,476.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M130.8,484.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M78.8,491.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M70.7,492.3c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM70.5,470.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M260.2,435.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M295.8,456.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M288.7,462.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.5,425.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M287,469.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M300,480.6c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM291.4,473c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M156.5,411.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M183,398.7c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M165.4,407.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M161.1,388.1c-1.6,0-3.2-.9-3.9-2.4-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2,1.1,2.2.2,4.8-2,5.8-.6.3-1.3.4-1.9.4ZM161.1,381.4c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4s-.2,1.2.1,1.8c.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.6-2,1.1-3.2h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M162.1,400.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M252,401.2c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M280.7,408.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M261.7,403.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M273,386.9c-.4,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM273,380.2c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8s-.8-.9-1.5-1.1c-.2,0-.4,0-.6,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M275.9,399.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,451.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M0,481.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6L.5,481.8c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M17.4,472.8c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3.1,462.1c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM3.1,455.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2s1-.8,1.2-1.4c.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M6.1,478.7c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M125.9,459.7c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5-.7,1.1-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM136.1,408.7c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,433.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M233.1,381.3c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM237.9,337.2l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M246.3,367c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-12.6,412c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5,1.3-.8,2.9-.9,4.4-.5l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM-30.7,396.3l22.2,6.3L.7,370.5c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-2.5,386.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,349.3c-.8,0-1.5-.1-2.2-.4l-36.1-15c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1s.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM77.4,334.2l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M94,343.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-18.6,636.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-34.3,644.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1s-1.7,2-1.9,3.3c-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-50,652.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-65.7,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M12.9,620.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-2.9,628.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,601.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2,606.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-8.3,611.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-18.5,617.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-28.8,622.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-39.1,627.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-49.4,632.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-59.7,637.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-70,643.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M266.6,552.7c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M232.2,570.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M226.8,563.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M270.8,543.6c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,578.7c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M219,585.8c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,5.9c-.7.7-4.3,4.4-9.5,4.4ZM214.8,565c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M188,377.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1s.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,393.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M219.5,399.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M185,367.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M218.8,407.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,416.8c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM223.7,410.2c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M46.4,553.9c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M23,541.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M28.6,534c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M34.3,551.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-9.6,523c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M24.4,550c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM-9.3,498.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,657c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.3-2.4,2.4-4.1,4.8-3.8,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM204.5,650.3c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,647.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M22.4,653.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M41.1,643.3c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2h0c-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM41.1,636.6c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-.9-.5-1.5-.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M37.1,655.5c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M86.3,534.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M74.9,551.2c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M67.5,544.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M75.7,529.6c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2,1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM75.7,522.9c-.6,0-1.3.3-1.8.8-.9,1-.8,2.5.2,3.3s2.5.8,3.3-.2c.9-1,.8-2.5-.2-3.3-.5-.4-1-.6-1.6-.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M58,536c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M191.6,582.2c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4s1-.5,1.4,0c2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M169.9,564.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.2-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M198,574.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M184.7,559c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2,1.5-1.9,4.3-2.1,6.1-.6h0c.9.7,1.5,1.8,1.6,2.9s-.2,2.3-1,3.2c-.9,1-2.1,1.6-3.4,1.6ZM184.7,552.3c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.7,3.3-.3.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.4-1-.5-1.5-.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M192.1,569.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M235.6,493.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M261.4,508.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4s-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M244.3,498.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M260.5,485.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6s-.2-2.3.4-3.3c.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM260.5,479c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.3-.6.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M259.2,498.4c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M154.4,549.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9h0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM116.6,515.9c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4.1,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M127.8,543.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M290.6,545.4c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5h0s1.9,4.3,1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM291.9,493.3c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M279.6,520.5c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M46.9,607.5c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM55.7,564.1l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M61,594.6c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M48.2,522.8c-1,0-2-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM17.6,503.1l28.7,17.2c1.8,1.1,4.1.5,5.2-1.3l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M32.8,514.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,512.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M28.3,580.5c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M317.6,434.2h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M219.4,632.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M152.5,357.4c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M237.1,538.9c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M10.9,651.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3H4c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2H-15.9v7.4H9.9v-11.7H-8.7c-.6,0-1-.4-1-1s.4-1,1-1H10.9c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,354.1c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,348.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,339h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M21.1,349.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M-38.6,354.3c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1H12.3c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3H-25.1v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM-39.2,332.6c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,331.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,334.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,331.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,333.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.2-.5,0-1.1.4-1.3L-8,211.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4L-8,213.8l-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,343.8s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,340.6s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,333.9s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,328.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2.7-2.2,1.6-2.9c.9-.7,2-1.1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM204.5,321.9c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,333s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,337.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,1006c-.8,0-1.5-.1-2.2-.4l-36.1-15c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM77.4,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M94,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM331.5,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M315.8,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM297.7,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M93.2,724.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M97.5,741.9c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3.1,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M101.8,759c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M106.1,776.1c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M33.6,798.4c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M84.7,690.6c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3-.7,1.1-.9,2.5-.6,3.8.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M88.9,707.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M15.6,699.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M18.4,710.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M21.2,721.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M24,733c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M26.8,744.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M29.6,755.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M32.4,766.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M35.2,777.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M38,789c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M231,811.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,827.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,844.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M212,860.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M210.5,864.5c-.1,0-.2,0-.4,0l-74-28.5c-.5-.2-.8-.8-.6-1.3l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M243.7,778.3h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M237.3,794.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M230.6,764.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M226.5,775.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M222.3,786.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.2,797c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M214,807.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M209.9,818.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M205.7,829.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M201.6,840c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M197.4,850.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M132.5,975c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M116.3,968.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M100,961.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.7,954.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M148.8,981.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.6-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M168.7,971.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M158,967.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M147.3,962.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M136.7,958.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M126,953.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M115.4,949.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M104.7,945c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.7,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M94,940.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.4,936.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,677.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M125.3,711.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M116.7,708.6c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M142.1,667.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M129.3,718.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M116.8,733.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0s-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM108.2,716.3c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M297.2,682.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M122.2,818.1c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M83.6,813.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M83.2,804.7c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M130.8,813c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M78.8,820c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M70.7,820.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8,0,.3-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM70.5,798.9c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M260.2,764c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M295.8,784.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M288.7,790.4c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.5,754.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M287,798.1c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M300,809c-.5,0-1,0-1.5-.1-6.3-.9-9.2-7.1-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8s-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM291.4,801.3c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M156.5,740.1c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M183,727.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M165.4,735.7c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M161.1,716.5c-1.6,0-3.2-.9-3.9-2.4-.5-1-.6-2.2-.2-3.3s1.2-2,2.2-2.5c1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2h0c.5,1,.6,2.2.2,3.3-.4,1.1-1.2,2-2.2,2.5-.6.3-1.3.4-1.9.4ZM161.1,709.7c-.4,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.7,3.2,1.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M162.1,728.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M252,729.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M280.7,736.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M261.7,732c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M273,715.3c-.4,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,3-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM273,708.5c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M275.9,727.7c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,780.1c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M0,810.2c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M17.4,801.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3.1,790.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM3.1,783.7c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2,1.2-.6,1.6-2,1-3.2-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M6.1,807.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M125.9,788.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.3,0-.5,0-.8-.1s-.4-.4-.4-.6l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5-.7,1.1-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM136.1,737c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,761.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M233.1,709.7c-1.2,0-2.4-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l20.7-27.5s0,0,0,0l2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-1.1,1.5-2.9,2.3-4.6,2.3ZM237.9,665.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c1.7,1.3,4,.9,5.3-.7l20.1-26.7-18.4-13.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M246.3,695.3c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-12.6,740.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM-30.7,724.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-2.5,714.6c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,677.7c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM77.4,662.6l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M94,671.5c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-18.6,964.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-34.3,972.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-50,980.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-65.7,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M12.9,948.6c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3s1.8-3.2,3.5-4.1c3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-2.9,956.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,929.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2,935c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-8.3,940.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-18.5,945.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-28.8,950.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-39.1,955.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-49.4,961c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-59.7,966.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-70,971.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M266.6,881.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M232.2,899.2c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M226.8,891.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M270.8,872c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,907c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4s1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M219,914.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM214.8,893.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M188,705.4c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,721.6c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M219.5,728.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M185,695.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M218.8,735.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,745.2c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM223.7,738.6c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M46.4,882.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M23,869.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M28.6,862.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M34.3,880.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-9.6,851.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M24.4,878.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM-9.3,826.9c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,985.3c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2.7-2.2,1.6-2.9c.9-.7,2-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM204.5,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,975.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M22.4,981.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M41.1,971.6c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM41.1,964.9c0,0-.2,0-.3,0-.6,0-1.2.4-1.6.9-.8,1-.6,2.5.4,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.8-1,.6-2.5-.4-3.3-.4-.3-.9-.5-1.5-.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M37.1,983.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M86.3,863c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M74.9,879.5c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M67.5,872.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M75.7,858c-1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3,0-1.2.3-2.3,1.1-3.1h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM74,852h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M58,864.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M191.6,910.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M169.9,892.7c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M198,902.8c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M184.7,887.4c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3,1-3.2,1.5-1.9,4.3-2.1,6.1-.6h0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM184.7,880.7c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3-.4-.4-1-.5-1.5-.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M192.1,898c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M235.6,822.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M261.4,836.7c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.2.1.4.3.5.6s0,.5,0,.8l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M244.3,827.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M260.5,814.1c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM260.5,807.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.3-.6.4-1.2.2-1.8s-.6-1.1-1.1-1.4c-.4-.2-.8-.3-1.2-.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M259.2,826.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M154.4,877.5c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-1.5-.3-2.8-1.2-3.7-2.5-.9-1.3-1.1-2.8-.8-4.3l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM116.6,844.3c-.7,0-1.4.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M127.8,872c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M290.6,873.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM291.9,821.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M279.6,848.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M46.9,935.9c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM55.7,892.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1.1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M61,923c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M48.2,851.2c-1,0-2-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6.4,1.5.1,3.1-.6,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM17.6,831.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M32.8,842.7c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,841c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M28.3,908.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M317.6,762.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M219.4,960.5c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M152.5,685.8c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M237.1,867.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M10.9,980h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3H4c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2H-15.9v7.4H9.9v-11.7H-8.7c-.6,0-1-.4-1-1s.4-1,1-1H10.9c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,682.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,676.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,667.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M21.1,677.7c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M-38.6,682.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1H12.3c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3H-25.1v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM-39.2,661c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,660.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,662.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,660.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7-1.2-.5-2.6-.5-3.8,0-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,662c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,672.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,669s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,662.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,657c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.3-2.4,2.4-4.1,4.8-3.8,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM204.5,650.3c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,661.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,665.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7s0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-1.2-.5-2.1-1.4-2.5-2.5s-.5-2.4,0-3.6l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.3.7-.3,1.4,0,2s.8,1.2,1.4,1.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM77.4,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M94,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM329.4,1115.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M315.7,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM297.7,1053l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4s-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M93.2,1053.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M97.5,1070.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M101.8,1087.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M106.1,1104.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M33.6,1126.8c-.4,0-.9-.3-1-.8l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M84.7,1019c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3s-.9,2.5-.6,3.8c.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M88.9,1036.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M15.6,1027.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M18.4,1039c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M21.2,1050.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M24,1061.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M26.8,1072.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M29.6,1083.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M32.4,1095c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M35.2,1106.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M38,1117.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M231,1139.6h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,1156h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,1172.4h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M212,1188.9h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M210.5,1192.8c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M243.7,1106.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M237.3,1123.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2-.2,1.3,0,2.6.8,3.7.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M230.6,1093c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M226.5,1103.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M222.3,1114.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.2,1125.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M214,1136.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M209.9,1146.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M205.7,1157.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M201.6,1168.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M197.4,1179.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M132.5,1303.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M116.3,1296.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M100,1289.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.7,1283.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M148.8,1310.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M168.7,1299.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M158,1295.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M147.3,1291c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M136.7,1286.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M126,1282.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M115.4,1277.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M104.7,1273.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M94,1269c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.4,1264.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,1005.7c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M125.3,1040c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M116.7,1037c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M142.1,995.8c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M129.3,1046.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M116.8,1061.5c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM108.2,1044.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M334,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M297.2,1010.8c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M122.2,1146.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M83.6,1142.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M83.2,1133.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1,0,.6-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M130.8,1141.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M78.8,1148.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M70.7,1148.9c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8,0-.3.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM70.5,1127.3c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M260.2,1092.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M295.8,1113.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M288.7,1118.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.5,1082.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M287,1126.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M300,1137.3c-.5,0-1,0-1.5-.1-6.3-.9-9.2-7.1-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.4.3-1.1.3-1.4-.2l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.6.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM291.4,1129.7c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M156.5,1068.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M183,1055.4c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M165.4,1064.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M161.1,1044.8c-1.6,0-3.2-.9-3.9-2.4-1.1-2.2-.2-4.8,2-5.8,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2s.6,2.2.2,3.3-1.2,2-2.2,2.5c-.6.3-1.3.4-1.9.4ZM161.1,1038.1c-.4,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.2,1.8-.1.6-.3,1-.8,1.2-1.4s.2-1.2-.1-1.8h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M162.1,1057.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M252,1057.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M280.7,1065c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M261.7,1060.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M273,1043.6c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.6-2.3,2.9-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM273,1036.9c-1.1,0-2,.7-2.3,1.8-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M275.9,1056.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,1108.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M0,1138.6c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8s-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M17.4,1129.5c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M3.1,1118.8c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1h0c-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM1,1115.5c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.6-.2-1.2-.1-1.8.2-1.2.6-1.6,2-1,3.2h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M6.1,1135.4c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8s.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M125.9,1116.4c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.6h0s1,4.6,1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM136.1,1065.4c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,1089.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M233.1,1038c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8-.2-1.5.2-3.1,1.1-4.3l23.6-31.2c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM237.9,993.9l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M246.3,1023.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-1-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5,1,.2,1.4-.2.3-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-12.6,1068.7c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-10.7,37.6c-.6,2.1-2.5,3.4-4.5,3.4ZM-30.7,1053l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-2.5,1043c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2s.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,1006c-.8,0-1.5-.1-2.2-.4l-36.1-15c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM77.4,990.9l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2.1l-30.9-12.8-8.8,21.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M94,999.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-18.6,1292.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-34.3,1300.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-50,1308.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-65.7,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M12.9,1277c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-2.9,1284.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,1258.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M2,1263.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-8.3,1268.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-18.5,1273.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.2,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-28.8,1279c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-39.1,1284.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-49.4,1289.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-59.7,1294.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-70,1299.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M266.6,1209.4c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M232.2,1227.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M226.8,1220.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M270.8,1200.3c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,1235.4c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M219,1242.5c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM214.8,1221.7c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M188,1033.8c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.3,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,1050c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M219.5,1056.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M185,1024.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M218.8,1064.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,1073.5c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-26.9c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8-.2.2-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM223.7,1066.9c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M46.4,1210.6c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M23,1197.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M28.6,1190.7c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M34.3,1208.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-9.6,1179.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M24.4,1206.6c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM-9.3,1155.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,1325.7s0,0-.1,0l-13.1-1.5c-.5,0-.9-.6-.9-1.1l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,1313.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM204.5,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.3,0,.5.2.7.4.2.2.2.5.2.7l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,1303.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M22.4,1310.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M41.1,1300c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM41.1,1293.3c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M37.1,1312.2c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M86.3,1191.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M74.9,1207.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M67.5,1201.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M75.7,1186.3c-1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3,0-1.2.3-2.3,1.1-3.1,1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM75.7,1179.6c-.6,0-1.3.3-1.8.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-.5-.4-1-.6-1.6-.6ZM73.2,1179.7h0,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M58,1192.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M191.6,1238.9c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M169.9,1221.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7s0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M198,1231.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M184.7,1215.7c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6,0,0,0,0,0,0,1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM184.7,1209c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3h0c-.4-.4-1-.5-1.5-.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M192.1,1226.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M235.6,1150.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M261.4,1165.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.2.1.4.3.5.6s0,.5,0,.8l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M244.3,1155.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M260.5,1142.5c-.7,0-1.5-.2-2.1-.6-2.1-1.2-2.8-3.8-1.7-5.9.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM260.5,1135.7c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.6,1.1-.2,2.6.9,3.2.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.3-.6.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M259.2,1155.1c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M154.4,1205.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.3-1.5,1.2-2.8,2.5-3.7,1.3-.9,2.8-1.1,4.3-.8l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM116.6,1172.6c-.7,0-1.5.2-2.1.6-.8.6-1.4,1.4-1.6,2.4l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M127.8,1200.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M290.6,1202.1c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5h0s1.9,4.3,1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM291.9,1150c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M279.6,1177.2c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M46.9,1264.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l23.6-25.1s0,0,0,0l3.2-3.4c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-.5-.5-1.2-.8-1.9-.7-.7,0-1.4.3-1.9.8l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM55.7,1220.8l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M61,1251.3c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M48.2,1179.5c-1,0-2-.3-3-.8l-29.5-17.7h0s-4-2.4-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM17.6,1159.8l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M32.8,1171.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,1169.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M28.3,1237.2c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M317.6,1090.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7s-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M219.4,1288.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M152.5,1014.1c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5.1-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M237.1,1195.6c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M10.9,1308.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3H4c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2H-15.9v7.4H9.9v-11.7H-8.7c-.6,0-1-.4-1-1s.4-1,1-1H10.9c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,1010.8c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,1004.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,995.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M21.1,1006.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M-38.6,1011c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1H12.3c.4,0,.8.3.9.6.1.4,0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3H-25.1v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM-39.2,989.3c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
  </g>
  <g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,988.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7s3.3-1.7,5.2-1.5c3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
    <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,990.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,988.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,990.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.2-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,1000.5s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.4-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,997.3s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,990.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,985.3c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6s-1-2.1-.9-3.2.7-2.2,1.6-2.9c.9-.7,2-1,3.2-.9h0c1.2.1,2.2.7,2.9,1.6.7.9,1,2.1.9,3.2-.3,2.2-2.1,3.9-4.3,3.9ZM204.5,978.6c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6s.1,1.2.5,1.7c.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1,0-.6-.1-1.2-.5-1.7-.4-.5-1-.8-1.6-.9h0c0,0-.2,0-.3,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,989.7s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
    </g>
    <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,994c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.7,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM77.4,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M94,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,1447.2c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM331.5,1440.4c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M315.8,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c.7.2,1.4.1,2-.2.6-.3,1.1-.9,1.3-1.6l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.3,1.2-1.1,2.2-2.2,2.8-.7.4-1.5.6-2.3.6ZM297.7,1381.4l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M93.2,1381.5c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M97.5,1398.6c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3.1,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M101.8,1415.7c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M106.1,1432.8c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M33.6,1455.1c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M84.7,1347.3c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3s.2,1.1-.3,1.4c-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M88.9,1364.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M15.6,1356.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M18.4,1367.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M21.2,1378.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M24,1389.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M26.8,1400.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M29.6,1412.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M32.4,1423.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M35.2,1434.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M38,1445.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M231,1467.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,1484.4h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,1500.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M212,1517.2h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M210.5,1521.2c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c.2-.5.8-.8,1.3-.6l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M243.7,1435h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M237.3,1451.5h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M230.6,1421.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M226.5,1432.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M222.3,1442.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M218.2,1453.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M214,1464.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M209.9,1475.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M205.7,1486c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M201.6,1496.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M197.4,1507.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M132.5,1631.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M116.3,1625c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M100,1618.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M83.7,1611.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M148.8,1638.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M168.7,1628.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M158,1623.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M147.3,1619.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M136.7,1615c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M126,1610.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M115.4,1606.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M104.7,1601.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M94,1597.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M83.4,1592.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,1334.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M125.3,1368.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M116.7,1365.3c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M142.1,1324.2c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M129.3,1375c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M116.8,1389.9c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4.3.5,0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM108.2,1373c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M297.2,1339.2c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M122.2,1474.8c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.6,1470.5c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.2,1461.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M130.8,1469.7c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M78.8,1476.7c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M70.7,1477.3c-2.5,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM70.5,1455.6c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M260.2,1420.7c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M295.8,1441.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M288.7,1447.1c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.5,1410.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M287,1454.8c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M300,1465.7c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1s.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM291.4,1458c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M156.5,1396.8c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M183,1383.8c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.5-.2,1.1,0,1.3.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M165.4,1392.4c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M161.1,1373.2c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-1.1-2.2-.2-4.8,2-5.8,2.2-1.1,4.8-.2,5.8,2h0c.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.5-1.9.5ZM161.1,1366.4c-.3,0-.7,0-1,.2-1.2.6-1.6,2-1.1,3.2.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M162.1,1385.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M252,1386.3c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.5-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M280.7,1393.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M261.7,1388.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M273,1372c-.4,0-.7,0-1.1-.1-1.1-.3-2.1-1-2.7-2-.6-1-.8-2.2-.5-3.3.3-1.1,1-2.1,2-2.7,1-.6,2.2-.8,3.3-.5,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM273,1365.2c-1.1,0-2,.7-2.3,1.8-.2.6,0,1.2.3,1.8.3.5.8.9,1.5,1.1.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M275.9,1384.4c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,1436.8c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M0,1466.9c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M17.4,1457.9c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3.1,1447.2c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM3.1,1440.4c-.4,0-.7,0-1.1.3-1.2.6-1.6,2-1,3.2.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M6.1,1463.8c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M125.9,1444.8c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM136.1,1393.7c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,1418.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M233.1,1366.4c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l20.7-27.5h0s2.8-3.7,2.8-3.7c1.6-2.1,4.5-2.5,6.6-.9l17.1,12.9c.4.3.5,1,.2,1.4s-1,.5-1.4.2l-17.1-12.9c-1.2-.9-2.9-.7-3.8.5l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM237.9,1322.3l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M246.3,1352c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-12.6,1397.1c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.5-.2-.8-.7-.7-1.2l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM-30.7,1381.4l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-2-.6-4.1.6-4.7,2.6l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-2.5,1371.3c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,1334.4c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM77.4,1319.3l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M94,1328.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-18.6,1621.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-34.3,1629.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-50,1637.2c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-65.7,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M12.9,1605.3c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-2.9,1613.3c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,1586.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2,1591.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-8.3,1596.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-18.5,1602.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-28.8,1607.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-39.1,1612.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-49.4,1617.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-59.7,1623c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-70,1628.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M266.6,1537.8c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M232.2,1555.9c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M226.8,1548.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M270.8,1528.7c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,1563.7c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M219,1570.9c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM214.8,1550.1c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M188,1362.1c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,1378.3c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M219.5,1384.8c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M185,1352.5c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.8,1392.6c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,1401.8c0,0-.1,0-.2,0-6.2,0-9.7-5.6-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.4-.4,1-.4,1.4,0l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM223.7,1395.3c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M46.4,1539c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M23,1526.2c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M28.6,1519.1c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M34.3,1536.8c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-9.6,1508c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M24.4,1535c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8s.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.2-.6,10.3,4.5,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM-9.3,1483.6c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,1642c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM204.5,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,1632.3c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1s.5,1,.1,1.4c-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M22.4,1638.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M41.1,1628.3c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.2-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1h0c-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM41.1,1621.6c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M37.1,1640.6c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M86.3,1519.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M74.9,1536.2c-.2,0-.5,0-.7-.3l-22-19.8c-.2-.2-.3-.4-.3-.7,0-.3,0-.5.3-.7l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M67.5,1529.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M75.7,1514.7c-1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3,0-1.2.3-2.3,1.1-3.1h0c1.6-1.8,4.4-1.9,6.2-.3,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM74,1508.7h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-1-.9-2.5-.8-3.3.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M58,1521c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M191.6,1567.3c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M169.9,1549.4c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4,0-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.3.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M198,1559.5c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M184.7,1544.1c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6t0,0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM184.7,1537.4c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M192.1,1554.7c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M235.6,1478.9c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M261.4,1493.4c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.2.1.4.3.5.6,0,.3,0,.5,0,.8l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M244.3,1483.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M260.5,1470.8c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6s.2,2.3-.4,3.3c-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM260.5,1464.1c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M259.2,1483.5c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M154.4,1534.2c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM116.6,1501c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.4,2,.9,4,2.9,4.5l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M127.8,1528.7c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M290.6,1530.5c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-.6-1.4-.7-3,0-4.4s1.6-2.6,3.1-3.2l14.2-6.2c2.9-1.3,6.3,0,7.6,3l13.9,31.5s0,0,0,0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM291.9,1478.4c-.5,0-1,.1-1.5.3l-14.2,6.2c-.9.4-1.6,1.2-2,2.1-.4.9-.3,2,0,2.9l13.5,30.6,21.1-9.3-13.5-30.6c-.6-1.4-2-2.3-3.5-2.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M279.6,1505.6c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M46.9,1592.6c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l26.8-28.5c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM55.7,1549.2l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M61,1579.7c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M48.2,1507.9c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-2.2-1.3-2.9-4.2-1.6-6.4l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.3-.5.9-.6,1.4-.3l29.5,17.7c2.7,1.6,3.6,5.2,2,7.9l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM17.6,1488.2l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c1.1-1.8.5-4.1-1.3-5.2l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M32.8,1499.4c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,1497.7c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.3,0,.5.3.6.5s.1.5,0,.8l-4.4,13c-.1.4-.5.7-.9.7Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M28.3,1565.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M317.6,1419.3h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M219.4,1617.2c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M152.5,1342.5c-.5,0-.9-.3-1-.8l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M237.1,1524c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.1-.2.3-.4.5-.5.2,0,.5,0,.8,0l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M10.9,1636.7h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3H4c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2H-15.9v7.4H9.9v-11.7H-8.7c-.6,0-1-.4-1-1s.4-1,1-1H10.9c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,1339.2c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,1333.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,1324.1h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M21.1,1334.4c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-38.6,1339.4c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1H12.3c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3H-25.1v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM-39.2,1317.7c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,1316.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,1319.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,1316.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,1318.7c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,1328.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,1325.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1,0,.5-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,1319s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,1313.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.1,1.2-.7,2.2-1.6,2.9-.8.6-1.7.9-2.7.9ZM204.5,1307c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9.6,0,1.2-.1,1.7-.5.5-.4.8-1,.9-1.6.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,1318.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,1322.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM77.4,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M94,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,1775.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3.3-1.1,1.1-2,2.1-2.6,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM331.5,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8s-.8-1-1.4-1.2c-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M315.7,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM297.7,1709.7l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-.8-.4-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M93.2,1709.9c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3.1,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M97.5,1727c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M101.8,1744.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M106.1,1761.2c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8s1.6,2.1,2.8,2.6c1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M33.6,1783.5c-.4,0-.9-.3-1-.8l-26.6-106.3c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M84.7,1675.7c-3.1,0-6-2.1-6.8-5.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-.6,0-1.2,0-1.7.1-1.3.3-2.4,1.1-3.1,2.3s-.9,2.5-.6,3.8c.7,2.7,3.4,4.3,6.1,3.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M88.9,1692.8c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M15.6,1684.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M18.4,1695.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M21.2,1706.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M24,1718.1c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M26.8,1729.3c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M29.6,1740.5c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M32.4,1751.7c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M35.2,1762.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M38,1774c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M231,1796.3h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,1812.7h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.7-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,1829.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M212,1845.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M210.5,1849.5c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M243.7,1763.4h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4.7-1.7,2-3.1,3.7-3.9,1.7-.8,3.6-.8,5.4-.1.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-1.2-.5-2.6-.4-3.8,0-1.2.5-2.2,1.5-2.6,2.8-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M237.3,1779.8h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M230.6,1749.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M226.5,1760.5c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M222.3,1771.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M218.2,1782c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M214,1792.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M209.9,1803.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M205.7,1814.3c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M201.6,1825.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M197.4,1835.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M132.5,1960.1c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M116.3,1953.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M100,1946.6c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M83.7,1939.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M148.8,1966.8c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M168.7,1956.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M158,1952.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M147.3,1947.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M136.7,1943.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M126,1938.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M115.4,1934.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M104.7,1930.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M94,1925.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M83.4,1921.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,1662.4c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M125.3,1696.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M116.7,1693.7c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M142.1,1652.5c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M129.3,1703.4c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M116.8,1718.2c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.2,0-.5-.3-.6-.5-.1-.2-.1-.5,0-.8l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM108.2,1701.4c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M297.2,1667.5c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M122.2,1803.2c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.6,1798.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.2,1789.8c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M130.8,1798.1c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M78.8,1805.1c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M70.7,1805.6c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.3-4.5,10.7-3.4,11.4-3.2.5,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.6-.4,1-1,1l-9.9.4c-.4,0-1.6.3-3.2.3ZM70.5,1784c-2.1,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M260.2,1749.1c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M295.8,1769.9c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M288.7,1775.5c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.5,1739.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M287,1783.2c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M300,1794c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.4-.3,1.1-.3,1.4.2l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM291.4,1786.4c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M156.5,1725.2c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M183,1712.1c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M165.4,1720.8c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M161.1,1701.5c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3s1.2-2,2.2-2.5c1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2,1.1,2.2.2,4.8-2,5.8-.6.3-1.3.5-1.9.5ZM161.1,1694.8c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4-.2.6-.2,1.2.1,1.8.6,1.2,2,1.6,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M162.1,1714c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M252,1714.6c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M280.7,1721.7c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c0-.3.2-.5.5-.6.2-.1.5-.2.8-.1l28.7,7.1c.5.1.9.7.7,1.2l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M261.7,1717c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M273,1700.3c-.4,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,2.9-3.8,5.3-3.2,1.1.3,2.1,1,2.7,2,.6,1,.8,2.2.5,3.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM273,1693.6c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.2-.6,0-1.2-.3-1.8-.3-.5-.8-.9-1.5-1.1-.2,0-.4,0-.6,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M275.9,1712.8c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,1765.2c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M0,1795.3c-.1,0-.2,0-.3,0-.3,0-.5-.3-.6-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M17.4,1786.2c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3.1,1775.5c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-1.1-2.1-.3-4.8,1.8-5.9,2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3s-1.1,2-2.1,2.6c-.6.3-1.3.5-2,.5ZM3.1,1768.8c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.4-.8-1.2-1.3-2.1-1.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M6.1,1792.1c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M125.9,1773.1c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c.7-.2,1.3-.6,1.7-1.2.4-.6.5-1.3.3-2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c1.5-.3,3.1,0,4.4.7,1.3.8,2.2,2.1,2.6,3.6l7.7,33.5s0,0,0,0l1,4.6c.3,1.2,0,2.5-.6,3.5s-1.7,1.8-2.9,2.1l-20.8,4.8c0,0-.2,0-.2,0ZM136.1,1722.1c-.3,0-.6,0-.9,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.2-1-.8-1.8-1.7-2.4-.6-.4-1.3-.6-2-.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,1746.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M233.1,1694.7c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM237.9,1650.6l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M246.3,1680.4c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-12.6,1725.4c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM-30.7,1709.7l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-2.5,1699.7c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.8,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,1662.7c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.7,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM77.4,1647.6l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M94,1656.6c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-18.6,1949.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-34.3,1957.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-50,1965.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-65.7,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M12.9,1933.7c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,1.7-.8,3.6-1,5.3-.4,1.8.6,3.2,1.8,4.1,3.5.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-.6-1.2-1.6-2.1-2.9-2.5-1.3-.4-2.6-.3-3.8.3s-2.1,1.6-2.5,2.9c-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-2.9,1941.6c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,1914.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2,1920c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-8.3,1925.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-18.5,1930.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-28.8,1935.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-39.1,1940.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-49.4,1946.1c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-59.7,1951.3c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-70,1956.5c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M266.6,1866.1c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M232.2,1884.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M226.8,1877c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M270.8,1857c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,1892.1c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M219,1899.2c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3-.2-.2-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM214.8,1878.4c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M188,1690.5c-.4,0-.8-.3-1-.7l-6.6-21.4c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,1706.7c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M219.5,1713.1c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M185,1680.9c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.8,1721c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,1730.2c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9.2-.2.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM223.7,1723.6c1,1.4,3.8,4.5,8,4.6,5.2,0,10-4.7,10.1-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M46.4,1867.3c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M23,1854.5c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M28.6,1847.4c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M34.3,1865.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-9.6,1836.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M24.4,1863.3c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.2.2-.4.3-.7.4-.3,0-.5,0-.7-.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM-9.3,1812c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM204.5,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,1960.6c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M22.4,1966.9c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M41.1,1956.7c-1,0-1.9-.3-2.7-1-.9-.7-1.5-1.8-1.6-2.9-.1-1.2.2-2.3.9-3.2,1.5-1.9,4.2-2.2,6.1-.7,1.9,1.5,2.2,4.3.7,6.1-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM41.1,1950c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7s.4,1.2.9,1.6,1.1.6,1.7.5c.6,0,1.2-.4,1.6-.9h0c.8-1,.6-2.5-.4-3.3-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M37.1,1968.9c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M86.3,1848c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M74.9,1864.6c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.5,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M67.5,1857.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M75.7,1843c-1.1,0-2.1-.4-2.9-1.1-.9-.8-1.4-1.8-1.4-3,0-1.2.3-2.3,1.1-3.1h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.8.9-1.9,1.4-3,1.4,0,0-.2,0-.2,0ZM75.7,1836.3c-.6,0-1.3.3-1.8.8h0c-.4.5-.6,1.1-.6,1.7,0,.6.3,1.2.8,1.6,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-.5-.4-1-.6-1.6-.6ZM73.2,1836.4h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M58,1849.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M191.6,1895.6c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M169.9,1877.8c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.4.1-3.4,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M198,1887.9c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M184.7,1872.4c-1,0-2-.3-2.8-1-1.9-1.5-2.1-4.3-.6-6.1,1.5-1.9,4.3-2.1,6.1-.6t0,0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM184.7,1865.7c-.7,0-1.4.3-1.8.9-.8,1-.7,2.5.3,3.3,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M192.1,1883.1c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M235.6,1807.3c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M261.4,1821.8c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.2.1.4.3.5.6,0,.3,0,.5,0,.8l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M244.3,1812.2c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M260.5,1799.2c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM260.5,1792.4c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4.5.3,1.2.4,1.8.2.6-.2,1.1-.6,1.4-1.1h0c.3-.5.4-1.2.2-1.8s-.6-1.1-1.1-1.4c-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M259.2,1811.8c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M154.4,1862.6c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.3-1.4-.6-2.9-2.1-3.2l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c2.5.5,4.2,3,3.6,5.5l-4.3,21c0,.5-.5.8-1,.8ZM116.6,1829.3c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M127.8,1857.1c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M290.6,1858.8c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.5.2-1.1,0-1.3-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5h0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM291.9,1806.7c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M279.6,1833.9c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M46.9,1920.9c-1.4,0-2.8-.5-4-1.6l-11.3-10.6c-2.3-2.2-2.4-5.9-.3-8.2l26.8-28.5c.9-.9,2-1.4,3.3-1.5,1.3,0,2.4.4,3.4,1.3l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.2-2.7,1.8-4.2,1.8ZM55.7,1877.5l-22.9,24.4c-1.4,1.5-1.4,3.9.2,5.4l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M61,1908c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M48.2,1836.2c-1,0-2-.3-3-.8l-29.5-17.7h0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.8,1.3-.3,2.9.9,3.7l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-1.1,1.8-3,2.8-5,2.8ZM17.6,1816.5l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M32.8,1827.8c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,1826.1c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6s-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M28.3,1893.9c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M317.6,1747.6h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M219.4,1945.6c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M152.5,1670.8c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5-.1,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M237.1,1852.3c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5s-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M10.9,1965.1h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3H4c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2H-15.9v7.4H9.9v-11.7H-8.7c-.6,0-1-.4-1-1s.4-1,1-1H10.9c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,1667.5c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,1661.5h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,1652.4h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M21.1,1662.8c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-38.6,1667.7c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1H12.3c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3H-25.1v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM-39.2,1646c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,1645.1c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,1647.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,1645.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,1647.1c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,1657.2s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,1654s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.3.2-.5.4-.7.2-.2.5-.2.7-.2l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6,1-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.4-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,1647.3s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,1642c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM204.5,1635.3c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.2,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,1646.4s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,1650.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
  <g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,2319.1c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0s-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,2319.4c-.8,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.7,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.6,1.4-1.7,2.5-3.1,3.1-.7.3-1.5.4-2.2.4ZM77.4,2304.3l30.9,12.8c.9.4,2,.4,2.9,0,.9-.4,1.7-1.1,2-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M94,2313.2c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M328.4,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M345.7,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M331.5,2103.9c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c1-.5,2.2-.6,3.3-.3,1.1.3,2,1.1,2.6,2.1,1.1,2.1.3,4.8-1.8,5.9-.6.3-1.3.5-2,.5ZM331.5,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334.4,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M315.7,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2s.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.9-3.1,4.1-4.9,7.1-4l14.9,4.2c3.1.9,4.9,4.1,4,7.1l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM297.7,2038.1l22.2,6.3,9.1-32.2c.3-1,.2-2-.3-2.9-.5-.9-1.3-1.5-2.3-1.8l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M325.8,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M294.1,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.3,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M325.5,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M340.7,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M330.4,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M320.1,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M309.8,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M299.5,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M289.2,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M278.9,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M268.6,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M258.3,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M351.3,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4s1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M318.8,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.1-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.1,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M352.7,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM319,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M339.2,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M93.2,2038.2c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M97.5,2055.3c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.3,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M101.8,2072.4c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-4.9,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M106.1,2089.5c-.8,0-1.6-.1-2.3-.4-3.6-1.3-5.5-5.3-4.2-8.9,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5,1-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.9,2.6.4,5.5,3,6.4,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M33.6,2111.8c-.2,0-.4,0-.5-.1-.2-.1-.4-.4-.5-.6l-26.6-106.3c-.1-.5.2-1.1.7-1.2l76.9-19.2c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-75.9,19,26.1,104.3,75.9-19c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-76.9,19.2c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M84.7,2004c-1.3,0-2.5-.3-3.6-1-1.6-1-2.7-2.5-3.2-4.3-.5-1.8-.2-3.7.8-5.3,1-1.6,2.5-2.7,4.3-3.2.8-.2,1.6-.3,2.4-.2.5,0,.9.5.9,1.1,0,.5-.6.9-1.1.9-.6,0-1.2,0-1.7.1-2.7.7-4.3,3.4-3.6,6.1.3,1.3,1.1,2.4,2.3,3.1,1.1.7,2.5.9,3.8.6.6-.1,1.1-.4,1.6-.7.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-.7.5-1.4.8-2.2,1-.6.1-1.1.2-1.7.2Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M88.9,2021.1c-.8,0-1.6-.1-2.3-.4-1.8-.6-3.2-1.9-4-3.6-.8-1.7-.9-3.6-.3-5.4,1.1-3,4.1-5,7.3-4.6.5,0,.9.5.9,1.1,0,.5-.5.9-1.1.9-2.3-.2-4.4,1.1-5.2,3.3-.4,1.3-.4,2.6.2,3.8.6,1.2,1.6,2.1,2.8,2.6,1.5.5,3.1.3,4.5-.6.5-.3,1.1-.2,1.4.3.3.5.2,1.1-.3,1.4-1.2.8-2.5,1.2-3.9,1.2Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M15.6,2012.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M18.4,2024c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M21.2,2035.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M24,2046.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M26.8,2057.6c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M29.6,2068.8c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M32.4,2080c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M35.2,2091.2c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M38,2102.4c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l54-13.5c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-54,13.5c0,0-.2,0-.2,0Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M231,2124.6h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,2141.1h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.2,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,2157.5h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4s-1,.5-1.4,0c-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M212,2173.9h0c-2.2,0-4.3-1.1-5.6-3-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-2.3,1.6-2.8,4.7-1.2,7,.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M210.5,2177.9c-.1,0-.2,0-.4,0l-74-28.5c-.2,0-.4-.3-.6-.5-.1-.2-.1-.5,0-.8l39.4-102.2c0-.2.3-.4.5-.6.2-.1.5-.1.8,0l74,28.5c.5.2.8.8.6,1.3-.2.5-.8.8-1.3.6l-73-28.1-38.7,100.4,73,28.1c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M243.7,2091.7h0c-.8,0-1.6-.2-2.4-.5-1.7-.7-3.1-2-3.9-3.7-.8-1.7-.8-3.6-.1-5.4,1.4-3.6,5.4-5.4,9-4,.8.3,1.5.7,2.1,1.3.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-.4-.4-.9-.7-1.5-.9-2.6-1-5.5.3-6.5,2.9-.5,1.2-.4,2.6,0,3.8.5,1.2,1.5,2.2,2.8,2.6.5.2,1.1.3,1.7.3.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M237.3,2108.2h0c-2.2,0-4.3-1.1-5.6-2.9-1.1-1.5-1.5-3.4-1.2-5.2.3-1.8,1.3-3.5,2.9-4.5,2.6-1.9,6.2-1.7,8.6.4.4.4.5,1,0,1.4-.4.4-1,.5-1.4,0-1.7-1.5-4.3-1.6-6.2-.3-1.1.8-1.8,1.9-2,3.2s0,2.6.8,3.7c.9,1.3,2.4,2.1,4,2.1.6,0,1,.5,1,1,0,.5-.5,1-1,1Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M230.6,2078.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M226.5,2088.8c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M222.3,2099.6c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M218.2,2110.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M214,2121.1c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M209.9,2131.9c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M205.7,2142.7c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M201.6,2153.4c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M197.4,2164.2c-.1,0-.2,0-.4,0l-51.9-20c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l51.9,20c.5.2.8.8.6,1.3-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M132.5,2288.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M116.3,2281.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M100,2275c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M83.7,2268.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-1.5-2.3-4.7-2.9-6.9-1.4-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.3-4.3,3.1-5.5,3.2-2.2,7.6-1.3,9.7,1.9,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,2304.2c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.2-.5.8-.8,1.3-.5l101.3,41.9c.2.1.4.3.5.5.1.2.1.5,0,.8l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,2301.9c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5.5-1.2.5-2.6,0-3.8-.5-1.2-1.5-2.2-2.7-2.7s-2.6-.5-3.8,0c-1.2.5-2.2,1.5-2.7,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,1.7.7,3.1,2.1,3.8,3.8s.7,3.6,0,5.4c-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M148.8,2295.2c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,1.6-1.7,1.8-4.2.5-6.2-.7-1.1-1.9-1.9-3.2-2.1-1.3-.3-2.6,0-3.8.8-1.3.9-2.1,2.3-2.2,3.9,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-2.2,1.2-4.3,3.1-5.5,1.6-1,3.4-1.4,5.3-1.1,1.8.4,3.4,1.4,4.5,3,1.8,2.7,1.5,6.2-.6,8.6-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M168.7,2284.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M158,2280.5c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M147.3,2276.1c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M136.7,2271.7c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M126,2267.3c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M115.4,2262.9c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M104.7,2258.4c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M94,2254c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M83.4,2249.6c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l21.2-51.4c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-21.2,51.4c-.2.4-.5.6-.9.6Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M143.7,1990.8c-.5,0-.9-.4-1-.8l-3.2-20.2-15.1,13.8c-.4.4-1,.3-1.4,0-.4-.4-.3-1,0-1.4l16.5-15.1c.3-.2.7-.3,1-.2.3.1.6.4.7.8l3.5,22.1c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M125.3,2025c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M116.7,2022c-.1,0-.2,0-.3,0-.5-.2-.8-.8-.6-1.3l10.5-30c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-10.5,30c-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M142.1,1980.9c-.1,0-.2,0-.3,0l-11-3.8c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l11,3.8c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M129.3,2031.7c-.1,0-.2,0-.3,0l-20-7c-.5-.2-.8-.8-.6-1.3.2-.5.8-.8,1.3-.6l20,7c.5.2.8.8.6,1.3-.1.4-.5.7-.9.7Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M116.8,2046.6c-4.6,0-9.2-2.6-11.1-6.8-2.6-5.7.9-11.2,1.3-11.8.3-.4.7-.5,1.2-.4l19,6.6,2.7-7.6-19-6.6c-.5-.2-.8-.8-.6-1.3l12.7-36.2c.1-.4.5-.7.9-.7.4,0,.8.2,1,.6l3,7,5.4-4c.3-.2.6-.2.9-.1.3.1.5.4.6.7l1.8,6.5,6.8-3.6c.5-.3,1.1,0,1.4.4s0,1.1-.4,1.4l-7.9,4.2c-.3.1-.6.2-.9,0-.3-.1-.5-.4-.6-.7l-1.7-6.3-5.3,3.8c-.2.2-.6.2-.9.2-.3,0-.5-.3-.7-.6l-2.5-5.8-11.5,32.9,19,6.6c.5.2.8.8.6,1.3l-3.3,9.4c-.2,1.1-1.4,7.3-7,9.8-1.6.7-3.2,1-4.9,1ZM108.2,2029.7c-.8,1.5-2.5,5.4-.7,9.2,2.2,4.7,8.5,7,13.4,4.8,3.9-1.8,5.3-5.9,5.7-7.6l-18.4-6.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M340.6,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4.3-.5.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M334,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1h31.8c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M349.5,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M297.2,1995.9c-.6,0-1-.4-1-1v-21.1c0-.6.4-1,1-1s1,.4,1,1v21.1c0,.6-.4,1-1,1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M289.8,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1h38.4c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3h-34.8v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM289.2,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M122.2,2131.5c-.3,0-.7-.2-.9-.5-.3-.5-.1-1.1.3-1.4l17.6-10.5-18.5-8.9c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7,1.3-.5l20.1,9.7c.3.2.5.5.6.9,0,.4-.2.7-.5.9l-19.2,11.4c-.2,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.6,2127.2c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M83.2,2118.1c-.5,0-1-.4-1-1,0-.6.4-1,1-1l31.8-1.4c.5,0,1,.4,1,1s-.4,1-1,1l-31.8,1.4s0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M130.8,2126.4c-.5,0-1-.4-1-1l-.5-11.6c0-.6.4-1,1-1,.5,0,1,.4,1,1l.5,11.6c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M78.8,2133.4c-.5,0-1-.4-1-1l-.9-21.1c0-.6.4-1,1-1,.5,0,1,.4,1,1l.9,21.1c0,.6-.4,1-1,1,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M70.7,2134c-2.4,0-5.8-.6-8.5-3.2-4.7-4.5-5-12.5-.6-17,4.4-4.6,11.1-3.3,11.4-3.2.4,0,.8.5.8.9l.9,20.1,8-.4-.9-20.1c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3l38.3-1.7c.4,0,.8.2,1,.6.2.4,0,.8-.2,1.1l-5.4,5.4,5.7,3.6c.3.2.5.5.5.8s-.1.6-.4.8l-5.4,4.1,5.9,4.9c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-6.8-5.7c-.2-.2-.4-.5-.4-.8s.2-.6.4-.8l5.2-3.9-5.5-3.5c-.3-.2-.4-.4-.5-.7,0-.3,0-.6.3-.8l4.5-4.5-34.8,1.5.9,20.1c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-9.9.4c-.4,0-1.6.3-3.2.3ZM70.5,2112.3c-2,0-5.1.5-7.4,2.8-3.6,3.8-3.3,10.5.5,14.2,3.1,3,7.4,2.7,9.2,2.5l-.8-19.4c-.4,0-.9,0-1.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M260.2,2077.4c-.5,0-.9-.3-1-.8l-3.9-22c0-.4,0-.7.4-1,.3-.2.7-.3,1-.1l20.5,8.9c.5.2.7.8.5,1.3-.2.5-.8.7-1.3.5l-18.8-8.2,3.5,20.2c0,.5-.3,1.1-.8,1.2,0,0-.1,0-.2,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M295.8,2098.2c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M288.7,2103.8c-.3,0-.6-.1-.8-.4l-19.7-25c-.3-.4-.3-1.1.2-1.4.4-.3,1.1-.3,1.4.2l19.7,25c.3.4.3,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M258.5,2067.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l9.1-7.2c.4-.3,1.1-.3,1.4.2.3.4.3,1.1-.2,1.4l-9.1,7.2c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M287,2111.5c-.3,0-.6-.1-.8-.4-.3-.4-.3-1.1.2-1.4l16.6-13.1c.4-.3,1.1-.3,1.4.2s.3,1.1-.2,1.4l-16.6,13.1c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M300,2122.4c-.5,0-1,0-1.5-.1-6.2-.9-9-6.7-9.3-7.4-.2-.4,0-.9.3-1.2l15.8-12.4-5-6.3-15.8,12.4c-.2.2-.5.2-.7.2-.3,0-.5-.2-.7-.4l-23.7-30.2c-.3-.3-.3-.8,0-1.1.2-.4.6-.5,1-.5l7.6,1.2-.4-6.7c0-.3.1-.6.4-.8.3-.2.6-.3.9-.2l6.4,2,.6-7.6c0-.5.5-1,1.1-.9.5,0,1,.5.9,1.1l-.7,8.9c0,.3-.2.6-.4.8-.3.2-.6.2-.9.1l-6.2-1.9.4,6.5c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.3-1,21.5,27.4,15.8-12.4c.2-.2.5-.2.7-.2.3,0,.5.2.7.4l6.1,7.8c.8.7,5.4,5.1,4.5,11.2-.8,5.9-6.3,10.5-12,10.5ZM291.4,2114.7c.8,1.5,3.2,5,7.4,5.6,5.1.7,10.5-3.4,11.2-8.7.6-4.3-2.1-7.6-3.3-8.9l-15.3,12Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M156.5,2053.5c-.4,0-.7-.2-.9-.6l-6.7-13.6c-.1-.2-.1-.5,0-.8.3-.9.9-3.4-.3-6-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5,1.4,3,.9,5.7.5,7.1l6.5,13.3c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M183,2040.5c-.4,0-.7-.2-.9-.6l-6.5-13.2c-1.4-.5-3.1-1.5-4.5-3.3-2.6-3.5-1.9-7.6-1.6-9l-5.2-10.6-24.7,12.2,5.4,10.9c.2.5,0,1.1-.5,1.3-.5.2-1.1,0-1.3-.5l-5.8-11.8c-.2-.5,0-1.1.5-1.3l26.5-13.1c.2-.1.5-.1.8,0,.3,0,.5.3.6.5l5.8,11.8c.1.2.1.5,0,.7-.1.4-1.3,4.4,1.1,7.6,1.2,1.6,2.8,2.4,3.9,2.8.3,0,.5.3.6.5l6.7,13.6c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M165.4,2049.1c-.4,0-.7-.2-.9-.6l-18.2-37c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l18.2,37c.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M161.1,2029.9c-.5,0-.9,0-1.4-.2-1.1-.4-2-1.2-2.5-2.2-.5-1-.6-2.2-.2-3.3.4-1.1,1.2-2,2.2-2.5,1-.5,2.2-.6,3.3-.2,1.1.4,2,1.2,2.5,2.2h0c.5,1,.6,2.2.2,3.3s-1.2,2-2.2,2.5c-.6.3-1.3.5-1.9.5ZM161.1,2023.1c-.4,0-.7,0-1,.2-.6.3-1,.8-1.2,1.4s-.2,1.2.1,1.8c.6,1.2,2,1.7,3.2,1.1,1.2-.6,1.7-2,1.1-3.2h0c-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.8-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M162.1,2042.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l10.6-5.2-14.4-29.3c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l14.9,30.2c.2.5,0,1.1-.5,1.3l-11.4,5.6c-.1,0-.3.1-.4.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M252,2042.9c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.7-14.7c0-.3.2-.5.4-.6.8-.5,2.9-2,3.6-4.8.2-.5.7-.8,1.2-.7.5.1.8.7.7,1.2-.9,3.1-3,4.9-4.2,5.7l-3.6,14.3c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M280.7,2050.1c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l3.6-14.3c-.7-1.3-1.4-3.1-1.3-5.4.2-4.4,3.4-7,4.6-7.9l2.8-11.4-26.7-6.7-2.9,11.8c-.1.5-.7.9-1.2.7-.5-.1-.9-.7-.7-1.2l3.2-12.8c.1-.5.7-.9,1.2-.7l28.7,7.1c.3,0,.5.2.6.5.1.2.2.5.1.8l-3.2,12.8c0,.2-.2.5-.4.6-.4.2-3.8,2.5-4,6.5-.1,2,.6,3.6,1.2,4.6.1.2.2.5.1.8l-3.7,14.7c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M261.7,2045.4c0,0-.2,0-.2,0-.5-.1-.9-.7-.7-1.2l10-40.1c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-10,40.1c-.1.5-.5.8-1,.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M273,2028.7c-.4,0-.7,0-1.1-.1-2.3-.6-3.8-3-3.2-5.3.6-2.3,2.9-3.8,5.3-3.2,2.3.6,3.8,3,3.2,5.3h0c-.3,1.1-1,2.1-2,2.7-.7.4-1.5.6-2.2.6ZM273,2021.9c-1.1,0-2,.7-2.3,1.8-.3,1.3.5,2.5,1.7,2.9.6.2,1.2,0,1.8-.3.5-.3.9-.8,1.1-1.5h0c.3-1.3-.5-2.5-1.7-2.9-.2,0-.4,0-.6,0ZM276.2,2025.1h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M275.9,2041.1c0,0-.2,0-.2,0l-12.4-3.1c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l11.4,2.8,7.9-31.7c.1-.5.7-.9,1.2-.7.5.1.9.7.7,1.2l-8.1,32.7c0,.3-.2.5-.5.6-.2,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,2093.5c-.4,0-.7-.2-.9-.6-1.4-2.9-1-5.7-.7-7.1l-6.8-13.1c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l7,13.4c.1.2.1.5,0,.8-.3.9-.8,3.4.5,6,.2.5,0,1.1-.5,1.3-.1,0-.3.1-.4.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M0,2123.6c-.4,0-.7-.2-.9-.5l-6.1-11.7c-.1-.2-.1-.5,0-.7.1-.4,1.2-4.4-1.3-7.6-1.3-1.6-2.9-2.3-4-2.7-.3,0-.5-.3-.6-.5l-7-13.4c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.8,13.1c1.4.5,3.2,1.4,4.6,3.2,2.7,3.4,2.1,7.5,1.8,9l5.5,10.4,24.4-12.8-5.6-10.8c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l6.1,11.7c.1.2.1.5,0,.8,0,.3-.3.5-.5.6l-26.2,13.7c-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M17.4,2114.6c-.4,0-.7-.2-.9-.5l-19.1-36.6c-.3-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l19.1,36.6c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M3.1,2103.9c-.4,0-.9,0-1.3-.2-1.1-.3-2-1.1-2.6-2.1-.5-1-.6-2.2-.3-3.3s1.1-2,2.1-2.6c2.1-1.1,4.8-.3,5.9,1.8.5,1,.6,2.2.3,3.3-.3,1.1-1.1,2-2.1,2.6-.6.3-1.3.5-2,.5ZM3.1,2097.1c-.4,0-.7,0-1.1.3-.6.3-1,.8-1.2,1.4-.2.6-.1,1.2.2,1.8h0c.3.6.8,1,1.4,1.2.6.2,1.2.1,1.8-.2.6-.3,1-.8,1.2-1.4.2-.6.1-1.2-.2-1.8-.3-.6-.8-1-1.4-1.2-.2,0-.5-.1-.7-.1Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M6.1,2120.5c-.4,0-.7-.2-.9-.5l-15.6-29.9c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l11.3-5.9c.5-.3,1.1,0,1.3.4.3.5,0,1.1-.4,1.3l-10.4,5.4,15.1,29c.3.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M125.9,2101.5c-.5,0-.9-.3-1-.8-.1-.5.2-1.1.8-1.2l20.8-4.8c1.4-.3,2.3-1.8,2-3.2l-.8-3.6-23.5,5.4c-.5.1-1.1-.2-1.2-.8l-7.7-33.6c-.7-3.1,1.2-6.2,4.3-6.9l15.1-3.5c3.1-.7,6.2,1.2,6.9,4.3l7.7,33.5s0,0,0,0l1,4.6c.6,2.5-1,5-3.5,5.6l-20.8,4.8c0,0-.2,0-.2,0ZM136.1,2050.4c-.3,0-.6,0-.8,0l-15.1,3.5c-2,.5-3.3,2.5-2.8,4.5l7.5,32.6,22.5-5.2-7.5-32.6c-.4-1.8-2-2.9-3.7-2.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,2074.9c-.4,0-.9-.3-1-.8-.1-.5.2-1.1.7-1.2l24.3-6.1c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-24.3,6.1c0,0-.2,0-.2,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M233.1,2023.1c-1.2,0-2.5-.4-3.5-1.2l-12.4-9.3c-1.2-.9-2-2.3-2.2-3.8s.2-3.1,1.1-4.3l23.6-31.2c.8-1,1.8-1.6,3.1-1.8,1.2-.2,2.5.1,3.5.9l17.1,12.9c.4.3.5,1,.2,1.4-.3.4-1,.5-1.4.2l-17.1-12.9c-.6-.4-1.3-.6-2-.5-.7,0-1.3.5-1.8,1l-2.2,2.9,19.2,14.5c.2.2.4.4.4.7,0,.3,0,.5-.2.7l-20.7,27.5c-.9,1.2-2.3,2-3.8,2.2-.3,0-.5,0-.8,0ZM237.9,1979l-20.1,26.7c-.6.8-.9,1.8-.7,2.8.1,1,.7,1.9,1.5,2.5l12.4,9.3c.8.6,1.8.9,2.8.7,1-.1,1.9-.7,2.5-1.5l20.1-26.7-18.4-13.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M246.3,2008.7c-.2,0-.4,0-.6-.2l-20.3-14.7c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5,1.4-.2l20.3,14.7c.4.3.5.9.2,1.4-.2.3-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-12.6,2053.8c-.4,0-.9,0-1.3-.2l-20.6-5.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l20.6,5.8c1.4.4,2.9-.4,3.3-1.8l1-3.5-23.2-6.6c-.3,0-.5-.2-.6-.5-.1-.2-.2-.5,0-.8l9.4-33.1c.4-1.5,1.4-2.7,2.7-3.5s2.9-.9,4.4-.5l14.9,4.2c1.5.4,2.7,1.4,3.5,2.7s.9,2.9.5,4.4l-9.4,33.1s0,0,0,0l-1.3,4.5c-.6,2.1-2.5,3.4-4.5,3.4ZM-30.7,2038.1l22.2,6.3,9.1-32.2c.6-2-.6-4.1-2.6-4.7l-14.9-4.2c-1-.3-2-.2-2.9.3-.9.5-1.5,1.3-1.8,2.3l-9.1,32.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-2.5,2028c0,0-.2,0-.3,0l-24.2-6.4c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7l24.2,6.4c.5.1.9.7.7,1.2-.1.4-.5.7-1,.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M109.7,1991.1c-.7,0-1.5-.1-2.2-.4l-31.8-13.2s0,0,0,0l-4.3-1.8c-2.4-1-3.5-3.7-2.5-6.1l8.2-19.8c.2-.5.8-.8,1.3-.5.5.2.8.8.5,1.3l-8.2,19.8c-.6,1.4,0,2.9,1.4,3.5l3.4,1.4,9.2-22.2c.2-.5.8-.8,1.3-.5l31.8,13.2c1.4.6,2.5,1.7,3.1,3.1.6,1.4.6,3,0,4.4l-5.9,14.3c-.9,2.2-3.1,3.6-5.4,3.6ZM77.4,1976l30.9,12.8c1.9.8,4.1-.1,4.9-2l5.9-14.3c.4-.9.4-2,0-2.9-.4-.9-1.1-1.7-2-2l-30.9-12.8-8.8,21.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M94,1984.9c-.1,0-.2,0-.4,0-.5-.2-.8-.8-.6-1.3l9.2-23.3c.2-.5.8-.8,1.3-.6.5.2.8.8.6,1.3l-9.2,23.3c-.2.4-.5.6-.9.6Z"/>
      </g>
      <g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-18.6,2278c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-34.3,2285.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-50,2293.9c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-65.7,2301.8c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.6,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,2303.8c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M12.9,2262c-.2,0-.5,0-.7-.3-.6-.6-1.1-1.2-1.5-1.9-.8-1.7-1-3.6-.4-5.3.6-1.8,1.8-3.2,3.5-4.1,3.4-1.7,7.7-.4,9.4,3.1.4.7.6,1.5.7,2.3,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9,0-.6-.2-1.1-.5-1.7-1.2-2.5-4.3-3.4-6.7-2.2-1.2.6-2.1,1.6-2.5,2.9-.4,1.3-.3,2.6.3,3.8.3.5.6,1,1,1.4.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-2.9,2270c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.5-3.8,3.9-6.6,7.8-6.1,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-2.7-.3-5.2,1.6-5.6,4.4-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        </g>
        <g>
          <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,2243.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M2,2248.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-8.3,2253.6c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-18.5,2258.8c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-28.8,2264c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-39.1,2269.2c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-49.4,2274.4c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-59.7,2279.7c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
          <path class={`cls-1${isDark?'-dark':''}`} d="M-70,2284.9c-.4,0-.7-.2-.9-.5l-25.1-49.6c-.2-.5,0-1.1.4-1.3.5-.3,1.1,0,1.3.4l25.1,49.6c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
        </g>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M266.6,2194.5c-.1,0-.3,0-.4,0-.5-.2-.7-.8-.5-1.3l8.7-18.6-20.3,3c-.5,0-1.1-.3-1.1-.8,0-.5.3-1.1.8-1.1l22.1-3.3c.4,0,.7,0,.9.4.2.3.3.7.1,1l-9.4,20.2c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M232.2,2212.6c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M226.8,2205.3c-.3,0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l25.5-19c.4-.3,1.1-.2,1.4.2.3.4.2,1.1-.2,1.4l-25.5,19c-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M270.8,2185.4c-.3,0-.6-.1-.8-.4l-7-9.3c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l7,9.3c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,2220.4c-.3,0-.6-.1-.8-.4l-12.7-16.9c-.3-.4-.2-1.1.2-1.4.4-.3,1.1-.2,1.4.2l12.7,16.9c.3.4.2,1.1-.2,1.4-.2.1-.4.2-.6.2Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M219,2227.6c-.6,0-1.2,0-1.8-.2-6.4-1.1-11.1-7.5-10.1-13.8,1-6.1,7-8.8,7.6-9.1.4-.2.9,0,1.2.3l12.1,16.1,6.4-4.8-12.1-16.1c-.2-.2-.2-.5-.2-.7,0-.3.2-.5.4-.7l30.7-23c.3-.2.8-.3,1.1,0,.3.2.5.6.5,1l-1.4,7.5,6.7-.2c.3,0,.6.1.8.4.2.3.3.6.1.9l-2.1,6.4,7.6.8c.5,0,.9.5.9,1.1,0,.5-.6,1-1.1.9l-8.9-.9c-.3,0-.6-.2-.7-.5-.2-.3-.2-.6-.1-.9l2.1-6.2-6.5.2c-.3,0-.6-.1-.8-.3s-.3-.5-.2-.8l1.2-6.2-27.9,20.8,12.1,16.1c.2.2.2.5.2.7,0,.3-.2.5-.4.7l-8,6c-.7.7-4.3,4.4-9.5,4.4ZM214.8,2206.8c-1.5.8-5.1,3.1-5.7,7.2-.8,5.1,3.2,10.6,8.4,11.5,4.3.7,7.6-1.9,9-3.1l-11.6-15.6Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M188,2018.8c-.4,0-.8-.3-1-.7l-6.6-21.3c-.1-.4,0-.7.2-1,.3-.3.6-.4,1-.3l21.4,6.3c.5.2.8.7.7,1.2-.2.5-.7.8-1.2.7l-19.6-5.8,6,19.6c.2.5-.1,1.1-.7,1.2,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,2035c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M219.5,2041.5c-.3,0-.5,0-.7-.3l-22.6-22.3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l22.6,22.3c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M185,2009.2c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l8.2-8.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.2,8.3c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.8,2049.3c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l14.8-15c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-14.8,15c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M231.8,2058.5c0,0-.1,0-.2,0-6.3,0-10-5.9-10.1-6.2-.2-.4-.2-.9.1-1.2l14.1-14.3-5.7-5.6-14.1,14.3c-.2.2-.4.3-.7.3h0c-.3,0-.5-.1-.7-.3l-27.3-27c-.3-.3-.4-.7-.2-1.1.2-.4.6-.6,1-.6l7.7.3-1.3-6.6c0-.3,0-.7.3-.9s.6-.3.9-.3l6.6,1.2-.4-7.7c0-.6.4-1,1-1,.5,0,1,.4,1,1l.4,8.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.2l-6.4-1.1,1.2,6.4c0,.3,0,.6-.2.8s-.5.4-.8.3l-6.3-.2,24.8,24.4,14.1-14.3c.2-.2.4-.3.7-.3h0c.3,0,.5.1.7.3l7.1,7c.9.6,6,4.3,5.9,10.5-.1,6.4-5.7,12-11.9,12ZM223.7,2052c1,1.4,3.8,4.5,8,4.6,0,0,0,0,.1,0,5.1,0,9.9-4.8,10-10,0-4.3-3-7.3-4.4-8.4l-13.7,13.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M46.4,2195.7c0,0-.1,0-.2,0l-22-4c-.5,0-.9-.6-.8-1.2,0-.5.6-.9,1.2-.8l20.2,3.6-8.1-18.8c-.2-.5,0-1.1.5-1.3.5-.2,1.1,0,1.3.5l8.8,20.5c.1.3,0,.7-.1,1-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M23,2182.9c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M28.6,2175.8c-.2,0-.4,0-.6-.2l-24.9-19.8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l24.9,19.8c.4.3.5,1,.2,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M34.3,2193.5c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l7.2-9.1c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-7.2,9.1c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M-9.6,2164.7c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l13.2-16.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-13.2,16.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M24.4,2191.7c-.5,0-.9-.4-1-.8l-1.4-8.8c0-.3,0-.6.2-.8.2-.2.5-.3.8-.3l6.5.4-1.9-6.2c0-.3,0-.6.1-.9.2-.3.4-.4.8-.4l6.3-.5-27.2-21.7-12.5,15.8c-.3.4-1,.5-1.4.2l-7.8-6.2c-1-.5-6.4-3.7-7-9.8-.6-6.5,4.4-12.6,10.7-13.2,6.3-.6,10.5,4.8,10.7,5.1.3.4.3.9,0,1.2l-12.5,15.8,6.3,5,12.5-15.8c.3-.4,1-.5,1.4-.2l30,23.9c.3.3.5.7.3,1.1-.1.4-.5.7-.9.7l-7.6.5,2,6.4c0,.3,0,.7-.2.9-.2.3-.5.4-.8.4l-6.7-.4,1.2,7.6c0,.5-.3,1.1-.8,1.1,0,0-.1,0-.2,0ZM-9.3,2140.3c-.3,0-.5,0-.8,0-5.2.5-9.4,5.8-8.9,11.1.4,4.3,3.8,6.9,5.3,7.9l12.1-15.2c-1.1-1.2-3.9-3.7-7.7-3.7Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,2313.9s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,2310.7s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-1,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.4,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.3.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,2304s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,2298.7c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.3-2.4,2.4-4.1,4.8-3.8,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM204.5,2292c-1.2,0-2.2.9-2.3,2.1,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,2303.1s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1s.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M14.6,2289c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.5-11.8c.2-.2.4-.3.7-.4,1-.1,3.4-.6,5.3-2.8.4-.4,1-.5,1.4-.1.4.4.5,1,.1,1.4-2.1,2.5-4.8,3.2-6.2,3.5l-9.2,11.5c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,2307.4c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.2.2.3.4.4.7,0,.3,0,.5-.2.7l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M22.4,2295.2c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l25.8-32.2c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-25.8,32.2c-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M41.1,2285c-1,0-1.9-.3-2.7-1-1.9-1.5-2.2-4.3-.7-6.1,1.5-1.9,4.3-2.2,6.1-.7.9.7,1.5,1.8,1.6,2.9.1,1.2-.2,2.3-.9,3.2-.7.9-1.8,1.5-2.9,1.6-.2,0-.3,0-.5,0ZM41.1,2278.3c-.7,0-1.4.3-1.8.9-.8,1-.6,2.5.4,3.3.5.4,1.1.6,1.7.5.6,0,1.2-.4,1.6-.9h0c.4-.5.6-1.1.5-1.7,0-.6-.4-1.2-.9-1.6-.4-.3-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M37.1,2297.3c-.2,0-.4,0-.6-.2l-10-8c-.4-.3-.5-1-.2-1.4.3-.4,1-.5,1.4-.2l9.2,7.3,20.4-25.5c.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4l-21,26.3c-.2.2-.4.3-.7.4,0,0,0,0-.1,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M86.3,2176.4c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4,2.2-2.4,5-3,6.4-3.1l9.9-11c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-10.2,11.3c-.2.2-.4.3-.7.3-1,0-3.5.4-5.5,2.5-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M74.9,2192.9c-.2,0-.5,0-.7-.3l-22-19.8c-.4-.4-.4-1,0-1.4l8.8-9.8c.2-.2.4-.3.7-.3.5,0,4.6-.4,6.6-3.9,1-1.7,1.2-3.5,1.1-4.7,0-.3,0-.5.3-.7l10.2-11.3c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-9.9,11c0,1.4-.2,3.4-1.4,5.4-2.3,3.8-6.3,4.6-7.8,4.8l-7.9,8.8,20.5,18.4,8.1-9c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-8.8,9.8c-.2.2-.4.3-.7.3,0,0,0,0,0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M67.5,2186.3c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l27.6-30.7c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-27.6,30.7c-.2.2-.5.3-.7.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M75.7,2171.4c-1,0-2.1-.4-2.9-1.1-1.8-1.6-1.9-4.4-.3-6.2h0c.8-.9,1.8-1.4,3-1.4,1.2,0,2.3.3,3.1,1.1,1.8,1.6,1.9,4.4.3,6.2-.9,1-2,1.4-3.2,1.4ZM75.7,2164.6s0,0-.1,0c-.6,0-1.2.3-1.6.8h0c-.9,1-.8,2.5.2,3.3,1,.9,2.5.8,3.3-.2.9-1,.8-2.5-.2-3.3-.4-.4-1-.6-1.6-.6ZM73.2,2164.7h0,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M58,2177.7c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l22.6-25c.4-.4,1-.4,1.4,0l9.5,8.5c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-8.7-7.9-21.9,24.3c-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M191.6,2224c-.2,0-.4,0-.6-.2l-11.7-9.6c-.2-.2-.3-.4-.4-.7-.1-1-.6-3.4-2.8-5.3-.4-.4-.5-1,0-1.4.4-.4,1-.5,1.4,0,2.5,2.1,3.2,4.8,3.4,6.2l11.4,9.4c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M169.9,2206.1c-.2,0-.4,0-.6-.2l-10.2-8.4c-.2-.2-.3-.4-.4-.7,0-.3,0-.5.2-.7l18.8-22.9c.4-.4,1-.5,1.4-.1l10.2,8.4c.2.2.3.4.4.6,0,.5.7,4.5,4.2,6.5,1.8,1,3.5,1,4.7.9.3,0,.5,0,.7.2l11.7,9.6c.4.4.5,1,.1,1.4-.4.4-1,.5-1.4.1l-11.4-9.4c-1.5.1-3.5,0-5.5-1.2-3.9-2.1-4.9-6.1-5.1-7.6l-9.1-7.5-17.5,21.3,9.4,7.7c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M198,2216.2c-.2,0-.4,0-.6-.2l-31.9-26.2c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l31.9,26.2c.4.4.5,1,.1,1.4-.2.2-.5.4-.8.4Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M184.7,2200.8c-1,0-2-.3-2.8-1-.9-.7-1.5-1.8-1.6-2.9s.2-2.3,1-3.2c1.5-1.9,4.3-2.1,6.1-.6t0,0c1.9,1.5,2.1,4.3.6,6.1-.9,1-2.1,1.6-3.4,1.6ZM184.7,2194.1c-.7,0-1.4.3-1.8.9-.4.5-.6,1.1-.5,1.7,0,.6.4,1.2.9,1.6,1,.8,2.5.7,3.3-.3.8-1,.7-2.5-.3-3.3-.4-.4-1-.5-1.5-.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M192.1,2211.4c-.2,0-.4,0-.6-.2-.4-.4-.5-1-.1-1.4l7.5-9.1-25.3-20.7c-.4-.4-.5-1-.1-1.4.4-.4,1-.5,1.4-.1l26.1,21.4c.4.4.5,1,.1,1.4l-8.1,9.9c-.2.2-.5.4-.8.4Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M235.6,2135.6c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.4-13.2c.1-.2.3-.4.6-.5.9-.3,3.3-1.2,4.8-3.7.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4-1.7,2.8-4.2,4-5.5,4.4l-7.2,12.9c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M261.4,2150.1c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l7.2-12.9c-.4-1.4-.5-3.4.2-5.6,1.4-4.2,5.1-5.9,6.5-6.4l5.8-10.3-24-13.5-6,10.6c-.3.5-.9.7-1.4.4-.5-.3-.7-.9-.4-1.4l6.5-11.5c.1-.2.3-.4.6-.5.3,0,.5,0,.8,0l25.8,14.5c.5.3.7.9.4,1.4l-6.5,11.5c-.1.2-.3.4-.6.5-.4.1-4.3,1.4-5.6,5.2-.6,1.9-.4,3.7,0,4.8,0,.3,0,.5,0,.8l-7.4,13.2c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M244.3,2140.5c-.2,0-.3,0-.5-.1-.5-.3-.7-.9-.4-1.4l20.2-36c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-20.2,36c-.2.3-.5.5-.9.5Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M260.5,2127.5c-.7,0-1.5-.2-2.1-.6-1-.6-1.7-1.5-2.1-2.6-.3-1.1-.2-2.3.4-3.3.6-1,1.5-1.7,2.6-2.1,1.1-.3,2.3-.2,3.3.4,1,.6,1.7,1.5,2.1,2.6.3,1.1.2,2.3-.4,3.3-.6,1-1.5,1.7-2.6,2.1-.4.1-.8.2-1.2.2ZM260.5,2120.8c-.2,0-.4,0-.6,0-.6.2-1.1.6-1.4,1.1-.3.5-.4,1.2-.2,1.8.2.6.6,1.1,1.1,1.4,1.1.6,2.6.2,3.2-.9h0c.3-.5.4-1.2.2-1.8-.2-.6-.6-1.1-1.1-1.4-.4-.2-.8-.3-1.2-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M259.2,2140.2c-.2,0-.3,0-.5-.1l-11.1-6.3c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l10.3,5.8,16-28.5c.3-.5.9-.7,1.4-.4.5.3.7.9.4,1.4l-16.5,29.4c-.1.2-.3.4-.6.5,0,0-.2,0-.3,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M154.4,2190.9c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.3-21c.1-.7,0-1.4-.4-2-.4-.6-1-1-1.7-1.1l-3.6-.7-4.8,23.6c-.1.5-.6.9-1.2.8l-33.7-6.9c-3.1-.6-5.1-3.7-4.5-6.8l3.1-15.2c.6-3.1,3.7-5.1,6.8-4.5l33.7,6.9s0,0,0,0l4.6.9c1.2.3,2.3,1,3,2,.7,1,.9,2.3.7,3.5l-4.3,21c0,.5-.5.8-1,.8ZM116.6,2157.7c-1.8,0-3.3,1.2-3.7,3l-3.1,15.2c-.2,1,0,2,.5,2.8.6.8,1.4,1.4,2.4,1.6l32.7,6.7,4.6-22.6-32.7-6.7c-.3,0-.5,0-.8,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M127.8,2185.4c0,0-.1,0-.2,0-.5-.1-.9-.6-.8-1.2l4.6-24.7c.1-.5.6-.9,1.2-.8.5.1.9.6.8,1.2l-4.6,24.7c0,.5-.5.8-1,.8Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M290.6,2187.2c-.4,0-.8-.2-.9-.6-.2-.5,0-1.1.5-1.3l19.6-8.6c1.4-.6,2-2.2,1.4-3.5l-1.5-3.4-22,9.7c-.2.1-.5.1-.8,0-.2,0-.4-.3-.6-.5l-13.9-31.5c-1.3-2.9,0-6.3,3-7.6l14.2-6.2c1.4-.6,3-.7,4.4,0,1.4.6,2.6,1.6,3.2,3.1l13.9,31.5h0l1.9,4.3c1,2.4,0,5.1-2.4,6.2l-19.6,8.6c-.1,0-.3,0-.4,0ZM291.9,2135.1c-.5,0-1,.1-1.5.3l-14.2,6.2c-1.9.8-2.8,3.1-1.9,5l13.5,30.6,21.1-9.3-13.5-30.6c-.4-.9-1.2-1.6-2.1-2-.4-.2-.9-.3-1.4-.3Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M279.6,2162.3c-.4,0-.7-.2-.9-.6-.2-.5,0-1.1.5-1.3l22.8-10.5c.5-.2,1.1,0,1.3.5.2.5,0,1.1-.5,1.3l-22.8,10.5c-.1,0-.3,0-.4,0Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M46.9,2249.2c-1.5,0-2.9-.6-4-1.6l-11.3-10.6c-1.1-1.1-1.8-2.5-1.8-4,0-1.5.5-3,1.6-4.1l26.8-28.5c1.8-1.9,4.7-2,6.6-.2l15.6,14.6c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-15.6-14.6c-1.1-1-2.8-1-3.8.1l-2.5,2.7,17.6,16.5c.2.2.3.4.3.7,0,.3,0,.5-.3.7l-23.6,25.1c-1.1,1.1-2.5,1.8-4,1.8,0,0-.1,0-.2,0ZM55.7,2205.9l-22.9,24.4c-.7.7-1.1,1.7-1,2.7s.5,1.9,1.2,2.6l11.3,10.6c.7.7,1.7,1,2.7,1,1,0,1.9-.5,2.6-1.2l22.9-24.4-16.8-15.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M61,2236.4c-.2,0-.5,0-.7-.3l-18.6-16.8c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l18.6,16.8c.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      </g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M48.2,2164.6c-1,0-2.1-.3-3-.8l-29.5-17.7s0,0,0,0l-4-2.4c-1.1-.6-1.8-1.7-2.1-2.9-.3-1.2-.1-2.5.5-3.5l11-18.4c.3-.5.9-.6,1.4-.3.5.3.6.9.3,1.4l-11,18.4c-.4.6-.5,1.3-.3,2,.2.7.6,1.3,1.2,1.6l3.2,1.9,12.4-20.6c.1-.2.4-.4.6-.5.3,0,.5,0,.8.1l29.5,17.7c1.3.8,2.3,2.1,2.6,3.6s.1,3.1-.6,4.4l-8,13.3c-.8,1.3-2.1,2.3-3.6,2.6-.5.1-.9.2-1.4.2ZM17.6,2144.9l28.7,17.2c.9.5,1.9.7,2.9.4,1-.2,1.8-.9,2.3-1.7l8-13.3c.5-.9.7-1.9.4-2.9-.2-1-.9-1.8-1.7-2.3l-28.7-17.2-11.9,19.8Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M32.8,2156.1c-.2,0-.3,0-.5-.1-.5-.3-.6-.9-.4-1.4l12.5-21.7c.3-.5.9-.6,1.4-.4.5.3.6.9.4,1.4l-12.5,21.7c-.2.3-.5.5-.9.5Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M119.8,2154.4c-.1,0-.2,0-.3,0l-26.3-8.8c-.5-.2-.8-.7-.6-1.3l2.9-8.9c0-.3.3-.5.5-.6.2-.1.5-.1.8,0l19.9,6.7c.5.2.8.7.6,1.2l-1.4,5c0,.3-.2.5-.5.6-.2.1-.5.2-.8,0l-9.6-2.8c-.5-.2-.8-.7-.7-1.2.2-.5.7-.8,1.2-.7l8.7,2.5.9-3.1-18-6-2.3,7,24.4,8.2,3.7-11.1-17.7-5.9c-.5-.2-.8-.7-.6-1.3.2-.5.7-.8,1.3-.6l18.6,6.2c.5.2.8.7.6,1.3l-4.4,13c0,.3-.3.5-.5.6-.1,0-.3.1-.4.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M28.3,2222.3c-.3,0-.5,0-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5.1.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M317.6,2075.9h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3h21c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4s0,0,0,0c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2h-19v7.4h25.8v-11.7h-18.6c-.6,0-1-.4-1-1s.4-1,1-1h19.6c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M219.4,2273.9c-.3,0-.5-.1-.7-.3l-19.8-19.4c-.4-.4-.4-1,0-1.4l6.5-6.7c.2-.2.4-.3.7-.3.3,0,.5,0,.7.3l15,14.7c.4.4.4,1,0,1.4l-3.5,3.9c-.2.2-.4.3-.7.3-.3,0-.5,0-.7-.3l-7.5-6.7c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l6.7,6,2.2-2.4-13.6-13.3-5.1,5.3,18.4,18,8.2-8.4-13.3-13c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l14,13.7c.4.4.4,1,0,1.4l-9.6,9.8c-.2.2-.4.3-.7.3h0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M152.5,1999.2c-.2,0-.4,0-.6-.2-.2-.1-.4-.4-.4-.6l-2.6-13.5c-.1-.5.3-1.1.8-1.2l27.3-5.2c.5,0,1.1.2,1.2.8l1.8,9.2c0,.3,0,.5-.2.8-.1.2-.4.4-.6.4l-20.6,4c-.5,0-1-.2-1.2-.7l-1.2-5.1c0-.3,0-.5.1-.8.1-.2.4-.4.6-.5l9.8-2.3c.5-.1,1.1.2,1.2.7.1.5-.2,1.1-.7,1.2l-8.8,2.1.8,3.2,18.7-3.6-1.4-7.2-25.3,4.9,2.2,11.5,18.3-3.5c.5-.1,1.1.3,1.2.8.1.5-.3,1.1-.8,1.2l-19.3,3.7c0,0-.1,0-.2,0Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M237.1,2180.7c-.1,0-.3,0-.4,0l-8.6-3.6c-.2-.1-.4-.3-.5-.5s0-.5,0-.8l8.2-19.3c.2-.5.8-.7,1.3-.5l4.9,1.8c.3,0,.5.3.6.5.1.2.1.5,0,.8l-3.6,9.4c-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3l3.2-8.4-3-1.1-7.4,17.5,6.8,2.9,10.1-23.7-10.8-4.6-7.3,17.1c-.2.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.3l7.7-18.1c.2-.5.8-.7,1.3-.5l12.6,5.4c.2.1.4.3.5.5s0,.5,0,.8l-10.9,25.5c-.2.4-.5.6-.9.6Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M10.9,2293.4h-27.8c-.6,0-1-.4-1-1v-9.4c0-.3,0-.5.3-.7.2-.2.4-.3.7-.3H4c.5,0,1,.4,1,1l.3,5.2c0,.3,0,.5-.3.7-.2.2-.4.3-.7.3l-10,.4c-.5,0-1-.4-1-1,0-.6.4-1,1-1l9-.4-.2-3.2H-15.9v7.4H9.9v-11.7H-8.7c-.6,0-1-.4-1-1s.4-1,1-1H10.9c.6,0,1,.4,1,1v13.7c0,.6-.4,1-1,1Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M12.3,1995.9c-.4,0-.7-.2-.9-.5-.3-.5,0-1.1.4-1.4l18-9.7-18-9.7c-.5-.3-.7-.9-.4-1.4s.9-.7,1.4-.4l19.7,10.6c.3.2.5.5.5.9s-.2.7-.5.9l-19.7,10.6c-.2,0-.3.1-.5.1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,1989.8h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M5.7,1980.7h-31.8c-.6,0-1-.4-1-1s.4-1,1-1H5.7c.6,0,1,.4,1,1s-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M21.1,1991.1c-.6,0-1-.4-1-1v-11.6c0-.6.4-1,1-1s1,.4,1,1v11.6c0,.6-.4,1-1,1Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-38.6,1996.1c-2.5,0-6.2-.6-9-3.6-4.5-4.7-4.4-12.7.2-17,4.5-4.3,10.8-2.9,11.5-2.7.4.1.8.5.8,1v20.1h8v-20.1c0-.6.4-1,1-1H12.3c.4,0,.8.3.9.6s0,.8-.3,1.1l-5.6,5.2,5.5,3.8c.3.2.4.5.4.8s-.2.6-.4.8l-5.5,3.8,5.6,5.2c.4.4.4,1,0,1.4-.4.4-1,.4-1.4,0l-6.6-6c-.2-.2-.3-.5-.3-.8,0-.3.2-.6.4-.8l5.4-3.7-5.4-3.7c-.2-.2-.4-.5-.4-.8,0-.3,0-.6.3-.8l4.7-4.3H-25.1v20.1c0,.6-.4,1-1,1h-9.9c-.4,0-1.3.2-2.5.2ZM-39.2,1974.4c-2,0-4.8.5-6.9,2.5-3.7,3.6-3.8,10.4-.1,14.2,3,3.1,7.3,3.1,9,2.9v-19.4c-.5,0-1.2-.2-2-.2Z"/>
    </g>
    <g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M262.6,1973.5c-.2,0-.5,0-.7-.3-1.6-1.5-2.4-3.7-2.2-5.9.2-1.9,1.2-3.5,2.6-4.7,1.5-1.2,3.3-1.7,5.2-1.5,3.2.4,5.7,2.9,6.1,6.1,0,.5-.3,1-.9,1.1-.5,0-1-.3-1.1-.9-.3-2.3-2.1-4.1-4.4-4.4-1.3-.2-2.6.2-3.7,1-1,.8-1.7,2-1.9,3.3-.2,1.6.4,3.1,1.5,4.2.4.4.4,1,0,1.4-.2.2-.5.3-.7.3Z"/>
      <path class={`cls-1${isDark?'-dark':''}`} d="M258.9,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
    </g>
    <g>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M170.6,1975.8c-.1,0-.3,0-.4,0-.5-.2-.8-.8-.5-1.3l29.9-72.3-99.4-41.1-29.9,72.3c-.2.5-.8.8-1.3.5-.5-.2-.8-.8-.5-1.3l30.3-73.3c.1-.2.3-.4.5-.5.2-.1.5-.1.8,0l101.3,41.9c.5.2.8.8.5,1.3l-30.3,73.3c-.2.4-.5.6-.9.6Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M165.1,1973.5c-.2,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4.4-.4.7-.9.9-1.5,1.1-2.5-.2-5.5-2.7-6.5-2.6-1.1-5.5.2-6.5,2.7-.2.5-.3,1.1-.4,1.7,0,.6-.5,1-1,1-.6,0-1-.5-1-1,0-.8.2-1.6.5-2.4.7-1.7,2.1-3.1,3.8-3.8,1.7-.7,3.6-.7,5.4,0,3.6,1.5,5.3,5.6,3.8,9.1-.3.7-.7,1.4-1.3,2-.2.2-.5.3-.7.3Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M-69.5,1975.4c-.4,0-.7-.2-.9-.5l-35.8-70.7c-.1-.2-.1-.5,0-.8,0-.3.3-.5.5-.6l97.7-49.5c.5-.3,1.1,0,1.3.4l35.8,70.7c.2.5,0,1.1-.4,1.3-.5.3-1.1,0-1.3-.4l-35.4-69.8-96,48.6,35.4,69.8c.2.5,0,1.1-.4,1.3-.1,0-.3.1-.5.1Z"/>
      <g>
        <path class={`cls-1${isDark?'-dark':''}`} d="M224.7,1985.6s0,0-.1,0l-15.1-1.7c-.3,0-.5-.2-.7-.4-.6-.8-2.3-2.6-5.2-3-.5,0-.9-.6-.8-1.1,0-.5.6-.9,1.1-.8,3.2.5,5.3,2.3,6.2,3.4l14.7,1.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M196.8,1982.4s0,0-.1,0l-13.1-1.5c-.3,0-.5-.2-.7-.4-.2-.2-.2-.5-.2-.7l3.4-29.4c0-.5.6-.9,1.1-.9l13.1,1.5c.3,0,.5.2.7.4.3.4,3,3.5,7,3.2,2-.1,3.5-1.1,4.4-1.8.2-.2.5-.2.7-.2l15.1,1.7c.5,0,.9.6.9,1.1s-.6.9-1.1.9l-14.7-1.7c-1.2.9-2.9,1.8-5.2,2-4.4.3-7.4-2.5-8.4-3.6l-11.7-1.3-3.2,27.4,12.1,1.4c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M225.9,1975.6s0,0-.1,0l-41-4.7c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l41,4.7c.5,0,.9.6.9,1.1,0,.5-.5.9-1,.9Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M204.5,1970.4c-.2,0-.3,0-.5,0-1.2-.1-2.2-.7-2.9-1.6-.7-.9-1-2.1-.9-3.2.1-1.2.7-2.2,1.6-2.9.9-.7,2-1,3.2-.9,2.4.3,4.1,2.4,3.8,4.8-.3,2.2-2.1,3.9-4.3,3.9ZM204.5,1963.7c-.5,0-1,.2-1.5.5-.5.4-.8,1-.9,1.6,0,.6.1,1.2.5,1.7.4.5,1,.8,1.6.9,1.3.1,2.5-.8,2.6-2.1.1-1.3-.8-2.5-2.1-2.6,0,0-.2,0-.3,0Z"/>
        <path class={`cls-1${isDark?'-dark':''}`} d="M218.3,1974.8s0,0-.1,0c-.5,0-.9-.6-.9-1.1l1.3-11.7-32.5-3.8c-.5,0-.9-.6-.9-1.1,0-.5.6-.9,1.1-.9l33.5,3.9c.5,0,.9.6.9,1.1l-1.5,12.7c0,.5-.5.9-1,.9Z"/>
      </g>
      <path class={`cls-1${isDark?'-dark':''}`} d="M37.7,1979.1c-.2,0-.4,0-.6-.2-.4-.3-.5-1-.2-1.4l9.2-11.5c-.1-1.4,0-3.5,1.1-5.5,2.1-3.9,6-5,7.5-5.2l7.4-9.2-21.5-17.2-7.6,9.5c-.3.4-1,.5-1.4.2-.4-.3-.5-1-.2-1.4l8.2-10.3c.2-.2.4-.3.7-.4.3,0,.5,0,.7.2l23.1,18.5c.4.3.5,1,.2,1.4l-8.2,10.3c-.2.2-.4.3-.6.4-.5,0-4.5.7-6.4,4.3-.9,1.8-1,3.6-.8,4.7,0,.3,0,.5-.2.7l-9.5,11.8c-.2.2-.5.4-.8.4Z"/>
    </g>
  </g>
</StyledSVG>
    )
}

export default BGComponent;