

import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconCourse(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
    <g>
      <g id="Layer_1">
        <path d="M136.2,44.6c1.2.4,2.5.6,3.7,1.1,5.4,2.2,9.1,7.5,9.1,13.3v44.2h-22.7v52.2c-.5-.2-.8-.4-1.2-.5-11.8-5.9-23.6-11.8-35.4-17.7-.8-.4-1.3-.4-2.1,0-11.8,5.9-23.5,11.8-35.3,17.7-.4.2-.7.3-1.2.6,0-.4,0-.8,0-1.1V59.5c0-7.5,5-13.5,12.3-14.8.2,0,.3,0,.5-.2,24.1,0,48.3,0,72.4,0ZM122.2,51h-55.9c-5.4,0-8.8,3.4-8.8,8.8v85.1c.5-.2.9-.4,1.2-.6,9.6-4.8,19.2-9.6,28.7-14.4.9-.4,1.5-.4,2.4,0,9.5,4.8,19.1,9.6,28.6,14.3.4.2.8.4,1.2.6V61.1c.1-3.5.5-6.8,2.6-10.1ZM142.4,96.7c0-.2.1-.3.1-.5v-37.4c0-2.3-1-4.2-2.7-5.7-2.6-2.2-5.5-2.8-8.7-1.4-3.2,1.4-4.9,4-4.9,7.4v36.8c0,.2,0,.5,0,.7,5.4.1,10.7.1,16.1.1Z"/>
      </g>
    </g>
  </StyledSVG>
  )
}