import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconTeam(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
      <g>
        <g id="Layer_1">
          <g>
            <path d="M117.4,70c0,17.5-14.2,31.8-31.8,31.8s-31.8-14.2-31.8-31.8,14.2-31.8,31.8-31.8,31.8,14.2,31.8,31.8Z"/>
            <path d="M38.2,143.3c0-17.1,13.8-30.9,30.9-30.9h33c17.1,0,30.9,13.8,30.9,30.9v6.2c0,6.8-5.5,12.4-12.4,12.4H50.5c-6.8,0-12.4-5.5-12.4-12.4v-6.2Z"/>
            <path d="M116.8,96.6c-1.4,1.6-1,4.2,1,4.8,2.4.8,5,1.2,7.7,1.2,14,0,25.4-11.4,25.4-25.4s-11.4-25.4-25.4-25.4-2.4,1.6-1.8,3c1.9,4.7,2.9,9.8,2.9,15.2,0,10.1-3.7,19.4-9.8,26.6Z"/>
            <path d="M142.3,145.2c0,1.7,1.4,3.1,3.1,3.1h10.3c3.4,0,6.2-2.8,6.2-6.2v-9.4c0-11.9-9.7-21.6-21.6-21.6h-4.8c-3,0-4.5,4.1-2.6,6.4,5.9,7,9.4,16,9.4,25.8v1.9Z"/>
          </g>
        </g>
      </g>
    </StyledSVG>
  )
}