import React, { useState, useEffect } from 'react'
import { history } from '../../history'
import './style.scss'
import { Link } from 'react-router-dom';
import { Menu, MenuItem, MenuButton,MenuDivider,MenuHeader } from '@szhsin/react-menu';

const Infos = (props) => {


  useEffect(() => {
    return () => {
    };
  }, []);
  return (
    <>
    {
                props.canShowTerms && 
                <div className="thoughtfully_terms_sec">
                    <div id="terms_close" className='close' onClick={() => {props.setCanShowTerms(false)}} ></div>
                    <div className="terms_content">
                        <div className="content_in">
                            <h2><u>Thoughtfully Terms & Conditions of Use</u></h2>
                            <p>Last Updated: 25 August 2024</p>
                            <h4>Welcome to Thoughtfully!</h4>
                            <p>Thank you for choosing Thoughtfully, a platform designed to enhance learning and engagement through interactive prompts and real-time feedback. By accessing or using Thoughtfully, you agree to comply with and be bound by the following Terms & Conditions (T&Cs). Please read them carefully.</p>
                            <h4>1. Acceptance of Terms</h4>
                            <p>1.1 <b>Agreement</b>: By accessing or using Thoughtfully, you accept and agree to be bound by these T&Cs and our Privacy Policy. If you do not agree with any part of these T&Cs, you should not use Thoughtfully.</p>
                            <p>1.2 <b>Modifications</b>: We may modify these T&Cs at any time. We will notify you of any significant changes via email or through the platform. Continued use of Thoughtfully after such changes will constitute your consent to the modified T&Cs.</p>
                            <h4>2. Eligibility and Account Responsibilities</h4>
                            <p>2.1 <b>Eligibility</b>: Thoughtfully is intended for use by teachers and students. Users must be at least 13 years old or the minimum age required by local law, whichever is higher, to create an account. Users under the age of 18 must have permission from a parent or legal guardian to use Thoughtfully.</p>
                            <p>2.2 <b>Account Creation</b>: When creating an account, you agree to provide accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
                            <p>2.3 <b>Account Security</b>: You agree to immediately notify us of any unauthorised use of your account or any other breach of security. Thoughtfully will not be liable for any loss or damage arising from your failure to comply with this provision.</p>
                            <h4>3. Use of the Platform</h4>
                            <p>3.1 <b>Permitted Use</b>: You may use Thoughtfully for lawful purposes only. You agree not to use the platform in any way that could harm, disable, overburden, or impair the service or interfere with any other party’s use of the platform.</p>
                            <p>3.2 <b>Content Restrictions</b>: You are responsible for the content you create or share on Thoughtfully. You agree not to upload, post, or otherwise transmit content that:</p>
                            <ul>
                                <li>- Is unlawful, harmful, defamatory, obscene, or otherwise objectionable.</li>
                                <li>- Infringes on any intellectual property rights or other proprietary rights.</li>
                                <li>- Contains viruses, malware, or any other harmful code.</li>
                            </ul>
                            <p>3.3 <b>User Conduct</b>: You agree to use Thoughtfully in a manner that respects the rights and dignity of others. Bullying, harassment, or any form of abusive behaviour is strictly prohibited.</p>
                            <h4>4. Content Ownership and Licence</h4>
                            <p>4.1 <b>User Content</b>: You retain ownership of the content you create or share on Thoughtfully. By posting content on Thoughtfully, you grant Jalan Journey Pte. Ltd. a worldwide, non-exclusive, royalty-free licence to use, reproduce, modify, adapt, publish, translate, and distribute such content for the purpose of operating, promoting, and improving the platform.</p>
                            <p>4.2 <b>Thoughtfully Content</b>: All content and materials on Thoughtfully, including but not limited to text, graphics, logos, and software, are the property of Jalan Journey Pte. Ltd. or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not copy, modify, distribute, or reverse engineer any part of the platform without our prior written consent.</p>
                            <h4>5. Privacy and Data Protection</h4>
                            <p>5.1 <b>Data Collection and Consent</b>: Thoughtfully collects and processes personal data in accordance with applicable data protection laws, including the General Data Protection Regulation (GDPR), the Personal Data Protection Act (PDPA) of Singapore, and the Children’s Online Privacy Protection Act (COPPA) of the United States. By using Thoughtfully, you consent to the collection, use, and sharing of your personal data as described in our Privacy Policy.</p>
                            <p>5.2 <b>Parental Consent</b>: Thoughtfully is intended for use by individuals aged 13 and above. If we become aware that we have collected personal data from a child under the age of 13, we will take steps to delete such data promptly. To comply with COPPA, if a parent or guardian wishes to provide consent for their child under 13 to use Thoughtfully, they must contact us to provide verifiable parental consent. Details on how to contact us are provided in Section 9 below.</p>
                            <p>5.3 <b>Purpose Limitation and Data Minimisation</b>: We only collect personal data that is necessary for the operation of Thoughtfully. The data will be used only for the specific purposes for which it was collected and will not be further processed in a manner incompatible with those purposes.</p>
                            <p>5.4 <b>Data Subject Rights</b>: In accordance with GDPR and PDPA, users have the right to:</p>
                            <ul>
                                <li>- <b>Access</b>: Request access to their personal data held by Thoughtfully.</li>
                                <li>- <b>Correction</b>: Request correction of inaccurate or incomplete personal data.</li>
                                <li>- <b>Erasure</b>: Request the deletion of their personal data, subject to legal and contractual restrictions.</li>
                                <li>- <b>Restriction</b>: Request the restriction of processing of their personal data under certain conditions.</li>
                                <li>- <b>Portability</b>: Request a copy of their personal data in a structured, commonly used, and machine-readable format.</li>
                            </ul>
                            <p>5.5 <b>Data Security</b>: We take appropriate technical and organisational measures to protect the security of your personal data against accidental or unlawful destruction, loss, alteration, unauthorised disclosure, or access. However, we cannot guarantee the absolute security of your data.</p>
                            <p>5.6 <b>Data Retention</b>: We retain your personal data only for as long as necessary to fulfil the purposes for which it was collected, including compliance with legal, accounting, or reporting requirements.</p>
                            <p>5.7 <b>International Data Transfers</b>: If we transfer your data outside of your home country, we will ensure that such transfers comply with applicable data protection laws, including GDPR’s requirement for adequate safeguards.</p>
                            <h4>6. Service Availability and Termination</h4>
                            <p>6.1 <b>Service Availability</b>: Thoughtfully does not guarantee that the platform will be available at all times or that it will be free from errors, interruptions, or delays. The platform may be temporarily or permanently suspended, shut down, or cease to operate at any time, with or without notice, due to technical issues, maintenance, or other reasons beyond our control.</p>
                            <p>6.2 <b>Limitation of Liability</b>: To the maximum extent permitted by law, Thoughtfully and its affiliates, officers, directors, employees, and agents shall not be liable for any direct, indirect, incidental, special, or consequential damages, including but not limited to loss of data, profits, or business opportunities, arising from your use of, or inability to use, the platform. This includes any damages resulting from service interruptions, technical issues, or the suspension or termination of the platform.</p>
                            <p>6.3 <b>Termination by Thoughtfully</b>: We reserve the right to suspend or terminate your account and access to Thoughtfully at any time, without notice, for conduct that we believe violates these T&Cs or is otherwise harmful to other users or the platform.</p>
                            <p>6.4 <b>Termination by You</b>: You may terminate your account at any time by contacting us or following the instructions provided in your account settings. Upon termination, your right to use Thoughtfully will cease immediately.</p>
                            <p>6.5 <b>Survival</b>: Provisions of these T&Cs that by their nature should survive termination shall survive, including but not limited to ownership provisions, warranty disclaimers, and limitations of liability.</p>
                            <h4>7. Warranties and Liability</h4>
                            <p>7.1 <b>Disclaimer of Warranties</b>: Thoughtfully is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, about the operation or availability of the platform, or the accuracy, reliability, or completeness of any content available on Thoughtfully.</p>
                            <p>7.2 <b>Indemnification</b>: You agree to indemnify, defend, and hold harmless Jalan Journey Pte. Ltd. and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable legal fees, arising out of or in connection with your use of the platform, your content, or any violation of these T&Cs.</p>
                            <p>7.3 <b>Limitation of Liability</b>: To the maximum extent permitted by law, Jalan Journey Pte. Ltd. and its affiliates, officers, directors, employees, and agents shall not be liable for any direct, indirect, incidental, special, or consequential damages, including but not limited to loss of data, profits, or business opportunities, arising from your use of, or inability to use, the platform. This includes any damages resulting from service interruptions, technical issues, or the suspension or termination of the platform.</p>
                            <h4>8. Governing Law and Dispute Resolution</h4>
                            <p>8.1 <b>Governing Law</b>: These T&Cs are governed by and construed in accordance with the laws of Singapore, without regard to its conflict of law principles.</p>
                            <p>8.2 <b>Dispute Resolution</b>: Any disputes arising out of or relating to these T&Cs shall be resolved through binding arbitration in Singapore in accordance with the rules of the Singapore International Arbitration Centre (SIAC). If arbitration is not available or applicable, disputes shall be resolved in the courts of Singapore.</p>
                            <h4>9. Contact Information</h4>
                            <p>If you have any questions about these T&Cs, please contact us at:</p>
                            <ul>
                                <li>- <b>Email</b>: info@jalanjourney.com</li>
                                <li>- <b>Address</b>: Jalan Journey Pte. Ltd., 68 Circular Road, #02-01, Singapore (049422)</li>
                            </ul>
                            <p>Thank you for using Thoughtfully!</p>
                            <p>By using Thoughtfully, you acknowledge that you have read, understood, and agree to these Terms & Conditions.</p>
                        </div>
                    </div>
                </div>
            }
            {
                props.canShowPrivacyPolicy && <div className="thoughtfully_privacy_sec">
                    <div id="privacy_close" className='close' onClick={() => {props.setCanShowPrivacyPolicy(false)}} ></div>
                    <div className="privacy_content">
                    <div className="content_in">
                        <h2><u>Thoughtfully Privacy Policy</u></h2>
                        <p>Last Updated: 25 August 2024</p>
                        <h4>Welcome to Thoughtfully!</h4>
                        <p>Jalan Journey Pte. Ltd. ("we", "us", or "our") is committed to protecting your privacy and ensuring that your personal data is handled with the utmost care. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform, Thoughtfully. By accessing or using Thoughtfully, you consent to the practices described in this Privacy Policy.</p>
                        <h4>1. Information We Collect</h4>
                        <p>1.1 <b>Personal Data</b>: When you create an account or use Thoughtfully, we may collect personal data, including but not limited to your name, email address, contact details, and any other information you provide.</p>
                        <p>1.2 <b>Usage Data</b>: We collect information about your interactions with Thoughtfully, including but not limited to your IP address, browser type, operating system, access times, and pages you have viewed.</p>
                        <p>1.3 <b>Content Data</b>: We collect and process the content you create or share on Thoughtfully, including responses to prompts, feedback, and any other information you input into the platform.</p>
                        <p>1.4 <b>Cookies and Tracking Technologies</b>: We use cookies and similar technologies to collect and store information about your preferences and usage patterns. You can manage your cookie preferences through your browser settings.</p>
                        <h4>2. How We Use Your Information</h4>
                        <p>2.1 <b>To Provide and Improve Our Services</b>: We use your personal data to operate, maintain, and improve Thoughtfully, including to personalise your experience and respond to your queries.</p>
                        <p>2.2 <b>To Communicate with You</b>: We may use your contact details to send you updates, notifications, and other information related to Thoughtfully, including changes to our policies or services.</p>
                        <p>2.3 <b>For Research and Analytics</b>: We may use aggregated and anonymised data to analyse trends, understand user behaviour, and improve our services.</p>
                        <p>2.4 <b>For Legal Compliance</b>: We may use your data to comply with legal obligations, resolve disputes, enforce our agreements, and protect our rights and the rights of others.</p>
                        <h4>3. How We Share Your Information</h4>
                        <p>3.1 <b>Service Providers</b>: We may share your information with third-party service providers who perform services on our behalf, such as hosting, data analysis, and customer support. These providers are obligated to protect your information and may only use it as necessary to perform their services.</p>
                        <p>3.2 <b>Legal Requirements</b>: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., subpoenas or court orders).</p>
                        <p>3.3 <b>Business Transfers</b>: In the event of a merger, acquisition, or other business transaction, your information may be transferred to the acquiring entity, subject to the same privacy protections described in this Privacy Policy.</p>
                        <p>3.4 <b>With Your Consent</b>: We may share your information with third parties if you have provided your consent to do so.</p>
                        <h4>4. Data Security</h4>
                        <p>4.1 <b>Measures</b>: We implement appropriate technical and organisational measures to protect your personal data against unauthorised access, alteration, disclosure, or destruction.</p>
                        <p>4.2 <b>Limitations</b>: While we strive to protect your data, no security system is impenetrable. We cannot guarantee the absolute security of your information and disclaim any liability for any unauthorised access or use.</p>
                        <h4>5. Data Retention</h4>
                        <p>5.1 <b>Retention Period</b>: We retain your personal data only for as long as necessary to fulfil the purposes for which it was collected, including to comply with legal, accounting, or reporting requirements.</p>
                        <p>5.2 <b>Deletion Requests</b>: You may request the deletion of your personal data by contacting us, subject to legal and contractual restrictions.</p>
                        <h4>6. Your Rights</h4>
                        <p>6.1 <b>Access and Correction</b>: You have the right to access and correct your personal data held by us.</p>
                        <p>6.2 <b>Erasure</b>: You may request the deletion of your personal data, subject to applicable legal obligations.</p>
                        <p>6.3 <b>Restriction and Portability</b>: You may request the restriction of processing of your personal data or obtain a copy of your data in a structured, commonly used, and machine-readable format.</p>
                        <p>6.4 <b>Objections</b>: You may object to the processing of your personal data under certain circumstances.</p>
                        <h4>7. Children's Privacy</h4>
                        <p>7.1 <b>Age Requirement</b>: Thoughtfully is intended for use by individuals aged 13 and above. We do not knowingly collect personal data from children under the age of 13 without verifiable parental consent.</p>
                        <p>7.2 <b>Parental Rights</b>: Parents or guardians may review, correct, or delete their child’s personal data by contacting us.</p>
                        <h4>8. Changes to This Privacy Policy</h4>
                        <p>8.1 <b>Modifications</b>: We may update this Privacy Policy from time to time. We will notify you of significant changes via email or through the platform. Continued use of Thoughtfully after such changes will constitute your consent to the modified Privacy Policy.</p>
                        <h4>9. Contact Us</h4>
                        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                        <ul>
                            <li>- <b>Email</b>: info@jalanjourney.com</li>
                            <li>- <b>Address</b>: Jalan Journey Pte. Ltd., 68 Circular Road, #02-01, Singapore (049422)</li>
                        </ul>
                        <p><b>Thank you for using Thoughtfully!</b></p>
                        <p>By using Thoughtfully, you acknowledge that you have read, understood, and agree to this Privacy Policy.</p>
                    </div>
                    </div>
                </div>
            }
            {
                props.canShowCookie && <div className="cookie_sec">
                    <div id="cookie_close" className='close' onClick={() => {props.setCanShowCookie(false)}} ></div>
                    <div className="cookie_content">
                        <div className="content_in">
                            <h2><u>Cookies Notice</u></h2>
                            <p>Last Updated: 25 August 2024</p>
                            <p>1. <b>What Are Cookies?</b> Cookies are small text files placed on your device when you visit a website. They help us to improve your experience by remembering your preferences and enabling certain functionalities.</p>
                            <p>2. <b>How We Use Cookies</b> We use cookies and similar technologies for the following purposes:</p>
                            <ul>
                                <li><b>-  Essential Cookies</b>: These cookies are necessary for the operation of Thoughtfully. They enable you to navigate the platform and use its features, such as logging in and accessing secure areas.</li>
                                <li><b>-  Performance Cookies</b>: These cookies collect information about how you use Thoughtfully, such as which pages you visit most often. They help us to improve the platform by understanding user behaviour and performance.</li>
                                <li><b>-  Functional Cookies</b>: These cookies allow Thoughtfully to remember your preferences and settings, such as your language choice, to provide a more personalised experience.</li>
                                <li><b>-  Analytics Cookies</b>: We use cookies to gather data on user interactions and performance, which helps us to analyse trends and improve the functionality of Thoughtfully.</li>
                            </ul>
                            <p>3. <b>Third-Party Cookies</b> We may also use third-party cookies from partners to help us analyse website usage, deliver targeted advertising, and enhance our services. These third-party cookies are governed by their own privacy policies.</p>
                            <p>4. <b>Managing Cookies</b> You can manage your cookie preferences through your browser settings. Most browsers allow you to refuse cookies or delete them from your device. However, if you disable cookies, some features of Thoughtfully may not function properly.</p>
                            <p>5. <b>Your Consent</b> By continuing to use Thoughtfully, you consent to the use of cookies as described in this notice. If you do not agree to our use of cookies, you should adjust your browser settings or stop using the platform.</p>
                            <p>6. <b>Changes to This Cookies Notice</b> We may update this Cookies Notice from time to time. Any changes will be posted on this page, and we will notify you of significant updates through the platform. Your continued use of Thoughtfully after any changes indicates your acceptance of the updated notice.</p>
                            <p>7. <b>Contact Us</b> If you have any questions about this Cookies Notice, please contact us at: Email: info@jalanjourney.com Address: Jalan Journey Pte. Ltd., 68 Circular Road, #02-01, Singapore (049422)</p>
                            <p><b>Thank you for using Thoughtfully!</b></p>
                        </div>
                    </div>
                </div>
            }
    </>
  )
}

export default Infos;