import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconPlusThick(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
      <g>
        <g id="Layer_1">
          <path class="" d="M153.5,107.3c0,4-3.3,7.3-7.3,7.3h-31.6v31.6c0,4-3.3,7.3-7.3,7.3h-14.6c-4,0-7.3-3.3-7.3-7.3v-31.6h-31.6c-4,0-7.3-3.3-7.3-7.3v-14.6c0-4,3.3-7.3,7.3-7.3h31.6v-31.6c0-4,3.3-7.3,7.3-7.3h14.6c4,0,7.3,3.3,7.3,7.3v31.6h31.6c4,0,7.3,3.3,7.3,7.3v14.6Z"/>
        </g>
      </g>
    </StyledSVG>
  )
}