import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconShared(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
      <g>
        <g id="Layer_1">
          <g>
            <path d="M45.3,56.7v77.4c0,11.9,9.6,21.5,21.5,21.5h29.1c.4-3,1.3-5.9,2.5-8.6,3.5-7.7,9.8-13.8,17.6-16.9-5.7-4-9.1-10.5-9.1-17.5,0-11.8,9.6-21.4,21.4-21.4,2.1,0,4.1.3,6.1.9,3.2,1,6.2,2.7,8.6,5v-40.4c0-11.9-9.6-21.5-21.5-21.5h-54.8c-11.9,0-21.5,9.6-21.5,21.5h0ZM71.4,56.6h45.6c2.4,0,4.3,1.9,4.3,4.3s-1.9,4.3-4.3,4.3h-45.6c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3ZM71.4,79.6h45.6c2.4,0,4.3,1.9,4.3,4.3s-1.9,4.3-4.3,4.3h-45.6c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3ZM71.4,102.5h20.7c2.4,0,4.3,1.9,4.3,4.3s-1.9,4.3-4.3,4.3h-20.7c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3Z"/>
            <circle cx="128.3" cy="112.6" r="14.9"/>
            <path d="M106.3,164.8h44.1c2.4,0,4.3-1.9,4.3-4.3,0-14.6-11.8-26.4-26.4-26.4-14.6,0-26.4,11.8-26.4,26.4,0,2.4,1.9,4.3,4.3,4.3Z"/>
          </g>
        </g>
      </g>
    </StyledSVG>
  )
}