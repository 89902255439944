import React, { useEffect } from "react";
import PropTypes from "prop-types";
import StudentProfileIcon from "../studentProfileIcon";

const Collapsible = ({ title, children, users , ...props}) => {
    const [
    isExpanded,
    setIsExpanded
  ] = React.useState(props.isOpen||false);

  const ref = React.useRef();

  const [height, setHeight] = React.useState();

  const handleToggle = e => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
    setHeight(ref.current.clientHeight+10);
  };

  const classes = `list-group-item ${
    isExpanded ? "is-expanded" : null
  }`;
  useEffect(() => {
    if(props.isOpen){
      setHeight(ref.current.clientHeight+10);
    }
   }, [])
  const currentHeight = isExpanded ? height : 0;
  return (
    <div
      className={classes}
      onClick={handleToggle}
    >
      <div className="card-title">
        {
          props.titleInfo!=undefined && <div className="info_sec">{props.titleInfo}</div>
        }
        <h2>{title}</h2>
        {users && users.length>0 && <>
            <div className="profiles">
                {
                    users.map((user, index) => (
                        index<3 && <StudentProfileIcon key={user.id} id={user.id} imgUrl={user.img} name={user.name} showName={false} showPhoto={true} extraProfileStyles={{margin:'-1vw', borderRadius:'10vw'}}/>
                    ))
                }
            </div>
        </>}
      </div>
      <div
        className="card-collapse"
        style={{ height: currentHeight + "px" }}
      >
        <div className="card-body" ref={ref}>
          {children}
        </div>
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  title: PropTypes.string
};

export default Collapsible;
