import React, {useEffect, useState} from 'react';
import AppWrapper from '../../containers/app-wrapper';
import QRCode from 'qrcode.react'; // Import qrcode.react
import '../../styles/landing.scss';
import ColyseusComponent from '../../common/socket'
import { Global } from '../../common/global';
import { saveSessionID } from '../../common/api';
import { initAudio, playAudio, playLobbyAudio } from '../../components/AudioContext';


const LandingPage = (props) => {


  const [studentURL, setStudentURL] = useState(`${window.location.protocol}//${window.location.host}/student/${Global.gameID}/${Global.gameMode=="anonymous"?'avatar':'name'}`);
  const [canvasWidth, setCanvasWidth]= useState(Math.min(.2*window.innerWidth, .3*window.innerHeight));
  const [canCountDown, setCanCountDown]= useState(false);
  const [countDown, setCountDown]= useState(10);


  const updateWaitTime= (data)=>{

    setCanCountDown(data['wait_time']!==0);
    // setCountDown(data['timeInSeconds']);
    setCountDown(data['wait_time']);
  };
  const handleResize = () => {
    setCanvasWidth(Math.min(.2*window.innerWidth, .3*window.innerHeight));
   
  };
  const getUrlParameter= (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? false : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
    const {connect} = ColyseusComponent({
        "initClient": true
    });
  useEffect(() => {

    initAudio();
    playLobbyAudio();

    const requestWakeLock= async () =>{
      let wakeLock = null;
      try {
        wakeLock = await navigator.wakeLock.request('screen');
        console.log('Wake Lock is active.');
        wakeLock.addEventListener('release', () => {
          console.log('Wake Lock was released.');
        });
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    }

    const fetchData = async () => {
      try{
        const connectionStats= await connect();
        setStudentURL(`${window.location.protocol}//${window.location.host}/student/${Global.gameID}/avatar`);
        const clientID= getUrlParameter("clientID");
        await saveSessionID({
          clientID: clientID, 
          sessionId: Global.sessionId
        });
        
      }catch(err){
      }
     
  
    }
    fetchData();
    requestWakeLock();
    Global.addEventListener("common:game_state_update", updateWaitTime);

    // Global.addEventListener("time:update_wait_time", updateWaitTime);
    window.addEventListener('resize', handleResize);

 
  
    return () => {
      window.removeEventListener('resize', handleResize);
      Global.removeEventListener("common:game_state_update", updateWaitTime);

      // Global.removeEventListener("time:update_wait_time", updateWaitTime);
    };
  }, []);


  return (
    <AppWrapper extraClass={"landscape_only"} gameData={props.gameData}>
      <div className="bg_cover"><img src="/images/BG.svg" alt="" srcset="" /></div>
      <div className="code_screen">
        <div className="left">
          <div className="video_sec">
              <img src="/images/LogoBig.png" alt="" srcset="" />
          </div>
          <div className="landing_code_sec">
            <div className="title">Use Code</div>
            <div className="code_area">{Global.gameID}</div>
          </div>
        </div>
        <div className="right">
          <div className="logo">
            <img src="/images/Logo.png" alt="" />
          </div>
          <div className={canCountDown?'qr_sec countdown':'qr_sec'}>
            <div className={canCountDown?'title countdown':'title'}>{canCountDown?'WE WILL BEGIN IN':'SCAN QR CODE TO BEGIN'}</div>
            <div className="code_container">
              { !canCountDown && <>
                <QRCode value={studentURL} size={canvasWidth}/>
                <div className="student_url">http{location.protocol.split('http')[1]}//{location.host}</div>
              </>
              
              }
              { canCountDown && <><div className='countdown_sec'>{countDown}</div><div className='countdown_head'>SECONDS</div></> }
            </div>
          </div>
        </div>
      </div>
      <div id="rotate">
        <img src="/images/tilt.png" alt="" srcset="" />
      </div>
    </AppWrapper>
  );
};

export default LandingPage;
