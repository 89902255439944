import React, { useState, useEffect } from 'react'
import './style.scss'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Infos from '../Infos';

const Copyright = (props) => {
  const [darkModeClass, setdarkModeClass ]= useState(
    localStorage.getItem('darkMode') == 'true'?'darkMode':''
  )
  const [canShowTerms, setCanShowTerms]= useState(false);
  const [canShowPrivacyPolicy, setCanShowPrivacyPolicy]= useState(false);
  const [canShowCookie, setCanShowCookie]= useState(false);
  
  useEffect(() => {
    return () => {
    };
  }, []);
  return (
    <div className={`copyright ${props.darkModeClass!=undefined?props.darkModeClass:darkModeClass}`}>
      <div className="inline">
        <div className="title">By using Thoughtfully, you agree to our</div>
        <div className="links">
                      <u className='link' onClick={setCanShowTerms.bind(this, true)}>Terms</u>
                      <u className='link' onClick={setCanShowPrivacyPolicy.bind(this, true)} id="privacy">Privacy</u>
                      <u className='link' onClick={setCanShowCookie.bind(this, true)}>Cookies Notice</u>
                      
        </div>
      </div>
      <div>
        ©️ 2024 Jalan Journey. All Rights Reserved.
      </div>
      <Infos 
                canShowTerms={canShowTerms} 
                canShowPrivacyPolicy={canShowPrivacyPolicy} 
                canShowCookie={canShowCookie} 
                setCanShowTerms={setCanShowTerms} 
                setCanShowPrivacyPolicy={setCanShowPrivacyPolicy} 
                setCanShowCookie={setCanShowCookie}
      />
    </div>
  )
}

export default Copyright;