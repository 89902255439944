import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconSettings(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
      <g>
        <g id="Layer_1">
          <path d="M87.2,39.2h25.6c2.7.9,4.1,2.8,4.4,5.7.5,4,1.2,8,1.7,12,0,.8.4,1.1,1.1,1.5,2.1,1.1,4.1,2.3,6.1,3.6.6.4,1.1.4,1.7.2,3.8-1.6,7.7-3.1,11.5-4.6,3.5-1.4,5.9-.6,7.8,2.7,3.6,6.3,7.3,12.6,10.9,18.9,1.9,3.2,1.3,5.9-1.6,8.2-3.2,2.5-6.5,5-9.7,7.6-.4.3-.7.9-.7,1.4-.1,2.5-.1,5,0,7.5,0,.5.3,1.1.7,1.4,3.3,2.6,6.6,5.2,9.9,7.8,2.7,2.2,3.3,4.8,1.5,7.9-3.7,6.4-7.4,12.8-11.1,19.2-1.8,3.1-4.3,3.9-7.6,2.6-3.9-1.6-7.8-3.1-11.7-4.6-.4-.2-1.1-.2-1.5,0-2.3,1.2-4.5,2.5-6.7,3.9-.3.2-.6.7-.7,1.1-.7,4.3-1.3,8.5-1.9,12.8-.5,3.2-2.5,5.1-5.8,5.1h-22.7c-3.2,0-5.3-1.9-5.8-5.1-.6-4.3-1.2-8.5-1.9-12.8,0-.4-.4-.9-.8-1.1-2.1-1.3-4.3-2.6-6.4-3.7-.4-.2-1.1-.3-1.6-.1-3.9,1.5-7.7,3.1-11.6,4.6-3.5,1.4-5.9.6-7.8-2.7-3.6-6.3-7.3-12.6-10.9-18.9-1.9-3.3-1.4-5.9,1.6-8.2,3.2-2.5,6.5-5,9.7-7.6.4-.3.7-.9.7-1.4,0-2.5,0-5,0-7.5,0-.5-.3-1.1-.7-1.4-3.2-2.6-6.5-5.1-9.8-7.7-2.9-2.2-3.4-4.9-1.6-8,3.7-6.4,7.3-12.7,11-19.1,1.8-3.1,4.3-4,7.6-2.6,3.9,1.6,7.8,3.2,11.8,4.7.4.2,1.1,0,1.5,0,2.2-1.2,4.3-2.5,6.4-3.8.4-.2.7-.7.8-1.1.6-3.8,1.2-7.6,1.6-11.5.8-3.4,1.9-5.8,5-6.8ZM120.2,100.1c0-11.2-8.9-20.3-20.1-20.3-11.2,0-20.3,8.9-20.3,20,0,11.2,8.9,20.3,20.1,20.4,11.2,0,20.3-8.9,20.3-20.1Z"/>
        </g>
      </g>
    </StyledSVG>
  )
}