import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/help.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import SideMenu from '../../components/dashboard/sidemenu';
import Button from '../../components/dashboard/button';
import Ads from '../../components/dashboard/Ad';
import { getProfile, sendEnqiury } from '../../common/api';
import IconProfile from '../../components/dashboard/icons/Icon-Profile';
import IconEmail from '../../components/dashboard/icons/Icon-Email';
import Copyright from '../../components/dashboard/copyright';

const HelpPage = (props) => {
    const [darkModeClass, setdarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      )
    const [fullName, setFullName]= useState('');
    const [email, setEmail]= useState('');
    const [subject, setSubject]= useState('');
    const [message, setMessage]= useState('');
    const [showHelpSection, setShowHelpSection]= useState(true);
    const [showThanks, setShowThanks]= useState(false);


    const sendHelpInfo= async () => {

        setShowHelpSection(false);
        setShowThanks(true);
        await sendEnqiury({
            fullName,
            email,
            subject,
            message
        })
    }
  useEffect(() => {
    // setEmail(props.profileData?props.profileData.email:'');
    // setFullName(props.profileData?props.profileData.fullName:'');
    const getProfileInfo= async () =>{
        let res= await getProfile();
        setFullName(res.fullName);
        setEmail(res.email);
    }
    getProfileInfo();
  }, []);

  return (
        <DashboardApp extraClass={"landscape_only"} {...props.profileData}>
            <div className={`help_container ${darkModeClass}`}>
                <SideMenu/>
                <div className="middle_area">
                    <div className="middle_content">
                        <div className="head">
                            <div className="left">
                            <div className="title">REACH OUT TO US</div>
                            </div>
                        </div>
                        <div className="help_sec">
                        {
                            showHelpSection && <>
                            <div className='field'>
                                    <div className="icon"><IconEmail darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                    <input
                                        type="email"
                                        disabled
                                        name="help_email"
                                        id="help_email"
                                        value={`${email}`}
                                        onChange={(v)=>{setEmail(v.target.value)}}
                                        placeholder="Email"
                                        autocomplete="off"
                                    />
                                </div>
                                <div className='field'>
                                    <div className="icon"><IconProfile  darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                    <input
                                        type="text"
                                        disabled
                                        name="help_name"
                                        id="help_name"
                                        value={`${fullName||'Guest User'}`}
                                        onChange={(v)=>{setFullName(v.target.value)}}
                                        placeholder="From: Full Name"
                                        autocomplete="off"
                                    />
                                </div>
                                
                                <div className='field'>
                                    <div className="icon"></div>
                                    <input
                                        type="text"
                                        name="help_subject"
                                        id="help_subject"
                                        value={subject}
                                        onChange={(v)=>{setSubject(v.target.value)}}
                                        placeholder="Subject"
                                        autocomplete="off"
                                    />
                                </div>
                                <div className='field'>
                                <div className="icon"></div>


                                    <textarea
                                        type="text"
                                        name="help_message"
                                        id="help_message"
                                        style={{resize: 'none'}}
                                        value={message}
                                        onChange={(v)=>{setMessage(v.target.value)}}
                                        placeholder="Write your message here"
                                    />
                                </div>
                                <div className="info">Prefer to reach out directly? You can email us at info@jalanjourney.com</div>
                                <Button 
                                    className={`${subject.length==0||message.length==0?'disabled ':''}purple-purple bold-text`}
                                    style={{borderRadius:'15px', padding:'10px 75px'}}
                                    value="SEND"
                                    onClick={sendHelpInfo}
                                />
                            </>
                        }
                        {
                            showThanks && <>
                                <div className="thank_icon">
                                    <img src="/images/dashboard/icons/tick.png" alt="" srcset="" />
                                </div>
                                <div className="thank_label">
                                    Thanks for your inquiry, our team will get back to you soon.
                                </div>
                            </>
                        }
                            
                        </div>
                  
                    </div>
                    <Copyright/>
                </div>
                <Ads darkModeClass={darkModeClass}/>
            </div>
        </DashboardApp>
  );
}

export default HelpPage;