import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconFAQ(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
      <g>
        <g id="Layer_1">
          <g>
            <path d="M170.3,86.8c-1.4-7.7-8.4-14.3-16.1-15.5-3.5-.6-13-1.3-21.2-1.5.2,2.7.2,5.5.2,8.4h0c0,8-.6,15.2-1.8,22.1h0c-1,5.4-3.8,10.5-8,14.6-4.2,4-9.4,6.7-14.7,7.5-4.9.8-18.2,1.6-27.6,1.8h0c0,.7.2,1.2.3,1.8,1.4,7.7,8.4,14.3,16.1,15.5,4.6.8,19.6,1.7,28.3,1.6l9.9,15.7c1.4,2.2,4.4,2.9,6.6,1.5.6-.4,1.1-.9,1.5-1.5l11-17.4c7.5-1.4,14.3-7.9,15.6-15.4.8-4.6,1.5-11,1.6-19.6s-.8-14.9-1.6-19.6h0Z"/>
            <path d="M124.8,57.3c-1.5-8.2-9-15.3-17.2-16.6-5-.8-21.1-1.8-30.3-1.7-9.2,0-25.4.9-30.3,1.7-8.2,1.3-15.7,8.4-17.2,16.6-.9,5-1.7,11.7-1.7,20.9s.8,16,1.7,20.9c1.5,8,8.7,15,16.7,16.5l11.7,18.6c1.5,2.4,4.7,3.1,7,1.6.6-.4,1.2-.9,1.6-1.6l10.6-16.8c9.2,0,25.3-.9,30.2-1.7,8.2-1.3,15.7-8.4,17.2-16.6.8-5,1.7-11.7,1.7-20.9s-.8-16-1.7-20.9h0ZM82.1,89.1c0,2.6-2,4.7-4.5,4.7,0,0,0,0,0,0-8.2,0-16.4-.3-24.6-.5-2.6-.1-4.7-2.2-5-4.8-.2-2.6,1.9-4.6,4.5-4.5,8.3.2,16.6.3,24.9.4,2.6,0,4.7,2.2,4.8,4.8h0ZM101.3,73.1c-16.3,0-32.7,0-49-.2-2.6,0-4.8-2.2-4.9-4.7s2.1-4.6,4.7-4.6c16.4,0,32.8,0,49.2,0,2.6,0,4.7,2.1,4.8,4.7,0,0,0,0,0,0,0,2.6-2.2,4.8-4.8,4.8h0Z"/>
          </g>
        </g>
      </g>
    </StyledSVG>
  )
}