import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconEmail(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
      <g>
        <g id="Layer_1">
          <g>
            <path d="M33.5,63.4c.8-2.1,1.3-4.3,2.4-6.2,3.1-5.1,7.7-7.8,13.7-7.8,33.6,0,67.2,0,100.9,0,8.9,0,16,7.3,16,16.2v68.4c0,9.5-7.1,16.6-16.7,16.6h-58.5c-13.6,0-27.2-.1-40.8,0-9.3.1-15.8-6.6-16.8-13.7,0-.1,0-.2-.2-.3,0-24.4,0-48.8,0-73.2ZM100,143.1h49.9c5.3,0,9.2-4.2,9.2-9.2-.1-22.5-.1-45,0-67.5,0-5.5-3.9-9.6-9.7-9.6-32.9,0-65.9,0-98.8,0s-9.5,3.6-9.5,9.4v67.4c0,.9,0,1.7.2,2.6,1.1,4.3,4.4,6.8,8.9,6.8,16.6,0,33.2,0,49.8,0Z"/>
            <path d="M100.1,104.6c4.4-4,8.8-7.9,13.2-11.8,10.4-9.3,20.8-18.7,31.2-28,2.5-2.2,5.8-1.5,6.6,1.5.4,1.6-.2,2.9-1.4,3.9-4.7,4.2-9.4,8.4-14.1,12.7-6,5.4-12,10.8-18.3,16.4.6.5,1.2.8,1.7,1.3,10.2,9.7,20.4,19.4,30.6,29,.9.9,1.6,1.8,1.6,3.1s-.6,2.7-2,3.4-2.7.5-3.9-.3c-.5-.3-.9-.8-1.4-1.2l-31.5-30c-.2,0-.3-.3-.6-.5-2.6,2.3-5.2,4.6-7.8,7-.5.4-.9.8-1.4,1.2-1.7,1.4-3.6,1.4-5.3,0-2.6-2.3-5.1-4.6-7.6-6.8-.4-.3-.7-.6-1.2-1-.5.5-1,.9-1.5,1.4-10.2,9.5-20.3,19.1-30.5,28.6-.9.8-1.9,1.5-3,1.9-1.6.5-3.1-.2-4-1.7-.8-1.4-.7-3.1.4-4.3,1.2-1.3,2.6-2.5,3.9-3.8,9.5-8.9,19-17.8,28.4-26.6.2,0,.4-.2.7-.5-2.2-2-4.2-3.8-6.2-5.6-8.4-7.5-16.9-15.2-25.5-22.8-.4-.3-.7-.6-1.1-1-1.6-1.6-1.8-3.8-.4-5.3,1.4-1.6,3.6-1.7,5.3-.2,1.9,1.6,3.8,3.3,5.6,5,12.9,11.4,25.6,22.8,38.4,34.2.3.3.7.6,1.1.9Z"/>
          </g>
        </g>
      </g>
    </StyledSVG>
  )
}


