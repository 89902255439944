
import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconGender(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
      <g>
        <g id="Layer_1">
          <path d="M100,156.3c-15.1,0-29.2-5.9-39.8-16.5s-16.5-24.8-16.5-39.8,5.9-29.2,16.5-39.8c10.6-10.7,24.7-16.5,39.8-16.5s29.2,5.9,39.8,16.5c10.6,10.6,16.5,24.8,16.5,39.8s-5.9,29.2-16.5,39.8c-10.6,10.7-24.7,16.5-39.8,16.5ZM100,52.7c-26.1,0-47.3,21.2-47.3,47.3s21.2,47.3,47.3,47.3,47.3-21.2,47.3-47.3-21.2-47.3-47.3-47.3Z"/>
        </g>
      </g>
    </StyledSVG>
  )
}