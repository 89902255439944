import React, {  useEffect } from 'react'
import './style.scss'

const Nav = (props) => {
  
  useEffect(() => {
 
    return () => {
      
    };
  }, []);
  console.log(props.className,'props')
  return (
    <>
      <div className="nav">
        { !props.iconUrl && props.className.indexOf('active')!==-1 && <div className="nav_active"></div> }
        { props.iconUrl && <img src={props.iconUrl} alt="" className="icon" /> }
      </div>
    </>
  )
}

export default Nav
