import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconRecent(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
      <g>
        <g id="Layer_1">
          <path d="M100,155.7c-30.7,0-55.7-24.9-55.7-55.7s24.9-55.7,55.7-55.7,55.7,24.9,55.7,55.7-24.9,55.7-55.7,55.7ZM100,60.6c-21.7,0-39.4,17.7-39.4,39.4s17.7,39.4,39.4,39.4,39.4-17.7,39.4-39.4-17.7-39.4-39.4-39.4ZM109.3,107c0,1.3-1,2.3-2.3,2.3h-23.2c-1.3,0-2.3-1-2.3-2.3v-4.6c0-1.3,1-2.3,2.3-2.3h16.2v-25.5c0-1.3,1-2.3,2.3-2.3h4.6c1.3,0,2.3,1,2.3,2.3v32.5Z"/>
        </g>
      </g>
    </StyledSVG>
  )
}