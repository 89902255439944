import React, {useEffect, useState, useRef} from 'react';
import '../../styles/dashboard/profile.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import SideMenu from '../../components/dashboard/sidemenu';
import Button from '../../components/dashboard/button';
import { Link } from 'react-router-dom';
import { Global } from '../../common/global';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {DatePicker} from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { getProfile, saveProfileImg, updateProfile } from '../../common/api';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Ads from '../../components/dashboard/Ad';
import IconCamera from '../../components/dashboard/icons/Icon-Camera';
import IconProfile from '../../components/dashboard/icons/Icon-Profile';
import IconSchool from '../../components/dashboard/icons/Icon-School';
import IconGender from '../../components/dashboard/icons/Icon-Gender';
import IconDOB from '../../components/dashboard/icons/Icon-DOB';
import IconCourse from '../../components/dashboard/icons/Icon-Course';
import { history } from '../../components/history';
import IconLocation from '../../components/dashboard/icons/IconLocation';
import Copyright from '../../components/dashboard/copyright';


const ProfilePage = (props) => {
    const [darkModeClass, setdarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      )
    const [crop, setCrop] = useState(null);
    const [src, setSrc] = useState(null);
    const imgRef = useRef(null)
    const [cropData, setCropData]= useState({});
    const [canShowCropButton, setCanShowCropButton]= useState(false);


    const [fullName, setFullName]= useState('');
    const [profileUrl, setProfileUrl]= useState('');
    const [school, setSchool]= useState('');
    const [gender, setGender]= useState('male');
    const [dob, setDob]= useState('');
    const [country, setCountry]= useState('');
    const [city, setCity]= useState('');
    const [state, setState]= useState('');
    const [department, setDepartment]= useState('');
    const [canEdit, setCanEdit]= useState(false);
    const [genderOptions]= useState([
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'prefer_not_to_say', label: 'Prefer not to Say' } 
    ]);

    const [accounts]= useState([
        { value: 'individual', label: 'Individual' },
        { value: 'organisation', label: 'Organisation' },
        { value: 'institution', label: 'Institution' },
        { value: 'others', label: 'Others' } 
    ]);
    const [institutions]= useState([
        { value: 'school', label: 'School' },
        { value: 'university', label: 'University' }
    ]);
    const [organizations]= useState([
        { value: 'for_profit', label: 'For-profit' },
        { value: 'non_profit', label: 'Non-profit' },
        { value: 'government', label: 'Government' },
        { value: 'charity_or_ngo', label: 'Charity or NGO' },
        { value: 'others', label: 'Others' } 
    ]);
    const [accountType, setAccountType]= useState('');
    const [institutionType, setInstitutionType]= useState('');
    const [organizationType, setOrganizationType]= useState('');

      const onSelectFile= function(e) {
        if (e.target.files && e.target.files.length > 0) {
       
            const maxSizeMB = 20;
            if (e.target.files[0].size > maxSizeMB * 1024 * 1024) {
                alert(`File size exceeds ${maxSizeMB} MB limit.`);
                return false;
            }

          const reader = new FileReader()
          reader.addEventListener('load', (e) =>{
        //   setSrc(reader.result?.toString() || ''),
                const img = new Image();
                img.onload = async function() {
                    // Get the canvas and context
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    
                    // Define the maximum width
                    const maxWidth = 1024;
                    
                    // Calculate the new dimensions while maintaining the aspect ratio
                    let width = img.width;
                    let height = img.height;
                    
                    if (width > maxWidth) {
                      const scaleFactor = maxWidth / width;
                      width = maxWidth;
                      height = height * scaleFactor;
                    }
                    
                    // Set canvas dimensions to the new dimensions
                    canvas.width = width;
                    canvas.height = height;
                    
                    // Draw the image on the canvas with the new dimensions
                    ctx.drawImage(img, 0, 0, width, height);
                    
        
                    // Convert canvas to PNG and set download link
                    const pngDataUrl = canvas.toDataURL('image/png');
                    const base64String = pngDataUrl;//e.target.result;//.split(',')[1];
                    
                    await updateProfile({
                        fullName,
                        accountType,
                        organizationType:(accountType=='organisation'?organizationType:null),
                        institutionType:(accountType=='institution'?institutionType:null),
                        gender,
                        dob,
                        department,
                        country,
                        city,
                        state
                    })

                    const res= await saveProfileImg({
                        image: base64String
                    });
                 
                    // setSrc(base64String);
                    location.reload();
                    // setActivityThumbnail(base64String);
                    // setIsCustomThumbnail(true);
                    // setCustomThumbnail(true);
                    // setSrc(base64String); 
                };
        
                // Set image source to FileReader result
                img.src = e.target.result;
            }
          )
          reader.readAsDataURL(e.target.files[0])
        }
      }
      const onCropUpdate= (crop) => {
        setCropData(crop);
        setCanShowCropButton(true);
      }
      const cropImage= async (crop) => {
        setCanShowCropButton(false);
        const image = imgRef.current;

        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height


        const offscreenCanvas = new OffscreenCanvas(
            cropData.width,
            cropData.height
          )
          const canvasCtx = offscreenCanvas.getContext('2d')
          if (!canvasCtx) {
            throw new Error('No 2d context')
          }
      
          canvasCtx.drawImage(
            image,
            cropData.x * scaleX,
            cropData.y * scaleY,
            cropData.width * scaleX,
            cropData.height * scaleY,
            0,
            0,
            cropData.width,
            cropData.height,
          )
          const imgBlob = await offscreenCanvas.convertToBlob({
            type: 'image/png',
          })

        const reader = new FileReader();
        reader.onload = async function(event) {
            const base64String = event.target.result;//.split(',')[1];

       

            const res= await saveProfileImg(base64String);
            setSrc(null);
            location.reload();
        };
        reader.readAsDataURL(imgBlob);
        
    }
      const enableEdit= () => {
        setCanEdit(true);
      }
      const saveEdits= async () => {
        setCanEdit(false);
        await updateProfile({
            fullName,
            accountType,
            organizationType:(accountType=='organisation'?organizationType:null),
            institutionType:(accountType=='institution'?institutionType:null),
            gender,
            dob,
            department,
            country,
            city,
            state
        })
        location.reload();
      }
      const formatDateString= function(dateString) {
        // Parse the input date string into a Date object
        const date = new Date(dateString);
    
        // Extract day, month, and year from the Date object
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
    
        // Format the date as dd/mm/yyyy
        return `${day}/${month}/${year}`;
    }
  useEffect(() => {
    const getProfileInfo= async () =>{
        let res= await getProfile();
        setFullName(res.fullName);
        setAccountType(res.accountType);
        setOrganizationType(res.organizationType);
        setInstitutionType(res.institutionType);
        setGender(res.gender);
        setProfileUrl(res.profileImg)
        setDob(res.dob?new Date(res.dob):null);
        setDepartment(res.department);
        setCanEdit(res.fullName===null)
        setCountry(res.country)
        setCity(res.city)
        setState(res.state)
    }

    if(props.profileData){
        setFullName(props.profileData.fullName);
        setAccountType(props.profileData.accountType);
        setOrganizationType(props.profileData.organizationType);
        setInstitutionType(props.profileData.institutionType);
        setGender(props.profileData.gender);
        setProfileUrl(props.profileData.profileImg)
        setDob((props.profileData.dob?new Date(props.profileData.dob):null));
        setDepartment(props.profileData.department);
        setCanEdit(props.profileData.fullName===null)
        setCountry(props.profileData.country)
        setCity(props.profileData.city)
        setState(props.profileData.state)
    }else{
        getProfileInfo();
    }
  



  }, []);

  return (
        <DashboardApp extraClass={"landscape_only"} {...props.profileData}>
            <div className={`profile_container ${darkModeClass}`}>
                <SideMenu/>
                <div className="middle_area">
                    <div className="middle_content">
                        <div className="head">
                            <div className="left">
                            <div className="title">PROFILE</div>
                            </div>
                            <div className="right">
                                <Button 
                                    className="purple-purple bold-text"
                                    style={{borderRadius:'15px', padding:'10px 45px'}}
                                    value={!canEdit?"EDIT":"UPDATE"}
                                    onClick={!canEdit?enableEdit:saveEdits}
                                />
                                {/* <Link to={{ pathname: "/dashboard/settings/profile" }} className="active">PROFILE</Link>
                                <Link to={{ pathname: "/dashboard/settings/account" }} className="">ACCOUNT</Link> */}
                            </div>
                        </div>
                        {<div className="profile_sec">
                            <div className="profile_photo_sec">
                                <div className={`${profileUrl?'photo':'photo mini'}`}>
                                    {/* <img src={profileUrl} alt="" /> */}
                                    <img src={profileUrl || '/images/dashboard/icons/Icon-Profile.svg'} alt="" />

                                </div>
                                {
                                    canEdit && <>
                                        <div className="camera_trigger" onClick={() => {
                                            document.querySelector("#profile_img_trigger").click();
                                        }}>
                                            <IconCamera/>
                                        </div>
                                        <input type="file" accept="image/*" onChange={onSelectFile} id="profile_img_trigger"/>
                                    </>
                                }
                                
                            </div>
                            <div className='field'>
                                <div className="icon"><IconProfile  darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                <input
                                    type="text"
                                    disabled={!canEdit}
                                    name="profile_full_name"
                                    id="profile_full_name"
                                    value={fullName}
                                    onChange={(v)=>{setFullName(v.target.value)}}
                                    placeholder="Full Name"
                                    autocomplete="off"
                                />
                            </div>
                            <div className='field'>
                                <div className="icon"><IconSchool  darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                <Dropdown options={accounts}  disabled={!canEdit} onChange={(v)=>setAccountType(v.value)} value={accountType} placeholder="Account Type" />
                                
                                {/* <input
                                    type="text"
                                    disabled={!canEdit}
                                    name="profile_school"
                                    id="profile_school"
                                    value={school}
                                    onChange={(v)=>{setSchool(v.target.value)}}
                                    placeholder="School/University"
                                /> */}
                            </div>
                            {
                                accountType=="institution" && <div className='field'>
                                    <div className="icon"><IconSchool  darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                    <Dropdown options={institutions}  disabled={!canEdit} onChange={(v)=>setInstitutionType(v.value)} value={institutionType} placeholder="Institution Type" />
                                </div>
                            }
                            {
                                accountType=="organisation" && <div className='field'>
                                    <div className="icon"><IconSchool  darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                    <Dropdown options={organizations}  disabled={!canEdit} onChange={(v)=>setOrganizationType(v.value)} value={organizationType} placeholder="Organisation Type" />
                                </div>
                            }
                            
                            <div className="inline_fields">
                                <div className='field'>
                                    <div className="icon"><IconGender  darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                    <Dropdown options={genderOptions}  disabled={!canEdit} onChange={(v)=>setGender(v.value)} value={gender} placeholder="Gender" />
                                </div>
                                <div className='field'>
                                    <div className="icon"><IconDOB  darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                    {
                                        dob!=null?
                                        <DatePicker placeholder="Date of Birth" format="dd/MM/yyyy" disabled={!canEdit} value={dob} onChange={ (e) => {setDob(new Date(e))}}  placement="leftBottom"/>:
                                        <DatePicker placeholder="Date of Birth" format="dd/MM/yyyy" disabled={!canEdit} onChange={ (e) => {setDob(new Date(e))}}  placement="leftBottom"/>
                                    }
                                    
                                </div>
                            </div>
                            <div className="inline_fields">
                                <div className='field'>
                                    <div className="icon"><IconCourse darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                    <input
                                        type="text"
                                        name="department_school"
                                        id="department_school"
                                        disabled={!canEdit}
                                        value={department}
                                        onChange={(v)=>{setDepartment(v.target.value)}}
                                        placeholder="Course/Department"
                                        autocomplete="off"
                                    />
                                </div>
                                <div className='field'>
                                    <div className="icon"><IconLocation darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                    <input
                                        type="text"
                                        disabled={!canEdit}
                                        name="profile_country"
                                        id="profile_country"
                                        value={country}
                                        onChange={(v)=>{setCountry(v.target.value)}}
                                        placeholder="Country"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                           <div className="inline_fields">
                            <div className='field'>
                                    <div className="icon"><IconLocation darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                    <input
                                        type="text"
                                        disabled={!canEdit}
                                        name="profile_country"
                                        id="profile_country"
                                        value={city}
                                        onChange={(v)=>{setCity(v.target.value)}}
                                        placeholder="City/Town"
                                        autocomplete="off"
                                    />
                                </div>
                                <div className='field'>
                                    <div className="icon"><IconLocation darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                    <input
                                        type="text"
                                        disabled={!canEdit}
                                        name="profile_state"
                                        id="profile_state"
                                        value={state}
                                        onChange={(v)=>{setState(v.target.value)}}
                                        placeholder="State/Province"
                                        autocomplete="off"
                                    />
                                </div>
                           </div>
                            
                        </div>}
                    </div>
                    <Copyright/>
                </div>
                <Ads darkModeClass={darkModeClass}/>
            </div>
           {/* { <div className={src!=null?'profile_crop_area active':'profile_crop_area'}>
                {<ReactCrop crop={crop} onChange={c => {
                    setCrop(c)
                }} aspect={1} onComplete={onCropUpdate}>
                    <img src={src}  ref={imgRef}/>
                </ReactCrop>}
                {
                    canShowCropButton && <Button 
                        className="purple-purple bold-text"
                        style={{borderRadius:'15px', padding:'10px 45px'}}
                        value= "CROP"
                        onClick={cropImage}
                    />
                }
                
            </div>} */}
        </DashboardApp>
  );
}

export default ProfilePage;