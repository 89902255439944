import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconNeedHelp(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
      <g>
        <g id="Layer_1">
          <path d="M56.6,56.6c-10.6,10.7-16.9,24.8-17.7,39.8v3.6c0,9.4,2.2,18.9,6.4,27.3,5.1,10.1,12.8,18.7,22.6,24.6,9.6,6,20.7,9.1,32.1,9.1h.1c7.8,0,15.4-1.5,22.6-4.4,2-.8,4-1.1,5.8-.8l14.7,1.6c3.9.4,7.8-1,10.5-3.8,2.8-2.7,4.1-6.6,3.7-10.5l-1.5-14.3c-.3-2.1,0-4,.8-6.1,2.9-7.3,4.4-14.9,4.4-22.8,0-11.4-3.2-22.5-9.1-32.1-6-9.6-14.5-17.5-24.6-22.5-8.5-4.3-17.8-6.4-27.3-6.5h-3.3c-15.3.8-29.5,7.1-40.1,17.7h0ZM98.9,133.2c-2.9,0-5.3-2.3-5.3-5.2s2.4-5.2,5.2-5.2h0c2.9,0,5.2,2.4,5.2,5.2s-2.4,5.2-5.2,5.2ZM110.4,104.9c-2,1.2-3.9,2.3-5.5,4.2-.3.3-.8,1.4-1,2.2-.7,2.3-2.8,3.8-5,3.8s-1,0-1.5-.2c-2.8-.8-4.3-3.7-3.6-6.4.2-.7,1.2-4,3.2-6.2,2.7-3.1,5.7-4.9,8.1-6.3,3.7-2.2,4.8-2.9,4.8-7.1s-1-5.4-2.7-7.4c-1.7-2.1-4.2-3.5-6.8-4-2.7-.4-5.4,0-7.8,1.5-2.3,1.3-4.1,3.5-5,6.1-1,2.7-4,4.2-6.7,3.2s-4.1-4-3.1-6.7c1.7-4.9,5.1-9,9.6-11.7,4.5-2.6,9.8-3.6,14.9-2.7,5.1.9,9.8,3.6,13.1,7.5s5.2,9,5.2,14.2c0,10.2-5.8,13.6-10,16.1h0Z"/>
        </g>
      </g>
    </StyledSVG>
  )
}