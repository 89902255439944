import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/shared.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import SideMenu from '../../components/dashboard/sidemenu';
import Button from '../../components/dashboard/button';
import ThoughtfullyMini from '../../components/dashboard/thoughtfully-mini';
import RecentActivity from '../../components/dashboard/recent-activity';
import { fetchSharedThoughtfully } from '../../common/api';
import { history } from '../../components/history'
import LivePopup from '../../components/dashboard/LivePopup';
import Copyright from '../../components/dashboard/copyright';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Tutorial from '../../components/dashboard/tutorial';

const Shared = (props) => {
  const [darkModeClass, setdarkModeClass ]= useState(
    localStorage.getItem('darkMode') == 'true'?'darkMode':''
  )
  const {tutorialIndex} = useParams();

  const [thoughtfullys, setThoughtfullys]= useState([]);
  const [searchKey, setSearchKey]= useState('');

  const onThoughtfullyView= (thoughtfullyID) => {
    history.push(`/dashboard/thoughtfully/${thoughtfullyID}/view`);
  }
  const onThoughtfullyEdit= (thoughtfullyID) => {
    history.push(`/dashboard/thoughtfully/${thoughtfullyID}/edit`);
  };

  const [showAd, setShowAd]= useState(false);
  const [liveActivityID, setLiveActivityID]= useState(null);
  const closeLivePopup= () => {
    setShowAd(false);
  };
  const onThoughtfullyLive= (thoughtfullyID) => {
    setShowAd(true);
    setLiveActivityID(thoughtfullyID);
  };


  useEffect(() => {
    const thoughtFullyShared= async (teamsFetched) => {
      const res= await fetchSharedThoughtfully();
   
    
      setThoughtfullys(res);
    }
    

    if(!props.tutorial){
      setTimeout(()=>{
        thoughtFullyShared();
      },100);
    }else{

      setThoughtfullys([
        {
          "_id": "66c9c279636d655494595b73",
          "teacherId": "123",
          "activityName": "<Thoughtfully Name>",
          "activityThumbnail": "/images/dashboard/Thoughtfully.png",
          "activityDesc": "",
          "activityTime": 30,
          "teams": [],
          "categories": [],
          "anonymous": true,
          "sessionsTotal": 4,
        "lastSession": "2024-08-25T00:18:21.788Z",
        "questions":[],
        "sharedTo":[]

        }
      ])
    }

  }, []);

  return (
        <DashboardApp extraClass={"landscape_only"} {...props.profileData}>
         
            <div className={`shared_container ${darkModeClass}`}>
              <SideMenu/>
              <div className="middle_area">
                  <div className="head">
                    <div className="left">
                      <div className="title">SHARED WITH ME</div>
                    </div>
                    <div className="right">
                      <input type="text" name="thoughtfully_search" id="thoughtfully_search" placeholder="Search..." value={searchKey} onChange={(v) => { setSearchKey(v.target.value); }}  autocomplete="off"/>
                   
                    </div>
                  </div>
                  <div className="middle_content">
                  {
                      thoughtfullys.length == 0 && 
                      <Button 
                        className={`disabled light_grey text-center bold-text ${darkModeClass}`}
                        // icon={<IconPlusThick darkModeColor={( localStorage.getItem('darkMode') == 'true')?"#000000":"#8d8d8d"}/>}
                        style={{padding:'25px 10px'}}
                        value="No Thoughtfully has been shared with you yet."
                        // onClick={() => {setActionType("create");setCanCreateTeam(true)}}
                      />
                    }
                    {
                      thoughtfullys.length>0 && thoughtfullys.map((thoughtfully, index) => {
                        if(thoughtfully.activityName.toLowerCase().indexOf(searchKey.toLowerCase())== 0){
                          return <ThoughtfullyMini 
                              key={index}
                              searchKey={searchKey}
                              {...thoughtfully}
                              showEditOrView={true}
                              onThoughtfullyLive= {onThoughtfullyLive.bind(this, thoughtfully._id)}
                              onThoughtfullyView={onThoughtfullyView}
                              onThoughtfullyEdit={thoughtfully.accessLevel=="editor"?onThoughtfullyEdit:null}
                              style={{'right':'20px','top':'60px'}}
                              thoughtfullyID={thoughtfully._id}

                          />
                        }
                        
                      })
                    }
                    {
                        thoughtfullys.length>0 && thoughtfullys.map((thoughtfully, index) => {
                          if(thoughtfully.activityName.toLowerCase().indexOf(searchKey.toLowerCase()) >0 ){
                            return <ThoughtfullyMini 
                                key={index}
                                searchKey={searchKey}
                                {...thoughtfully}
                                showEditOrView={true}
                                onThoughtfullyLive= {onThoughtfullyLive.bind(this, thoughtfully._id)}
                                onThoughtfullyView={onThoughtfullyView}
                                onThoughtfullyEdit={thoughtfully.accessLevel=="editor"?onThoughtfullyEdit:null}
                                style={{'right':'20px','top':'60px'}}
                                thoughtfullyID={thoughtfully._id}
                              />
                          }
                        })
                    }
                  </div>
                  <Copyright/>
              </div>
              <RecentActivity onThoughtfullyLive= {onThoughtfullyLive}/>
            </div>
            {
              showAd && <LivePopup showAd={showAd} liveActivityID={liveActivityID} closeLivePopup={closeLivePopup}/>
            }
              {
              props.tutorial && <Tutorial tutorialIndex={tutorialIndex}/>
            }
        </DashboardApp>
  );
}


export default Shared;