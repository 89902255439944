import React from 'react'
import './studentProfileIcon.scss';

const StudentProfileIcon = (props) => {
    return (
        <div className={`${props.className} profile`}  style={{...props.extraProfileStyles}}  onClick={props.onClick?(event) => props.onClick(event, props.id):null}>
            { props.showPhoto && <div className="img" style={{...props.extraImgStyles}}><img src={props.imgUrl} alt=""/></div> }
            { props.showName && <div className="studentName" style={{...props.extraNameStyles}}>{props.name}</div> }
        </div>
    );
};

export default StudentProfileIcon