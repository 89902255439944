import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/home.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import SideMenu from '../../components/dashboard/sidemenu';
import Button from '../../components/dashboard/button';
import ThoughtfullyMini from '../../components/dashboard/thoughtfully-mini';
import RecentActivity from '../../components/dashboard/recent-activity';
import { deleteThoughtfullyByID, fetchCreatedTeams, fetchCreatedThoughtfully, fetchTeachers, saveDuplicatedThoughtfully, thoughtfullyShareTo, updateIntroStats } from '../../common/api';
import { history } from '../../components/history'
import TeamAndMemberMini from '../../components/dashboard/team-mini';
import ShareMini from '../../components/dashboard/share-mini';
import LivePopup from '../../components/dashboard/LivePopup';
import IconPlusThick from '../../components/dashboard/icons/Icon-Plus_Thick';
import ErrorPanel from '../../components/dashboard/Error';
import Copyright from '../../components/dashboard/copyright';
import ConfirmPopup from '../../components/dashboard/confirmPopup';
import Tutorial from '../../components/dashboard/tutorial';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Global } from '../../common/global';

const HomePage = (props) => {
  

  const [darkModeClass, setdarkModeClass ]= useState(
    localStorage.getItem('darkMode') == 'true'?'darkMode':''
  )
  const {tutorialIndex} = useParams();

  // const [newMember, setNewMember]= useState('');
  const [errorMsg, setErrorMsg]= useState(false);
  const [showError, setShowError]= useState(false);

  const [newMemberAccessLevel, setNewMemberAccessLevel]= useState('viewer');
  const [thoughtfullys, setThoughtfullys]= useState([]);
  const [searchKey, setSearchKey]= useState('');
  const [teams, setTeams]= useState([]);
  const [thoughtfullToDelete, setThoughtfullToDelete]= useState(0);
  const [canShowDelete, setCanShowDelete]= useState(false);
  const [canShare, setCanShare]= useState(false);
  const [canSendShare, setCanSendShare]= useState(false);
  const [newShare, setNewShare]= useState('');
  const [thoughtfullyToShare, setThoughtfullyToShare]= useState('');
  const [sharedTo, setSharedTo]= useState([
      {
          "email":"",
          "saved": false
      }
  ]);

  const [teachers, setTeachers]= useState([]);


  const [showAd, setShowAd]= useState(false);
  const [liveActivityID, setLiveActivityID]= useState(null);
  const closeLivePopup= () => {
    setShowAd(false);
  };
  const onThoughtfullyLive= (thoughtfullyID) => {
    setShowAd(true);
    setLiveActivityID(thoughtfullyID);
  };


  const removeShare= (email) => {
    let memberSet= [...sharedTo];
    let removeIndex=-1
    memberSet.forEach((member, index) => {
      if(member.email === email){
        removeIndex= index;
      }
    });
    if(removeIndex>=0){
      memberSet.splice(removeIndex, 1);
      setSharedTo(memberSet);
      teachers.forEach((teacher, index) => {
        if(teacher.email == email){
          teacher.added= false;
        }
      })
    }
    setTimeout(() => {
      checkIfCanSendShare(memberSet)
    }, 0);
  };
  const addNewShare= () => {
   
    let teacherToAdd=null;
    let teacherClone=[...teachers];
    teacherClone.forEach((teacher, index) => {
      if(teacher.email == newShare){
        teacherToAdd=teacher;
        // teacherToAdd.added= true;
      }
    })
    if(teacherToAdd){
      // alert(JSON.stringify(teacherToAdd))
      if(!teacherToAdd.added){
        teacherToAdd.added= true;
       
        setSharedTo((prevMembers) => {
          const updatedMembers = [...prevMembers];
          const lastIndex = updatedMembers.length - 1;
          if (lastIndex >= 0) {
            updatedMembers[lastIndex] = {
              ...updatedMembers[lastIndex],
              // id: teacherToAdd._id,
              accessLevel:teacherToAdd.accessLevel,
              email: teacherToAdd.email,
              saved: true
            };
            updatedMembers.push(
              {
                  "id":"",
                  "email":"",
                  "saved": false,
                  "accessLevel":"viewer"
              }
            )
          }
          return updatedMembers;
        });
      
      }
      
      setNewShare('')
      setNewMemberAccessLevel('viewer')
    }else{
       teacherToAdd={
        email: newShare,
        accessLevel: newMemberAccessLevel,
        added: false
      };
      setSharedTo((prevMembers) => {
        const updatedMembers = [...prevMembers];
        const lastIndex = updatedMembers.length - 1;
        if (lastIndex >= 0) {
          updatedMembers[lastIndex] = {
            ...updatedMembers[lastIndex],
            id: teacherToAdd._id,
            email: teacherToAdd.email,
            accessLevel:teacherToAdd.accessLevel,
            saved: true
          };
          updatedMembers.push(
            {
                "id":"",
                "email":"",
                "saved": false,
                "accessLevel":"viewer"
            }
          )
        }
        return updatedMembers;
      });
      setTeachers([
        ...teachers,
        {
          email: newShare,
          accessLevel:newMemberAccessLevel,
          added: true
        }
      ])
     
      setNewShare('');
      setNewMemberAccessLevel('viewer')
    }
    /* let teacherToAdd=null;
    teachers.forEach((teacher, index) => {
      if(teacher._id == newShare){
        teacherToAdd=teacher;
        teacherToAdd.added= true;
      }
    })
    if(teacherToAdd){
      
      setSharedTo((prevMembers) => {
        const updatedMembers = [...prevMembers];
        const lastIndex = updatedMembers.length - 1;
        if (lastIndex >= 0) {
          updatedMembers[lastIndex] = {
            ...updatedMembers[lastIndex],
            id: teacherToAdd._id,
            email: teacherToAdd.email,
            saved: true
          };
          updatedMembers.push(
            {
                "id":"",
                "email":"",
                "saved": false
            }
          )
        }
        setTimeout(() => {
          checkIfCanSendShare(updatedMembers)
        }, 0);
        return updatedMembers;
      });
    } */
     
  };
  const validateEmail= (email) => {
    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
      return false;
    }
    return true;
  }
  const shareThoughtfully= async () => {
    let shareToIDs= [];
    let validInfo= true;
    sharedTo.forEach((sharedToObj) => {
      if(!validateEmail(sharedToObj.email) && sharedToObj.saved){
        validInfo= false;
      }

      if(sharedToObj.saved){
        shareToIDs.push({
          email: sharedToObj.email,
          accessLevel: sharedToObj.accessLevel
        });
      }
      
    }); 
    if(!validInfo){
      setErrorMsg("Invalid Email");
      setShowError(true);
        const timer = setTimeout(() => {
          setShowError(false);
        }, 2000);
        return false;
    }
    const shareToRes= await thoughtfullyShareTo({
      thoughtfullyID: thoughtfullyToShare,
      sharedTo: shareToIDs
    })
    if(shareToRes.status === 201){
      setErrorMsg(shareToRes.message);
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 2000);
      return false;
    }

    setCanShare(false);
    setThoughtfullys(shareToRes['thoughtfullys'])
  }


  useEffect(() => {

    const teamsCreated= async () => {
      const res= await fetchCreatedTeams(false);

      setTeams(res);
      thoughtFullyCreated(res);
    }
    const saveTutorialStats= async () => {
      await updateIntroStats();
    }
    const thoughtFullyCreated= async (teamsFetched) => {
      const res= await fetchCreatedThoughtfully();
      if(res.length>0){
        res.forEach((thoughtfully) => {
          for(let i=0;i<thoughtfully.teams.length;i++){
            thoughtfully.teams[i]=teamsFetched.filter((team) => team.id == thoughtfully.teams[i])[0] || null;
          }
          thoughtfully.teams=thoughtfully.teams.filter(item => item !== null)
        });
      }
      
      setThoughtfullys(res);
    }
    if(!props.tutorial){
      setTimeout(()=>{
        let teamsFetched= teamsCreated();
      },100);
    }else{
     saveTutorialStats();

      setThoughtfullys([
        {
          "_id": "66c9c279636d655494595b73",
          "teacherId": "123",
          "activityName": "<Thoughtfully Name>",
          "activityThumbnail": "/images/dashboard/Thoughtfully.png",
          "activityDesc": "",
          "activityTime": 30,
          "teams": [],
          "categories": [],
          "anonymous": true,
          "sessionsTotal": 4,
        "lastSession": "2024-08-25T00:18:21.788Z",
        "questions":[],
        "sharedTo":[]

        }
      ])
    }
   

  }, []);

  const onThoughtfullyView= (thoughtfullyID) => {
    history.push(`/dashboard/thoughtfully/${thoughtfullyID}/view`);
  }
  const onThoughtfullyEdit= (thoughtfullyID) => {
    history.push(`/dashboard/thoughtfully/${thoughtfullyID}/edit`);
  };
 
  const onThoughtfullyInvite= () => {

  };
  const onThoughtfullyShare= (thoughtfullyID) => {
    checkIfCanSendShare(sharedTo);
    setThoughtfullyToShare(thoughtfullyID);
    setCanShare(true);

    let shareSet=[];
    thoughtfullys.forEach((thoughtfully) => {
      if(thoughtfully._id === thoughtfullyID){
        shareSet=[...thoughtfully.sharedTo];      
      }
    });

    shareSet.forEach((shareObj, index) => {
      shareSet[index]={
        id:shareObj,
        saved: true
      };
    });
    teachers.forEach((teacher, index) => {
      if(isIdPresent(shareSet, teacher._id)){
        teacher.added= true;
        if(shareSet[index]){
          shareSet[index]['email']=teacher['email']
        }
       
      }else{
        teacher.added= false;
      }
    });

    shareSet.push({
        "email":"",
        "saved": false
    });
    setSharedTo(shareSet);

  };
  const isIdPresent= function(array, idToCheck) {
    return array.some(obj => obj.id === idToCheck);
  };
  const checkIfCanSendShare= (sharedTo) => {
    let canSendToShare=  sharedTo.filter((sharedToObj) => sharedToObj.saved);
    setCanSendShare(canSendToShare.length>0);

  }
  const onThoughtfullyDuplicate= async (thoughtfullyID) => {
    const duplicatedRes= await saveDuplicatedThoughtfully({
      thoughtfullyID
    });
    const duplicate= duplicatedRes.duplicate;
    setThoughtfullys([
      ...thoughtfullys,
      duplicate
    ])
   
  };
  const onThoughtfullyDeleteCancel= async () => {
    
    setCanShowDelete(false);
  }
  const onThoughtfullyDeleteConfirm= async (thoughtfullyID) => {
    const deleteRes= await deleteThoughtfullyByID(thoughtfullToDelete);
    setThoughtfullys((prevThoughtfullys) => 
    prevThoughtfullys.filter((thoughtfully) => thoughtfully._id !== deleteRes.id)
    );
    onThoughtfullyDeleteCancel();
  }
  const onThoughtfullyDelete= (thoughtfullyID) => {
    setThoughtfullToDelete(thoughtfullyID);
    setCanShowDelete(true);
 
  };


  return (
        <DashboardApp extraClass={"landscape_only"} {...props.profileData}>
            
            <div className={`home_container ${darkModeClass}`}>
              <SideMenu />
              <div className="middle_area">
                  <div className="head">
                    <div className="left">
                      <div className="title">DASHBOARD</div>
                    </div>
                    <div className="right">
                      <input type="text" name="thoughtfully_search" id="thoughtfully_search" placeholder="Search..." value={searchKey} onChange={(v) => { setSearchKey(v.target.value); }} autocomplete="off"/>
                      <Button 
                        className={`yellow-black-opaque minimal-padding bold-text ${darkModeClass}`}
                        icon={<IconPlusThick darkModeColor={( localStorage.getItem('darkMode') == 'true')?"#000000":"#000000"}/>}
                        value="Create Thoughtfully"
                        id="thoughtfully_create"
                        onClick={() => {history.push(`/dashboard/thoughtfully/create`);}}
                      />
                    </div>
                  </div>
                  <div className={`middle_content ${darkModeClass}`}>
                    {
                      thoughtfullys.length == 0 && 
                      <Button 
                        className={`light_grey text-center bold-text ${darkModeClass}`}
                        icon={<IconPlusThick darkModeColor={( localStorage.getItem('darkMode') == 'true')?"#000000":"#8d8d8d"}/>}
                        style={{padding:'25px 10px'}}
                        value="Create your first Thoughtfully to get started!"
                        onClick={() => {history.push(`/dashboard/thoughtfully/create`);}}
                      />
                    }
                    {
                      thoughtfullys.length>0 && thoughtfullys.map((thoughtfully, index) => {
                        if(thoughtfully.activityName.toLowerCase().indexOf(searchKey.toLowerCase())== 0){
                          return <ThoughtfullyMini 
                              key={index}
                              searchKey={searchKey}
                              {...thoughtfully}
                              style={{'right':'20px','top':'60px'}}
                              showEditOrView={true}
                              thoughtfullyID={thoughtfully._id}
                              onThoughtfullyView= {onThoughtfullyView}
                              onThoughtfullyInvite={onThoughtfullyInvite}
                              onThoughtfullyShare= {onThoughtfullyShare}
                              onThoughtfullyEdit= {onThoughtfullyEdit}
                              onThoughtfullyDuplicate= {onThoughtfullyDuplicate}
                              onThoughtfullyDelete= {onThoughtfullyDelete}
                              onThoughtfullyLive= {onThoughtfullyLive.bind(this, thoughtfully._id)}
                          />
                        }
                        
                      })
                    }
                    {
                        thoughtfullys.length>0 && thoughtfullys.map((thoughtfully, index) => {
                          if(thoughtfully.activityName.toLowerCase().indexOf(searchKey.toLowerCase()) >0 ){
                            return <ThoughtfullyMini 
                                key={index}
                                {...thoughtfully}
                                searchKey={searchKey}
                                showEditOrView={true}
                                style={{'right':'20px','top':'60px'}}
                                thoughtfullyID={thoughtfully._id}
                                onThoughtfullyView= {onThoughtfullyView}
                                onThoughtfullyInvite={onThoughtfullyInvite}
                                onThoughtfullyShare= {onThoughtfullyShare}
                                onThoughtfullyEdit= {onThoughtfullyEdit}
                                onThoughtfullyDuplicate= {onThoughtfullyDuplicate}
                                onThoughtfullyDelete= {onThoughtfullyDelete}
                                onThoughtfullyLive= {onThoughtfullyLive.bind(this, thoughtfully._id)}
                            />
                          }
                        })
                    }
                  </div>
                  <Copyright/>
              </div>
              

              <RecentActivity onThoughtfullyLive= {onThoughtfullyLive} tutorial={props.tutorial}/>
            </div>
            {
              canShare && <div className={`share_ui ${darkModeClass}`}>
                  <div id="share_ui_close" className='close' onClick={() => {setCanShare(false)}} ></div>
                  <div className="container">
                      <div className="head">
                          <div className="title">SHARE THOUGHTFULLY WITH</div>
                          <div className="closeBtn"><img src="/images/dashboard/icons/Icon-Close.svg" alt="" srcset="" /></div>
                      </div>
                      <div className="body">                          
                          {
                   
                            /* teachers.length>0? */sharedTo.map((currentShareTo) => (
                                  <ShareMini
                                      {...currentShareTo}
                                      isTeam={false}
                                      newShare={newShare}
                                      setNewShare={setNewShare}
                                      removeShare={removeShare}
                                      addNewShare={addNewShare}
                                      newMemberAccessLevel={newMemberAccessLevel}
                                      setNewMemberAccessLevel={setNewMemberAccessLevel}
                                      actionType="edit"
                                      style={{'right':'20px','top':'60px'}}
                                  />
                              ))/* :<></> */
                          }
                          {/* {
                            teachers.length==0 && <div className="no_teacher_found">No Teacher Found to share thoughtfully.</div>
                          } */}
                          
                      </div>
                      {
                        /* teachers.length>0 &&  */ <Button 
                          className={`purple-purple bold-text text-center`}
                          value="SHARE"
                          onClick={shareThoughtfully}
                        />
                      }
                     
                      
                  </div>
              </div>
              
            }
             <ErrorPanel showError={showError} errorMsg={errorMsg}/>
            {
              showAd && <LivePopup showAd={showAd} liveActivityID={liveActivityID} closeLivePopup={closeLivePopup}/>
            }
            {
              canShowDelete && <ConfirmPopup isMini={true} onProceed={onThoughtfullyDeleteConfirm} onCancel={onThoughtfullyDeleteCancel} title='Are you sure you want to delete?' desc=''/>

            }
            {
              props.tutorial && <Tutorial tutorialIndex={tutorialIndex}/>
            }
        </DashboardApp>
  );
}


export default HomePage;