

function IconLeft (){
  return(
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200">
      <g>
        <g id="Layer_1">
          <path d="M66,100c0-.9.5-2,1.2-2.7l54.4-54.4c.7-.7,1.8-1.2,2.7-1.2s2,.5,2.7,1.2l5.8,5.8c.7.7,1.2,1.8,1.2,2.7s-.5,2-1.2,2.7l-45.9,45.9,45.9,45.9c.7.7,1.2,1.8,1.2,2.7s-.5,2-1.2,2.7l-5.8,5.8c-.7.7-1.8,1.2-2.7,1.2s-2-.5-2.7-1.2l-54.4-54.4c-.7-.7-1.2-1.8-1.2-2.7Z"/>
        </g>
      </g>
    </svg>
  )
}


export default IconLeft;