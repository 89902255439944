import React, {useEffect, useState} from 'react';
import AppWrapper from '../../containers/app-wrapper';
import '../../styles/response.scss';
import Nav from '../../components/nav';
import { Global } from '../../common/global';
import ColyseusComponent from '../../common/socket';
import { playWaitAudio, stopAll } from '../../components/AudioContext';

const ResponsePage = (props) => {
    ColyseusComponent({
        "initClient": false
    });
    const [responses, setResponses]= useState([])
    const [responseIndex, setResponseIndex]= useState(0);//
    const [question, setQuestion]= useState("");

    const navigateSlides= (toAdd) => {
        Global.emitEvent(
            "colyseus:send_actions",
            {
                "action_type": "navigate_slides",
                'toAdd': toAdd
            }
        )
    };
    useEffect(() => {
        stopAll();
        Global.playPromptEnd= false;
        setQuestion(props.question)
        setResponses(props.responses);
        setResponseIndex(props.slide_index);

        return () =>{
        
        }
    }, [props.responses])
    return (
        <AppWrapper extraClass={"landscape_only"} gameData={props.gameData}>
            <div className="bg_cover"><img src="/images/BG.svg" alt="" srcset="" /></div>
            <div className="response_container">
                <div className="title">{question}</div>
                {
                    responses.length>0 && <>
                        <div className="response_sec">
                            <div className="left">
                                <div className="profile_img"><img src={responses[props.slide_index]['img']} alt="" srcset="" /></div>
                            </div>
                            <div className="right">
                                <div className="title">{responses[props.slide_index]['name']}'s Response: {responses[props.slide_index]['response']}</div>
                                <div className="explanation">{responses[props.slide_index]['explanation']}</div>
                            </div>
                        </div>

                        <div className="navs">
                            <Nav key='home_nav' className={props.slide_index===0?'active':''} iconUrl='/images/icons/home.png'/>
                            {
                                responses.map((response, index) =>(
                                    <Nav key={response.key} className={props.slide_index+1===index+1?'active':''} />
                                ))
                            }
                        </div>
                    </>
                }
                
            </div>
            <div className="nav_btns">
                <div className={props.slide_index<0?"nav_btn nav_prev disabled":"nav_btn nav_prev"} onClick={navigateSlides.bind(this, -1)}>
                    <img src="/images/icons/Icon-Previous.svg" alt="" />
                </div>
                <div className={props.responses.length-1 <= props.slide_index?"nav_btn nav_next disabled":"nav_btn nav_next"} onClick={navigateSlides.bind(this, 1)}>
                    <img src="/images/icons/Icon-Next.svg" alt="" />
                </div>
            </div>
            <div id="rotate">
                <img src="/images/tilt.png" alt="" srcset="" />
            </div>
        </AppWrapper>
    )
    
}

export default ResponsePage;