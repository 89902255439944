import React, {useEffect, useState} from 'react';
import AppWrapper from '../../containers/app-wrapper';
import '../../styles/thoughts.scss';
import Collapsible from "../../components/collapsible/index";
import StudentProfileIcon from '../../components/studentProfileIcon';
import { Global } from '../../common/global';
import ColyseusComponent from '../../common/socket';
import { useMediaQuery } from 'react-responsive';

const ThoughtsScreen = (props) => {
    const isPortraitScreen = useMediaQuery({
        query: '(max-width: 1024px) and (max-aspect-ratio: 13/10)'
    });
    ColyseusComponent({
        "initClient": false
    });
    
    const [pickedToShare, setPickedToShare]= useState(false);
    const [sessionEnded, setSessionEnded]= useState(false);
    const [pickedUserDetails, setPickedUserDetails]= useState(null);
    const [results, setResults]= useState([]);
    const [canCountDown, setCanCountDown]= useState(false);
    const [countDown, setCountDown]= useState(10);
    const [activityCompleted, setActivityCompleted]= useState(props.question);
    const [studentToRemove, setStudentToRemove]= useState(null);

    const [students, setStudents]= useState([
     
    ]);
    const updateGameState= (data)=>{
        setCanCountDown(data['question_time']!==0);
        setCountDown(data['question_time']);
        setActivityCompleted(data['activity_completed'])
        setSessionEnded(data['sessionEnded'])

    };
    const updateStudents=(data)=>{
        setStudents(data);
    };
    const saveOpinions= (data) => {
        setResults(data['results']);
    }
    const copyUserDetails = (event, id) => {
        results.forEach((result)=>{
            result['users'].forEach((user)=>{
                if(user['id'] === id){
                    setPickedUserDetails(user);
                }
            })
        })
        
    };

    const clearUserDetails= () => {
        setPickedUserDetails(null);
        setPickedToShare(false);
    }
    const selectToShare= () => {
        Global.emitEvent(
            "colyseus:send_actions",
            {
                "action_type": "pick_to_share",
                'user': pickedUserDetails
            }
        )
        setPickedToShare(true);
    }
    const navigateSlides= (toAdd) => {
        Global.emitEvent(
            "colyseus:send_actions",
            {
                "action_type": "navigate_slides",
                'toAdd': toAdd
            }
        )
    };
    const showNextQuestion= () => {
        Global.emitEvent(
            "colyseus:send_actions",
            {
                "action_type": "next_question"
            }
        )
    }
    const endCurrentSession= () =>{
        setSessionEnded(true);
        Global.emitEvent(
            "colyseus:send_actions",
            {
                "action_type": "end_session"
            }
        )
    } 
    const replayCurrentQn= ()=>{
        Global.emitEvent(
            "colyseus:send_actions",
            {
                "action_type": "replay_question"
            }
        )
    }
    const requestUserRemoval= () => {
        Global.emitEvent("colyseus:send_actions",{
            "action_type": "remove_student",
            "student_id": studentToRemove.id
        });
        clearUserRemoval();
    }
    const clearUserRemoval= () => {
        setStudentToRemove(null);
    }
    const showUserToRemove= (data) => {
        setStudentToRemove(data);
    }
    useEffect(() =>{
        setResults(props.results);

        Global.addEventListener("screen:student_join", updateStudents);
        Global.addEventListener("common:game_state_update", updateGameState);
        return () => {
            Global.removeEventListener("common:game_state_update", updateGameState);
            Global.removeEventListener("screen:student_join", updateStudents);

        }
    })

    return (
        <AppWrapper questionTime={countDown}>
            <div className="bg_cover"><img src="/images/BG.svg" alt="" srcset="" /></div>
            <div className="thoughts_sec">
                <div className="role">
                    <div className="role_icon">
                        <img src="/images/icons/Icon-Tutor.svg" alt="" />
                    </div>
                    <div className="label">INSTRUCTOR</div>
                </div>
                <div className="thoughts">
                <div className={`${students.length==0?'disabled ':''}students purple-minimal`}>
                    <Collapsible titleInfo={students.length} title={'ACTIVE STUDENTS'} key={'joined'} >
                                {
                                    students.map((student, userIndex) => (
                                        <div className="student" key={student.id}>
                                                    <StudentProfileIcon 
                                                        id={student.id} 
                                                        imgUrl={student.img} 
                                                        name={student.name} 
                                                        showName={true} 
                                                        showPhoto={true}  
                                                        extraImgStyles={{width: isPortraitScreen?'8vw':'5vh',height: isPortraitScreen?'8vw':'5vh'}}
                                                        extraNameStyles={{fontFamily:'MontserratRegular',fontSize: isPortraitScreen?'3.5vw':'2.25vh', color:'#ffffff',marginLeft:'10px',width:'40%'}}
                                                    />
                                                    <div className="close" onClick={showUserToRemove.bind(this, student)}>
                                                        <img src="/images/icons/Icon-Cross.svg" alt="" srcset="" />
                                                    </div>
                                        </div>
                                    ))
                                }
                    </Collapsible>
                </div>
                {results && results.map((result, resultIndex) => (
                    <div className={(!result || (result && result.users.length===0) || canCountDown)?'thought disabled':'thought'}>
                        <Collapsible title={result['title']} key={result['key']} users={result['users']}>
                            {
                                result.users.map((user, userIndex) => (
                                    <StudentProfileIcon key={user.id} id={user.id} imgUrl={user.img} name={user.name} showName={true} showPhoto={true}  extraNameStyles={{fontFamily:'MontserratRegular',fontSize:isPortraitScreen?'4vw':'3vh',marginLeft:isPortraitScreen?'3vw':'2vh'}} extraProfileStyles={{padding: isPortraitScreen?'2vw':'1vh', borderRadius:isPortraitScreen?'10vw':'10vh'}} onClick={(canCountDown || resultIndex >= 6)?null:copyUserDetails}/>
                                ))
                            }
                        </Collapsible>
                    </div>
                    
                ))}
                </div>
                {
                    !canCountDown && <>
                        <div className="thoughts_navs">
                            <div className={(props.slide_index<0)?"thoughts_nav prev disabled":"thoughts_nav prev"} onClick={navigateSlides.bind(this, -1)}>
                                <div className="icon">
                                <img src="/images/icons/Icon-Previous.svg" alt="" />
                                </div>
                                {/* <div className="label">PREVIOUS SLIDE</div> */}
                            </div>
                            {
                                !activityCompleted && <div id="nextQn" onClick={showNextQuestion}>
                                        NEXT QUESTION
                                    </div>
                            }
                            {
                                activityCompleted && !sessionEnded && <div id="endSession" onClick={endCurrentSession}>
                                        END
                                    </div>
                            }
                            {
                                props && props.responses && <div className={props.responses.length-1 <= props.slide_index?"thoughts_nav next disabled":"thoughts_nav next"} onClick={navigateSlides.bind(this, 1)}>
                                <div className="icon">
                                <img src="/images/icons/Icon-Next.svg" alt="" />
                                </div>
                                {/* <div className="label">NEXT SLIDE</div> */}
                            </div>
                            }
                        </div>
                        {
                             !sessionEnded && <div id="replay_qn" onClick={replayCurrentQn}>REPLAY QUESTION</div>
                        }
                    </>
                }
               
            </div>
            {
                pickedUserDetails && <>
                    <div className="thoughts_detail">
                        <div className="close" onClick={clearUserDetails}>
                            <img src="/images/icons/Icon-Cross.svg" alt="" srcset="" />
                        </div>
                        <div className="container">
                            <div className="profile_img">
                                <StudentProfileIcon 
                                    key={pickedUserDetails.id} 
                                    id={pickedUserDetails.id} 
                                    imgUrl={pickedUserDetails.img} 
                                    name={pickedUserDetails.name} 
                                    showName={true} 
                                    showPhoto={true} 
                                    extraImgStyles={{width:'100%',height:'100%', backgroundColor:'#684d94'}} 
                                    extraProfileStyles={{width:'100%', borderRadius:'10vw'}}
                                />
                            </div>
                            <div className="profile_info">
                                <div className="response">Response: {pickedUserDetails.response}</div>
                            </div>
                            {
                                !pickedToShare&& <>
                                <div className="why_head">WHY?</div>
                                <div className="explanation">{pickedUserDetails.explanation}</div>
                                <div id="pickToShare" onClick={selectToShare}>PICK TO SHARE</div>
                                </>
                            }
                            {
                                pickedToShare&& <>
                                <div className="picked_head">Rationale Shared!</div>
                                <div className="picked_info">You can call upon the participant to elaborate.</div>
                                </>
                            }
                            
                        </div>
                    </div>
                </>
            }
             {
                studentToRemove && <>
                    <div className="remove_confirm">
                        <div className="close" onClick={clearUserRemoval}>
                            <img src="/images/icons/Icon-Cross.svg" alt="" srcset="" />
                        </div>
                        <div className="container">
                            <div className="profile_img">
                                <StudentProfileIcon 
                                    id={studentToRemove.id} 
                                    imgUrl={studentToRemove.img} 
                                    name={studentToRemove.name} 
                                    showName={true} 
                                    showPhoto={true} 
                                    extraImgStyles={{marginBottom:'5vw',width:'100%',height:'100%', backgroundColor:'#684d94'}} 
                                    extraProfileStyles={{width:'100%', borderRadius:'10vw'}} 
                                    extraNameStyles={{fontSize:isPortraitScreen?'7vw':'2.5vh'}}
                                />
                            </div>
                            <div className="picked_head">Are you sure you want to remove this user?</div>
                            <div id="removeConfirm" onClick={requestUserRemoval}>
                               CONFIRM
                            </div>
                        </div>
                    </div>
                </>
            }
        </AppWrapper>
    );
}

export default ThoughtsScreen;