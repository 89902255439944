import { useEffect, useState } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconTutorial(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
      <g>
        <g id="Layer_1">
          <g>
            <path d="M130,137.7h-3.1l-1.2-7h-7.7l1.2,7h-38.4l1.2-7h-7.7l-1.2,7h-3.1c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8h1.9l-2,12.2c-.3,2.1,1.1,4,3.1,4.4,2.1.3,4-1.1,4.4-3.1l2.2-13.5h41l2.2,13.5c.3,1.9,1.9,3.2,3.8,3.2s.4,0,.6,0c2.1-.3,3.5-2.3,3.1-4.4l-2-12.2h1.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8h0Z"/>
            <path d="M103.8,41.8c0-2.1-1.7-3.8-3.8-3.8s-3.8,1.7-3.8,3.8v6.1h7.6v-6.1Z"/>
            <path d="M149.2,118.1h-6v-62.3c0-2.1-1.7-3.8-3.8-3.8H60.6c-2.1,0-3.8,1.7-3.8,3.8v62.3h-6c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8h98.3c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8ZM73.5,64.7h12.8c2.1,0,3.8,1.7,3.8,3.8s-1.7,3.8-3.8,3.8h-12.8c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8ZM106.6,87.6h-33.1c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8h33.1c2.1,0,3.8,1.7,3.8,3.8s-1.7,3.8-3.8,3.8Z"/>
          </g>
        </g>
      </g>
    </StyledSVG>
  )
}