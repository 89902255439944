import React, {useEffect, useState} from 'react';
import AppWrapper from '../../containers/app-wrapper';
import '../../styles/question.scss';
import { Global } from '../../common/global';
import ColyseusComponent from '../../common/socket';
import { playPromptAudio } from '../../components/AudioContext';

const QuestionPage = (props) => {

    if(!props.isPreview){
        ColyseusComponent({
            "initClient": false
        });
    }
  
    const [question, setQuestion]= useState(props.question);
    const [questionTime, setQuestionTime]= useState(props.question_time);
    const [questionIndex, setQuestionIndex]= useState(props.question_index);
    const [questionsTotal, setQuestionsTotal]= useState(props.question_total);
    
    const updateGameState= (data)=>{
        setQuestion(data['question']);
        setQuestionTime(data['question_time']);
      };

    useEffect(() => {
        Global.playPromptEnd= true;
        setQuestionTime(props.question_time);
        setQuestionIndex(props.question_index);
        setQuestionsTotal(props.question_total);
        setQuestion(props.question);

        if(!props.isPreview){
            playPromptAudio(props.question_time);
        }
        Global.addEventListener("common:game_state_update", updateGameState);
        return () => {
            Global.removeEventListener("common:game_state_update", updateGameState);
        }
    }, props.isPreview?[props]:[props.question_time]);
    return (
        props.isPreview
        ?   <>
                <div className="bg_cover"><img src="/images/BG.svg" alt="" srcset="" /></div>
                <div className="question_container" style={{display:'flex'}}>
                    <div className="timer_sec">{questionTime}</div>
                    <div className="question_sec">{question}</div>
                    <div className="question_nav">{questionIndex+1}/{questionsTotal}</div>
                </div>
            </>:
            <AppWrapper extraClass={"landscape_only"} gameData={props.gameData}>
                <div className="bg_cover"><img src="/images/BG.svg" alt="" srcset="" /></div>
                <div className="question_container">
                    <div className="timer_sec">{questionTime}</div>
                    <div className="question_sec">{question}</div>
                    <div className="question_nav">{questionIndex+1}/{questionsTotal}</div>
                </div>
                <div id="rotate">
                    <img src="/images/tilt.png" alt="" srcset="" />
                </div>
            </AppWrapper>
    );

}

export default QuestionPage;