import React, { useState, useEffect } from 'react'
import './style.scss'
import IconLaptop from '../icons/IconLaptop';
import { isMobile,isMobileOnly } from 'mobile-device-detect';
import IconRotate from '../icons/IconRotate';


const RotateCheck = (props) => {
  const [darkModeClass, setdarkModeClass ]= useState(
    localStorage.getItem('darkMode') == 'true'?'darkMode':''
  )
  useEffect(() => {
  
    return () => {
    };
  }, []);
  return (
    <div id="rotateScr" class={darkModeClass?"dark":""}>
        <div className="rotateIcon">
          {
            isMobile && isMobileOnly ?<IconLaptop darkModeColor={darkModeClass=="darkMode"?"#ffffff":"#000000"}/>:<IconRotate darkModeColor={darkModeClass=="darkMode"?"#ffffff":"#000000"}/>
          }
          
          <div className={`${isMobile && isMobileOnly?'rotateLabel':'rotateLabel tablet'}`}>{isMobile && !isMobileOnly?'Tilt your Device to use Thoughtfully!':'Please use Thoughtfully on your laptop to Login!'}</div>
        </div>
        

    </div>
  )
}

export default RotateCheck;