var Global = {
    "userName":"",
    "avatarID":"",
    "imgKey":"",
    "deviceType":"",
    "gameID":"",
    "isScreen":"",
    "userJoined":false,
    "canShowWait":false,
    playPromptEnd:false,
    sessionId:"",
    "studentData":{
      "activityId":"",
      "studentId":"",
      "sessionId":"",
      "studentName":"",
      "studentAvatar":"",
      "teacherId":"",
      "responses":[]
    },
    "disableScreenLock": async () => {
      let wakeLock = null;
      try {
          wakeLock = await navigator.wakeLock.request('screen');
          // console.log(wakeLock,'wakeLock')
      } catch (err) {
      // The Wake Lock request has failed - usually system related, such as battery.
      }
    },
    getLastPath:function(){
      const urlObject = new URL(location.href);

        // Get the pathname
        const pathname = urlObject.pathname;

        // Split the pathname by '/' and filter out empty segments
        const segments = pathname.split('/').filter(Boolean);

        // Get the last segment
        const lastSegment = segments.length > 0 ? segments[segments.length - 1] : '';

      return lastSegment;
    }
  };
  export { Global };
  