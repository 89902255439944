import React, {useEffect, useState} from 'react';
import AppWrapper from '../../containers/app-wrapper';
import '../../styles/result.scss';
import StudentProfileIcon from '../../components/studentProfileIcon';
import ColyseusComponent from '../../common/socket';
import { playPromptAudio, playWaitAudio } from '../../components/AudioContext';
import { Global } from '../../common/global';

const ResultPage = (props) => {
    ColyseusComponent({
        "initClient": false
    });
    const [results, setResults]= useState([]);
    const [question, setQuestion]= useState("");
    const [noRespose, setNoRespose]= useState([]);
    const [sessionEnded, setSessionEnded]= useState(false);
   
    useEffect(() =>{
        let waitTO=null;
        let audioTO=null;
        setSessionEnded(props['sessionEnded'])
        if(Global.playPromptEnd && !props['sessionEnded']){
            audioTO= setTimeout(()=>{
                playPromptAudio('end');
                
            },0)
        }
      
        waitTO=setTimeout(()=>{
            playWaitAudio();
        },!Global.playPromptEnd?0:250)
        setResults(props.results);
        // console.log(props.results,'props.results')
        setNoRespose(props.no_response)
        setQuestion(props.question)
      return () => {
        audioTO && clearTimeout(audioTO);
        waitTO && clearTimeout(waitTO);
      }
  })
    return (
        <AppWrapper extraClass={"landscape_only"} gameData={props.gameData}>
            <div className="bg_cover"><img src="/images/BG.svg" alt="" srcset="" /></div>
            <div className="result_container">
                <div className="title">{question}</div>
                <div className="result_charts">
                {results.map((result, index) => (
                    index<=5 && <>
                        <div className="chart" key={result['key']+index} id={result['key']}>
                                <div className="polls">
                                        {result.users.map((obj, index) => (
                                            <StudentProfileIcon key={obj.id} id={obj.id} imgUrl={obj.img} name={obj.name} showName={true} showPhoto={false}  extraNameStyles={{fontFamily:'MontserratRegular',fontSize:'1.25vw'}}/>
                                        ))}
                                </div>
                                <div className="chart_progress"> 
                                    <div className="txt">{result['title']}<br/>{`(${result['percentage']}%)`}</div>
                                </div>
                        </div>
                    </>
                ))}
                   
                </div>
                <div className="chart2">
                    <div className="chart_progress"> 
                        <div className="head">No Responses</div>
                        <div className="txt">{noRespose}</div>
                    </div>
                </div>
            </div>
            <div id="rotate">
                <img src="/images/tilt.png" alt="" srcset="" />
            </div>
        </AppWrapper>
    );

}

export default ResultPage;