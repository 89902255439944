import React, { useState, useEffect } from 'react'
import './style.scss'
import classnames from 'classnames';
import ControlPanel from '../controlPanel';
import IconTeam from '../icons/Icon-Team';

const ThoughtfullyMini = (props) => {
    const [darkModeClass, setdarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      )
    const [showControlPanel, setShowControlPanel]= useState(false);
  useEffect(() => {
    setShowControlPanel(props.showControlPanel)
    return () => {
    };
  }, []);
  const thioughtfullyClass = classnames('thoughtfully_mini', props.className)

  return (
    <div className={`${thioughtfullyClass} ${darkModeClass}`}>
        <div className="left">
            <div className="cover_photo">
                <img src={props.activityThumbnail} alt="" srcset="" />
            </div>
            <div className="thoghtfully_mini_info">
                <div className="thoghtfully_title">{props.activityName}</div>
                <div className="info_sub">
                    {
                        thioughtfullyClass.indexOf(' mini') == -1?<>
                            NO. OF PROMPTS: {props.questions.length} | TIME LIMIT: {props.activityTime}s
                        </>:<>
                            NO. OF PROMPTS: {props.questions.length}
                        </>
                    }
                </div>
                <div className="info_footer">
                    {
                        /* props.teams.length>0 &&  */<div className="teams">
                            {
                                props.teams.map((team) => (
                                    <div className="mini_info">
                                        <div className="icon">
                                            <IconTeam/>
                                        </div>
                                        <div className="label">{team.name}</div>
                                    </div>
                                ))
                            }
                            {
                                props.categories.length>0 && props.categories.map((category) => (
                                    <div className="mini_info" key={category._id}>
                                        <div className="label">{category.categoryName}</div>
                                    </div>
                                ))
                            
                            }
                        </div>
                    }
                    
                   
                   
                </div>
            </div>
        </div>
       <div className="right">
        <div className="live_btn" onClick={props.onThoughtfullyLive}>GO LIVE</div>
        {
            (props.showEditOrView && !props.showEditOrView=== false) && <div className="thoghtfully_mini_trigger" onClick={() => {
                setShowControlPanel(!showControlPanel);
            }}>
                 <span></span>
                <span></span>
                <span></span>
            </div>
        }
        
           
        {
            showControlPanel && <>
                <ControlPanel 
                    style={props.style} 
                    controlType="type1" 
                    setShowControlPanel={setShowControlPanel}
                    onView={ props.onThoughtfullyView ? () => {props.onThoughtfullyView(props.thoughtfullyID);  setShowControlPanel(false); }:null} 
                    onEdit={ props.onThoughtfullyEdit ? () => {props.onThoughtfullyEdit(props.thoughtfullyID);  setShowControlPanel(false); }:null} 
                    onDuplicate={ props.onThoughtfullyDuplicate ? () => {props.onThoughtfullyDuplicate(props.thoughtfullyID);  setShowControlPanel(false);}:null} 
                    // onInvite={ props.onThoughtfullyInvite ? () => {props.onThoughtfullyInvite(props.thoughtfullyID);  setShowControlPanel(false); }:null} 
                    onShare={ props.onThoughtfullyShare ? () => {props.onThoughtfullyShare(props.thoughtfullyID);  setShowControlPanel(false); }:null} 
                    onDelete={ props.onThoughtfullyDelete ? () => {props.onThoughtfullyDelete(props.thoughtfullyID);  setShowControlPanel(false); }:null}
                />
            </>
        }
       </div>
    </div>
  )
}

export default ThoughtfullyMini
