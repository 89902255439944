import React, { useState, useEffect } from 'react'
import { history } from '../history'
import Session from '../session'
import './style.scss'
import StudentProfileIcon from '../studentProfileIcon'
import { Global } from '../../common/global'
import { muteAll, unmuteAll } from '../AudioContext'

const Header = (props) => {
  
  const user = Session.getUserData();
  const [students, setStudents]= useState([
  
  ]);
  
  const updateStudents=(data)=>{
    setStudents(data);
  }
  
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [showList, setShowList] = useState(false);
  /* const logout = () => {
    Session.logout()
    history.push('/')
  } */

  const leaveGame= () => {
    window.close();
  }
  useEffect(() => {
   
    const updateVolume= () => {
      setSoundEnabled(!soundEnabled);
      if(!soundEnabled){
        unmuteAll();
      }else{
        muteAll();
      }
    }
    const isFullscreen= function(){
      return !!(
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      );
    }
    const requestFullScreen= () => {
      if(!isFullscreen()){
        document.body.requestFullscreen();
      }else{
        document.exitFullscreen();
      }
      
    }
    const showFullUsersList= () => {
      setShowList(!showList);
    }
    if(Global.isScreen){
      
      document.querySelector("#volume_btn").addEventListener("click", updateVolume);
      document.querySelector("#screen_resize").addEventListener("click", requestFullScreen);
      document.querySelector("#leave_btn").addEventListener("click", leaveGame);

      
      document.querySelector(".user_list").addEventListener("click", showFullUsersList);
    }
    Global.addEventListener("screen:student_join", updateStudents);

    return () => {
      Global.removeEventListener("screen:student_join", updateStudents);

      if(Global.isScreen){
        document.querySelector("#leave_btn").removeEventListener("click", leaveGame);
        document.querySelector("#volume_btn").removeEventListener("click", updateVolume);
        document.querySelector("#screen_resize").removeEventListener("click", requestFullScreen);
        document.querySelector(".user_list").removeEventListener("click", showFullUsersList);
      }

    };
  }, [soundEnabled, showList]);

  return (
    (Global.isScreen || props.showQnHeader) ? ( <>
    <header className={`header ${props.extraClass} ${props.type=="type1"?'type1':props.type=="type2"?'type2':''}`}>
      <div className="head_container">
        <div className="activity_name">{props.activityName}</div>
        {!props.showQnHeader && <div className="user_list">
            <div className="user_icons">
              {students.map((student, index) => (
                index<4 && <StudentProfileIcon key={student.id} id={student.id} imgUrl={student.img} name={student.name} showName={false} showPhoto={true}/>
              ))}
            </div>
          <div className={students.length>=5?'remaining_total active':'remaining_total'}>{students.length-4}+</div>
          </div>
        }
        <div className="right_controls">
            <div id="volume_btn">
              <img src={`/images/icons/${soundEnabled?'Icon-AudioOn':'Icon-AudioOff'}.svg`} alt="" />
            </div>
            <div id="screen_resize">
              <img src="/images/icons/Icon-FullScreen.svg" alt="" />
            </div>
            <div id="leave_btn">
              <img src="/images/icons/Icon-Leave.svg" alt="" />
              <div className="label">Leave</div>
            </div>
        </div>
      </div>
    </header>
    {showList&&<div className="students_full_list">
      <div className="triangle"></div>
      <div className={students.length<5?'full_list vertical':'full_list'}>
        {
          students.length === 0 && <div className="empty_room_info">There’s nobody here yet!</div>
        }
        {
          students.length>=5 &&  <div className="column_break"></div>
        }
       
      {students.map(student => (
        <StudentProfileIcon className={students.length>=5?'half_width':'full_width'} key={student.id} id={student.id} imgUrl={student.img} name={student.name} showName={true} showPhoto={true} extraNameStyles={{marginLeft:'10px',width:students.length<5?'60%':'100%'}} />
      ))}
      </div>
      
    </div>}
    </> ):(<>
      <header className={`header normal ${props.type=='type1'?'type1':props.type=='type2'?'type2':''}`}>
        <div className="logo">
          <img src="/images/Logo.png" alt="" />
        </div>
      </header>
    </>)
    
  )
}

export default Header
