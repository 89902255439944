


import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconPasswordDark(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor} className={props.className}>
    <g>
      <g id="Layer_1">
        <path id="lock_path" d="M102.7,37.5c2,.4,4,.7,5.9,1.2,13.3,3.8,22.5,15.9,22.6,29.8v15.7c2.1,0,4,0,5.9.3,5.4.8,9.5,5.5,9.7,11,.1,2.9,0,5.8,0,8.7v46.3c0,5.6-3.4,10.1-8.7,11.6-1.1.3-2.2.4-3.4.4h-69.6c-7,0-12.1-5.1-12.1-12.1v-54c0-7,5.1-12,12.1-12.1h3.5v-1.7c0-5.7-.2-11.5.3-17.2,1.3-13.4,11.2-24.2,24.5-27.2,1.3-.3,2.6-.5,3.9-.7h5.4ZM100,92.2h-34.5c-3,0-4.5,1.5-4.5,4.5v53.5c0,3.1,1.5,4.5,4.6,4.5h69c3.1,0,4.6-1.5,4.6-4.6v-53.3c0-3.2-1.5-4.6-4.6-4.6-11.7,0-23.1,0-34.6,0ZM123.4,84.3c0-5.7.2-11.4,0-17-.5-11.8-10.4-21.5-22.1-22-13.6-.6-24.6,9.9-24.7,23.5v14.4c0,.4,0,.7,0,1.1h46.7Z"/>
      </g>
    </g>
  </StyledSVG>
  )
}


