
import { useEffect, useState } from 'react';
import styled from 'styled-components';


const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;

export default function IconHomeFill(props){


  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
      <g>
        <g id="Layer_1">
          <path d="M135.3,157.9h-70.7c0-.1-.2-.1-.3-.2-5.2-1.3-7.9-4.8-7.9-10.1v-42.8h-8.1c-2.1,0-3.9-.7-5.1-2.4-.5-.7-.9-1.6-1.3-2.5v-2.5c.7-1.1,1.2-2.4,2.1-3.3,17.7-17,35.5-34,53.2-51,1.7-1.6,3.7-1.6,5.4,0,.2.2.4.4.6.5,17.1,16.4,34.2,32.8,51.4,49.1,1.4,1.3,2.7,2.7,3.4,4.6v2.5c-.1,0-.2.3-.2.4-.9,2.8-2.9,4.3-5.8,4.4-1.4,0-2.9,0-4.3,0h-4.2v43.4c0,4.1-2.5,7.6-6.3,9-.6.6-1.2.7-1.9.9Z"/>
        </g>
      </g>
    </StyledSVG>
  )
}