import React, { useState, useEffect } from 'react'
import { history } from '../../history'
import './style.scss'
import Button from '../button';
import IconHomeFill from '../icons/Icon-HomeFill';
import IconTeam from '../icons/Icon-Team';
import IconShared from '../icons/Icon-Shared';
import IconTutorial from '../icons/Icon-Tutorial';
import IconFAQ from '../icons/Icon-FAQs';
import IconNeedHelp from '../icons/Icon-NeedHelp';
import IconSettings from '../icons/Icon-Settings';
import IconReport from '../icons/Icon-Report';
import { Global } from '../../../common/global';

const SideMenu = (props) => {
  
  const currentPath = location.pathname;
  const [darkModeClass, setdarkModeClass ]= useState('')
  useEffect(() => {
    setdarkModeClass((props.darkMode&&props.darkMode.length>0)?props.darkMode:(localStorage.getItem('darkMode') == 'true'?'darkMode':''))
    return () => {
    };
  }, [props.darkMode]);
  return (
    <div className='side_menu'>
      <div className="side_menu_container">
          <div className="top">
            <Button 
              className={(currentPath=='/dashboard'||currentPath=='/dashboard/')?`active `:`${darkModeClass}` }
              icon={<IconHomeFill darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/>}
              navigateTo="/dashboard"
              id="side_menu_home"
              value="Dashboard"
            />
            <Button 
              icon={<IconTeam darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/>}
              navigateTo="/dashboard/teams"
              id="side_menu_teams"
              className={(currentPath.indexOf('/dashboard/teams')!=-1)?'active':`${darkModeClass}`}
              value="Teams"
            />
            <Button 
              icon={<IconShared darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/>}
              navigateTo="/dashboard/shared"
              id="side_menu_shared"
              className={(currentPath.indexOf('/dashboard/shared')!=-1)?'active':`${darkModeClass}`}
              value="Shared with me"
            />
            <Button 
              icon={<IconReport darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/>}
              className={(currentPath.indexOf('/dashboard/reports')!=-1)?'active':`${darkModeClass}`}
              value="Reports"
              showLock={!Global.isSubscribed}
              darkModeColor={darkModeClass}
              id="side_menu_reports"
              navigateTo="/dashboard/reports"
            />
          </div>
          <div className={`bottom ${darkModeClass}`}>
              <Button 
                icon={<IconTutorial darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/>}
                value="Tutorial"
                navigateTo="/dashboard/tutorial/1"
                className={`sideBarBottom ${darkModeClass}`}
              />
              <Button 
                icon={<IconFAQ darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/>}
                value="FAQs"
                id="side_menu_faq"
                className={`${(currentPath.indexOf('/dashboard/faq')!=-1)?'active ':''}sideBarBottom ${darkModeClass}`}
                navigateTo="/dashboard/faq"
              />
              <Button 
                icon={<IconNeedHelp darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/>}
                value="Need Help"
                id="side_menu_help"
                navigateTo="/dashboard/help"
                className={`${(currentPath.indexOf('/dashboard/help')!=-1)?'active ':''}sideBarBottom ${darkModeClass}`}
              />
              <Button 
                icon={<IconSettings darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/>}
                value="Settings"
                id="side_menu_settings"
                navigateTo="/dashboard/settings"
                className={`${(currentPath.indexOf('/dashboard/settings')!=-1)?'active ':''}sideBarBottom ${darkModeClass}`}

              />
          </div>
      </div>
    </div>
  )
}

export default SideMenu
