import React, {useEffect, useState} from 'react';
import AppWrapper from '../../containers/app-wrapper';
import '../../styles/avatar.scss';
import StudentProfileIcon from '../../components/studentProfileIcon';
import { Global } from '../../common/global';
import { history } from '../../components/history'
import { useMediaQuery } from 'react-responsive'
// import { uuid } from 'uuidv4';

const AvatarScreen = (props) => {
  
  
    const isPortraitScreen = useMediaQuery({
        query: '(max-width: 1024px) and (max-aspect-ratio: 13/10)'
    });

    const [avatarID, setAvatarID]= useState(null);
    const [avatarList]= useState([
        {
            "key":"avatar1",
            "img":"/images/profileImg/ProfilePhoto01.png"
        },
        {
            "key":"avatar2",
            "img":"/images/profileImg/ProfilePhoto02.png"
        },
        {
            "key":"avatar3",
            "img":"/images/profileImg/ProfilePhoto03.png"
        },
        {
            "key":"avatar4",
            "img":"/images/profileImg/ProfilePhoto04.png"
        },
        {
            "key":"avatar5",
            "img":"/images/profileImg/ProfilePhoto05.png"
        },
        {
            "key":"avatar6",
            "img":"/images/profileImg/ProfilePhoto06.png"
        },
        {
            "key":"avatar7",
            "img":"/images/profileImg/ProfilePhoto07.png"
        },
        {
            "key":"avatar8",
            "img":"/images/profileImg/ProfilePhoto08.png"
        },
        {
            "key":"avatar9",
            "img":"/images/profileImg/ProfilePhoto09.png"
        }
    ]);
    const selectAvatar= (event, id) => {
        setAvatarID(id);
        
        avatarList.forEach((obj) => {
            if(obj['key'] === id){
                Global.imgKey= obj['img'];
                Global.studentData.studentAvatar= obj['img'];
            }
        })
    }
    const shuffleArray = (array) => {
        let currentIndex = array.length;
        let temporaryValue, randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
      
        return array;
      }
     
    const proceedAndConnect= async ()=>{
        if(avatarID ===null) return false;

        // alert(Global.deviceType)
        history.push(`/${Global.deviceType}/${Global.gameID}/wait`)

       /*  initColyseus();
        let connectStatus = await connect();
        console.log(connectStatus,'connectStatus') */
    }
    if(Global.gameMode=="anonymous" && Global.userName == ''){
        Global.userName=shuffleArray([
            'Silent Sunflower',
            'Whispering Wisteria',
            'Dancing Daffodil',
            'Radiant Rose',
            'Gentle Gardenia',
            'Velvet Violet',
            'Charming Chrysanthemum',
            'Luminous Lily',
            'Brilliant Begonia',
            'Peaceful Peony',
            'Glowing Gladiolus',
            'Blushing Bluebell',
            'Delicate Daisy',
            'Majestic Magnolia',
            'Scented Snapdragon',
            'Twinkling Tulip',
            'Heavenly Hydrangea',
            'Graceful Geranium',
            'Mystic Marigold',
            'Pristine Poppy',
            'Sweet Smilax',
            'Bright Buttercup',
            'Lovely Lavender',
            'Cheerful Carnation',
            'Rosy Rhododendron',
            'Humble Hibiscus',
            'Serene Sweetpea',
            'Joyful Jasmine',
            'Gleaming Gardenia',
            'Winsome Wallflower',
            'Quiet Quince',
            'Elegant Elderflower',
            'Mystical Moonflower',
            'Pure Petunia',
            'Radiant Ranunculus',
            'Velvet Verbena',
            'Whimsical Waterlily',
            'Tender Tuberose',
            'Gossamer Goldenrod',
            'Flourishing Foxglove',
            'Bold Bleeding Heart',
            'Noble Narcissus',
            'Fragrant Freesia',
            'Crimson Chrysanthemum',
            'Blooming Buttercup',
            'Regal Rose',
            'Precious Primrose',
            'Dainty Dahlia',
            'Whispering Willowherb',
            'Shining Snowdrop'
        ])[0];
        Global.studentData.studentName=  Global.userName;
    }
    useEffect(() =>{
        if(Global.userName === "") {
            history.push(`/${Global.deviceType}/${Global.gameID}/name`)
        }
        if(avatarID !==null){
            Global.avatarID=avatarID;
            document.querySelector("#readyBtn").classList.remove("disabled")
        }
    }, [avatarID])
    return (
        <AppWrapper type={"type2"}>
            <div className="bg_cover"><img src="/images/BG.svg" alt="" srcset="" /></div>
            <div className="avatar_sec">
                {<div className="avatar_title">
                    Welcome, <span className="bold">{Global.userName},</span><br/>Select a photo from below!
                </div>}
                <div className="avatars">
                    {
                        avatarList.map((avatar) => (
                            <StudentProfileIcon className={avatarID===avatar.key?'active':''} key={avatar.key} id={avatar.key} imgUrl={avatar.img} name="" showName={false} showPhoto={true} onClick={selectAvatar} extraImgStyles={{width: isPortraitScreen?'20vw':'12vh',height: isPortraitScreen?'20vw':'12vh'}}/>

                        ))
                    }
                </div>
                <div id="readyBtn" className='disabled' onClick={proceedAndConnect}>
                    I'M READY
                </div>
            </div>
        </AppWrapper>
    )
}
export default AvatarScreen