import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Header from '../components/header'
import Footer from '../components/footer'
import { Global } from '../common/global';

function App({ children, ...props}) {
  const isLobby= window.location.pathname.indexOf('lobby')!==-1;
  const isScreen= window.location.pathname.indexOf('screen')!==-1;
  const isThoughts=  window.location.pathname.indexOf('thought')!==-1;
  const [canCountDown, setCanCountDown]= useState(false);
  
  const updateWaitTime= (data)=>{
    setCanCountDown(data['question_time']!==0);
  };

  useEffect(() => {
    
    Global.addEventListener("common:game_state_update", updateWaitTime);
    return () => {
      Global.removeEventListener("common:game_state_update", updateWaitTime);
    }
  }, [])
  return (
    <div className={!isScreen?"extraPadd app":"app"}>
      {
        (props.addHeader===undefined || props.addHeader!==undefined && props.addHeader) && <Header extraClass={props.extraClass} {...props.gameData} {...props}/>
      }
      <main>{children}</main>
      {
        (props.addFooter===undefined || props.addFooter!==undefined && props.addFooter) && (!isLobby || !isScreen) && <Footer extraClass={ isScreen?props.extraClass:'normal'} {...props} canCountDown={canCountDown} isThoughts={isThoughts}/>
      }
    </div>
  )
}

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default App
