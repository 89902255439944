import React, { useState, useEffect } from 'react'
import './style.scss'
import classnames from 'classnames';
import ControlPanel from '../controlPanel';
import IconTeam from '../icons/Icon-Team';
import Button from '../button';
import { history } from '../../history';

const ReportsMini = (props) => {
    const [darkModeClass, setdarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      )
    const [showControlPanel, setShowControlPanel]= useState(false);

 
    const openThoughtfully= (thoughtfullyID) => {
        history.push(`/dashboard/reports/${thoughtfullyID}/sessions`);
    }
  useEffect(() => {
    setShowControlPanel(props.showControlPanel)
    return () => {
    };
  }, []);
  const reportsClass = classnames('reports_mini', props.className)

  return (
    <div className={`${reportsClass} ${darkModeClass}`}>
        <div className="left">
            <div className="cover_photo">
                <img src={props.activityThumbnail} alt="" srcset="" />
            </div>
            <div className="report_mini_info">
                <div className="report_title">{props.activityName}</div>
            </div>
        </div>
       <div className="right">
            <div className="info_sec">
                <span>No. of Live Sessions: <span className='bold'>{props.sessionsTotal}</span> | </span><span>Last Live: <span className="bold">{props.sessionsTotal==0?'----':props.lastSession}</span></span>
            </div>
            <div className="buttons">
                <Button 
                    className={`${props.sessionsTotal==0?'disabled ':''}dark_grey regular-text text-center`}
                    value="View Report"
                    onClick={openThoughtfully.bind(this, props.activityId)}
                />
                {/* <Button 
                    className={`${props.sessionsTotal==0?'disabled ':''}dark_grey no-margin`}
                    icon="/images/dashboard/icons/download.png"
                    // onClick={openThoughtfully.bind(this, props.activityId)}
                /> */}
            </div>
       </div>
    </div>
  )
}

export default ReportsMini
