import React, { useEffect, useState } from 'react';
import '../../styles/dashboard/register.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import { doGmailRegister, doRegister } from '../../common/api';
import { Link } from 'react-router-dom';
import Button from '../../components/dashboard/button';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useGoogleLogin } from '@react-oauth/google';
import IconProfile from '../../components/dashboard/icons/Icon-Profile';
import IconPassword from '../../components/dashboard/icons/Icon-Password';
import axios from 'axios';
import { history } from '../../components/history';
import Infos from '../../components/dashboard/Infos';
import IconPasswordShow from '../../components/dashboard/icons/Icon-Password-Show';
import IconPasswordHide from '../../components/dashboard/icons/Icon-Password-Hide';


const RegisterPage = (props) => {
    if (props.profileData && props.profileData._id) {
        history.push('/dashboard');
    }

    const [canShowPassword, setCanShowPassword]= useState(false);
    const [canShowConfirmPassword, setCanShowConfirmPassword]= useState(false);
    const [canShowTerms, setCanShowTerms]= useState(false);
    const [canShowPrivacyPolicy, setCanShowPrivacyPolicy]= useState(false);
    const [canShowCookie, setCanShowCookie]= useState(false);
    const [registerCheckActive, setRegisterCheckActive] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [validData, setValidData] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');

    const [gmailUser, setGmailUser]= useState(null)
    // Initialize the Google login hook
    const signUp = useGoogleLogin({
        onSuccess: function(user){
            setGmailUser(user);
        },
        onError: (error) => {
        },
    });

    const handleGoogleSignUp = () => {
        signUp();
    };

    const registerCheck = async () => {
        if (email.length === 0) {
            setErrorMsg("Invalid Email");
            setValidData(false);
            return false;
        }
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            setErrorMsg("Invalid Email");
            setValidData(false);
            return false;
        }
        if (password.length === 0) {
            setErrorMsg("Invalid Password");
            setValidData(false);
            return false;
        }
        if (confirmPassword !== password) {
            setErrorMsg("Password Mismatch");
            setValidData(false);
            return false;
        }
        if (!termsAgreed) {
            setErrorMsg("Please accept the terms and conditions");
            setValidData(false);
            return false;
        }
        setRegisterCheckActive(true);
        setValidData(true);
        setErrorMsg('');
        const res = await doRegister(email, password);
        setRegisterCheckActive(false);
        if (res.data && res.data.status === 200) {
            localStorage.setItem('tutorialShown', false);
            if(!res.data.tutorialShown){
                location.href='/dashboard/tutorial/1';
              }else{
                location.href='/dashboard';
              }
        } else if (res.data.status === 422) {
            setErrorMsg("User with same email id exists already.");
            setValidData(false);
        }
    };
    useEffect(() => {
        if (gmailUser) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${gmailUser.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${gmailUser.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then(async (res) => {
                    const res2= await doGmailRegister({
                        email: res.data.email,
                        password: res.data.id,
                        profileImg: res.data.picture,
                        fullName: res.data.name,
                        savePassword:false,
                    })
                    if (res2.data && res2.data.status === 200) {
                        localStorage.setItem('tutorialShown', false);
                        localStorage.setItem('darkMode', false);
                        if(!res2.data.tutorialShown){
                            location.href='/dashboard/tutorial/1';
                          }else{
                            location.href='/dashboard';
                          }
                    } else if (res2.data.status === 422) {
                        setErrorMsg("User with same email id exists already.");
                        setValidData(false);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [gmailUser])
    return (
        <DashboardApp extraClass={"landscape_only"} addHeader={false}>
            <div className="register_container">
                <div className="left">
                    <div className="logo">
                        {localStorage.getItem('darkMode') !== 'true' ?
                            <img src="/images/Logo.png" alt="" /> :
                            <img src="/images/LogoW.png" alt="" />}
                    </div>
                </div>
                <div className="right">
                    <div className="register_area">
                        <div className="head">
                            <div className="title">Get Started</div>
                            <div className="redirect_sec">Have an account <Link to={{ pathname: "/dashboard/login" }}>Login</Link></div>
                        </div>
                        <div className="content">
                            <div className='field'>
                                <div className="icon"><IconProfile /></div>
                                <input
                                    type="email"
                                    name="register_email"
                                    id="register_email"
                                    value={email}
                                    onChange={(v) => { setEmail(v.target.value) }}
                                    placeholder="Email"
                                    autocomplete="off"
                                />
                            </div>
                            <div className='field'>
                                <div className="icon"><IconPassword /></div>
                                <input
                                    type={canShowPassword?"text":"password"}
                                    name="register_password"
                                    id="register_password"
                                    value={password}
                                    onChange={(v) => { setPassword(v.target.value) }}
                                    placeholder="Password"
                                    autocomplete="off"
                                />
                                <div className="icon2" onClick={()=> {
                                    setCanShowPassword(!canShowPassword);
                                }}>
                                     {
                                        !canShowPassword?<IconPasswordShow />:<IconPasswordHide/>
                                    }
                                </div>
                            </div>
                            {password.length > 0 &&
                                <PasswordStrengthBar password={password} scoreWordStyle={{ fontFamily: 'MontserratLight', color: '#ffffff' }} />
                            }
                            <div className='field'>
                                <div className="icon"><IconPassword /></div>
                                <input
                                    type={canShowConfirmPassword?"text":"password"}
                                    name="register_confirm_password"
                                    id="register_confirm_password"
                                    value={confirmPassword}
                                    onChange={(v) => { setConfirmPassword(v.target.value) }}
                                    placeholder="Confirm Password"
                                    autocomplete="off"
                                />
                                <div className="icon2" onClick={() => {
                                    setCanShowConfirmPassword(!canShowConfirmPassword);
                                }}>
                                    {
                                        !canShowConfirmPassword?<IconPasswordShow />:<IconPasswordHide/>
                                    }
                                </div>
                            </div>
                            <div className="terms_sec">
                                <div className="check"><input type="checkbox" name="t_c_check" id="t_c_check" checked={termsAgreed} onChange={(v) => { setTermsAgreed(v.target.checked) }} /></div>
                                <div className="label">I agree to the <u onClick={setCanShowTerms.bind(this, true)}>Terms & Conditions</u> and <u onClick={setCanShowPrivacyPolicy.bind(this, true)}>Privacy Policy</u>.</div>
                            </div>
                            <div className="buttons">
                                <Button
                                    className={`yellow bold-text ${registerCheckActive ? 'disabled' : ''} text-center`}
                                    style={{ borderRadius: '30px', padding: '15px 0' }}
                                    value="SIGN UP"
                                    onClick={registerCheck}
                                />
                                {!validData && <div className="signup_error">{errorMsg}</div>}
                                <div className="divider">
                                    <span className="dash"></span>
                                    <span className="or">OR</span>
                                    <span className="dash"></span>
                                </div>
                                <Button
                                    icon="/images/dashboard/icons/Icon-Google.svg"
                                    className="white_transparent big_icon text-center"
                                    style={{ borderRadius: '30px', padding: '2px 15px', color: '#ffffff' }}
                                    value="Sign Up with Google"
                                    onClick={handleGoogleSignUp}
                                />
                            </div>
                            <div className="cookie_notice"><u onClick={setCanShowCookie.bind(this, true)}>Cookies Notice</u></div>
                        </div>
                    </div>
                </div>
            </div>
            <Infos 
                canShowTerms={canShowTerms} 
                canShowPrivacyPolicy={canShowPrivacyPolicy} 
                canShowCookie={canShowCookie} 
                setCanShowTerms={setCanShowTerms} 
                setCanShowPrivacyPolicy={setCanShowPrivacyPolicy} 
                setCanShowCookie={setCanShowCookie}
            />
        </DashboardApp>
    );
};

export default RegisterPage;
