

import { useEffect, useState } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconLaptop(props){

  return(
    <>
        <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" darkModeColor={props.darkModeColor}>
        <g>
            <g id="Layer_1">
            <path d="M95.4,359.6h321.1c6.6,0,12-5.4,12-12v-206.4c0-6.6-5.4-12-12-12H95.4c-6.6,0-12,5.4-12,12v206.4c0,6.6,5.4,12,12,12ZM93.7,141.1c0-.9.8-1.7,1.7-1.7h321.1c.9,0,1.7.8,1.7,1.7v206.4c0,.9-.8,1.7-1.7,1.7H95.4c-.9,0-1.7-.8-1.7-1.7v-206.4ZM454.5,368.8v9.6c0,2.6-2.1,4.6-4.6,4.6H62.1c-2.6,0-4.6-2.1-4.6-4.6v-9.6h163c.8,2.7,3.3,4.7,6.2,4.7h58.5c2.9,0,5.4-2,6.2-4.7h163Z"/>
            </g>
        </g>
        </StyledSVG>
    </>
  )
}