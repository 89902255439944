import React, { useState, useEffect } from 'react'
import { history } from '../../history'
import './style.scss'
import Button from '../button';
import classnames from 'classnames';
import ControlPanel from '../controlPanel';
import IconDelete from '../icons/Icon-Delete';

const TeamAndMemberMini = (props) => {
    const [darkModeClass, setdarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      )
    const [showControlPanel, setShowControlPanel]= useState(false);
  useEffect(() => {
    // setShowControlPanel(props.showControlPanel)
    return () => {
    };
  }, []);
  const teamClass = classnames('team_mini', props.className)

  return (
    <div className={`${teamClass} ${darkModeClass}`}>
        <div className={`${!props.isTeam?'left inline':`left`} ${props.actionType === "view"?'full_size':''}`}>
            {
                 props.saved ? <>
                    {
                        props.isTeam?<>
                            <div className="team_title">
                                        {props.name}
                            </div>
                            <div className="team_members">
                                NO. OF MEMBERS: {props.members.length/* +props.pendingMembers.length */}
                            </div>
                        </>:
                        <>
                            <div className="user_email">
                                    {props.email}
                            </div>
                            <div className="access_field">
                                <select id="dropdown" value={props.accessLevel}  onChange={(v) => {props.updateAccessLevel(props.email, v.target.value)}} disabled={(props.actionType === "view")}>
                                    <option value='viewer'>Viewer</option>
                                    <option value='editor'>Editor</option>
                                </select>
                            </div>
                        </>
                    }
                    
                 </>:<>
                    <div className="email_field">
                        <input type="email" name="team_email" id="team_email" placeholder='Email' value={props.newMember}  onChange={(v) => {props.setNewMember(v.target.value)}} autocomplete="off"/>
                        {/* {
                            props.teachers.length >0 && <select id="dropdown" value={props.newMember} onChange={(v) => {props.setNewMember(v.target.value)}}>
                                <option value="">SELECT MEMBERS</option>
                                {
                                    props.teachers.map((teacher) => (
                                        !teacher.added && <option value={teacher._id}>{teacher.email}</option>
                                    ))
                                }
                            </select>
                        } */}
                    
                    </div>
                    <div className="access_field">
                        <select id="dropdown"  value={props.newMemberAccessLevel}  onChange={(v) => {props.setNewMemberAccessLevel(v.target.value)}}>
                            <option value='viewer'>Viewer</option>
                            <option value='editor'>Editor</option>
                        </select>
                    </div>
                 </>
            }
            
        </div>
       <div className="right">
        {
            props.isTeam ? <>
                <div className="team_mini_trigger" onClick={() => {setShowControlPanel(!showControlPanel)}}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </>:<>
                {
                    !props.saved?<>
                        <Button 
                            className={`${props.newMember.length==0?'disabled':''} yellow bold-text`}
                            value="ADD"
                            style={{fontSize:'10px'}}
                            onClick={props.addNewMember}
                        />
                    </>:<>
                        {
                            (props.actionType !== "view") && <div className="deleteBtn" onClick={() => {props.removeMember(props.email)}}>
                                <IconDelete/>
                            </div>
                        }
                    </>
                }
            </>
        }
        {
            //  
        }
        {
            showControlPanel && <>
               <ControlPanel style={{right:'0',top:'40px'}} controlType="type2" setShowControlPanel={setShowControlPanel} onView={ () => {props.onTeamView(props.teamID);  setShowControlPanel(false); }} onEdit={ () => {props.onTeamEdit(props.teamID);  setShowControlPanel(false); }} onDuplicate={ () => {props.onTeamDuplicate(props.teamID);  setShowControlPanel(false); }} onDelete={ () => {props.onTeamDelete(props.teamID);  setShowControlPanel(false); }}/>
            </>
        }
       </div>
    </div>
  )
}

export default TeamAndMemberMini
