import axios from "axios";


const REACT_APP_API_URL= process.env.NODE_ENV=='development'?process.env.REACT_APP_API_URL:'/';

async function isAuthenticated() {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}api/check-session`, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}

async function getProfile() {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}api/getprofile`, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function getSessionInfos(activityId, sessionId) {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}api/activity/${activityId}/questions/${sessionId}`, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function downloadSessionInfos(activityId, sessionId) {
  try {
    const response = await fetch(`${REACT_APP_API_URL}api/download-report/${activityId}/${sessionId}`,{
      method: 'GET', // or 'POST', 'PUT', etc.
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include' // or 'same-origin'
    }); 
    return response;//['data'];
  } catch (error) {
    return false;
  }
}


async function getActivityInfo(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/getActivityInfo`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}

async function updateProfile(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/updateProfile`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function updatePassword(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/updatePassword`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function saveThoughtfully(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/saveThoughtfully`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function updateThoughtfully(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/updateThoughtfully`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function thoughtfullyShareTo(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/thoughtfullyShareTo`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}

async function saveDuplicatedThoughtfully(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/duplicateThoughtfully`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function saveDuplicatedTeam(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/duplicateTeam`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function logout(data) {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}api/logout`, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}

async function doLogin(data) {
  try {
    const res = await axios.post(`${REACT_APP_API_URL}api/login`, data, {
      withCredentials: true
    })
    return res;
  } catch (err) {
    return err;
  }
}

async function deleteUser(data) {
  try {
    const res = await axios.delete(`${REACT_APP_API_URL}api/teacher`, {
      withCredentials: true
    })
    return res;
  } catch (err) {
    return err;
  }
}

async function doRegister(email, password) {
  try {
    const res = await axios.post(`${REACT_APP_API_URL}api/register`, {
      email,
      password
    }, {
      withCredentials: true
    })
    return res;
  } catch (err) {
    return err;
  }
}

async function doGmailRegister(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/registerGmail`, data, {
      withCredentials: true
    });

    return response;
  } catch (err) {
    return err;
  }
}

async function saveProfileImg(data) {
  try {


    const response = await axios.post(`${REACT_APP_API_URL}api/saveProfileImage`, data, {
  /*     headers: {
        'Content-Type': 'multipart/form-data'
      }, */
      withCredentials: true
    });

    return response;
  } catch (err) {
    return err;
  }
}

async function fetchCreatedTeams(fetchMembers) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/fetchCreatedTeams`, {
      fetchMembers
    }, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function fetchCreatedThoughtfully() {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}api/fetchCreatedThoughtfully`, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function fetchSharedThoughtfully() {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}api/fetchSharedThoughtfully`, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function fetchTeachers() {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}api/fetchTeachers`, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function getRecentActivities(id) {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}api/getRecentActivities`, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function updateThoughtfullyLiveInfo(id, clientId) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/updateThoughtfullyLiveInfo`, {
      id,
      clientId
    }, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function fetchThoughtfullyByID(id) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/fetchThoughtfullyByID`, {
      id
    }, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function fetchCategories() {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}api/fetchCategories`, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}

async function deleteThoughtfullyByID(id) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/deleteThoughtfullyByID`, {
      id
    }, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
};
async function getActivityClientID(id) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/getActivityClientID`, {
      id
    }, {
      withCredentials: true
    });
    return response['data'];s
  } catch (error) {
    return false;
  }
};

async function deleteTeamByID(id) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/deleteTeamByID`, {
      id
    }, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
};

async function sendRecoveryCode(email) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/sendRecoveryCode`, {
      email
    }, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
};

async function verifyRecoveryCode(recoveryCode) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/verifyRecoveryCode`, {
      recoveryCode
    }, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
};
async function changeNewPassword(password, recoveryCode) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/changeNewPassword`, {
      password,
      recoveryCode
    }, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
};



async function createTeam(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/createTeam`, {
      teamName: data.teamName,
      members: data.members
    }, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}

async function updateTeam(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/updateTeam`, {
      id: data.id,
      teamName: data.teamName,
      members: data.members
    }, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function updateTheme(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/updateTheme`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function updateIntroStats(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/updateIntroStats`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}


async function updateTimeZone(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/updateTimeZone`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}

async function addNewCategory(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/addNewCategory`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function addNewStudent(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/addNewStudent`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function saveStudentThoughts(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/saveStudentThoughts`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function getAllActivities() {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}api/getAllActivities`, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function getAllActivitySessions(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/getAllActivitySessions`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}
async function saveSessionID(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/saveSessionID`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}





async function sendEnqiury(data) {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}api/sendEnqiury`, data, {
      withCredentials: true
    });
    return response['data'];
  } catch (error) {
    return false;
  }
}


export {
  logout,
  doLogin,
  doRegister,
  isAuthenticated,
  getProfile,
  updateProfile,
  saveProfileImg,
  updatePassword,
  saveThoughtfully,
  updateThoughtfully,
  fetchCreatedThoughtfully,
  fetchSharedThoughtfully,
  fetchCreatedTeams,
  saveDuplicatedThoughtfully,
  saveDuplicatedTeam,
  fetchThoughtfullyByID,
  deleteThoughtfullyByID,
  deleteTeamByID,
  fetchTeachers,
  createTeam,
  sendRecoveryCode,
  verifyRecoveryCode,
  changeNewPassword,
  thoughtfullyShareTo,
  updateThoughtfullyLiveInfo,
  updateTeam,
  getActivityClientID,
  getRecentActivities,
  deleteUser,
  updateTheme,
  updateIntroStats,
  updateTimeZone,
  doGmailRegister,
  sendEnqiury,
  addNewCategory,
  fetchCategories,
  getActivityInfo,
  addNewStudent,
  saveStudentThoughts,
  getAllActivities,
  saveSessionID,
  getAllActivitySessions,
  getSessionInfos,
  downloadSessionInfos
}