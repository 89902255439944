import React, { useState, useEffect } from 'react'
import { history } from '../../history'
import './style.scss'
import Button from '../button';
import classnames from 'classnames';
import ControlPanel from '../controlPanel';
import IconDelete from '../icons/Icon-Delete';

const ShareMini = (props) => {
    const [darkModeClass, setdarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      )
    const [showControlPanel, setShowControlPanel]= useState(false);
  useEffect(() => {
    return () => {
    };
  }, []);
  const teamClass = classnames('share_mini', props.className)

  return (
    <div className={`${teamClass} ${darkModeClass}`}>
        <div className="left inline">
            {
                 props.saved ? <>
                    <div className="user_email">
                                    {props.email}
                    </div>
                    <div className="access_field">
                                <select id="dropdown" value={props.accessLevel}  onChange={(v) => {props.updateAccessLevel(props.email, v.target.value)}} disabled={(props.actionType === "view")}>
                                    <option value='viewer'>Viewer</option>
                                    <option value='editor'>Editor</option>
                                </select>
                            </div>
                 </>:<>
                    {/* <div className="email_field">
                        {
                            props.teachers.length >0 && <select id="dropdown" value={props.newShare} onChange={(v) => {props.setNewShare(v.target.value)}}>
                                <option value="">SELECT MEMBERS</option>
                                {
                                    props.teachers.map((teacher) => (
                                        !teacher.added && <option value={teacher._id}>{teacher.email}</option>
                                    ))
                                }
                            </select>
                        }
                    
                    </div> */}
                    <div className="email_field">
                        <input type="email" name="team_email" id="team_email" placeholder='Email' value={props.newShare}  onChange={(v) => {props.setNewShare(v.target.value)}} autocomplete="off"/>
                        {/* {
                            props.teachers.length >0 && <select id="dropdown" value={props.newMember} onChange={(v) => {props.setNewMember(v.target.value)}}>
                                <option value="">SELECT MEMBERS</option>
                                {
                                    props.teachers.map((teacher) => (
                                        !teacher.added && <option value={teacher._id}>{teacher.email}</option>
                                    ))
                                }
                            </select>
                        } */}
                    
                    </div>
                    <div className="access_field">
                        <select id="dropdown"  value={props.newMemberAccessLevel}  onChange={(v) => {props.setNewMemberAccessLevel(v.target.value)}}>
                            <option value='viewer'>Viewer</option>
                            <option value='editor'>Editor</option>
                        </select>
                    </div>
                 </>
            }
            
        </div>
       <div className="right">
       {
                    !props.saved?<>
                        <Button 
                            className={`${props.newShare.length==0?'disabled':''} yellow bold-text`}
                            value="ADD"
                            style={{fontSize:'10px'}}
                            onClick={props.addNewShare}
                        />
                    </>:<>
                        {
                            (props.actionType !== "view") && <div className="deleteBtn" onClick={() => {props.removeShare(props.email)}}>
                                <IconDelete/>
                            </div>
                        }
                    </>
        }
       
        {
            showControlPanel && <>
               <ControlPanel style={{right:'0',top:'40px'}} controlType="type2" setShowControlPanel={setShowControlPanel} onView={ () => {props.onTeamView(props.teamID);  setShowControlPanel(false); }} onEdit={ () => {props.onTeamEdit(props.teamID);  setShowControlPanel(false); }} onDuplicate={ () => {props.onTeamDuplicate(props.teamID);  setShowControlPanel(false); }} onDelete={ () => {props.onTeamDelete(props.teamID);  setShowControlPanel(false); }}/>
            </>
        }
       </div>
    </div>
  )
}

export default ShareMini
