import React, { useState, useEffect } from 'react'
import './style.scss'
import classnames from 'classnames';
import ControlPanel from '../controlPanel';
import IconTeam from '../icons/Icon-Team';
import Button from '../button';
import { history } from '../../history';
import { downloadSessionInfos } from '../../../common/api';

const SessionsMini = (props) => {
    const [darkModeClass, setdarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      )
    const [showControlPanel, setShowControlPanel]= useState(false);

 
    const openSession= (thoughtfullyID, sessionID) => {
        history.push(`/dashboard/reports/${thoughtfullyID}/sessions/${sessionID}`);
    }
    const downloadSession= async (thoughtfullyID, sessionID) => {
        const response= await downloadSessionInfos(thoughtfullyID, sessionID);
        // return false;

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
  useEffect(() => {
    setShowControlPanel(props.showControlPanel)
    return () => {
    };
  }, []);
  const reportsClass = classnames('reports_mini', props.className)

  return (
    <div className={`${reportsClass} ${darkModeClass}`}>
        <div className="left">
            <div className="cover_photo">
                <img src={props.activityThumbnail} alt="" srcset="" />
            </div>
            <div className="report_mini_info">
                <div className="report_title">{props.activityName} Thoughtfully_{props.sessionId}</div>
            </div>
        </div>
       <div className="right">
            <div className="info_sec">
                Last Live: <span className="bold">{props.lastLiveAt/* new Date(props.lastLiveAt).toUTCString().replace('GMT', '').trim() */}</span>
            </div>
            <div className="buttons">
                <Button 
                    className={`dark_grey regular-text text-center`}
                    value="View Report"
                    onClick={openSession.bind(this, props.activityId, props.sessionId)}
                />
                <Button 
                    className={`dark_grey no-margin`}
                    icon="/images/dashboard/icons/download.png"
                    onClick={downloadSession.bind(this, props.activityId, props.sessionId)}
                />
            </div>
       </div>
    </div>
  )
}

export default SessionsMini
