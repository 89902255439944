let lobbyAudio = null;
let prompt30Audio = null;
let prompt45Audio = null;
let prompt60Audio = null;
let prompt75Audio = null;
let prompt90Audio = null;
let prompt105Audio = null;
let prompt120Audio = null;
let promptEndAudio = null;
let waitAudio = null;

function initAudio() {
    lobbyAudio = new Audio('/audios/lobby.mp3');
    waitAudio = new Audio('/audios/wait.mp3');
    lobbyAudio.loop = true;
    waitAudio.loop = true;
    prompt30Audio = new Audio('/audios/30s.mp3');
    prompt45Audio = new Audio('/audios/45s.mp3');
    prompt60Audio = new Audio('/audios/60s.mp3');
    prompt75Audio = new Audio('/audios/75s.mp3');
    prompt90Audio = new Audio('/audios/90s.mp3');
    prompt105Audio = new Audio('/audios/105s.mp3');
    prompt120Audio = new Audio('/audios/120s.mp3');
    promptEndAudio = new Audio('/audios/end.mp3');


    lobbyAudio.volume = 0;
    waitAudio.volume = 0;
    prompt30Audio.volume = 0;
    prompt45Audio.volume = 0;
    prompt60Audio.volume = 0;
    prompt75Audio.volume = 0;
    prompt90Audio.volume = 0;
    prompt105Audio.volume = 0;
    prompt120Audio.volume = 0;
    promptEndAudio.volume = 0;
}

function playLobbyAudio() {
    lobbyAudio.volume=1;
    lobbyAudio.play();
}

function playWaitAudio() {
    stopAll('waitAudio');
    waitAudio.volume=1;
    waitAudio.play();
}

function playPromptAudio(key) {
   let matchAudio = null;

    switch (key) {
        case 30:
            prompt30Audio.volume=1;
            prompt30Audio.currentTime=0
            prompt30Audio.play();
            matchAudio = 'prompt30Audio';
            break;
        case 45:
            prompt45Audio.volume=1;
            prompt45Audio.currentTime=0
            prompt45Audio.play();
            matchAudio = 'prompt45Audio';
            break;
        case 60:
            prompt60Audio.volume=1;
            prompt60Audio.currentTime=0
            prompt60Audio.play();
            matchAudio = 'prompt60Audio';
            break;
        case 75:
            prompt75Audio.volume=1;
            prompt75Audio.currentTime=0
            prompt75Audio.play();
            matchAudio = 'prompt75Audio';
            break;
        case 90:
            prompt90Audio.volume=1;
            prompt90Audio.currentTime=0
            prompt90Audio.play();
            matchAudio = 'prompt90Audio';
            break;
        case 105:
            prompt105Audio.volume=1;
            prompt105Audio.currentTime=0
            prompt105Audio.play();
            matchAudio = 'prompt105Audio';
            break;
        case 120:
            prompt120Audio.volume=1;
            prompt120Audio.currentTime=0
            prompt120Audio.play();
            matchAudio = 'prompt120Audio';
            break;
        case 'end':
            promptEndAudio.volume=1;
            promptEndAudio.currentTime=0
            promptEndAudio.play();
            matchAudio = 'promptEndAudio';
            break;
    }
    matchAudio!=null && stopAll(matchAudio);
}

function fadeOut(audio, duration) {
    if (audio.volume === 0) return;
    console.log('fadeOut', audio);

    let volume = audio.volume;
    const fadeInterval = 50; // milliseconds
    const fadeStep = fadeInterval / duration; // volume decrease per interval

    const fadeOutInterval = setInterval(() => {
        volume -= fadeStep;
        if (volume <= 0) {
            audio.volume = 0;
            audio.currentTime = 0;
            audio.pause();
            clearInterval(fadeOutInterval);
        } else {
            audio.volume = volume;
        }
    }, fadeInterval);
}

function stopAll(audioToAvoid) {
    console.log(audioToAvoid,'audioToAvoid')
    if (audioToAvoid !== 'lobbyAudio') fadeOut(lobbyAudio, 1000);
    if (audioToAvoid !== 'waitAudio') fadeOut(waitAudio, 1000);
    if (audioToAvoid !== 'prompt30Audio') fadeOut(prompt30Audio, 1000);
    if (audioToAvoid !== 'prompt45Audio') fadeOut(prompt45Audio, 1000);
    if (audioToAvoid !== 'prompt60Audio') fadeOut(prompt60Audio, 1000);
    if (audioToAvoid !== 'prompt75Audio') fadeOut(prompt75Audio, 1000);
    if (audioToAvoid !== 'prompt90Audio') fadeOut(prompt90Audio, 1000);
    if (audioToAvoid !== 'prompt105Audio') fadeOut(prompt105Audio, 1000);
    if (audioToAvoid !== 'prompt120Audio') fadeOut(prompt120Audio, 1000);
    if (audioToAvoid !== 'promptEndAudio') fadeOut(promptEndAudio, 1000);
}

function muteAll() {
    lobbyAudio.muted = true;
    waitAudio.muted = true;
    prompt30Audio.muted = true;
    prompt45Audio.muted = true;
    prompt60Audio.muted = true;
    prompt75Audio.muted = true;
    prompt90Audio.muted = true;
    prompt105Audio.muted = true;
    prompt120Audio.muted = true;
    promptEndAudio.muted = true;
}

function unmuteAll() {
    lobbyAudio.muted = false;
    waitAudio.muted = false;
    prompt30Audio.muted = false;
    prompt45Audio.muted = false;
    prompt60Audio.muted = false;
    prompt75Audio.muted = false;
    prompt90Audio.muted = false;
    prompt105Audio.muted = false;
    prompt120Audio.muted = false;
    promptEndAudio.muted = false;
}

module.exports = {
    initAudio,
    playLobbyAudio,
    playWaitAudio,
    playPromptAudio,
    muteAll,
    unmuteAll,
    stopAll
}
