import React, { useState, useEffect } from 'react'
import './style.scss'
import classnames from 'classnames';
import ControlPanel from '../controlPanel';
import Button from '../button';
import IconDelete from '../icons/Icon-Delete';

const QuestionMini = (props) => {
    const [darkModeClass, setdarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      )
    const [showControlPanel, setShowControlPanel]= useState(false);
  useEffect(() => {
    return () => {
    };
  }, []);
  const thoughtfullyClass = classnames('question_mini', props.className)

  return (
    <div className={`${thoughtfullyClass} ${darkModeClass}`}>
        <div className="left">
            <div className="qn_index">
                {props.qnIndex<10?'0':''}{props.qnIndex}
            </div>
            {
                props.saved ? <>
                    <div className="question_sec">
                        {props.question}
                    </div>
                </> : <>
                    <div className="question_field">
                        <input type="text" name="question" id="question" placeholder='Enter your prompt' value={props.question} onChange={(e) => props.updateQuestion(props.qnIndex, e.target.value)} maxLength={150}/>
                    </div>
                </>
            }
            
        </div>
       <div className="right">
        {
            !props.saved ?<>
                <div className={props.question.trim().length==0?"addBtn disabled":"addBtn"} onClick={props.question.trim().length==0?null:() => {
                     props.saveQuestion(props.qnIndex)
                }}>ADD</div>
               
                <div className="deleteBtn" style={{width:'40px'}} onClick={()=>{
                    props.onQnDelete(props.qnIndex)
                }}>
                    <IconDelete/>
                </div>
            </>:<>
                {
                    props.extraTime>0 && <div className="extraTimeInfo">+{props.extraTime}s</div>
                }
                {
                    props.actionType!=='view' && <>
                    <div className="arrow_ctrl">
                        {
                            props.qnIndex>1 && <div className="arrow_border" onClick={props.updateQnOrder.bind(this, props.qnIndex-1, -1)}><div class="arrow up"></div></div>
                        }
                        {
                            props.qnIndex<props.qnMax && <div className="arrow_border" onClick={props.updateQnOrder.bind(this, props.qnIndex-1, 1)}><div class="arrow down"></div></div>
                        }
                            
                            
                        </div>
                        <div className="thoghtfully_mini_trigger" onClick={() => {
                            // props.showQnMenu(props.qnIndex)
                            setShowControlPanel(!showControlPanel);
                        }}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        
                    </>
                }
                
                
            </>
        }
       
        {
            showControlPanel && <>
               <ControlPanel style={{right:'0',top:'40px'}} controlType="type2" setShowControlPanel={setShowControlPanel} onEdit={ () => {props.onQnEdit(props.qnIndex);  setShowControlPanel(false); }} onDuplicate={ () => {props.onQnDuplicate(props.qnIndex);  setShowControlPanel(false); }} onDelete={ () => {props.onQnDelete(props.qnIndex);  setShowControlPanel(false); }}/>
            </>
        }
       </div>
    </div>
  )
}

export default QuestionMini
