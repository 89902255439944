
import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconDOB(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
    <g>
      <g id="Layer_1">
        <g>
          <path d="M99.9,71.8c1.7,0,3.5-.5,5.2-1.4,3.8-2.2,5.7-7.2,4.3-11.3-1.6-4.4-4.1-8.3-6.5-12-.3-.5-.8-.9-1.2-1.3-.2,0-.3-.3-.5-.4l-.3-.3h-1.6l-.3.2c0,0-.2.2-.3.2-.4.2-.8.5-1.1,1-.4.7-.9,1.5-1.3,2.2-1.7,2.8-3.4,5.7-4.9,8.7-2,3.9-1.5,7.7,1.4,11.1,1.8,2.2,4.4,3.3,7.1,3.3ZM102.5,65.4c-1.4,1-3.5,1-5,0-1.5-1.1-2.2-2.9-1.6-4.5,1-2.8,2.5-5.4,4.1-8.1,0,.2.2.4.3.6.9,1.6,1.8,3.1,2.6,4.7.4.8.8,1.8,1.2,2.7.5,1.7,0,3.6-1.6,4.6Z"/>
          <path d="M148.9,131.7c3.4-2.3,5.1-5.6,5.1-9.6.1-5.6.1-11.1,0-15.7,0-5.4-4-9.7-9.1-9.9-1.5,0-3,0-4.5,0h-2.4v-16.1c0-2.2-1-3.3-3.3-3.3h-9.3c-2.5,0-3.5,1-3.5,3.5v15.8h-13.9v-19.5c0-2.3-1.1-3.4-3.4-3.4h-9c-2.7,0-3.7,1-3.7,3.7v19.2h-13.9v-15.8c0-2.5-1-3.5-3.5-3.5h-9.1c-2.7,0-3.6.9-3.6,3.6v15.8h-5.6c-5.9,0-10.1,4.1-10.2,9.7,0,5.5,0,11.1,0,16.8,0,3.5,1.8,6.5,4.8,8.7.4.2.4.4.4.8v16.9h0c-.6.1-1.1.1-1.6.1-1.4,0-2.3.3-3,1-.5.5-.7,1.1-.7,1.9s.5,2.7,3.6,2.7h100.8c.4,0,1,0,1.5-.1,1.3-.3,2.1-1.3,2.1-2.7s-.8-2.3-2-2.6c-.5-.1-1.1-.2-1.6-.2h-1.6v-17.5c0,0,.2-.2.2-.2ZM67.5,96.3v-13.8h5v13.8h-5ZM97.5,96.2v-17.3h5v17.3h-5ZM127.6,96.2v-13.7h4.9v13.7h-4.9ZM56.9,133.8c.3,0,.6-.1.9-.1,1.3-.2,2.6-.3,3.8-.7,4.7-1.6,7.6-5.7,7.7-10.9v-6.6c0-1.7.9-2.8,2.5-2.9.6,0,1.2.2,1.7.7s.9,1.3.9,2.1v10c0,4.9,2.3,8.5,6.5,10.7,2.9,1.5,6.4,1.6,9.6.3s5.5-3.8,6.4-6.9c.4-1.3.6-2.8.7-4.3,0-2.4,0-4.9,0-7.2v-2.5c0-.9.2-1.6.8-2.2.7-.7,1.5-.9,2.4-.6.7.2,1.6.8,1.8,2,0,1,0,2,.2,3.1v.6c0,5.5,3.3,9.8,8.6,11.4,3.2,1,6.7.4,9.6-1.7,2.9-2,4.7-5,5-8.3,0-.8,0-1.5,0-2.3v-1.7c0-.6,0-1.2.2-1.7.4-1.1,1.5-1.7,2.7-1.5,1.1.2,2.1,1.4,2.1,2.8v6.2c.1,1.6.3,2.8.6,4,1.4,4.9,5.9,8.2,11.1,8.1h.7v15.7H56.9v-15.6ZM136.4,122.1v-7.1c-.1-2.3-1-4.5-2.7-6s-3.8-2.2-6.2-2c-3.8.3-7,3.5-7.2,7.3,0,.7,0,1.5,0,2.2,0,1,0,1.9,0,2.8-.3,2.9-2.9,5.3-5.9,5.3h0c-3.1,0-5.7-2.4-6-5.4,0-.8,0-1.6,0-2.4s0-1.5,0-2.3c-.3-4.1-3.7-7.5-7.8-7.5h-.2c-4.3,0-7.7,3.1-8,7.2-.2,2.9-.2,5.8-.2,8.6v2.4c0,2.1-.7,3.7-2,4.9-2,1.7-4.1,2.1-6.5,1.1s-3.6-3-3.7-5.8v-10.4c0-3-1.6-5.6-4.2-7-1.2-.7-2.5-1-3.9-1s-2.9.4-4.2,1.2c-2.5,1.5-3.8,3.9-3.8,6.9v7c0,2.1-1,4-2.7,5.1-1.6,1.1-3.6,1.3-5.6.6-2.4-.9-3.7-2.6-3.8-5-.2-5.7,0-11.4,0-17,0-1.6,1.4-3.2,3.1-3.6.7-.2,1.4-.2,2-.2h86.7c3.5,0,5.2,1.6,5.2,5.1v15.1c0,2.6-1.3,4.5-3.6,5.5s-4.5.7-6.5-1.1c-1.2-1.2-1.9-2.7-1.9-4.5Z"/>
          <path d="M70,75.3h0c3.1,0,6.1-1.6,7.9-4.1,1.8-2.4,2.3-5.3,1.5-7.9-1.5-5-4.4-9.2-7.1-13.4-.6-.8-1.4-1.3-2.3-1.3h0c-.9,0-1.8.5-2.3,1.3-2.5,3.8-5.2,7.9-6.9,12.6-1,2.8-.6,6,1.2,8.6,1.8,2.7,4.7,4.2,8,4.2ZM65.8,66.4c0-.6,0-1.3,0-1.8,1-2.9,2.4-5.5,4.2-8.3.3.5.5,1,.8,1.5,1.1,2,2.2,4.1,3.1,6.2.5,1.1.5,2.3,0,3.3-.5,1.1-1.5,1.9-2.7,2.3-1.2.3-2.4.2-3.4-.4-1.2-.6-1.9-1.6-2.1-2.8Z"/>
          <path d="M122.8,72.1c1.9,2.1,4.5,3.2,7.2,3.2s3.5-.5,5.1-1.4c3.9-2.2,5.7-7.2,4.3-11.3-1.6-4.6-4.2-8.6-6.9-12.6-.6-.9-1.5-1.4-2.4-1.4s-1.9.6-2.5,1.6c-1.6,2.6-3.4,5.5-5.1,8.5-.9,1.7-2.1,4.1-2.2,6.4h0c0,2.9.8,5.1,2.5,7ZM125.9,64.6c1-2.9,2.5-5.5,4.1-8.2,1.7,2.7,3.3,5.4,4.2,8.4.4,1.5-.3,3.3-1.8,4.2-1.5,1-3.5.9-4.9,0-1.4-1-2.1-2.8-1.6-4.3Z"/>
        </g>
      </g>
    </g>
  </StyledSVG>
  )
}