import React, {useEffect, useState} from 'react';
import AppWrapper from '../../containers/app-wrapper';
import '../../styles/student_thought.scss';
import { Global } from '../../common/global';
import ColyseusComponent from '../../common/socket';
import { history } from '../../components/history'
import { saveStudentThoughts } from '../../common/api';

const StudentTought = (props) => {

    ColyseusComponent({
        "initClient": false
    });
    const [studentData, setStudentData]= useState({})
    const [maxTime, setMaxTime]= useState({})
    const [explanationActive, setExplanationActive]= useState(false);
    // const [submitActive, setSubmitActive]= useState(false);
    const [questionTime, setQuestionTime]= useState(0);
    const [opinionSent, setOpinionSent]= useState(false);
    const [questionInfo, setQuestionInfo]= useState(null);
    
    const [thoughtShared, setThoughtShared]= useState(false);
    const [opinion, setOpinion]= useState("");
    const [explanation, setExplanation]= useState("");

    const submitThought= async ()=>{
        if(explanation.length===0) return false;

        setThoughtShared(true);
  
        setOpinionSent(true);
        Global.studentData.responses[Global.studentData.responses.length-1]['answer']=opinion;
        Global.studentData.responses[Global.studentData.responses.length-1]['explanation']=explanation;
        Global.studentData.responses[Global.studentData.responses.length-1]['timeTaken']=questionInfo.maxTime - questionTime;

        Global.emitEvent(
            "colyseus:send_actions",
            {
                "action_type": "save_student_opinion",
                "opinion": opinion,
                "explanation": explanation
            }
        )


        await saveStudentThoughts({
            'activityId': Global.studentData.activityId,
            'sessionId': Global.studentData.sessionId,
            'studentId': Global.studentData.studentId,
            'thoughts':Global.studentData.responses
        });
     
    }
  
    const saveExplanation= (v)=>{
        setExplanation(v.target.value)
    }
    const clearExplanationActive= () =>{
        setExplanationActive(false);

        if(!thoughtShared) return false;

        Global.canShowWait= true;
        history.push(`/${Global.deviceType}/${Global.gameID}/wait`)
    }
    const updateQnInfo= (data)=>{
        setQuestionTime(data['question_time']);
       
    };
    const moveToAnswer= () => {
        Global.canShowWait= false;
    }
    const updateQnData= (data) => {
        setExplanationActive(false);
        setExplanation("")
        setOpinion("");

        
        setQuestionInfo(data);
        setOpinionSent(false);
        if(!Global.studentData.responses[data.questionIndex]){
            Global.studentData.responses.push({
                questionId: data.questionId,
                answer:'no_response',
                explanation:'',
                timeTaken:0
            })
        }
       
    }
    const selectOpinion= function(opinion){
        if(thoughtShared) return false;

        
        setOpinion(opinion);
        // setSubmitActive(true);
    };
    const showExplanationPopup= ()=>{
        if(opinion.length===0 || thoughtShared) return false;

        setExplanationActive(true);
    }
    useEffect(() => {

        setStudentData({
            'id': Global.avatarID,
            'name': Global.userName,
            'img': Global.imgKey
        })

        Global.addEventListener("common:game_state_update", updateQnInfo);
        Global.addEventListener("common:question_update_info", updateQnData);
        Global.addEventListener("common:move_to_answer", moveToAnswer);

        return () => {
            Global.removeEventListener("common:game_state_update", updateQnInfo);
            Global.removeEventListener("common:question_update_info", updateQnData);
            Global.removeEventListener("common:move_to_answer", moveToAnswer);

        }
    }, []);
    return (
        <AppWrapper {...studentData} questionTime={questionTime} isStudent={true}>
            <div className="bg_cover"><img src="/images/BG.svg" alt="" srcset="" /></div>
            <div className="thought_sec">
                <div className="title">Look at the screen.</div>
                <div className="opinions">
                    <div className={opinion==='Strongly Disagree' && !thoughtShared?'opinion active':'opinion'} onClick={selectOpinion.bind(this,'Strongly Disagree')}>Strongly Disagree</div>
                    <div className={opinion==='Disagree' && !thoughtShared?'opinion active':'opinion'} onClick={selectOpinion.bind(this,'Disagree')}>Disagree</div>
                    <div className={opinion==='Somewhat Disagree' && !thoughtShared?'opinion active':'opinion'} onClick={selectOpinion.bind(this,'Somewhat Disagree')}>Somewhat Disagree</div>
                    <div className={opinion==='Somewhat Agree' && !thoughtShared?'opinion active':'opinion'} onClick={selectOpinion.bind(this,'Somewhat Agree')}>Somewhat Agree</div>
                    <div className={opinion==='Agree' && !thoughtShared?'opinion active':'opinion'} onClick={selectOpinion.bind(this,'Agree')}>Agree</div>
                    <div className={opinion==='Strongly Agree' && !thoughtShared?'opinion active':'opinion'} onClick={selectOpinion.bind(this,'Strongly Agree')}>Strongly Agree</div>
                    
                </div>
                <div id="confirmThought" onClick={showExplanationPopup} className={opinion.length===0 || thoughtShared?'disabled':''}>
                    CONFIRM
                </div>
                
                
            </div>
            {
                explanationActive && <>
                    <div className="explanation_ui">
                        
                        <div className="container">
                        <div className="close" onClick={clearExplanationActive}>
                            <img src="/images/icons/Icon-Cross.svg" alt="" srcset="" />
                        </div>
                        {
                            !thoughtShared?<>
                                <div className="title">Share why you think so!</div>
                                <div className="explanation">
                                    <textarea type="text" name="thought_explanation" id="thought_explanation" rows="12" value={explanation} onChange={saveExplanation}/>
                                </div>
                                <div id="confirmExplanation" className={explanation.length===0?'disabled':''} onClick={submitThought} >
                                    SUBMIT
                                </div>
                            </>:<>
                                <div className="title">Share why you think so!</div>
                                <div className="title">Rationale Shared!</div>
                            </>
                        }
                            
                        </div>
                    </div>
                </>
            }
            
            
        </AppWrapper>
    )
}

export default StudentTought;