import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/recover.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import {doLogin, sendRecoveryCode} from '../../common/api';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Button from '../../components/dashboard/button';
import ToggleButton from 'react-toggle-button'
import { history } from '../../components/history'
import IconProfile from '../../components/dashboard/icons/Icon-Profile';

const RecoverPage = (props) => {

    const [email, setEmail]= useState('');
    const [errorMsg, setErrorMsg]= useState("");
    const [recoverCodeSent, setRecoverCodeSent]= useState(false);

    const [validData, setValidData]= useState(true);

    const recoverCheck= async() => {
      if(email.length ==0){
          setErrorMsg("Invalid Email");
          setValidData(false);
          return false;
      }
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
          setErrorMsg("Invalid Email");
          setValidData(false);
          return false;
      }
    
      const res= await sendRecoveryCode(email);
      if(res.status === 401){
          setErrorMsg("Invalid User");
          setValidData(false);
      }else if(res.status === 200){
        setRecoverCodeSent(true)
      }
    }
  useEffect(() => {
  
  }, []);

  return (
        <DashboardApp extraClass={"landscape_only"} addHeader={false}>
            <div className="recover_container">
              <div className="left">
                <div className="logo">
                    {
                        localStorage.getItem('darkMode') !== 'true'?<img src="/images/Logo.png" alt="" />:<img src="/images/LogoW.png" alt="" />
                    }
                </div>
              </div>
              <div className="right">
                <div className="recover_area">
                    {
                        recoverCodeSent ? <>
                            <div className="recovered_label">
                                A recovery email is sent to you.
                            </div>
                        </>:<>
                          <div className="head">
                                <div className="title">Recover Password</div>
                            </div>
                            <div className="content">
                                <div className='field'>
                                    <div className="icon"><IconProfile/></div>
                                    <input
                                        type="email"
                                        name="recover_email"
                                        id="recover_email"
                                        value={email}
                                        onChange={(v)=>{setEmail(v.target.value)}}
                                        placeholder="Email"
                                        autocomplete="off"
                                    />
                                </div>
                                
                                <div className="buttons">
                                    <Button 
                                        className="yellow bold-text text-center"
                                        style={{borderRadius:'30px', padding:'15px 0'}}
                                        value="RECOVER"
                                        onClick={recoverCheck}
                                    />
                                    {
                                        !validData && <div className="recover_error">{errorMsg}</div>
                                    }
                                </div>

                            </div>
                        </>
                    }
                  
                </div>
              </div>
            </div>
        </DashboardApp>
  );
}


export default RecoverPage;