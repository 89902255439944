import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/teams.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import SideMenu from '../../components/dashboard/sidemenu';
import Button from '../../components/dashboard/button';
import RecentActivity from '../../components/dashboard/recent-activity';
import TeamAndMemberMini from '../../components/dashboard/team-mini';
import { createTeam, deleteTeamByID, fetchCreatedTeams, fetchTeachers, saveDuplicatedTeam, updateTeam } from '../../common/api';
import { history } from '../../components/history'
import ErrorPanel from '../../components/dashboard/Error';
import LivePopup from '../../components/dashboard/LivePopup';
import IconPlusThick from '../../components/dashboard/icons/Icon-Plus_Thick';
import Copyright from '../../components/dashboard/copyright';
import Tutorial from '../../components/dashboard/tutorial';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const Teams = (props) => {
    const [darkModeClass, setdarkModeClass ]= useState(
      localStorage.getItem('darkMode') == 'true'?'darkMode':''
    )
    const {tutorialIndex} = useParams();
    const [searchKey, setSearchKey]= useState('');
    const [teamName, setTeamName]= useState('');
    const [newMember, setNewMember]= useState('');
    const [newMemberAccessLevel, setNewMemberAccessLevel]= useState('viewer');
    const [teachers, setTeachers]= useState([]);
    const [showError, setShowError]= useState(false);
    const [errorMsg, setErrorMsg]= useState(false);
    const [showControlPanel, setShowControlPanel]= useState(false);
    const [canCreateTeam, setCanCreateTeam]= useState(false);
    const [editTeamID, setEditTeamID]= useState(false);
    
    const [actionType, setActionType]= useState("")
    const [teamMembers, setTeamMembers]= useState([
        {
            "email":"",
            "saved": false
        }
    ])
  const [teams, setTeams]= useState([]);

  const [showAd, setShowAd]= useState(false);
  const [liveActivityID, setLiveActivityID]= useState(null);
  const closeLivePopup= () => {
    setShowAd(false);
  };
  const onThoughtfullyLive= (thoughtfullyID) => {
    setShowAd(true);
    setLiveActivityID(thoughtfullyID);
  };

  const updateAccessLevel= (email, accessLevel) => {
    let memberSet= [...teamMembers];
    let teacherSet= [...teachers];
    memberSet.forEach((member, index) => {
      if(member.email === email){
        member.accessLevel= accessLevel;
      }
    });
    teacherSet.forEach((teacher, index) => {
      if(teacher.email === email){
        teacher.accessLevel= accessLevel;
      }
    });
    setTeamMembers(memberSet);
    setTeachers(teacherSet);
    
  }
  const removeMember= (email) => {
    let memberSet= [...teamMembers];
    let removeIndex=-1
    memberSet.forEach((member, index) => {
      if(member.email === email){
        removeIndex= index;
      }
    });
    if(removeIndex>=0){
      memberSet.splice(removeIndex, 1);
      setTeamMembers(memberSet);
      teachers.forEach((teacher, index) => {
        if(teacher.email == email){
          teacher.added= false;
        }
      })
    }
  };
  const addNewMember= () => {
    let teacherToAdd=null;
    let teacherClone=[...teachers];
    teacherClone.forEach((teacher, index) => {
      if(teacher.email == newMember){
        teacherToAdd=teacher;
        // teacherToAdd.added= true;
      }
    })
    if(teacherToAdd){
      // alert(JSON.stringify(teacherToAdd))
      if(!teacherToAdd.added){
        teacherToAdd.added= true;
        
        setTeamMembers((prevMembers) => {
          const updatedMembers = [...prevMembers];
          const lastIndex = updatedMembers.length - 1;
          if (lastIndex >= 0) {
            updatedMembers[lastIndex] = {
              ...updatedMembers[lastIndex],
              // id: teacherToAdd._id,
              accessLevel:teacherToAdd.accessLevel,
              email: teacherToAdd.email,
              saved: true
            };
            updatedMembers.push(
              {
                  "id":"",
                  "email":"",
                  "saved": false,
                  "accessLevel":"viewer"
              }
            )
          }
          return updatedMembers;
        });
      }
      
      setNewMember('')
      setNewMemberAccessLevel('viewer')
    }else{
       teacherToAdd={
        email: newMember,
        accessLevel: newMemberAccessLevel,
        added: false
      };
      setTeamMembers((prevMembers) => {
        const updatedMembers = [...prevMembers];
        const lastIndex = updatedMembers.length - 1;
        if (lastIndex >= 0) {
          updatedMembers[lastIndex] = {
            ...updatedMembers[lastIndex],
            id: teacherToAdd._id,
            email: teacherToAdd.email,
            accessLevel:teacherToAdd.accessLevel,
            saved: true
          };
          updatedMembers.push(
            {
                "id":"",
                "email":"",
                "saved": false,
                "accessLevel":"viewer"
            }
          )
        }
        return updatedMembers;
      });
      setTeachers([
        ...teachers,
        {
          email: newMember,
          accessLevel:newMemberAccessLevel,
          added: true
        }
      ])
     
      setNewMember('');
      setNewMemberAccessLevel('viewer')
    }
    
  };

  const validateEmail= (email) => {
    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
      return false;
    }
    return true;
  }
  const createNewTeam= async() => {
    let membersToAdd= [];
    let validInfo= true;
    teamMembers.forEach((member) => {
     /*  if(member.id){
        membersToAdd.push(member.id);
      } */
      if(!validateEmail(member.email) && member.saved){
        validInfo= false;
      }

      if(member.saved){
        membersToAdd.push({
          email: member.email,
          accessLevel: member.accessLevel
        });
      }
    });   
    if(!validInfo){
      setErrorMsg("Invalid Email");
      setShowError(true);
        const timer = setTimeout(() => {
          setShowError(false);
        }, 2000);
        return false;
    }

    const teamRes= await createTeam({
        'teamName': teamName,
        'members': membersToAdd
    });
    if(teamRes.status === 201){
      setErrorMsg(teamRes.message);
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 2000);
      return false;
    }
    if(teamRes.status === 422){
      setErrorMsg("Team name exists already.");
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
    if(teamRes.status === 200)
    {
      setTeamMembers([
        {
            "email":"",
            "saved": false
        }
      ]);
      setTeamName('')
      setCanCreateTeam(false);
      setTeams(teamRes.teams);
    }
  };
  const updateExisingTeam= async () => {
    // let membersToAdd= [];
    // teamMembers.forEach((member) => {
    //   if(member.id){
    //     membersToAdd.push(member.id);
    //   }
      
    // });    
    let validInfo=true;
    let membersToAdd= [];
    teamMembers.forEach((member) => {
     /*  if(member.id){
        membersToAdd.push(member.id);
      } */
      if(!validateEmail(member.email) && member.saved){
        validInfo= false;
      }

      if(member.saved){
        membersToAdd.push({
          email: member.email,
          accessLevel: member.accessLevel
        });
      }
    }); 
  if(!validInfo){
      setErrorMsg("Invalid Email");
      setShowError(true);
        const timer = setTimeout(() => {
          setShowError(false);
        }, 2000);
        return false;
    }
    const teamRes= await updateTeam({
        'id': editTeamID,
        'teamName': teamName,
        'members': membersToAdd
    });
    if(teamRes.status === 201){
      setErrorMsg(teamRes.message);
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 2000);
      return false;
    }
    if(teamRes.status === 422){
      setErrorMsg("Team name exists already.");
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
    else if(teamRes.status === 200)
    {
      setCanCreateTeam(false);
      setTeams(teamRes.teams);
    }
    
  }
  const onTeamView= (teamID) => {
    let memberSet=[];
    teams.forEach((team) => {
      if(team.id === teamID){
        memberSet=[...team.members/* , ...team.pendingMembers */];
        setTeamName(team.name);
      }
    });
    memberSet.forEach((member) => {
       member.saved=true;
    });
    setTeamMembers(memberSet);
    setActionType("view");
    setCanCreateTeam(true);
  };
  const onTeamEdit= (teamID) => {
    setEditTeamID(teamID);
    let memberSet=[];
    teams.forEach((team) => {
      if(team.id === teamID){
        memberSet=[...team.members/* , ...team.pendingMembers */];
        setTeamName(team.name);
      }
    });
    memberSet.forEach((member) => {
       member.saved=true;
    });
    teachers.forEach((teacher, index) => {
      if(isIdPresent(memberSet, teacher._id)){
        teacher.added= true;
      }else{
        teacher.added= false;
      }
    });

    memberSet.push({
        "email":"",
        "saved": false
    });
    
    setTeamMembers(memberSet);
    
    setActionType("edit");
    setCanCreateTeam(true);
  };
  const onTeamDuplicate= async (teamID) => {
    const duplicatedRes= await saveDuplicatedTeam({
      teamID
    });
    setTeams(duplicatedRes.teams)
    
  };
  const onTeamDelete= async (teamID) => {
    const deleteRes= await deleteTeamByID(teamID);
    setTeams(deleteRes.teams);
  };
  const isIdPresent= function(array, idToCheck) {
    return array.some(obj => obj.id === idToCheck);
  };
  useEffect(() => {
    const teamsCreated= async () => {
      const res= await fetchCreatedTeams(true);
      setTeams(res);
    }
    // const fetchAllTeachers= async () => {
    //   const teachers= await fetchTeachers();
    //   setTeachers(teachers);
    //   teachers.forEach((teacher) => {
    //     teacher['added']=false
    //   })
    // }
   

    if(!props.tutorial){
      setTimeout(()=>{
        // fetchAllTeachers();
        teamsCreated();
      },100);
    }else{

      setTeams([
        {
            "id": "66d2c4e831e8a2bc6443659b",
            "name": "<Team Name>",
            "saved": true,
            "members": [{
              "_id": "66c9c279636d655494595b73",
              "teacherId": "123",
              "activityName": "<Thoughtfully Name>",
              "activityThumbnail": "/images/dashboard/Thoughtfully.png",
              "activityDesc": "",
              "activityTime": 30,
              "teams": [],
              "categories": [],
              "anonymous": true,
              "sessionsTotal": 4,
            "lastSession": "2024-08-25T00:18:21.788Z",
            "questions":[],
            "sharedTo":[]
    
            }]
        }
      ])
    }

  }, []);

  return (
        <DashboardApp extraClass={"landscape_only"} {...props.profileData}>
          
            <div className={`team_container ${darkModeClass}`}>
              <SideMenu/>
              <div className="middle_area">
                  <div className="head">
                    <div className="left">
                      <div className="title">TEAMS</div>
                    </div>
                    <div className="right">
                      <input type="text" name="team_search" id="team_search" placeholder="Search..." value={searchKey} onChange={(v) => { setSearchKey(v.target.value); }}  autocomplete="off"/>
                      <Button 
                        id="create_team"
                        className={`black minimal-padding bold-text ${darkModeClass}`}
                        icon={<IconPlusThick  darkModeColor={( localStorage.getItem('darkMode') == 'true')?"#000000":"#ffffff"}/>}
                        value="Create a Team"
                        onClick={() => {setActionType("create");setCanCreateTeam(true)}}
                      />
                    </div>
                  </div>
                  <div className="middle_content">
                  {
                      teams.length == 0 && 
                      <Button 
                        className={`light_grey text-center bold-text ${darkModeClass}`}
                        icon={<IconPlusThick darkModeColor={( localStorage.getItem('darkMode') == 'true')?"#000000":"#8d8d8d"}/>}
                        style={{padding:'25px 10px'}}
                        value="Create a team to get started!"
                        onClick={() => {setActionType("create");setCanCreateTeam(true)}}
                      />
                    }
                    {
                      teams.map((team) => {
                        if(team.name.toLowerCase().indexOf(searchKey.toLowerCase())== 0){
                          return <TeamAndMemberMini 
                              {...team}
                              isTeam={true}
                              searchKey={searchKey}
                              onTeamView={onTeamView.bind(this, team.id)}
                              onTeamEdit={onTeamEdit.bind(this, team.id)}
                              onTeamDuplicate={onTeamDuplicate.bind(this, team.id)}
                              onTeamDelete={onTeamDelete.bind(this, team.id)}

                              style={{'right':'20px','top':'60px'}}
                          />
                        }
                      })
                    }
                    {
                      teams.map((team) => {
                        if(team.name.toLowerCase().indexOf(searchKey.toLowerCase()) > 0 ){
                          return <TeamAndMemberMini 
                              {...team}
                              isTeam={true}
                              searchKey={searchKey}
                              onTeamView={onTeamView.bind(this, team.id)}
                              onTeamEdit={onTeamEdit.bind(this, team.id)}
                              onTeamDuplicate={onTeamDuplicate.bind(this, team.id)}
                              onTeamDelete={onTeamDelete.bind(this, team.id)}

                              style={{'right':'20px','top':'60px'}}
                          />
                        }
                      })
                    }
                  </div>
                  <Copyright/>
              </div>
              <RecentActivity onThoughtfullyLive= {onThoughtfullyLive}/>
            </div>
            {
              canCreateTeam && <div className={`create_team ${darkModeClass}`}>
                  <div id="team_create_close" className='close' onClick={() => {setCanCreateTeam(false)}} ></div>
                  <div className="container">
                      <div className="head">
                          <div className="title">{actionType=="create"?"CREATE TEAM":actionType=="edit"?"EDIT TEAM":teamName}</div>
                          <div className="closeBtn"><img src="/images/dashboard/icons/Icon-Close.svg" alt="" srcset="" /></div>
                      </div>
                      <div className="body">
                        {
                          actionType !=="view" && <div className='field'>
                                <input
                                    type="text"
                                    name="new_team_name"
                                    id="new_team_name"
                                    value={teamName}
                                    onChange={(v)=>{setTeamName(v.target.value)}}
                                    placeholder="Team Name*"
                                    autocomplete="off"
                                    maxLength={30}
                                />
                            </div>
                        }
                          
                          {
                              teamMembers.map((currentTeamMember) => (
                                  <TeamAndMemberMini
                                      {...currentTeamMember}
                                      // teachers= {teachers}
                                      isTeam={false}
                                      newMember={newMember}
                                      newMemberAccessLevel={newMemberAccessLevel}
                                      setNewMemberAccessLevel={setNewMemberAccessLevel}
                                      updateAccessLevel={updateAccessLevel}
                                      setNewMember={setNewMember}
                                      removeMember={removeMember}
                                      addNewMember={addNewMember}
                                      actionType={actionType}
                                      style={{'right':'20px','top':'60px'}}
                                  />
                              ))
                          }
                          
                      </div>
                      {
                        (actionType !=="view") && <Button 
                          className={`${teamName.trim().length === 0?'disabled':''} purple-purple bold-text text-center`}
                          value={actionType=="edit"?"UPDATE":"CREATE"}
                          onClick={actionType=="create"?createNewTeam:updateExisingTeam}
                        />
                      }
                      
                  </div>
              </div>
              
            }
            <ErrorPanel showError={showError} errorMsg={errorMsg}/>
            {
              showAd && <LivePopup showAd={showAd} liveActivityID={liveActivityID} closeLivePopup={closeLivePopup}/>
            }
             {
              props.tutorial && <Tutorial tutorialIndex={tutorialIndex}/>
          }
        </DashboardApp>
  );
}


export default Teams;