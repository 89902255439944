import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/reports.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import SideMenu from '../../components/dashboard/sidemenu';
import { history } from '../../components/history'
import Copyright from '../../components/dashboard/copyright';
import ReportsMini from '../../components/dashboard/reports-mini';
import { getAllActivities } from '../../common/api';
import Button from '../../components/dashboard/button';
import Tutorial from '../../components/dashboard/tutorial';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const Reports = (props) => {
  const [darkModeClass, setdarkModeClass ]= useState(
    localStorage.getItem('darkMode') == 'true'?'darkMode':''
  )
  const {tutorialIndex} = useParams();

  const [searchKey, setSearchKey]= useState('');
  const [activities, setActivities]= useState('');



  useEffect(() => {
        const getActivities= async () => {
            const res= await getAllActivities();
            setActivities(res.activities);
        }
        
        if(!props.tutorial){
          setTimeout(()=>{
            getActivities();
          },100);
        }else{
          setActivities([
            {
                "activityId": "66d1e97eb6f9bedbed8e4b31",
                "activityName": "<Thoughtfully Name>",
                "activityThumbnail": "/images/dashboard/Thoughtfully.png",
                "activityDesc": "",
                "sessionsTotal": 4,
                "lastSession": "30-08-2024 22:09:36"
            }
        ]);
        }
        
  }, []);

  return (
        <DashboardApp extraClass={"landscape_only"} {...props.profileData}>
           {
              props.tutorial && <Tutorial tutorialIndex={tutorialIndex}/>
          }
            <div className={`report_container ${darkModeClass}`}>
              <SideMenu/>
              <div className="middle_area">
                  <div className="head">
                    <div className="left">
                      <div className="title">REPORTS</div>
                    </div>
                    <div className="right">
                      <input type="text" name="reports_search" id="reports_search" placeholder="Search..." value={searchKey} onChange={(v) => { setSearchKey(v.target.value); }}  autocomplete="off"/>
                   
                    </div>
                  </div>
                  <div className="middle_content">
                    {
                      activities.length==0 &&  <Button 
                      className={`disabled light_grey text-center bold-text ${darkModeClass}`}
                      style={{padding:'25px 10px'}}
                      value="Unlocking Reports lets you refine your approach, track progress, and make data-driven decisions, elevating your educational sessions to the next level. This premium feature is available with an upgrade for enhanced learning impact."
                    />
                    }
                  {
                      activities.length>0 && activities.map((activity, index) => {
                        if(activity.activityName.toLowerCase().indexOf(searchKey.toLowerCase())== 0){
                          return <ReportsMini {...activity}/>
                        }
                      })
                  }
                  {
                      activities.length>0 && activities.map((activity, index) => {
                        if(activity.activityName.toLowerCase().indexOf(searchKey.toLowerCase())>0){
                          return <ReportsMini {...activity}/>
                        }
                      })
                  }
                        
                  </div>
                  <Copyright/>
              </div>
            </div>
        </DashboardApp>
  );
}


export default Reports;