import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/login.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import {doGmailRegister, doLogin} from '../../common/api';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Button from '../../components/dashboard/button';
import ToggleButton from 'react-toggle-button'
import { history } from '../../components/history'
import IconProfile from '../../components/dashboard/icons/Icon-Profile';
import IconPassword from '../../components/dashboard/icons/Icon-Password';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const LoginPage = (props) => {
    if(props.profileData && props.profileData._id){
      history.push('/dashboard')
    }
    const [email, setEmail]= useState('');
    const [password, setPassword]= useState('');
    const [savePassword, setSavePassword]= useState(false);
    const [errorMsg, setErrorMsg]= useState("");
    const [validData, setValidData]= useState(true);

    const [gmailUser, setGmailUser]= useState(null)

    const signIn = useGoogleLogin({
      onSuccess: function(user){
        setGmailUser(user);
    },
      onError: (error) => {
      },
  });
  const handleGoogleSignIn = () => {
    signIn();
};
    const loginCheck= async() => {
      if(email.length ==0){
          setErrorMsg("Invalid Email");
          setValidData(false);
          return false;
      }
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
          setErrorMsg("Invalid Email");
          setValidData(false);
          return false;
      }
      if(password.length ==0){
          setErrorMsg("Invalid Password");
          setValidData(false);
          return false;
      }
      const res= await doLogin({
        email,
        password,
        savePassword
      });
      if(res.data.status === 401){
          setErrorMsg(res.data.message);
          setValidData(false);
      }else if(res.data.status === 200){
        localStorage.setItem('tutorialShown', res.data.tutorialShown);
        localStorage.setItem('darkMode', res.data.darkMode);
        if(!res.data.tutorialShown){
          location.href='/dashboard/tutorial/1';
        }else{
          location.href='/dashboard';
        }
        
      }
    }
  useEffect(() => {
    if (gmailUser) {
      axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${gmailUser.access_token}`, {
              headers: {
                  Authorization: `Bearer ${gmailUser.access_token}`,
                  Accept: 'application/json'
              }
          })
          .then(async (res) => {
              const res2= await doGmailRegister({
                  email: res.data.email,
                  password: res.data.id,
                  profileImg: res.data.picture,
                  fullName: res.data.name,
                  savePassword:savePassword
              })
              if(res2.data.status === 401){
                  setErrorMsg(res2.data.message);
                  setValidData(false);
              }else if(res2.data.status === 200){
                localStorage.setItem('darkMode', res2.data.darkMode);
                if(!res2.data.tutorialShown){
                  location.href='/dashboard/tutorial/1';
                }else{
                  location.href='/dashboard';
                }
              }

              /* if (res2.data && res2.data.code === 200) {
                  location.href=`/dashboard/`;
              } else if (res2.data.code === 422) {
                  setErrorMsg("User with same email id exists already.");
                  setValidData(false);
              } */
          })
          .catch((err) => console.log(err));
  }
  }, [gmailUser]);

  return (
        <DashboardApp extraClass={"landscape_only"} addHeader={false}>
            <div className="login_container">
              <div className="left">
                <div className="logo">
                    {
                      localStorage.getItem('darkMode') !== 'true'?<img src="/images/Logo.png" alt="" />:<img src="/images/LogoW.png" alt="" />
                    }
                </div>
              </div>
              <div className="right">
                <div className="login_area">
                    <div className="head">
                        <div className="title">Welcome Back!<br/>Please Log In</div>
                        <div className="redirect_sec">New here? <Link to={{ pathname: "/dashboard/register" }}>Sign Up</Link> to get started</div>
                    </div>
                    <div className="content">
                        <div className='field'>
                            <div className="icon"><IconProfile/></div>
                            <input
                                type="email"
                                name="login_email"
                                id="login_email"
                                value={email}
                                onChange={(v)=>{setEmail(v.target.value)}}
                                placeholder="Email"
                                autocomplete="off"
                            />
                        </div>
                        <div className='field'>
                            <div className="icon"><IconPassword/></div>
                            <input
                                type="password"
                                name="login_password"
                                id="login_password"
                                value={password}
                                onChange={(v)=>{setPassword(v.target.value)}}
                                placeholder="Password"
                                autocomplete="off"
                            />
                        </div>
                       
                       
                        <div className="password_modify_sec">
                            <div className="forgot_password"><Link to={{ pathname: "/dashboard/recover" }} className="">Forgot password?</Link></div>
                            <div className="remember_sec">
                              <div className="label">Remember me </div>
                                <ToggleButton
                                  inactiveLabel={''}
                                  activeLabel={''}
                                  colors={{
                                    activeThumb: {
                                      base: '#06c900',
                                    },
                                    inactiveThumb: {
                                      base: '#e5e5e5',
                                    },
                                    active: {
                                      base: '#4fdb00',
                                      hover: '#4fdb00',
                                    },
                                    inactive: {
                                      base: '#940000',
                                      hover: '#940000',
                                    }
                                  }}
                                  thumbStyle={{borderRadius: 10, border:'0', width:'20px', height:'20px'}}
                                  trackStyle={{borderRadius: 10, width:'50px', height:'20px'}}
                                  thumbAnimateRange={[0, 30]}
                                  thumbIcon={
                                    <img src={`/images/dashboard/icons/${savePassword?'tick_active':'cross'}.png`} alt="" srcset="" />
                                  }
                                  value={savePassword}
                                  onToggle={(value) => {
                                    setSavePassword(!value)
                                  }}
                                />
                            </div>
                        </div>
                        <div className="buttons">
                            <Button 
                                className="yellow bold-text text-center"
                                style={{borderRadius:'30px', padding:'15px 0'}}
                                value="LOGIN"
                                onClick={loginCheck}
                            />
                             {
                                  !validData && <div className="signin_error">{errorMsg}</div>
                              }
                            <div className="divider">
                                <span className="dash"></span>
                                <span className="or">OR</span>
                                <span className="dash"></span>
                            </div>
                            <Button 
                                icon="/images/dashboard/icons/Icon-Google.svg"
                                className="white_transparent big_icon text-center"
                                style={{borderRadius:'30px', padding:'2px 15px', color:'#ffffff'}}
                                value="Log in with Google"
                                onClick={handleGoogleSignIn}
                            />
                        </div>

                    </div>
                </div>
              </div>
            </div>
        </DashboardApp>
  );
}


export default LoginPage;