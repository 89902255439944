import React, { useState, useEffect } from 'react'
import './style.scss'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Ads = ({recentThoughtfullys, ...props}) => {


  
  useEffect(() => {
    return () => {
    };
  }, []);
  return (
    <div className={`ads_right_area ${props.darkModeClass}`}>
        <div className="top">
          <Carousel showThumbs={false} showArrows={false} showStatus={false} interval={3000} autoPlay={true} infiniteLoop={true}>
                <div>
                  <a href='https://jalanjourney.com/' target='_blank'><img src="/images/dashboard/ads/Banner01.png" /></a>
                </div>
                <div>
                <a href='https://jalanjourney.com/' target='_blank'><img src="/images/dashboard/ads/Banner02.png" /></a>
                </div>
                <div>
                <a href='https://jalanjourney.com/' target='_blank'><img src="/images/dashboard/ads/Banner03.png" /></a>
                </div>
                <div>
                <a href='https://jalanjourney.com/' target='_blank'><img src="/images/dashboard/ads/Banner04.png" /></a>
                </div>
          </Carousel>
        </div>
        <div className="bottom">
        <Link to={{ pathname: '/dashboard/help' }} className=""><img src="/images/dashboard/BannerPro.png" alt="" /></Link>
         
          <div className="label">Interested? Simply drop us an email! info@jalanjourney.com</div>
        </div>
    </div>
  )
}

export default Ads;