import React, { useState, useEffect } from 'react'
import { history } from '../../history'
import './style.scss'
import Button from '../button';
import classnames from 'classnames';

const ErrorPanel = (props) => {
    
    useEffect(() => {
        // setControlType(props.controlType);
    }, [])
    return (
        <div className={props.showError?"team_panel active":"team_panel"}>{props.errorMsg}</div>
    )
};
export default ErrorPanel;