import React, { useState, useEffect } from 'react'
import './style.scss'
import Button from '../button';
import { getActivityClientID, updateThoughtfullyLiveInfo } from '../../../common/api';
import QRCode from 'qrcode.react'; // Import qrcode.react
import ConfirmPopup from '../confirmPopup';

const LivePopup = (props) => {
    const [darkModeClass, setDarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      )
    const [teacherURL, setTeacherURL]= useState('');
    const [screenURL, setScreenURL]= useState('');
    const [studentURL, setStudentURL]= useState('');
    const [showAd, setShowAd]= useState(props.showAd);
    const [videoCompleted, setVideoCompleted]= useState(false);
    const [showQr, setshowQr]= useState(false);
    const [showConfirm, setShowConfirm]= useState(false);
    const [liveActivityID, setLiveActivityID]= useState(props.liveActivityID);
    const [clientID, setClientID]= useState(null);
    const [canvasWidth, setCanvasWidth]= useState(Math.min(.2*window.innerWidth*1.2, .3*window.innerHeight*1.0));


    const onLiveCreate= () => {
        setShowAd(false);
        setshowQr(true);
        fetchClientID();
    }
    const goLiveNow= async () => {
        await updateThoughtfullyLiveInfo(liveActivityID, clientID);
        // location.href=screenURL;

    }
    const showConfirmation= ()=>{
       

        setShowAd(false);
        setshowQr(false);
        setShowConfirm(true);
    }
    const hideConfirmation= () => {
        setShowConfirm(false);
        setShowAd(false);
        setshowQr(true);
    }
    const handleResize = () => {
        setCanvasWidth(Math.min(.2*window.innerWidth*1.2, .3*window.innerHeight*1.0));
    };
 
    const fetchClientID= async () => {
      
        
        const res= await getActivityClientID(liveActivityID);
        setClientID(res['clientID']);
        
        setTeacherURL(`${window.location.protocol}//${window.location.host}/teacher/${res['clientID']}/start`);
        setScreenURL(`${window.location.protocol}//${window.location.host}/screen/${res['clientID']}/lobby/?clientID=${res['clientID']}`);
        setStudentURL(`${window.location.protocol}//${window.location.host}/student/${res['clientID']}/name`);
    };

  useEffect(() => {
   
    if(showAd){
        document.querySelector(".video_sec video").currentTime=0;
        document.querySelector(".video_sec video").play();
    }
   
 /*    if(liveActivityID != null && clientID == null){
        console.log(liveActivityID,'clientID')
       
    } */

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);

    };

  }, [showAd]);

  if(!showAd && !showQr && !setShowConfirm){
    return false;
  }else{
    return (
        <div className={`live_popup ${darkModeClass}`}>
            <div id="live_popup_close" className='close' onClick={props.closeLivePopup} ></div>
            <div className="popup_container">
                {
                    showQr?<>
                        <div className="QR_sec">
                            <div className='desc'>For Instructor use only</div>
                            <div className='title'>USE YOUR PHONE TO SCAN AND CONTROL THE THOUGHTFULLY</div>
                            

                            <div className="code_container">
                                <QRCode value={teacherURL} size={canvasWidth}/>
                            </div>
                            <Button
                                className={`purple-purple bold-text text-center`}
                                style={{borderRadius:'10px', width:'150px'}}
                                value="PROCEED"
                                navigateToBlank={screenURL}
                                onClick={goLiveNow}
                                // onClick={showConfirmation}
                            />
                        </div>
                    </>:showConfirm?<>
                        <ConfirmPopup isMini={false} onProceed={goLiveNow} onCancel={hideConfirmation} title='Have you scanned the QR code yet?' desc='Please ensure you have scanned the QR code before proceeding. You must log in to moderate the Thoughtfully'/>
                    </>:
                    <>
                        <div className="video_sec">
                            <a href='https://jalanjourney.com/' target='_blank'>
                                <video src="/videos/Ad1.mp4" onEnded={() => {setVideoCompleted(true)}}></video>
                            </a>
                        </div>
                        <Button
                            className={`${!videoCompleted?'disabled ':''}purple-purple bold-text text-center`}
                            style={{borderRadius:'10px', width:'150px'}}
                            value="HOST LIVE"
                            onClick={onLiveCreate}
                        />
                    </>
                    
                }
                
            </div>
        </div>
        
      )
  }
  
}

export default LivePopup
