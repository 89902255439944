import styled from 'styled-components';

const StyledSVG = styled.svg`
.bin_path {
  fill: #ee4131;
}
`;
function IconDelete(){
  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200">
      <g>
        <g id="Layer_1">
          <g>
            <path class="bin_path" d="M77,148.7c-.6-.2-1.3-.3-1.9-.5-4.7-1.4-7.9-5.8-8.2-10.9v-55.8c0-.9-.3-1.2-1.1-1.5-3.6-1.3-5.9-5.3-5.4-9,.5-4.1,3.8-7.4,7.7-7.6,3.9-.2,7.8,0,11.7,0h.8c0-1.3,0-2.5.2-3.7.3-4.5,4.1-8.1,8.9-8.1,6.8,0,13.6,0,20.5,0,5.3,0,8.8,3.9,8.9,9.4v2.4h11.2c4,0,7,1.8,8.6,5.6,1.8,4.2-.5,9.5-4.8,11.1-.8.3-1.1.7-1.1,1.6v54.4c0,3.8-1.1,7.1-3.9,9.8-1.6,1.5-3.5,2.5-5.7,2.9-.2,0-.3.1-.5.2-15.2-.2-30.6-.2-45.9-.2ZM71.5,80.6v55.4c0,.7,0,1.4.2,2.1.6,3.6,3.3,6.1,6.8,6.1h43c2.3,0,4.2-1,5.6-2.9,1.1-1.6,1.5-3.4,1.5-5.3v-55.3c-19.1-.1-38.1-.1-57.1-.1ZM100,75.9h29.9c.5,0,1,0,1.4,0,2.2-.2,3.8-2.1,3.6-4.3-.2-2.4-2-3.8-4.8-3.8h-60.2c-.5,0-1,0-1.4,0-1.9.3-3.3,1.9-3.5,3.9,0,1.8,1.2,3.7,3,4.1.6.2,1.3.2,2,.2,10,0,20,0,30,0ZM114.5,63.1c0-1.2,0-2.3,0-3.4-.2-1.9-1.3-3.1-3.2-3.6-.4,0-.9-.2-1.4-.2h-19.9c-2.4,0-4.2,1.3-4.5,3.5-.2,1.2,0,2.4,0,3.7h29Z"/>
            <path class="bin_path" d="M88.8,110.4v21c0,.6-.2,1.3-.4,1.9-.4.8-1.2,1.1-2.1.9-.9-.1-1.6-.7-1.8-1.6,0-.4-.2-.7-.2-1.1v-42.1c0-.5,0-1.1.3-1.6.4-.9,1.2-1.3,2.2-1.2,1,0,1.7.6,1.9,1.6,0,.4,0,.9,0,1.4v20.8Z"/>
            <path class="bin_path" d="M97.7,110.4v-21c0-1.7.7-2.6,2-2.7,1.2,0,2.2.6,2.4,1.8,0,.4,0,.8,0,1.1v41.7c0,.4,0,.8,0,1.2-.2,1.1-1.2,1.8-2.2,1.8s-1.9-.7-2.2-1.8c0-.4,0-.8,0-1.2,0-7,0-13.9,0-20.9Z"/>
            <path class="bin_path" d="M111.2,110.5v-20.8c0-2,.8-3,2.2-3s2.3,1.1,2.3,3v41.6c0,2-.9,3.1-2.3,3-1.5,0-2.2-1.1-2.2-3v-20.8Z"/>
          </g>
        </g>
      </g>
    </StyledSVG>
  )
}

export default IconDelete;