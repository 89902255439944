import React, { useEffect, useState, useRef, useCallback } from 'react';

import '../../styles/dashboard/thoughtfully-view.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import SideMenu from '../../components/dashboard/sidemenu';
import ReactTags from 'react-tag-autocomplete';
import Button from '../../components/dashboard/button';
import QuestionMini from '../../components/dashboard/question-mini';
import Ads from '../../components/dashboard/Ad';
import { Global } from '../../common/global';
import ToggleButton from 'react-toggle-button'
import ControlPanel from '../../components/dashboard/controlPanel';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { history } from '../../components/history'
import { Link, useParams  } from 'react-router-dom/cjs/react-router-dom';
import { addNewCategory, deleteThoughtfullyByID, fetchCategories, fetchCreatedTeams, fetchThoughtfullyByID, saveThoughtfully, updateThoughtfully } from '../../common/api';
import ErrorPanel from '../../components/dashboard/Error';
import QuestionPage from '../screen/question';
import Header from '../../components/header';
import Footer from '../../components/footer';
import LivePopup from '../../components/dashboard/LivePopup';
import IconCamera from '../../components/dashboard/icons/Icon-Camera';
import IconLeft from '../../components/dashboard/icons/Icon-ArrowLeft';
import IconPlusThin from '../../components/dashboard/icons/Icon-Plus_Thin';
import Copyright from '../../components/dashboard/copyright';
import Tutorial from '../../components/dashboard/tutorial';


const ThoughtfullyView = (props) => {
  const [darkModeClass, setdarkModeClass ]= useState(
    localStorage.getItem('darkMode') == 'true'?'darkMode':''
  )
  const { id } = useParams();
  const {tutorialIndex} = useParams();
  const [crop, setCrop] = useState(null);
  const [src, setSrc] = useState(null);
  const imgRef = useRef(null)
  const [cropData, setCropData]= useState({});
  const [canShowCropButton, setCanShowCropButton]= useState(false);


  const actionType= Global.getLastPath();
  const [showName, setShowName]= useState(true);
  const [isCustomThumbnail, setIsCustomThumbnail]= useState(false)
  const [canCustomizeCategory, setCanCustomizeCategory]= useState(false)
  const [activityName, setActivityName]= useState('');
  const [activityThumbnail, setActivityThumbnail]= useState('/images/dashboard/ThoughtfullyIconDark.png');
  const [activityDesc, setActivityDesc]= useState('');
  const [activityCategory, setActivityCategory]= useState([]);
  const [showControlPanel, setShowControlPanel]= useState(false);
  const [customThumbnail, setCustomThumbnail]= useState(false);
  const [showError, setShowError]= useState(false);
  const [showPreview, setShowPreview]= useState(false);
  const [showPreviewControls, setShowPreviewControls]= useState(false);
  const [previewIndex, setPreviewIndex]= useState(0);
  const [isShared, setIsShared]= useState(false);
  const reactTags = useRef(null);
  const reactTags2 = useRef(null);
  const [extras]= useState([
    15, 30, 45, 60
  ])
  const [questions, setQuestions]= useState([]);
  const [teamsSelected, setTeamsSelected] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [activityTime, setActivityTime]=useState(30);
  const [teams, setTeams]= useState([]);
  const [categories, setCategories]= useState([
  /*   {id: "66c6b9618ef751e5c14c9126", name: "Category1"},
    {id: "66c6b9668ef751e5c14c912d", name: "Category2"},
    {id: "66c6b96a8ef751e5c14c9134", name: "Category3"},
    {id: "66c6b9798ef751e5c14c913b", name: "Category4"} */
  ]);
  const [errorMsg, setErrorMsg]= useState(false);
  
  const [showAd, setShowAd]= useState(false);
  const [liveActivityID, setLiveActivityID]= useState(null);
  const closeLivePopup= () => {
    setShowAd(false);
  };
  const onThoughtfullyLive= () => {
    setShowAd(true);
    setLiveActivityID(id);
  };
  const addCustomCategory= async () => {
    const existing=categorySelected.filter((obj) => obj.name === reactTags2.current.state.query)
    
    if(existing.length>0){
      setErrorMsg("Category already exists.");
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 2000);
      return false;
    }
    if(reactTags2.current.state.query.trim().length==0){
      setErrorMsg("Category name cannot be empty.");
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 2000);
      return false;
    }
    const updatedCategory=await addNewCategory({
      categoryName: reactTags2.current.state.query
    });
    onCategoryAddition(updatedCategory.category);
    setCanCustomizeCategory(false);
    reactTags2.current.clearInput();
  }
  const onSelectFile= function(e) {
    if (e.target.files && e.target.files.length > 0) {
      const maxSizeMB = 20;
      if (e.target.files[0].size > maxSizeMB * 1024 * 1024) {
          alert(`File size exceeds ${maxSizeMB} MB limit.`);
          return false;
      }
      
      const reader = new FileReader()
      reader.addEventListener('load', (e) =>{
        const img = new Image();
        img.onload = function() {
            // Get the canvas and context
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            
            // Define the maximum width
            const maxWidth = 1024;
            
            // Calculate the new dimensions while maintaining the aspect ratio
            let width = img.width;
            let height = img.height;
            
            if (width > maxWidth) {
              const scaleFactor = maxWidth / width;
              width = maxWidth;
              height = height * scaleFactor;
            }
            
            // Set canvas dimensions to the new dimensions
            canvas.width = width;
            canvas.height = height;
            
            // Draw the image on the canvas with the new dimensions
            ctx.drawImage(img, 0, 0, width, height);
            
            // Convert canvas to PNG and set download link
            const pngDataUrl = canvas.toDataURL('image/png');
            const base64String = pngDataUrl;
            setActivityThumbnail(base64String);
            setIsCustomThumbnail(true);
            setCustomThumbnail(true);
            setSrc(null);
            
        };
  
        // Set image source to FileReader result
        img.src = e.target.result;

      /*   const base64String = event.target.result;//.split(',')[1];
        setActivityThumbnail(base64String);
        setIsCustomThumbnail(true);
        setCustomThumbnail(true);
        setSrc(null); */
      }
      // setSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }
  const onCropUpdate= (crop) => {
    setCropData(crop);
    setCanShowCropButton(true);
  }
  const cropImage= async (crop) => {

    const image = imgRef.current;

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height


    const offscreenCanvas = new OffscreenCanvas(
        cropData.width,
        cropData.height
      )
      const canvasCtx = offscreenCanvas.getContext('2d')
      if (!canvasCtx) {
        throw new Error('No 2d context')
      }
  
      canvasCtx.drawImage(
        image,
        cropData.x * scaleX,
        cropData.y * scaleY,
        cropData.width * scaleX,
        cropData.height * scaleY,
        0,
        0,
        cropData.width,
        cropData.height,
      )
      const imgBlob = await offscreenCanvas.convertToBlob({
        type: 'image/png',
      })

    const reader = new FileReader();
    reader.onload = async function(event) {
        const base64String = event.target.result;//.split(',')[1];
        setActivityThumbnail(base64String);
        setIsCustomThumbnail(true);
        setCustomThumbnail(true);
        setSrc(null);
        // const res= await saveProfileImg(base64String);
    };
    reader.readAsDataURL(imgBlob);

  }

  const validateThoughtfully= () => {
    let isValidThoughtfully= true;
    if(activityName.trim().length==0){
      setErrorMsg("Thoughtfully name is invalid");
      isValidThoughtfully= false;
    }
    let totalQns=0;
    questions.forEach((question)=>{
      if(question.saved){
        totalQns++;
      }
    })
    if(totalQns==0){
      setErrorMsg("Please add 1 prompt atleast.");
      isValidThoughtfully= false;
    }
    if(!isValidThoughtfully){
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 2000);
      return false;
    }else{
      return true;
    }
  }
  const validateAndUpdateThoughtfully= async ()=>{
    const isValid= validateThoughtfully();
    if(!isValid){
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 2000);
      return false;
    }else{
      let selectedTeamIDs=teamsSelected.map((team) => team.id);
      let categories= categorySelected.map((category) => category.id);
      await updateThoughtfully({
        id,
        activityName,
        activityThumbnail:activityThumbnail,
        customThumbnail:customThumbnail,
        activityDesc,
        activityTime,
        categories,
        questions,
        selectedTeamIDs,
        showName
      });
      history.push(`/dashboard`);
    }
  }
  const validateAndCreateThoughtfully= async ()=>{
   
    const isValid= validateThoughtfully();
    if(!isValid){
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 2000);
      return false;
    }else{
      let selectedTeamIDs=teamsSelected.map((team) => team.id);
      let categories= categorySelected.map((category) => category.id);
      await saveThoughtfully({
        activityName,
        activityThumbnail:isCustomThumbnail?activityThumbnail:'/images/dashboard/Thoughtfully.png',
        customThumbnail:customThumbnail,
        activityDesc,
        activityTime,
        categories,
        questions,
        selectedTeamIDs,
        showName
      });
      history.push(`/dashboard`);
    }
   
  }
  const onThoughtfullyEdit= () => {
    history.push(`/dashboard/thoughtfully/${id}/edit`);
  };
  const onThoughtfullyDelete= async () => {
    const deleteRes= await deleteThoughtfullyByID(id);
    history.push(`/dashboard`);
  };
  const onQnEdit= (qnIndex) => {
    let prevData= [...questions];
    prevData[qnIndex-1]['saved']= false;
    setQuestions(prevData);
  };
  const onQnDuplicate= (qnIndex) => {
 
    let duplicateQn= {...questions[qnIndex-1]}
    duplicateQn.qnIndex= qnIndex;//questions.length+1;
    let postQn= [];
    if(qnIndex<questions.length){
      postQn= questions.slice(qnIndex, questions.length)
    }
    let finalQns=[
      ...questions.slice(0, qnIndex),
      duplicateQn,
      ...postQn
    ]
    finalQns.forEach((finalQn, index) => {
      finalQn.qnIndex= index+1;
    })
    setQuestions(finalQns);
    
  };
  const onQnDelete= (qnIndex) => {
    let qnSet= [...questions];
    qnSet.splice(qnIndex-1, 1);
    for(let i=0;i<qnSet.length;i++){
      qnSet[i].qnIndex= i+1;
    }
    setQuestions(qnSet)
  };
  const showQnMenu= (qnIndex) => {
    let prevData= [...questions];
    prevData[qnIndex-1]['showControlPanel']= true;
    setQuestions(prevData);
  }
  const updateActivityName= (e) => {
    setActivityName(e.target.value);
  }
  const updateActivityDesc= (e) => {
    setActivityDesc(e.target.value);
  }

  const adjustTime= (time) => {
    let oldQns=[...questions];
    oldQns.forEach((qn) => {
      if(qn['saved']){
        if(activityTime+time+qn['extraTime']>120){
          qn['extraTime']=0;
        }
      }
       
    })

    setActivityTime(activityTime+time);
    setQuestions(oldQns);
    
  }
  const addNewQuestion= () => {
    setQuestions([
      ...questions,
      {
        "qnIndex": questions.length+1,
        "question":"",
        "extraTime":0,
        "showControlPanel": false,
        "saved": false
      }
    ])
  }
  
  const saveQuestion= (qnIndex) => {
    let prevData= [...questions];
    prevData[qnIndex-1]['saved']= true;
    setQuestions(prevData);
  }
  const updateQuestion= function(qnIndex, question){
    let prevData= [...questions];
    prevData[qnIndex-1]['question']= question;
    setQuestions(prevData);
  }
  const updateExtraTime= function(qnIndex, extraTime, extraOpt){
    let prevData= [...questions];
    if(prevData[qnIndex-1]['extraTime'] == extraTime){
      prevData[qnIndex-1]['extraTime']=0;
    }else{
      prevData[qnIndex-1]['extraTime']= extraTime;
    }
    setQuestions(prevData);
  }
  const onDelete = useCallback((teamIndex) => {
    let removedTeam= teamsSelected[teamIndex];
    setTeamsSelected(teamsSelected.filter((_, i) => i !== teamIndex));
    setTeams([...teams, removedTeam]);
  }, [teamsSelected]);

  const onCategoryDelete = useCallback((categoryIndex) => {
    let removedCategory= categorySelected[categoryIndex];
    setCategorySelected(categorySelected.filter((_, i) => i !== categoryIndex));
    setCategories([...categories, removedCategory]);
  }, [categorySelected]);

  const onAddition = useCallback((newTag) => {
  
    if(newTag){
      setTeamsSelected([...teamsSelected, newTag]);
      if(teams.length>0){
        setTeams(teams.filter(team => team.id !== newTag.id));
      }
 
    }
  }, [teamsSelected, teams]);
  const onCategoryAddition = useCallback((newTag) => {
    if(newTag){
      setCategorySelected([...categorySelected, newTag]);
      if(categories.length>0){
        setCategories(categories.filter(category => category.id !== newTag.id));
      }
 
    }
  }, [categorySelected, categories]);
  const onCategoryInput= ()=>{
      setTimeout(() => {
        if(
          (
            reactTags2.current.state.options.length==1 && 
            reactTags2.current.state.options[0].disabled
          )
        ){
            setCanCustomizeCategory(true);
          }else{
            setCanCustomizeCategory(false);
          }
      })
  }
  const navigatePreview= (indexPadd) => {
    setPreviewIndex(previewIndex+indexPadd);
  }
  const skipPreview= () => {
    setShowPreview(false);
    setShowPreviewControls(false);
    setPreviewIndex(0);
  }
  const updateQnOrder= (qnIndex ,toAdd) => {
    const newArray = [...questions];
  
    // Swap the elements
    [newArray[qnIndex], newArray[qnIndex+toAdd]] = [newArray[qnIndex+toAdd], newArray[qnIndex]];
  
    const updatedArray = newArray.map((item, idx) => ({
      ...item,
      qnIndex: idx + 1
    }));

    setQuestions(updatedArray)

    //qnIndex
    // console.log("updateQnOrder",qnIndex)
    //questions
  }

  useEffect(() => {
    const teamsAndCategoryCreated= async (id) => {
      const res= await fetchCreatedTeams(false);
      const categoryRes= await fetchCategories();
      if(id){
        fetchThoughtfullyById(id, res, categoryRes);
      }else{
        setTeams(res);
        setCategories(res);
      }
    }
   
    
    const fetchThoughtfullyById= async (id, teamsFetched, categoryFetched) => {
      let thoughfullyByID= await fetchThoughtfullyByID(id);
      if(thoughfullyByID && thoughfullyByID.teams){
        if(!thoughfullyByID.isShared){
          for(let i=0;i<thoughfullyByID.teams.length;i++){
            thoughfullyByID.teams[i]=teamsFetched.filter((team) => team.id == thoughfullyByID.teams[i])[0] || null;
          }
          thoughfullyByID.teams=thoughfullyByID.teams.filter(item => item !== null)
        }else{
          // thoughfullyByID.teams= teamsFetched;
        }
        
      }
      if(thoughfullyByID && thoughfullyByID.categories){
        if(!thoughfullyByID.isShared){
          for(let i=0;i<thoughfullyByID.categories.length;i++){
            thoughfullyByID.categories[i]=categoryFetched.filter((team) => team.id == thoughfullyByID.categories[i])[0] || null;
          }
          thoughfullyByID.categories=thoughfullyByID.categories.filter(item => item !== null)
        }else{
          // thoughfullyByID.teams= teamsFetched;
        }
        
      }
      if(thoughfullyByID && thoughfullyByID.questions){
        thoughfullyByID.questions.forEach((question) => {
          question['showControlPanel']= false;
          question['saved']= true;
         
        });
      }

      

      setQuestions(thoughfullyByID.questions);
      setShowName(!thoughfullyByID.anonymous);
      setActivityName(thoughfullyByID.activityName);
      setActivityThumbnail(thoughfullyByID.activityThumbnail);
      setActivityDesc(thoughfullyByID.activityDesc);
      setCategorySelected(thoughfullyByID.categories);
      setTeamsSelected(thoughfullyByID.teams);
      setActivityTime(thoughfullyByID.activityTime);
      setIsShared(thoughfullyByID.isShared?thoughfullyByID.isShared:false);

      if(thoughfullyByID.isShared && actionType === 'edit' && thoughfullyByID.accessLevel == 'viewer'){
        history.push('/dashboard')
      }
      const ids1 = new Set(thoughfullyByID.teams.map(item => item.id));
      const ids2 = new Set(teamsFetched.map(item => item.id));
      const uniqueToArray1 = thoughfullyByID.teams.filter(item => !ids2.has(item.id));
      const uniqueToArray2 = teamsFetched.filter(item => !ids1.has(item.id));
      const result = [...uniqueToArray1, ...uniqueToArray2];
      setTeams(result);

      const ids_1 = new Set(thoughfullyByID.categories.map(item => item.id));
      const ids_2 = new Set(categoryFetched.map(item => item.id));
      const uniqueToArray_1 = thoughfullyByID.categories.filter(item => !ids_2.has(item.id));
      const uniqueToArray_2 = categoryFetched.filter(item => !ids_1.has(item.id));
      const result_1 = [...uniqueToArray_1, ...uniqueToArray_2];
      setCategories(result_1);
    }
    teamsAndCategoryCreated(id);
   if(!id){
    setQuestions([
      {
        "qnIndex":1,
        "question":"",
        "extraTime":0,
        "showControlPanel": true,
        "saved": false
      }
    ])
   }else{

   }

  }, []);

 
  return (
    <DashboardApp extraClass={"landscape_only"} {...props.profileData}>
    
      <div className={`thoughtfully_view_container ${darkModeClass}`}>
        <SideMenu />
        <div className="middle_area">
          <div className="middle_content">
            <div className="left">
              
                <div className="activity_photo_sec">
                    <div className="photo">
                      <img src={activityThumbnail} alt="" />
                    </div>
                    {
                      (actionType!=="view") && <div className="camera_trigger" onClick={() => {
                              document.querySelector("#activity_img_trigger").click();
                        }}>
                          <IconCamera/>
                      </div>
                    }
                    
                    <input type="file" accept="image/*" onChange={onSelectFile} id="activity_img_trigger"/>
                </div>
                <div className="sec1">
                <div className="bottom">
                {
                  (actionType=="view" && !isShared) && <div className="activity_edit_trigger" onClick={() => {setShowControlPanel(!showControlPanel)}}>
                      <span></span>
                      <span></span>
                      <span></span>
                  </div>
                  
                }
                 {
                    showControlPanel && <>
                       <ControlPanel 
                          style={{'right':'0px','top':'30px'}} 
                          controlType="type1" 
                          onEdit={()=>{onThoughtfullyEdit(); setShowControlPanel(false);}} 
                          onDelete={()=>{onThoughtfullyDelete(); setShowControlPanel(false);}}
                        />
                    </>
                }
                <div className={actionType=="view"?'field disabled_underline':'field'}>
                  <input
                    disabled={actionType=="view"}
                    type="text"
                    name="thoughtfully_name"
                    id="thoughtfully_name"
                    value={activityName}
                    onChange={updateActivityName}
                    placeholder="Name*"
                    maxLength={40}
                    autocomplete="off"
                  />
                </div>
                <div className={actionType=="view"?'field disabled':'field'}>
                  <textarea
                    disabled={actionType=="view"}
                    name="thoughtfully_description"
                    id="thoughtfully_description"
                    placeholder="Description"
                    value={activityDesc}
                    onChange={updateActivityDesc}
                    rows={actionType=="view"?2:2}
                    style={{ resize: 'none' }}
                    maxLength={100}
                  />
                </div>
                <div className={(actionType=="view" || isShared)?'field disabled':'field'} id="team_addition">
                  <ReactTags
                    ref={reactTags}
                    tags={teamsSelected}
                    suggestions={teams}
                    onDelete={onDelete}
                    onAddition={onAddition}
                    allowBackspace={false}
                    activateFirstOption={true}
                    minQueryLength={0}
                    maxSuggestionsLength={10}
                    placeholderText='Add to Teams'
                    noSuggestionsText="No Team Found"
                    classNames={{root: 'react-tags', rootIsActive: 'is-active', rootIsDisabled: 'is-disabled', rootIsInvalid: 'is-invalid', label: 'react-tags__label', tagList: 'react-tags__list', tagListItem: 'react-tags__list-item', tag: 'react-tags__tag', tagName: 'react-tags__tag-name', comboBox: 'react-tags__combobox', input: 'react-tags__combobox-input', listBox: 'react-tags__listbox', option: 'react-tags__listbox-option', optionIsActive: 'is-active', highlight: 'react-tags__listbox-option-highlight'}}
                  />
                </div>
                <div className={(actionType=="view" || isShared)?'field disabled':'field inline'} id="category_addition" >
                  <ReactTags
                    ref={reactTags2}
                    tags={categorySelected}
                    suggestions={categories}
                    onDelete={onCategoryDelete}
                    onAddition={onCategoryAddition}
                    allowBackspace={false}
                    activateFirstOption={true}
                    minQueryLength={1}
                    onInput={onCategoryInput}
                    onBlur={()=>{setTimeout(setCanCustomizeCategory.bind(this, false), 200)}}
                    maxSuggestionsLength={10}
                    placeholderText='Add to category'
                    noSuggestionsText='No Category Found'
                    classNames={{root: 'react-tags', rootIsActive: 'is-active', rootIsDisabled: 'is-disabled', rootIsInvalid: 'is-invalid', label: 'react-tags__label', tagList: 'react-tags__list', tagListItem: 'react-tags__list-item', tag: 'react-tags__tag', tagName: 'react-tags__tag-name', comboBox: 'react-tags__combobox', input: 'react-tags__combobox-input', listBox: 'react-tags__listbox', option: 'react-tags__listbox-option', optionIsActive: 'is-active', highlight: 'react-tags__listbox-option-highlight'}}
                  />
                  {
                    canCustomizeCategory && <Button 
                      className={`purple-white bold-text no-icon`}
                      style={{padding:'5px 10px'}}
                      value="ADD"
                      onClick={addCustomCategory}
                    />
                  }
                  
                </div>
                </div>
            
                <div className="left_buttons">
                    <Button 
                      className={`${questions.length>0 && questions[0].saved?'':'disabled '}white-purple bold-text no-icon`}
                      value="PREVIEW"
                      onClick={ () => { setShowPreview(true); setTimeout(setShowPreviewControls.bind(this, true), 0)}}
                    />
                {
                  (actionType=="view") &&  <Button 
                    className="green bold-text no-icon text-center"
                    value="GO LIVE"
                    onClick={onThoughtfullyLive}
                  />
                }
                {
                  (actionType!="view") &&  <Button 
                    className="purple-white bold-text no-icon"
                    value={id?"UPDATE":"CREATE"}
                    onClick={id?validateAndUpdateThoughtfully:validateAndCreateThoughtfully}
                  />
                }
                 
                </div>
              </div>
            </div>
            <div className="right">
              <div className="activity_view_head">
                <div className="title">{actionType=='create'?'CREATE THOUGHTFULLY':actionType=='view'?'YOUR THOUGHTFULLY':'EDIT THOUGHTFULLY'}</div>
                <Button 
                  icon={<IconLeft/>}
                  className="yellow-black-opaque bold-text minimal-padding"
                  value="BACK"
                  navigateTo={"/dashboard"}
                  
                />
                                                

              </div>
                <div className="activity_time_sec">
                      <div className="time_left">
                        <div className="title">Set Default Time</div>
                        <div className="adjust_sec">
                          <div className={`${(actionType === 'view'||activityTime<=30)?'disabled':''} dec`} onClick={ actionType!=='view'?adjustTime.bind(this, -15):()=>{}}>-15s</div>
                          <div className="time_txt">{activityTime} seconds</div>
                          <div className={`${(actionType === 'view'||activityTime>=120)?'disabled':''} inc`} onClick={actionType!=='view'?adjustTime.bind(this, 15):()=>{}}>+15s</div>
                        </div>
                      </div>
                      <div className="time_right">
                        {/* <Button 
                          className={`${actionType === 'view'?'disabled2':''} purple-purple bold-text`}
                          value={showName?"View Name":"Anonymous"}
                          onClick={() => { actionType!=='view'?setShowName(!showName):() => {} }}
                        /> */}
                        <div className={`${darkModeClass} label`}>Display Names</div>
                       <div className="toggle_sec">
                       <div className={`${darkModeClass} toggle_labels`}>
                                            <div className="label">Yes</div>
                                            <div className="label">No</div>
                                        </div>
                          <ToggleButton
                                            value={!showName}
                                            colors={{
                                            activeThumb: {
                                                base: '#684d94',
                                            },
                                            inactiveThumb: {
                                                base: '#684d94',
                                            },
                                            active: {
                                                base: darkModeClass=='darkMode'?'rgb(40 40 40)':'#7e7e7e',
                                                hover: darkModeClass=='darkMode'?'rgb(40 40 40)':'#7e7e7e',
                                            },
                                            inactive: {
                                                base: darkModeClass=='darkMode'?'rgb(40 40 40)':'#7e7e7e',
                                                hover: darkModeClass=='darkMode'?'rgb(40 40 40)':'#7e7e7e',
                                            }
                                            }}
                                            activeLabel={''}
                                            inactiveLabel={''}
                                            thumbAnimateRange={[0, 65]}
                                            thumbStyle={{borderRadius: 5, border:'0', width:'65px', height:'35px'}}
                                            trackStyle={{borderRadius: 5, width:'130px', height:'35px'}}
                                            activeLabelStyle={{width:'50%',fontSize:'14px', fontFamily:'MontserratBold', color:'#ffffff'}}
                                            inactiveLabelStyle={{width:'50%',fontSize:'14px', fontFamily:'MontserratBold', color:'#ffffff'}}
                                            onToggle={async(value) => {
                                              setShowName(!showName)
                                                /* setDarkMode(!value);
                                               
                                                const res= await updateTheme({
                                                    theme: !darkMode
                                                });
                                                localStorage.setItem('darkMode', res.theme);
                                                setDarkModeClass(localStorage.getItem('darkMode') == 'true'?'darkMode':'')
                                                if(res.theme){
                                                    document.querySelector(".dashboard_header").classList.add('dark');
                                                    document.querySelector(".dashboard-app").classList.add('dark');
                                                    document.querySelector(".account_container").classList.add('dark');
                                                }else{
                                                    document.querySelector(".dashboard_header").classList.remove('dark');
                                                    document.querySelector(".dashboard-app").classList.remove('dark');
                                                    document.querySelector(".account_container").classList.remove('dark');
                                                } */
                                            }}
                                        />
                       </div>
                      </div>
                    </div>
              <div className="questions_sec">
                {
                  questions.map((question, index) => (
                    <div className="question_group">
                      <QuestionMini 
                          {...question}
                          onQnEdit={onQnEdit}
                          onQnDuplicate={onQnDuplicate}
                          onQnDelete={onQnDelete}
                          saveQuestion={saveQuestion}
                          showQnMenu={showQnMenu}
                          updateQnOrder={updateQnOrder}
                          qnMax={questions.length}
                          // thumbnail={question['img']}
                          updateQuestion={updateQuestion}
                          actionType={actionType}
                      />
                      {/* {
                        showEmptyQnError && <div className="qnError">Question should not be empty</div>
                      } */}
                       {
                            !question['saved'] &&  
                            <div className="extra_time_picker">
                              <div className="title">Add More Time for This Prompt</div>
                              <div className="extras">
                                { 
                                  extras.map((extra) => (
                                    (activityTime + extra <=120) ? <div className={question['extraTime']== extra?"extra active":"extra"} id={`${extra}s`} onClick={ (v) => {
                                      updateExtraTime(question.qnIndex, extra, v.target)
                                    }}>+{extra}s</div>:<></>
                                  ))
                                }
                              </div>
                            </div>
                        }   
                            
                      
                    </div>
                  ))
                }
              </div>
              {
                actionType!=='view' && (questions.length>0 && questions[questions.length-1]['saved']) && questions.length<20 && <>
                    <Button 
                      style={{marginBottom:'20px'}}
                      className="transparent bold-text"
                      icon={<IconPlusThin/>}
                      value="Add another prompt"
                      onClick={addNewQuestion}
                    />
                </>
              }
              
            </div>
          </div>
          <Copyright/>
        </div>
        <Ads  darkModeClass={darkModeClass}/>
        
      </div>
      { <div className={src!=null?'thumbnail_crop_area active':'thumbnail_crop_area'}>
                {<ReactCrop crop={crop} onChange={c => {
                    setCrop(c)
                }} aspect={1} onComplete={onCropUpdate}>
                    <img src={src}  ref={imgRef}/>
                </ReactCrop>}
                {
                    canShowCropButton && <Button 
                        className="purple-purple bold-text"
                        style={{borderRadius:'15px', padding:'10px 45px'}}
                        value= "CROP"
                        onClick={cropImage}
                    />
                }
                
            </div>}
            {
              questions.filter(obj => obj.saved).length >0 && showPreview && 
                <>
                  <div className="preview_sec">
                    <Header extraClass={props.extraClass} showQnHeader={true} activity_name={activityName}/>
                    <QuestionPage 
                      isPreview={true} 
                      question={questions[previewIndex].question} 
                      question_index={previewIndex} 
                      question_total={questions.filter(obj => obj.saved).length} question_time={activityTime+questions[previewIndex]['extraTime']}
                    />
                    <Footer showQnFooter={true} thoughtfullyID={'xxxxxx'}/>
                    {/* <Button 
                          className="purple-purple bold-text transparent"
                          icon={<IconLeft/>}
                          style={{borderRadius:'15px', width:'20px', height:'20px' }}
                          value= ""
                          onClick={cropImage}
                      /> */}
                  </div>
                  <div className={`${showPreviewControls?'active ':''}preview_controls`}>
                      <Button 
                          className={`${previewIndex==0?'disabled ':''}purple-purple bold-text text-center`}
                          // icon="/images/dashboard/icons/Icon-ArrowLeft.svg"
                          style={{borderRadius:'15px', padding:'10px 0px', width:'120px' }}
                          value= "PREVIOUS"
                          onClick={navigatePreview.bind(this, -1)}
                      />
                      <Button 
                          className="yellow bold-text text-center"
                          // icon="/images/dashboard/icons/Icon-ArrowLeft.svg"
                          style={{borderRadius:'15px', padding:'10px 20px', width:'120px' }}
                          value= "End Preview"
                          onClick={skipPreview}
                      />
                      <Button 
                          className={`${previewIndex == questions.filter(obj => obj.saved).length-1?'disabled ':''}purple-purple bold-text text-center`}
                          // icon="/images/dashboard/icons/Icon-ArrowLeft.svg"
                          style={{borderRadius:'15px', padding:'10px 0px', width:'120px' }}
                          value= "NEXT"
                          onClick={navigatePreview.bind(this, 1)}
                      />
                  </div>
                </>
            }    
            <ErrorPanel showError={showError} errorMsg={errorMsg}/>
            {
              showAd && <LivePopup showAd={showAd} liveActivityID={liveActivityID} closeLivePopup={closeLivePopup}/>
            }
            {
                props.tutorial && <Tutorial tutorialIndex={tutorialIndex}/>
            }
    </DashboardApp>
  );
};

export default ThoughtfullyView;