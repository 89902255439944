import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/newPassword.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import {changeNewPassword, doLogin, sendRecoveryCode, verifyRecoveryCode} from '../../common/api';
import { Link, useParams  } from 'react-router-dom/cjs/react-router-dom';
import Button from '../../components/dashboard/button';
import ToggleButton from 'react-toggle-button'
import { history } from '../../components/history'
import IconProfile from '../../components/dashboard/icons/Icon-Profile';
import IconPassword from '../../components/dashboard/icons/Icon-Password';

const NewPasswordPage = (props) => {
    const { id } = useParams();
   
    
    const [password, setPassword]= useState('');
    const [newPassword, setNewPassword]= useState('');
    const [resetSuccess, setResetSuccess]= useState(false);
    const [errorMsg, setErrorMsg]= useState("");
    const [validData, setValidData]= useState(true);

    const recoverCheck= async() => {
      if(password.length ==0){
          setErrorMsg("Invalid new password");
          setValidData(false);
          return false;
      }
      if(password != newPassword){
        setErrorMsg("Password and newpassword doesnt match");
        setValidData(false);
        return false;
    }
    
      const res= await changeNewPassword(password, id);
      if(res.status === 200){
        setResetSuccess(true)
      }
    }
  useEffect(() => {

    const verifyCode= async () => {
        const res= await verifyRecoveryCode(id);
        if(res.status === 401){
            history.push('/dashboard/login')
        }
    }

    // verifyCode();
  
  }, []);

  return (
        <DashboardApp extraClass={"landscape_only"} addHeader={false}>
            <div className="newpassword_container">
              <div className="left">
                <div className="logo">
                    {
                        localStorage.getItem('darkMode') !== 'true'?<img src="/images/Logo.png" alt="" />:<img src="/images/LogoW.png" alt="" />
                    }
                </div>
              </div>
              <div className="right">
                <div className="newpassword_area">
                    {
                        resetSuccess?<>
                             <div className="thank_icon">
                                    <img src="/images/dashboard/icons/tick.png" alt="" srcset="" />
                                </div>
                                <div className="thank_label">
                                    Password changed successfully.
                                </div>
                        </>:<>
                           <div className="head">
                                <div className="title">Reset Password</div>
                            </div>
                            <div className="content">
                                <div className='field'>
                                    <div className="icon"><IconPassword/></div>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        value={password}
                                        onChange={(v)=>{setPassword(v.target.value)}}
                                        placeholder="New password"
                                        autocomplete="off"
                                    />
                                </div>
                                <div className='field'>
                                    <div className="icon"><IconPassword/></div>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        value={newPassword}
                                        onChange={(v)=>{setNewPassword(v.target.value)}}
                                        placeholder="Confirm password"
                                        autocomplete="off"
                                    />
                                </div>
                                
                                <div className="buttons">
                                    <Button 
                                        className="yellow bold-text text-center"
                                        style={{borderRadius:'30px', padding:'15px 0'}}
                                        value="RECOVER"
                                        onClick={recoverCheck}
                                    />
                                    {
                                        !validData && <div className="recover_error">{errorMsg}</div>
                                    }
                                </div>

                            </div>
                        </>
                    }
                 
                </div>
              </div>
            </div>
        </DashboardApp>
  );
}


export default NewPasswordPage;