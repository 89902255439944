

import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconSchool(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
    <g>
      <g id="Layer_1">
        <g>
          <path d="M144.2,100v45.7c0,6.7-4.4,11.9-10.9,13.2-.8.2-1.7.2-2.6.2-20.5,0-41-.1-61.5,0-6.3,0-11.9-4.3-13.1-10.2-.2-1.1-.4-2.3-.4-3.4V54.6c0-7,4.4-12.3,11.3-13.5.7,0,1.4,0,2.1,0h61.8c7.5,0,13.3,5.7,13.3,13.3.1,15.2,0,30.4,0,45.7ZM61.1,99.8v45.1c0,.9,0,1.9.2,2.8,1,3.9,4,6.1,8.3,6.1h60.9c.9,0,1.9-.1,2.8-.3,3.7-1,5.8-4,5.8-8.4V55c0-1-.1-2-.3-2.9-1-3.8-4-5.9-8.4-5.9h-60.5c-1,0-2,0-2.9.3-2.9.7-5.8,3.6-5.8,8.1-.1,15.1-.1,30.2-.1,45.2Z"/>
          <path d="M99.9,132.6h-17c-2.7,0-3.7-1-3.6-3.7.3-9.9,7.4-18.3,16.8-20,11-2.1,21.5,5,23.9,16,.3,1.4.5,2.8.5,4.3,0,2.5-.9,3.5-3.5,3.5h-10.6c-2-.1-4.2-.1-6.5-.1ZM115.2,127.3c-1.1-8.1-8.3-14.3-17-13.3-7.7.9-12.9,7.6-13.2,13.3h30.2Z"/>
          <path d="M100,103.7c-7.3,0-13.4-6.1-13.4-13.5s6-13.4,13.4-13.5,13.5,6.1,13.5,13.5-6.1,13.5-13.5,13.5ZM108.2,90.2c0-4.5-3.7-8.2-8.2-8.2s-8.1,3.7-8.1,8.3,3.8,8.2,8.2,8.2c4.5,0,8.1-3.8,8.1-8.3Z"/>
          <path d="M99.6,53.2h10.4c1.3,0,2.3.9,2.5,2,.2,1.3-.3,2.4-1.5,2.9-.4.2-.9.2-1.3.2h-20.2c-1.7,0-2.9-1.1-2.9-2.6s1.2-2.5,2.9-2.5c3.3,0,6.7,0,10.1,0h0Z"/>
        </g>
      </g>
    </g>
  </StyledSVG>
  )
}