import React, {useEffect} from 'react';
import AppWrapper from '../containers/app-wrapper';
import '../styles/notFound.scss';

const NotFound = (props) => {
    useEffect(() =>{
       
    }, [])

    return (
        <AppWrapper addHeader={false} addFooter={false}>
            <div className="bg_cover"><img src="/images/BG.svg" alt="" srcset="" /></div>
            <div className="error_title">404- Not Found</div>
        </AppWrapper>
    )

}

export default NotFound;