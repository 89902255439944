import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './style.scss'
import { Link } from 'react-router-dom'
import IconPasswordDark from '../icons/Icon-Password-Dark'

function Button({ icon, value, onClick, disabled, className, onEnterKeyPressed , ...props}) {
  const enterPressed = (ev) => {
    const code = ev.keyCode || ev.which
    if (code === 13) {
      onEnterKeyPressed()
    }
  }
  const [isHovered, setIsHovered] = useState(false);

  const buttonClass = classnames('button', className, { 'button--hovered': isHovered });


  return (
    <div
      onClick={!disabled ? onClick : () => {}}
      disabled={disabled ? 'disabled' : undefined}
      onKeyUp={(ev) => enterPressed(ev)}
      className={buttonClass}
      style={{...props.style}}
      id={props.id}
    >
      {
        icon && <span className="icon">
          {
            typeof icon == 'string'?<img src={icon} alt="" />:icon
          }
        </span>
      }
      <span className="label">{value}</span>
      {
        props.navigateTo && <Link to={{ pathname: props.navigateTo }} className=""></Link>
      }
      {
        props.navigateToBlank && <a href={props.navigateToBlank} className="" target='_blank'></a>
      }
      {
        props.showLock && <IconPasswordDark  darkModeColor={( props.darkModeColor && props.darkModeColor == 'darkMode')?"#ffffff":"#000000"} className="icon_exrta"/>
      }
    </div>
  )
}
Button.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  onEnterKeyPressed: PropTypes.func,
}

export default Button
