import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconProfile(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 500 500" darkModeColor={props.darkModeColor}>
    <g>
      <g id="Layer_1">
        <path class="" d="M422.8,424.8h-22.6c-1.3,0-2.3-1-2.3-2.3-1.7-45.7-19.9-83.5-55.4-112.5-29-23.7-62.7-34.2-100-32.5-74.3,3.4-138.7,64.8-140.4,145,0,1.3-1.1,2.3-2.3,2.3h-22.6c-1.3,0-2.4-1.1-2.4-2.4v-7.6c0-.2,0-.3,0-.5.2-.9.5-1.8.5-2.7,1.2-18.1,5.1-35.6,12.1-52.2,19.7-47,53.5-79.7,101-98.3h0c1.7-.7,2-2.9.6-4q-.1-.1-.2-.2c-25.3-19.9-39.1-45.8-40.2-78-.7-21,4.9-40.5,16.6-58.1,13.9-20.7,32.8-34.9,56.9-41.6,6.5-1.8,13.3-2.7,20-3.9,4.7,0,9.4,0,14.1-.2,5.6,1,11.3,1.5,16.7,2.9,38,10.1,63.7,33.7,74,71.5,10.8,39.6-.2,73.8-30,102-2.9,2.8-6.2,5.1-9.3,7.7.2.3.2.4.3.5.6.3,1.2.6,1.8.8,23.7,8.6,44.5,21.8,62.5,39.4,26.6,26.2,43.4,57.6,49.8,94.3,1.2,6.6,1.8,13.3,2.7,20v8c.3,1.6-.7,2.6-2,2.6ZM249.9,102.6c-40.6,0-73.7,33.2-73.7,73.7s33.4,73.9,74,73.8c40.6-.1,73.7-33.2,73.7-73.7s-33.4-74-74-73.8Z"/>
      </g>
    </g>
  </StyledSVG>
  )
}

