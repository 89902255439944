function IconCamera(){
  return(
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200">
      <g>
        <g id="Layer_1">
          <g>
            <path d="M166.2,72.6v66.4c-.1.2-.2.4-.2.6-1.1,6.1-5.7,10.9-11.7,12.2-.9.2-1.8.3-2.8.4H48.6c-.3-.1-.6-.2-.9-.2-4.6-.4-8.2-2.5-11-6.1-1.6-2-2.2-4.3-2.9-6.6v-66.4c0-.2.2-.3.2-.5,1.8-8.1,7.4-12.7,15.6-12.7h18.3c.5,0,.9,0,1.3,0,0-.2,0-.3,0-.4v-.5c.4-4.2,2.3-7.5,6.1-9.4,1.7-.8,3.7-1.1,5.5-1.6h37.7c.3,0,.7.2,1,.2,4.5.2,8.9,3.8,10.1,8.1.3,1.1.4,2.3.7,3.5h20.3c5.3,0,9.6,2.2,12.6,6.6,1.4,2.1,2,4.4,2.9,6.5ZM100,145.4h50.1c1.1,0,2.3-.1,3.3-.4,4-1.1,6.2-4.3,6.2-9v-60.6c0-1-.1-2-.3-2.9-1-3.8-4.1-6.1-8.3-6.1h-23.3c-2.6,0-4-1.3-4-3.9v-2.6c0-3.5-1.9-5.4-5.4-5.4h-37c-3,0-4.8,1.8-5,4.7,0,1.1,0,2.2,0,3.2,0,2.6-1.4,3.9-4,3.9-7.6,0-15.2,0-22.7,0-5.6,0-9.2,3.9-9.1,9.1.2,13,0,26.1,0,39.1v22.2c0,2,.5,3.7,1.6,5.3,1.9,2.5,4.5,3.3,7.5,3.3,16.7,0,33.5,0,50.3,0Z"/>
            <path d="M69.1,107.1c-.7-15.9,11-30.1,27.4-31.9,17.4-1.9,32.4,10.5,34.2,27.3,1.9,17.9-11,32.4-27.4,34.2-18.3,1.9-33.5-12-34.2-29.6ZM124.1,106.1c0-13.5-10.7-24.2-24.2-24.2s-24.1,10.8-24.1,24.2,10.9,24.2,24.2,24.1c13.4,0,24.1-10.8,24.1-24.1Z"/>
            <path d="M144.8,87.1c-3.4,0-6.2-2.7-6.2-6.1s2.8-6.1,6.1-6.1,6.2,2.7,6.2,6.1c0,3.4-2.7,6.1-6.1,6.1Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconCamera;