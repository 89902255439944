import { useEffect, useRef } from 'react';
import EventEmitter from 'events';

const useEventEmitter = () => {
  const emitterRef = useRef(new EventEmitter());

  useEffect(() => {
    const emitter = emitterRef.current;

    return () => {
      emitter.removeAllListeners(); // Cleanup when component unmounts
    };
  }, []);

  const emitEvent = (eventName, payload) => {
    const emitter = emitterRef.current;
    emitter.emit(eventName, payload);
  };

  const addEventListener = (eventName, listener) => {
    const emitter = emitterRef.current;
    emitter.on(eventName, listener);
    // Return a cleanup function to remove the listener
    return () => {
      emitter.removeListener(eventName, listener);
    };
  };
  const removeEventListener = (eventName, listener) => {
        const emitter = emitterRef.current;
        emitter.off(eventName, listener);
   
    };
    return { emitEvent, addEventListener, removeEventListener };
}

export default useEventEmitter;
