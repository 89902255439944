import React, { useState, useEffect } from 'react'
import { history } from '../../history'
import './style.scss'
import Button from '../button';
import ThoughtfullyMini from '../thoughtfully-mini';
import { getRecentActivities } from '../../../common/api';
import IconRecent from '../icons/Icon-Recent';

const RecentActivity = (props) => {
  const [darkModeClass, setdarkModeClass ]= useState(
    localStorage.getItem('darkMode') == 'true'?'darkMode':''
  )
  const [recentThoughtfullys, setRecentThoughtfullys] = useState([]);
  useEffect(() => {
    const getRecentThoughtfullys= async() => {
      const res= await getRecentActivities();
      setRecentThoughtfullys(res.activities);
    }
    if(!props.tutorial){
      getRecentThoughtfullys();
    }else{
      setRecentThoughtfullys([
        {
          "_id": "66c9c279636d655494595b73",
          "teacherId": "123",
          "activityName": "<Thoughtfully Name>",
          "activityThumbnail": "/images/dashboard/Thoughtfully.png",
          "activityDesc": "",
          "activityTime": 30,
          "teams": [],
          "categories": [],
          "anonymous": true,
          "sessionsTotal": 4,
        "lastSession": "2024-08-25T00:18:21.788Z",
        "questions":[],
        "sharedTo":[]

        }
      ])
    }

    return () => {
    };
  }, []);
  return (
    <div className={`right_area ${darkModeClass}`}>
        <div className="recent_title">
            <div className="icon">
                <IconRecent darkModeColor={( localStorage.getItem('darkMode') == 'true')?"#ffffff":"#000000"}/>
            </div>
            <div className="label">Recent</div>
            </div>
            <div className="recent_content">
            {
                recentThoughtfullys.map((thoughtfully) => (
                <ThoughtfullyMini 
                    className="mini"
                    key={"mini"+thoughtfully['key']}
                    {...thoughtfully}
                    showEditOrView={false}
                    style={{'right':'0px','top':'30px'}}
                    onThoughtfullyLive={props.onThoughtfullyLive.bind(this, thoughtfully._id)}
                />
                ))
            }
        </div>
    </div>
  )
}

export default RecentActivity;