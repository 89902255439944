
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconLocation(props){

  return(
    <>
        <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" darkModeColor={props.darkModeColor}>
        <g>
            <g id="Layer_1">
            <g id="Layer_41">
                <path d="M246.7,421.5c4,5.4,11.6,6.6,17,2.6,1-.7,1.9-1.6,2.6-2.6l93.3-127.2c28.6-39.2,33.6-90.9,13-134.8-19-42.3-59.9-70.6-106.1-73.6-6.6-.4-13.3-.4-20,0-46.3,3-87.1,31.3-106.1,73.6-20.6,44-15.6,95.6,13,134.8l93.3,127.2ZM162.5,169.7c15.3-34.2,48.3-57.1,85.7-59.6,2.8-.2,5.6-.3,8.4-.3s5.6,0,8.3.3c37.4,2.5,70.4,25.4,85.7,59.6,16.8,35.9,12.8,78.1-10.6,110.1l-83.4,113.8-83.4-113.8c-23.4-32-27.4-74.2-10.6-110.1Z"/>
                <path d="M256.5,280.3c37,0,67-30,67-67s-30-67-67-67-67,30-67,67c0,37,30,66.9,67,67ZM256.5,170.8c23.5,0,42.6,19.1,42.6,42.6s-19.1,42.6-42.6,42.6-42.6-19.1-42.6-42.6c0-23.5,19.1-42.6,42.6-42.6Z"/>
            </g>
            </g>
        </g>
        </StyledSVG>
    </>
  )
}