import React, {useEffect, useState} from 'react';
import AppWrapper from '../../containers/app-wrapper';
import '../../styles/finish.scss';
import { Global } from '../../common/global';
import ColyseusComponent from '../../common/socket';
import StudentProfileIcon from '../../components/studentProfileIcon';
import { useMediaQuery } from 'react-responsive';


const FinishScreen = (props) => {
    const isPortraitScreen = useMediaQuery({
        query: '(max-width: 1024px) and (max-aspect-ratio: 13/10)'
    });

    const [pickedToShare, setPickedToShare]= useState(false);
    const [studentData, setStudentData]= useState({})

    ColyseusComponent({
        "initClient": false
    }); 
    const onPickedToShare= () => {
        setPickedToShare(true);
    }
    const clearPickedToShare= () =>{
        setPickedToShare(false);
    }
    useEffect(() => {
        setStudentData({
            'id': Global.avatarID,
            'name': Global.userName,
            'img': Global.imgKey
        })
        Global.addEventListener("user:picked_to_share", onPickedToShare);
        return () => {
            Global.removeEventListener("user:picked_to_share", onPickedToShare);
        }
    }, []);
    return (
        <AppWrapper>
            <div className="bg_cover"><img src="/images/BG.svg" alt="" srcset="" /></div>
            <div className="finish_ui">
                        <div className="container">
             
                        <div className="finish_icon">
                            <img src="/images/icons/Icon-Tick.svg" alt="" srcset="" onClick={clearPickedToShare} />
                        </div>
                        <div className="title">Well Done!</div>
                        <div className="info">Thank you for participating.</div>
                            
                        </div>
                    </div>
            
            {
                pickedToShare && <>
                    <div className="picked_ui">
                        
                        <div className="container">
                        <div className="close" onClick={clearPickedToShare}>
                            <img src="/images/icons/Icon-Cross.svg" alt="" srcset="" />
                        </div>
                        <div className="profile_img">
                            <StudentProfileIcon id={studentData.id} imgUrl={studentData.img} name={studentData.name} showName={true} showPhoto={true} extraImgStyles={{marginBottom: isPortraitScreen?'5vw':'5vh',width:isPortraitScreen?'30vw':'20vh',height:isPortraitScreen?'30vw':'20vh', backgroundColor:'#684d94'}} extraProfileStyles={{width:'100%', borderRadius:'10vw'}} extraNameStyles={{fontSize: isPortraitScreen?'7vw':'5.5vh'}}/>
                        </div>
                        <div className="title">You have been picked to share rationale!</div>
                        <div className="info">Let your teacher know if you would like to respond.</div>
                            
                        </div>
                    </div>
                </>
            }
        </AppWrapper>
    )
}

export default FinishScreen