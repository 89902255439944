import styled from 'styled-components';

const StyledSVG = styled.svg`
#lock_path {
  fill: #ffffff;
}
`;
export default function IconPasswordHide(){
  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200">
        <g>
            <g id="Layer_1">
            <g>
                <path d="M183.4,101c-.8,1.1-1.6,2.2-2.5,3.2-12.4,13.5-26.3,25.1-42.5,33.8-9.4,5.1-19.3,8.8-30,10.2-10.1,1.3-20.2.4-30-2.4-4.4-1.3-8.6-2.9-12.9-4.5-1-.4-1.5-.3-2.2.5-6.3,6.4-12.6,12.7-19,19-.5.5-.9.9-1.4,1.3-1.5,1-3.2.8-4.4-.5-1.1-1.2-1.3-2.9-.3-4.3.4-.5.9-1,1.3-1.4,12.6-12.6,25.2-25.2,37.7-37.8,17.8-17.8,35.5-35.5,53.3-53.3,8.3-8.3,16.6-16.7,25-25,.3-.3.6-.6.9-.9,1.7-1.5,3.7-1.6,5.1-.2,1.4,1.4,1.4,3.5-.2,5.1-4.2,4.3-8.4,8.5-12.6,12.7-2.5,2.5-4.9,4.9-7.3,7.3,4.3,2.9,8.7,5.6,12.8,8.5,10.2,7.3,19.4,15.6,27.8,25,.5.6,1,1.3,1.4,1.9,0,.5,0,1.1,0,1.8ZM175.3,100.1c-.4-.5-.6-.7-.8-.9-1.5-1.6-3-3.1-4.6-4.6-9.7-9.5-20.3-18-32.1-24.8-1.3-.7-2.1-.8-3.1.4-3.2,3.4-6.5,6.6-9.8,9.8-1,1-2.1,1.9-3,2.8,9,12.3,6.8,26.9-1.1,35.6-4.1,4.5-9,7.6-15,8.8-8.4,1.7-16,0-22.6-5-4.5,4.5-8.9,8.8-13.3,13.2,0,0,.3.2.5.2,9,3.7,18.3,5.8,28,6,10.9.3,21.1-2.5,30.9-7.1,14.2-6.5,26.5-15.8,37.8-26.4,2.8-2.5,5.4-5.3,8.2-8ZM88.3,117.1c6.4,5,18.5,5.7,26.6-2.7,8.2-8.5,6.8-20.5,2.1-26.1-9.5,9.6-19.1,19.2-28.7,28.8Z"/>
                <path d="M24.6,100.3c2.3,2.6,4.5,5.2,6.9,7.8,6.3,6.7,13.2,12.7,20.8,17.9.9.6,1.7,1.6,2.1,2.6.5,1.4,0,2.7-1.3,3.6-1.3,1-2.8.9-4.1-.1-4.3-3.2-8.7-6.4-12.8-9.8-6.7-5.6-12.7-12-18-19-2.2-2.9-2.2-3.9.3-6.6,13.4-14.7,28.4-27.2,46.3-36.1,12-6,24.7-9.7,38.3-9,6.6.3,13.1,1.7,19.4,3.8,2.1.7,3.1,2.5,2.5,4.4s-2.5,2.8-4.6,2.1c-7.1-2.3-14.4-3.8-21.9-3.5-10.6.3-20.5,3.4-29.9,8.1-16.6,8.1-30.7,19.8-43.4,33.1-.2.2-.3.4-.6.7Z"/>
                <path d="M72.2,99.6c.3-17,15.6-29.8,32.4-27,2.1.3,3.4,1.9,3.2,3.8-.2,2-1.8,3.3-3.9,3-4-.6-7.8-.3-11.5,1.2-9,3.6-14.1,12.6-12.9,22.1,0,.8,0,1.8,0,2.6-.4,1.4-1.9,2.4-3.4,2.3-1.6,0-3-1.2-3.3-2.9-.2-1.6-.4-3.3-.5-5.1Z"/>
            </g>
            </g>
        </g>
        </StyledSVG>
  )
}