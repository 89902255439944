import React, { useState, useEffect } from 'react'
import { history } from '../../history'
import './style.scss'
import Button from '../button';
import classnames from 'classnames';

const ConfirmPopup = (props) => {
    const [darkModeClass, setdarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      );
        const [controlType, setControlType]= useState('');


  useEffect(() => {
    return()=>{
    }
  }, [])
  return (
    props.isMini?
    <div className={`confirm_container ${darkModeClass}`}>
        <div id="closeBtn" className='close' onClick={props.onCancel} ></div>
        <div className="popup_container">
            <div className={`confirm_sec ${darkModeClass}`}>
                <div className="txt">{props.title}</div>
                {
                    props.desc.length>0 && <div className="desc">{props.desc}</div>
                }
                <div className="buttons">
                    <Button
                        className={`purple-purple bold-text text-center`}
                        style={{borderRadius:'10px', width:'150px'}}
                        value="PROCEED"
                        onClick={props.onProceed}
                    />
                    <Button
                        className={`purple-purple bold-text text-center`}
                        style={{borderRadius:'10px', width:'150px'}}
                        value="CANCEL"
                        onClick={props.onCancel}
                    />
                </div>
            </div>
        </div>
    </div>:<div className={`confirm_sec ${darkModeClass}`}>
        <div className="txt">{props.title}</div>
        {
            props.desc.length>0 && <div className="desc">{props.desc}</div>
        }
        
        <div className="buttons">
            <Button
                className={`purple-purple bold-text text-center`}
                style={{borderRadius:'10px', width:'150px'}}
                value="PROCEED"
                navigateTo={props.navigateTo}
                // onClick={props.onProceed}
            />
            <Button
                className={`purple-purple bold-text text-center`}
                style={{borderRadius:'10px', width:'150px'}}
                value="CANCEL"
                onClick={props.onCancel}
            />
        </div>
    </div>

    
  )
}

export default ConfirmPopup;