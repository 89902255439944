import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/account.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import SideMenu from '../../components/dashboard/sidemenu';
import Button from '../../components/dashboard/button';
import { Link } from 'react-router-dom';
import 'react-dropdown/style.css';
import 'rsuite/dist/rsuite.min.css';
import ToggleButton from 'react-toggle-button';
import { deleteUser, getProfile, updatePassword, updateTheme, updateTimeZone } from '../../common/api';
import Ads from '../../components/dashboard/Ad';
import IconEmail from '../../components/dashboard/icons/Icon-Email';
import IconPasswordDark from '../../components/dashboard/icons/Icon-Password-Dark';
import IconDelete from '../../components/dashboard/icons/Icon-Delete';
import Copyright from '../../components/dashboard/copyright';
import ConfirmPopup from '../../components/dashboard/confirmPopup';
import Dropdown from 'react-dropdown';

const AccountPage = (props) => {
    const [darkModeClass, setDarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      )
    const [email, setEmail] = useState('');
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode')!='false');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [canEdit, setCanEdit]= useState(false);
    const [passwordUpdateEnabled, setPasswordUpdateEnabled]= useState(true);
    const [passwordInvalidData, setPasswordInvalidData]= useState(false);
    const [passwordErrorMsg, setPasswordErrorMsg]= useState("");
    const [canShowDelete, setCanShowDelete]= useState(false);
    const [timeZones]= useState([
        { value: 'EST', label: 'Eastern Standard Time (EST) / Eastern Daylight Time (EDT)' },
        { value: 'CST', label: 'Central Standard Time (CST) / Central Daylight Time (CDT)' },
        { value: 'PST', label: 'Pacific Standard Time (PST) / Pacific Daylight Time (PDT)' },
        { value: 'GMT', label: 'Greenwich Mean Time (GMT) / British Summer Time (BST)' } ,
        { value: 'CET', label: 'Central European Time (CET) / Central European Summer Time (CEST)' } ,
        { value: 'EET', label: 'Eastern European Time (EET) / Eastern European Summer Time (EEST)' } ,
        { value: 'SST', label: 'Singapore Standard Time (SST)' } ,
        { value: 'JST', label: 'Japan Standard Time (JST)' } ,
        { value: 'IST', label: 'India Standard Time (IST)' } ,
        { value: 'AEST', label: 'Australian Eastern Standard Time (AEST) / Australian Eastern Daylight Time (AEDT)' } ,
        { value: 'BRT', label: 'Brasília Time (BRT)' } ,
        { value: 'AST', label: 'Arabian Standard Time (AST)' } ,
        { value: 'CAT', label: 'Central Africa Time (CAT)' }
    ]);
    const [timeZone, setTimeZone]= useState('')

    const deleteTeacher=  () => {
        setCanShowDelete(true);
    
    }
    const onTimeZoneUpdate= async (value) => {
        
         setTimeZone(value);
         await updateTimeZone({
            timezone: value
         })
    }
    const deleteTeacherConfirm= async () => {
        deleteTeacherCancel();
        const res= await deleteUser();
        location.href='/dashboard/login';
    }
    const deleteTeacherCancel= async () => {
        setCanShowDelete(false);
    }
    const enableEdit= () => {
        setCanEdit(true);
      }
      const saveEdits= async () => {
        if(oldPassword.trim().length==0){
            setPasswordInvalidData(true);
            setPasswordErrorMsg("Invalid old password.")
            return false;
        }
        if(newPassword.trim().length==0){
            setPasswordInvalidData(true);
            setPasswordErrorMsg("Invalid new password.")
            return false;
        }
        if(confirmNewPassword.trim().length==0){
            setPasswordInvalidData(true);
            setPasswordErrorMsg("Invalid confirm new password.")
            return false;
        }
        if(newPassword !== confirmNewPassword){
            setPasswordInvalidData(true);
            setPasswordErrorMsg("New password and confirm new password should be exact same.")
            return false;
        }
        setPasswordUpdateEnabled(false);
        setPasswordInvalidData(false);
        let response= await updatePassword({
            oldPassword,
            newPassword
        });
        if(response['status'] !== 200){

            setPasswordUpdateEnabled(true);
            setPasswordInvalidData(true);
            setPasswordErrorMsg(response['message'])
            return false;
        }
        setCanEdit(false);
     
        // await updateProfile({
        //     fullName,
        //     school,
        //     gender,
        //     dob,
        //     department
        // })
      }
  useEffect(() => {
    
    const getProfileInfo= async () =>{
        let res= await getProfile();
        if(!props.profileData){
            setEmail(res.email)
        }
        
        setTimeZone(res.timezone);
    };

    if(props.profileData){
        setEmail(props.profileData.email);
        
    }  
    getProfileInfo();
  }, []);

  return (
        <DashboardApp extraClass={"landscape_only"} {...props.profileData}>
            <div className={`account_container ${darkModeClass}`}>
                <SideMenu darkMode={darkModeClass}/>
                <div className="middle_area">
                    <div className="middle_content">
                        <div className="head">
                            <div className="left">
                            <div className="title">SETTINGS</div>
                            </div>
                           {/*  <div className="right">
                                <Link to={{ pathname: "/dashboard/settings/profile" }} className="">PROFILE</Link>
                                <Link to={{ pathname: "/dashboard/settings/account" }} className="active">ACCOUNT</Link>
                            </div> */}
                        </div>
                        <div className="account_sec">
                            <div className='field'>
                                <div className="icon"><IconEmail darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                <input
                                    type="text"
                                    disabled
                                    name="setting_email"
                                    id="setting_email"
                                    value={email}
                                    onChange={(v)=>{setEmail(v.target.value)}}
                                    placeholder="Email"
                                    autocomplete="off"
                                />
                            </div>
                            <div className='field'>
                                <div className="icon"><IconPasswordDark  darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                <input
                                    type="password"
                                    disabled={!canEdit}
                                    name="old_email"
                                    id="old_email"
                                    value={!canEdit?'*********':oldPassword}
                                    onChange={(v)=>{setOldPassword(v.target.value)}}
                                    placeholder="Old password"
                                    autocomplete="off"
                                />
                            </div>
                            {
                                canEdit ? <>
                                    <div className='field'>
                                        <div className="icon"><IconPasswordDark darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                        <input
                                            type="password"
                                            disabled={!canEdit}
                                            name="new_password"
                                            id="new_password"
                                            value={newPassword}
                                            onChange={(v)=>{setNewPassword(v.target.value)}}
                                            placeholder="New password"
                                            autocomplete="off"
                                        />
                                    </div>
                                    <div className='field'>
                                        <div className="icon"><IconPasswordDark darkModeColor={( darkModeClass == 'darkMode')?"#ffffff":"#000000"}/></div>
                                        <input
                                            type="password"
                                            disabled={!canEdit}
                                            name="confirm_new_password"
                                            id="confirm_new_password"
                                            value={confirmNewPassword}
                                            onChange={(v)=>{setConfirmNewPassword(v.target.value)}}
                                            placeholder="Confirm New password"
                                            autocomplete="off"
                                        />
                                    </div>
                                </>:<></>
                            }
                            
                            <Button 
                                className={`purple-purple bold-text text-center minimal-padding ${(canEdit && !passwordUpdateEnabled)?'disabled':''}`}
                                style={{borderRadius:'15px', padding:'10px 45px'}}
                                value={!canEdit?"CHANGE PASSWORD":"UPDATE"}
                                onClick={!canEdit?enableEdit:saveEdits}
                            />
                            {
                                passwordInvalidData && <div className="password_error">{passwordErrorMsg}</div>
                            }
                            <div className={canEdit?"dashboard_settings min-margin-top":"dashboard_settings"}>
                                <div className="setting_mode setting">
                                    <div className="setting_label">TIME ZONE</div>
                                    <div className="setting_content">
                                    <div className={`field ${darkModeClass}`}>
                                    <Dropdown options={timeZones} onChange={(v)=>{onTimeZoneUpdate(v.value)}} value={timeZone} placeholder="Account Type" />
                                    </div>
                                    </div>
                                </div>
                                <div className="setting_mode setting">
                                    <div className="setting_label">SCREEN MODE</div>
                                    <div className="setting_content toggle_container">
                                        <div className={!darkMode?"toggle_labels":"dark_mode toggle_labels"}>
                                            <div className="label">LIGHT</div>
                                            <div className="label">DARK</div>
                                        </div>
                                        <ToggleButton
                                            value={darkMode}
                                            colors={{
                                            activeThumb: {
                                                base: '#684d94',
                                            },
                                            inactiveThumb: {
                                                base: '#684d94',
                                            },
                                            active: {
                                                base: '#ffffff',
                                                hover: '#ffffff',
                                            },
                                            inactive: {
                                                base: 'rgb(65,66,68)',
                                                hover: 'rgb(65,66,68)',
                                            }
                                            }}
                                            activeLabel={''}
                                            inactiveLabel={''}
                                            thumbAnimateRange={[0, 122.5]}
                                            thumbStyle={{borderRadius: 8, border:'0', width:'122.5px', height:'50px'}}
                                            trackStyle={{borderRadius: 10, width:'245px', height:'50px'}}
                                            activeLabelStyle={{width:'50%',fontSize:'14px', fontFamily:'MontserratBold'}}
                                            inactiveLabelStyle={{width:'50%',fontSize:'14px', fontFamily:'MontserratBold'}}
                                            onToggle={async(value) => {
                                                setDarkMode(!value);
                                               
                                                const res= await updateTheme({
                                                    theme: !darkMode
                                                });
                                                localStorage.setItem('darkMode', res.theme);
                                                setDarkModeClass(localStorage.getItem('darkMode') == 'true'?'darkMode':'')
                                                if(res.theme){
                                                    document.querySelector(".dashboard_header").classList.add('dark');
                                                    document.querySelector(".dashboard-app").classList.add('dark');
                                                    document.querySelector(".account_container").classList.add('dark');
                                                }else{
                                                    document.querySelector(".dashboard_header").classList.remove('dark');
                                                    document.querySelector(".dashboard-app").classList.remove('dark');
                                                    document.querySelector(".account_container").classList.remove('dark');
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                
                                <div className="setting_mode setting">
                                    <div className="setting_label">DELETE MY ACCOUNT</div>
                                    <div className="setting_content">
                                        <Button 
                                            className="transparent-red bold-text"
                                            icon={<IconDelete/>}
                                            style={{borderRadius:'15px'}}
                                            value="DELETE"
                                            onClick={deleteTeacher}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Copyright darkModeClass={darkModeClass}/>
                </div>
                <Ads darkModeClass={darkModeClass}/>
            </div>
            {
              canShowDelete && <ConfirmPopup isMini={true} onProceed={deleteTeacherConfirm} onCancel={deleteTeacherCancel} title='Are you sure you want to delete account?' desc=''/>

            }
        </DashboardApp>
  );
}

export default AccountPage;