import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/reports.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import SideMenu from '../../components/dashboard/sidemenu';
import { history } from '../../components/history'
import Copyright from '../../components/dashboard/copyright';
import ReportsMini from '../../components/dashboard/reports-mini';
import { Link, NavLink, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getAllActivitySessions } from '../../common/api';
import IconLeft from '../../components/dashboard/icons/Icon-ArrowLeft';
import Button from '../../components/dashboard/button';
import SessionsMini from '../../components/dashboard/sessions-mini';

const ReportSessions = (props) => {
  const [darkModeClass, setdarkModeClass ]= useState(
    localStorage.getItem('darkMode') == 'true'?'darkMode':''
  )
 

  const [searchKey, setSearchKey]= useState('');
  const [activity, setActivity]= useState('');
  const [sessions, setSessions]= useState('');
  const {thoughtfullyID}= useParams();



  useEffect(() => {
        const getActivitySessions= async () => {
          
            const res= await getAllActivitySessions({
              activityId: thoughtfullyID
            });
            setActivity(res.activity);
            setSessions(res.sessions);
        }
        getActivitySessions();
  }, []);



  return (
        <DashboardApp extraClass={"landscape_only"} {...props.profileData}>
            <div className={`report_container ${darkModeClass}`}>
              <SideMenu/>
              <div className="middle_area">
                  <div className="head">
                    <div className="left">
                      <div className="title">REPORTS</div>
                    </div>
                    <div className="right">
                      <input type="text" name="reports_search" id="reports_search" placeholder="Search..." value={searchKey} onChange={(v) => { setSearchKey(v.target.value); }}  autocomplete="off"/>
                   
                    </div>
                  </div>
                  <div className="middle_content">
                    <div className="activity_head">
                      <div className="left">
                        <div className="activity_title">{activity.activityName}</div>
                        <div className="activity_info">No. of Live Sessions: <span className='bold'>{activity.sessionsTotal}</span> | Last Live: <span className="bold">{activity.lastSession/* new Date(activity.lastSession).toUTCString().replace('GMT', '').trim() */}</span></div>
                      </div>
                      <div className="right">
                        <div className="breadcrumb">
                          <Link to={{ pathname: '/dashboard/reports', state: { isNew: true } }}>Report</Link>&nbsp;/&nbsp;<Link to={{ pathname: `/dashboard/reports/${activity.activityId}/sessions`, state: { isNew: true } }}>{activity.activityName}</Link>
                        </div>
                        <Button 
                          icon={<IconLeft/>}
                          className="yellow-black-opaque regular-text minimal-padding"
                          value="BACK"
                          navigateTo={`/dashboard/reports`}
                          
                        />
                      </div>
                    </div>
                    {
                        sessions.length>0 && sessions.map((session, index) => {
                          if(session.sessionId.toLowerCase().indexOf(searchKey.toLowerCase())== 0){
                            return <SessionsMini {...session} {...activity}/>
                          }
                        })
                    }
                    {
                          sessions.length>0 && sessions.map((session, index) => {
                            if(session.sessionId.toLowerCase().indexOf(searchKey.toLowerCase())> 0){
                              return <SessionsMini {...session} {...activity}/>
                            }
                          })
                    }   
                  </div>
                  <Copyright/>
              </div>
            </div>
        </DashboardApp>
  );
}


export default ReportSessions;