import styled from 'styled-components';

const StyledSVG = styled.svg`
.cls-1 {
  fill: #7e7e7e;
}
`;
export default function IconPlusThin(){
  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200">
      <g>
        <g id="Layer_1">
          <path class="cls-1" d="M109.5,109.9v43.8h-18.8v-43.8h-44.3v-19.8h44.3v-43.8h18.8v43.8h44v19.8h-44Z"/>
        </g>
      </g>
    </StyledSVG>
  )
}