import styled from 'styled-components';

const StyledSVG = styled.svg`
#lock_path {
  fill: #ffffff;
}
`;
export default function IconPasswordShow(){
  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200">
    <g>
        <g id="Layer_1">
        <g>
            <path d="M183.4,101.3c-14.8,16.8-31.6,30.8-52.1,40.1-11.5,5.2-23.5,8-36.3,7-9.4-.7-18.3-3.4-26.8-7.3-15.1-6.9-28.2-16.6-40.1-27.9-3.6-3.4-6.9-7-10.3-10.6-1.6-1.7-1.6-3.4-.1-5.1,14.5-16.1,30.9-29.7,50.8-38.8,12.1-5.5,24.7-8.2,38-7.1,13.8,1.2,26.6,5.5,38.6,12.5,14.4,8.4,26.6,19.4,36.7,32.7.5.7,1,1.3,1.5,2,.1.8.1,1.6.1,2.5ZM175.6,100.1c-5.5-6.9-11.3-12.9-17.7-18.2-11.3-9.5-23.7-16.9-38-20.8-8.6-2.3-17.3-3.4-26.2-2.3-10.2,1.2-19.6,4.7-28.6,9.5-12.8,6.8-24,15.8-34.3,25.8-2,1.9-3.9,3.9-5.8,5.9.3.4.4.6.6.7,11.9,12.5,25.1,23.5,40.5,31.6,9.7,5.1,19.8,8.7,30.8,9.3,9.9.6,19.4-1.5,28.5-5.2,12.8-5.2,24.1-12.8,34.5-21.7,5.3-4.8,10.4-9.7,15.7-14.6Z"/>
            <path d="M100.1,72.2c15.3,0,27.8,12.5,27.8,27.8s-12.5,27.8-27.8,27.8-27.8-12.5-27.8-27.8c0-15.3,12.5-27.8,27.8-27.8ZM100,79.2c-11.4,0-20.8,9.5-20.8,20.9s9.5,20.8,20.9,20.8,20.8-9.5,20.8-20.9c0-11.4-9.5-20.8-20.9-20.8Z"/>
        </g>
        </g>
    </g>
    </StyledSVG>
  )
}