
import { useState, useEffect } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;
export default function IconReport(props){

  return(
    <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200" darkModeColor={props.darkModeColor}>
      <g>
        <g id="Layer_1">
          <g>
            <path d="M39.4,110.9c0-2.5,0-5,0-7.6,0-.3.2-.6.2-.9.3-1.9.4-3.9.8-5.8,5.1-24.5,27.1-42.6,52-42.7,2.9,0,4.2,1.3,4.2,4.2,0,15.7,0,31.5,0,47.2,0,.7.1,1.5.4,2.2,2.7,7.2,5.3,14.4,8,21.5,2.9,7.8,5.8,15.5,8.7,23.3.8,2.3,0,4.2-2.1,5-8.6,3.1-17.4,4.1-26.4,2.8-11.4-1.7-21.3-6.7-29.6-14.8-8.1-7.9-13.2-17.3-15.3-28.4-.4-2-.6-3.9-.9-5.9Z"/>
            <path d="M111.1,39.4c2,.3,4,.6,6,.9,12,2.3,22,8.1,30.1,17.2,6.3,7.1,10.4,15.3,12.2,24.5.7,3.5.9,7.2,1.1,10.8.1,2.1-1.5,3.6-3.6,3.6-16.5,0-33,0-49.5,0-2.2,0-3.6-1.5-3.6-3.7,0-16.6,0-33.1,0-49.7,0-1.9,1.1-2.9,2.6-3.7,1.6,0,3.2,0,4.7,0Z"/>
            <path d="M132.3,103.5c8.1,0,16.2,0,24.2,0,2.7,0,4.1,1.4,4,4.1-.5,17.6-7.9,31.8-21.8,42.6-3.7,2.9-7.9,5-12.2,6.8-2.4.9-4.3.1-5.2-2.3-5.7-15.3-11.4-30.6-17.2-45.9-1.1-3,.5-5.2,3.7-5.2,8.2,0,16.3,0,24.5,0Z"/>
          </g>
        </g>
      </g>
    </StyledSVG>
  )
}