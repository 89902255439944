import React, { useState, useEffect } from 'react'
import { history } from '../../history'
import './style.scss'
import { Link } from 'react-router-dom';
import { Menu, MenuItem, MenuButton,MenuDivider,MenuHeader } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/zoom.css';
import { logout } from '../../../common/api';
const DashboardHeader = (props) => {
  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode')!='false');

  const doLogout= async () => {
    await logout();
    location.href='/dashboard/login';
    // history.push('/dashboard/login')
  };

  useEffect(() => {
    return () => {
    };
  }, []);
  return (
    <header className={`${darkMode?'dark ':''}dashboard_header`}>
      <div className="head_container">
        <div className="logo">
        <Link to={{ pathname: "/dashboard/" }} className="active">
          {
              localStorage.getItem('darkMode') !== 'true'?<img src="/images/Logo.png" alt="" />:<img src="/images/LogoW.png" alt="" />
          }
        </Link>
        </div>
        <div className="center">
            <div className="more_info"><span className="label">If you love Thoughtfully, check out more educational tools at </span><span className="more_info_link"><a href="https://jalanjourney.com/" target="_blank" rel="noopener noreferrer">www.jalanjourney.com</a></span></div>
        </div>
       
          <Menu menuButton={
            <MenuButton className="right_controls">
                <div className={`${props.profileImg?'profile_img':'profile_img mini'}`}>
                  <img src={props.profileImg || '/images/dashboard/icons/Icon-Profile.svg'} alt="" />
                </div>
                <div className="profile_details">
                    <div className="name">{props.fullName||'Guest User'}</div>
                    <div className="role">{props.accountType=='others'?'':props.accountType}</div>
                </div>
                <div className="menu_trigger">
                        <img src="/images/dashboard/arrow_down.svg" alt="" />
                </div>
            </MenuButton>
            }>
            <Link to={{ pathname: "/dashboard/settings/profile" }} className=""><MenuItem>Profile</MenuItem></Link>
            <Link to={{ pathname: "/dashboard/settings" }} className=""><MenuItem>Settings</MenuItem></Link>
            <MenuDivider />
            <MenuItem onClick={doLogout}>Logout</MenuItem>
          </Menu>
      </div>
    </header>
  )
}

export default DashboardHeader
