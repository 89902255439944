

import { useEffect, useState } from 'react';
import styled from 'styled-components';

const getSVGStyle = (darkModeColor) => `
transform: translateX(-50%) scale(0.7) !important;
  &>* {
    fill: ${darkModeColor}
  }
`;

const StyledSVG = styled.svg`
  ${props => getSVGStyle(props.darkModeColor)}
`;

export default function IconRotate(props){
  
  return(
    <>
        <StyledSVG xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30.5 30.63" darkModeColor={props.darkModeColor}>
        <g>
          <g>
            <path d="M21 3.22A13.39 13.39 0 0128.59 14H30.5A15.31 15.31 0 0015.25 0l-.84 0L19.27 4.9zM11.64 4.42L26.15 18.93l-7.29 7.29L4.35 11.7l7.29-7.28m0-2.75a1.91 1.91 0 00-1.35.56L2.17 10.35a1.91 1.91 0 000 2.71L17.51 28.4a2 2 0 001.36.56 1.91 1.91 0 001.35-.56l8.11-8.12a1.9 1.9 0 000-2.71L13 2.23a1.91 1.91 0 00-1.35-.56zM9.53 27.42A13.41 13.41 0 011.91 16.59H0a15.31 15.31 0 0015.25 14l.84 0-4.86-4.86z"></path>
          </g>
        </g>
        </StyledSVG>
    </>
  )
}