import React, { useState, useEffect } from 'react'
import { history } from '../../history'
import './style.scss'
import Button from '../button';
import classnames from 'classnames';

const ControlPanel = (props) => {
    const [darkModeClass, setdarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      );
        const [controlType, setControlType]= useState('');


        const hideMenu= () => {
            setTimeout(() => {
                props.setShowControlPanel && props.setShowControlPanel(false)
            }, 0)
        }

  useEffect(() => {
    document.addEventListener('mouseup', hideMenu);
    setControlType(props.controlType);

    return()=>{
        document.removeEventListener('mouseup', hideMenu);
    }
  }, [])
  return (
    <div className={`control_panel ${darkModeClass}`} {...props}>
    {
        props.onView && <Button onClick={props.onView}
            value="View"
            className={`sideBarBottom mini text-center ${darkModeClass}`}
        />
    }
    {
        props.onEdit && <Button onClick={props.onEdit}
            value="Edit"
            className={`sideBarBottom mini text-center ${darkModeClass}`}
        />
    }
    {
        props.onDuplicate && <Button onClick={props.onDuplicate}
            value="Duplicate"
            className={`sideBarBottom mini text-center ${darkModeClass}`}
        />
    }
    {
        props.onInvite && <Button onClick={props.onInvite} 
            value="Invite"
            className={`sideBarBottom mini text-center ${darkModeClass}`}
        />
    }
    {
        props.onShare && <Button onClick={props.onShare}  
            value="Share"
            className={`sideBarBottom mini text-center ${darkModeClass}`}
        />
    }
    {
        props.onDelete && <Button onClick={props.onDelete} 
            value="Delete"
            className={`mini text-center text-red`}
        />
    }
</div>
    
  )
}

export default ControlPanel;